import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FactoryService } from '../facts/factory.service';
import { Factopia, Factsier, Factsheet, Factum, Bookmark } from '../facts/facts.module';
import { GlobalRevealLevelService } from '../global-vars/global-reveal-level.service';
import { GlobalVarsModule } from '../global-vars/global-vars.module';


export class RevealDialogData {
  title: string;
  message: string;
  showOKBtn = true;
  showCancelBtn = false;
}

@Component({
  selector: 'app-reveal',
  templateUrl: './reveal.component.html',
  styleUrls: ['./reveal.component.scss']
})
export class RevealComponent implements OnInit {
  // data from MAT_DIALOG_DATA, with data.title, data.showCancelBtn, data.showOKBtn, data.message(not used directly, stringify facts)
  type: string; // from data.message in MAT_DIALOG_DATA, // override, with_facts_bkmark, with_facts, physland_principia
  dispType: string; // factopia, factsier (default), factsheet, factum, other. 
  title: string; // modify from data.title as needed.
  facts: FactoryService | Factopia | Factsier | Factsheet | Factum;
  bkmark: Bookmark; // can be from FactoryService if the singleton is used
  dmessage: string; // data.message, for override case only
  gReveal: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: RevealDialogData, private dialogRef: MatDialogRef<RevealComponent>, private _facts: FactoryService, private _grev: GlobalRevealLevelService, private _gvars: GlobalVarsModule) { }

  ngOnInit() {
    this.gReveal = this._grev.gReveal;
    // this._parseMsg(this.data.message);
    const o = JSON.parse(this.data.message);
    this.type = o.type;
    if (this.type=='override') { this.title = this.data.title; this.dispType = 'dialog'; this.dmessage = o.msg; return; } 
    if (this.type=='with_facts_bkmark') { return; }  // To-be-implemented
    if (this.type=='with_facts') { return; } // To-be-implemented
    // default use case, with singleton FactoryService
    this.dmessage = '';
    this.bkmark = this._facts.bkmark;
    this.dispType = 'factsier';
    this.facts = this._facts.findFactsier(this.bkmark.factsier);
    this.title = this.facts.title;
    return;
  }

  get showoverride(): Boolean { return this.type=='override'; }
  get showprincipia(): Boolean { return this.type=='physland_principia'; }
  c2rv(n:any): number { return 10-Math.floor(n); }
  // c2step(n:any): number { return 1+Math.floor(n); }

  setReveal(x: number): void { this._gvars.setReveal(x); this.gReveal=this._grev.gReveal; }

  passReveal(factA: Factum[]): Factum[] { return factA.filter(f => ( (f.importance + this.gReveal) >9 ) ); }

  // c2step(n:any): number { console.log(typeof(n)); return 1+Math.floor(n); }

  // private _parseMsg(msg: string): void {
  //   let o = JSON.parse(msg);
  //   if (o.type=='override') { }
  //   if (o.type=='override' || o.type=='default') { this.dmessage = o.message; return; }
  //   this.facts = o;
  //   return;
  // }

  close(): void { this.dialogRef.close(true); }

  print(divName:string): void { 
    // window.print(); 
    let w=window.open();
    w.document.write(document.getElementById(divName).innerHTML);
    w.print();
    w.close();
    return; 
  }

}
