import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapWord1LPipe } from './cap-word1-l.pipe';
import { SystemDispPipe } from './system-disp.pipe';
import { ValueRoundPipe } from './value-round.pipe';
import { NameHtmlDispPipe } from './name-html-disp.pipe';
import { UnitDispPipe } from './unit-disp.pipe';
import { PfxDispPipe } from './pfx-disp.pipe';
import { PfxUnitDispPipe } from './pfx-unit-disp.pipe';
import { ValueDispPipe } from './value-disp.pipe';
import { ValueUnitDispPipe } from './value-unit-disp.pipe';
import { StdErrDispPipe } from './std-err-disp.pipe';
import { StdErrRelDispPipe } from './std-err-rel-disp.pipe';
import { ValueStdErrorUnitDispPipe } from './value-std-error-unit-disp.pipe';
import { AngleDispPipe } from './angle-disp.pipe';
import { TemperatureDispPipe } from './temperature-disp.pipe';
import { Vec3dDispRectPipe } from './vec3d-disp-rect.pipe';
import { Vec3dDispCylPipe } from './vec3d-disp-cyl.pipe';
import { Vec3dDispSphPipe } from './vec3d-disp-sph.pipe';
import { Vec3dDispPipe } from './vec3d-disp.pipe';
import { PrefixDropListPipe } from './prefix-drop-list.pipe';


@NgModule({
  imports: [ CommonModule ],
  declarations: [ CapWord1LPipe, SystemDispPipe, ValueRoundPipe, NameHtmlDispPipe, UnitDispPipe, PfxDispPipe, PfxUnitDispPipe, ValueDispPipe, ValueUnitDispPipe, ValueStdErrorUnitDispPipe, StdErrRelDispPipe, StdErrDispPipe, ValueStdErrorUnitDispPipe, AngleDispPipe, TemperatureDispPipe, Vec3dDispRectPipe, Vec3dDispCylPipe, Vec3dDispSphPipe, Vec3dDispPipe, PrefixDropListPipe ],
  exports: [ CommonModule, CapWord1LPipe, SystemDispPipe, ValueRoundPipe, NameHtmlDispPipe, UnitDispPipe, PfxDispPipe, PfxUnitDispPipe, ValueDispPipe, ValueUnitDispPipe, ValueStdErrorUnitDispPipe, StdErrRelDispPipe, StdErrDispPipe, ValueStdErrorUnitDispPipe, AngleDispPipe, TemperatureDispPipe, Vec3dDispRectPipe, Vec3dDispCylPipe, Vec3dDispSphPipe, Vec3dDispPipe, PrefixDropListPipe ]
})
export class CommonPipeModule { }
