import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalVarsModule } from '../../global-vars/global-vars.module';
import { GlobalSigfigService } from '../../global-vars/global-sigfig.service';
import { GlobalSystemService } from '../../global-vars/global-system.service';
import { GlobalDigitService } from '../../global-vars/global-digit.service';
import { GlobalPhysConstsService } from 'src/app/numeric-library/invariant/global-phys-consts.service';

export class MonologData { // modeled from RevealDialogData
  title: string;
  message: string;
  showOKBtn = true;
  showCancelBtn = false;
}

@Component({
  selector: 'app-monolog',
  templateUrl: './monolog.component.html',
  styleUrls: ['./monolog.component.scss']
})
export class MonologComponent implements OnInit {
  // data from MAT_DIALOG_DATA, with data.title, data.showCancelBtn, data.showOKBtn, data.message(not used directly, stringify facts)
  type: string; // from data.message in MAT_DIALOG_DATA, // override, gsettings, physcons, scratchpad
  title: string; // modify from data.title as needed.
  dmessage: string; // data.message, for override case only
  gDigit: number;
  gSigfig: string;
  gSystem: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MonologData, private dialogRef: MatDialogRef<MonologComponent>, private _gsys: GlobalSystemService, private _gds: GlobalDigitService, private _gsig: GlobalSigfigService, private _gvars: GlobalVarsModule, private _gps: GlobalPhysConstsService) { }

  ngOnInit() {
    // this._parseMsg(this.data.message);
    const o = JSON.parse(this.data.message);
    this.type = o.type; // override, gsettings, physcons, scratchpad
    this.title = this.data.title; 
    this.dmessage = (this.type=='override')? o.msg : '';
    this.gDigit = this._gds.gDigit;
    this.gSigfig = this._gsig.gSigfig;
    this.gSystem = this._gsys.gSystem;
    return;
  }
  
  get showoverride(): Boolean { return this.type=='override'; }
  get showgsettings(): Boolean { return this.type=='gsettings'; }
  get showphyscons(): Boolean { return this.type=='physcons'; }
  get showscratchpad(): Boolean { return this.type=='scratchpad'; }

  close(): void { this.dialogRef.close(true); }

  setDigit(x: number): void { this._gvars.setDigit(x); this.gDigit=this._gds.gDigit; }
  setSigfig(x: string): void { this._gvars.setSigfig(x); this.gSigfig=this._gsig.gSigfig; }
  setSystem(x: string): void { 
    this._gvars.setSystem(x); 
    this._gps.physCons.chgSystem();
  }

  print(divName:string): void { 
    // window.print(); 
    let w=window.open();
    w.document.write(document.getElementById(divName).innerHTML);
    w.print();
    w.close();
    return; 
  }

}
