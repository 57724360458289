import { Pipe, PipeTransform } from '@angular/core';
import { CapWord1LPipe } from './cap-word1-l.pipe';
import { PhysConShell } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { PhysQty0 } from 'src/app/numeric-library/bbb/bbb.module';

@Pipe({ name: 'systemDisp' })
export class SystemDispPipe implements PipeTransform {

  transform(pq: PhysQty0|PhysConShell|string): string {
    const cw: CapWord1LPipe = new CapWord1LPipe();
    let str: string = '';
    if (pq instanceof PhysQty0 || pq instanceof PhysConShell) { str = pq.system; }
    else { str = <string>pq; }
    if (str=='si'||str=='s.i.'||str=='Si'||str=='S.i.'||str=='SI'||str=='S.I.'){ return 'SI'; }
    return cw.transform(str);
  }

}
