import { Pipe, PipeTransform } from '@angular/core';
import { Vector3d } from 'src/app/numeric-library/tensor/tensor.module';
import { ValueUnitDispPipe } from './value-unit-disp.pipe';
import { AngleDispPipe } from './angle-disp.pipe';

@Pipe({ name: 'vec3dDispCyl', pure: false }) // impure // Angular only detect object reference, not deep object mutation 
export class Vec3dDispCylPipe implements PipeTransform {

  transform(vec: Vector3d): string {
    const vdu: ValueUnitDispPipe = new ValueUnitDispPipe();
    const ad: AngleDispPipe = new AngleDispPipe();
    return "("+vdu.transform(vec.o)+", "+ad.transform(vec.p,vec.p.unit)+", "+vdu.transform(vec.z)+")"; 
  }
}

