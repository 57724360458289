import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniconComponent } from './unicon.component';
import { UniconAngleFigureSvgComponent } from './unicon-angle-figure-svg/unicon-angle-figure-svg.component';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ UniconComponent, UniconAngleFigureSvgComponent ],
  imports: [ CommonModule, CommonPipeModule, FormsModule ]
})
export class UniConModule { 
  constructor(@Optional() @SkipSelf() parentModule: UniConModule) {
    if (parentModule) { throw new Error('UniConModule is already loaded. Import it in the AppModule only'); }
  }

  static forRoot(): ModuleWithProviders {
    // console.log("instantiating UniConModule.forRoot");
    return {
      ngModule: UniConModule,
      providers: [ 
        // { provide: GlobalClipboardService, useValue: { cbmax: cbm, pconList: pclist } } // set clipboard/scratchPad max length 
      ]
    };
  }

}
