import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { FigureSvg, FigureProps } from 'src/app/visual/figure-svg/figure-svg';
import { PureMathService } from 'src/app/numeric-library/operator/pure-math.service';
import { PhysQty } from 'src/app/numeric-library/bbb/bbb.module';

class Kinematics1dFigureSvg extends FigureSvg {
  a: PhysQty; 
  d: PhysQty; 
  t: PhysQty; 
  v1: PhysQty; 
  v2: PhysQty; 
  // readonly xp = [0,1]; // positions for delta x
  // readonly v1p = [0,2];
  // readonly v2p = [1,3];
  // readonly ap = [4,5];

  constructor( input: {width:number, height:number, a: PhysQty, d: PhysQty, t: PhysQty, v1: PhysQty, v2: PhysQty, depth?:number} ) { 
    // const d = Math.floor(input['depth']); // for 3-D rendering in the future
    super({ width: Math.floor(input['width']), height: Math.floor(input['height']), xs: [], ys: [], zs: [] }); // input { width, height, depth, xs: number[], ys, zs

    this.a = input.a;
		this.d = input.d;
		this.t = input.t;
		this.v1 = input.v1;
    this.v2 = input.v2;
    this.setXsYs();
  }

	setXsYs(): void { // solution #1 or #2 if applicable
		// this.xp=[0,1];
		if(this.d.valueSI<0) {this.xs=[this.width,0];} else if (this.d.valueSI>0) {this.xs=[0,this.width];} else {this.xs=[0,0];} // scale delta x to width w if possible
		// next scale |vi| + |vf| to 0.4 w if delta d != 0
		const v1abs = Math.abs(this.v1.valueSI);
		const v2abs = Math.abs(this.v2.valueSI);
		let vs = 0.4*this.width/( v1abs+v2abs ); vs = (PureMathService.isNumeric(vs))?vs:0; // reset NaN and Infinity to zero
		// vi starts from xp[0] position, ends at the new point.
		this.xs.push(this.xs[0]+this.v1.valueSI*vs);
		// vf starts from xp[1] position, ends at the new point.
		this.xs.push(this.xs[1]+this.v2.valueSI*vs);
		// first rescale to fit width and shift upper left corner
		this.shiftX(-this.xmin);
		this.scaleX(0.76*this.width/this.xmax); // 24% left on two sides as margins

		// next, set acceleration a as arrow squarely inside the two middle points of the four values in xs, as if the two velocities are both pointing inwards, 0.30 of the total width
		const xctr = (4*this.xavg-this.xmin-this.xmax)/2;
		if(this.a.valueSI>0){ this.xs.push( xctr-this.width*0.15 ); this.xs.push( xctr+this.width*0.15 ); } else 
		if(this.a.valueSI<0){ this.xs.push( xctr+this.width*0.15 ); this.xs.push( xctr-this.width*0.15 ); } else { this.xs.push( xctr ); this.xs.push( xctr ); } 
		// first trial, not assuming both inwards, but then same acceleration have two very different lengths in the 2 alternative solutions
		// finally shift 0.12 width to set 0.76 in the middle
		this.shiftX(0.12*this.width);
		this.ys = [0.2*this.height,0.43*this.height,0.6*this.height,0.75*this.height,0.9*this.height];
		// this.dispOrder = ['d','i','f','a','t'];

    return;
  }
  
}

@Component({
  selector: 'app-kinematics1d-figure-svg',
  templateUrl: './kinematics1d-figure-svg.component.svg',
  styleUrls: ['./kinematics1d-figure-svg.component.scss']
})
export class Kinematics1dFigureSvgComponent implements OnInit, OnChanges {
  @Input() a: PhysQty;
  @Input() d: PhysQty;
  @Input() t: PhysQty;
  @Input() v1: PhysQty;
  @Input() v2: PhysQty;
  @Input() ta: PhysQty;
  @Input() v1a: PhysQty;
  @Input() v2a: PhysQty;
  @Input() solnCnt: number;
  @Input() inpNullv1: boolean;
  @Input() inpNullv2: boolean;
  @Input() inpColor: {v1:string,v2:string,d:string,a:string,t:string};
  @Input() colorScheme: {highlight:string, highlight2:string, otherborder:string };  // from parent component, = {highlight: '#ffff0099', highlight2: '#55dd1190', otherborder: '#ff0000' };
  @Input() color5comp: {a: string, d: string, t: string, v1: string, v2: string}; 
  figure: Kinematics1dFigureSvg;
  figure2: Kinematics1dFigureSvg;
  showPlot: boolean;
  showPlotButton: string;

  constructor() { }

  ngOnInit() { this.showPlot=false; this.showPlotButton="Show"; this.figRender(); }

  ngOnChanges() { this.figRender(); }

  figRender(): void {
    if (this.solnCnt > 0) {
      this.figure = new Kinematics1dFigureSvg( {width: this.figsize().width, height: this.figsize().height, a: this.a, d: this.d, t: this.t, v1: this.v1, v2: this.v2} );
    }
    if (this.solnCnt==2) {
      let thisv1 = (this.inpNullv1) ? this.v1a : this.v1;
      let thisv2 = (this.inpNullv2) ? this.v2a : this.v2;
      this.figure2 = new Kinematics1dFigureSvg( {width: this.figsize().width, height: this.figsize().height, a: this.a, d: this.d, t: this.ta, v1: thisv1, v2: thisv2} );
    }
    return;
  }

  figsize(): FigureProps { 
    const tw=document.getElementById("svgSolnKinematics1D").clientWidth; 
    const th = Math.max(210, Math.min(tw*0.4, 300)); // setting min/max height to 200/350px per solution
    return <FigureProps>{ width: tw, height: th }; 
  }

  togglePlot(): void { this.showPlot = !this.showPlot; this.showPlotButton=(this.showPlot)?"Hide":"Show"; }

}
