import { Component, OnInit } from '@angular/core';
import { GlobalDigitService } from 'src/app/service/global-vars/global-digit.service';
import { GlobalSigfigService } from 'src/app/service/global-vars/global-sigfig.service';
import { GlobalRevealLevelService } from 'src/app/service/global-vars/global-reveal-level.service';
import { GlobalSystemService } from 'src/app/service/global-vars/global-system.service';
import { GlobalPhysConstsService } from 'src/app/numeric-library/invariant/global-phys-consts.service';
import { GlobalVarsModule } from 'src/app/service/global-vars/global-vars.module';
// import { GlobalConstService } from 'src/app/numeric-library/global-const.service';
// import { GlobalDigitService, GlobalRevealLevelService, GlobalSigfigService, GlobalSystemService } from 'src/app/service/global-vars';
// import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';

@Component({
  selector: 'app-sys-settings',
  templateUrl: './sys-settings.component.html',
  styleUrls: ['./sys-settings.component.scss']
})
export class SysSettingsComponent implements OnInit {
  // @HostBinding('class')
  gDigit: number;
  gReveal: number;
  gSigfig: string;
  gSystem: string;

  // constructor(private _gds: GlobalDigitService, private _grev: GlobalRevealLevelService, private _gsig: GlobalSigfigService, private _gsys: GlobalSystemService ) { }
  constructor(private _gds: GlobalDigitService, private _grev: GlobalRevealLevelService, private _gsig: GlobalSigfigService, private _gsys: GlobalSystemService, private _gps: GlobalPhysConstsService, private _gvars: GlobalVarsModule) { }

  ngOnInit() {
    this.gDigit = this._gds.gDigit;
    this.gReveal = this._grev.gReveal;
    this.gSigfig = this._gsig.gSigfig;
    this.gSystem = this._gsys.gSystem;
  }

  // setDigit(x: number): void { console.log(this.gc); this._gds.setDigit(x); this.gDigit=this._gds.gDigit; }
  setDigit(x: number): void { this._gvars.setDigit(x); this.gDigit=this._gds.gDigit; }
  setReveal(x: number): void { this._gvars.setReveal(x); this.gReveal=this._grev.gReveal; }
  setSigfig(x: string): void { this._gvars.setSigfig(x); this.gSigfig=this._gsig.gSigfig; }
  setSystem(x: string): void { 
    this._gvars.setSystem(x); 
    this._gps.physCons.chgSystem();
  }

}
