import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpBarComponent } from './help-bar.component';
import { MonologBoxModule } from 'src/app/service/monolog-box/monolog-box.module';



@NgModule({
  declarations: [ HelpBarComponent ],
  imports: [ CommonModule, MonologBoxModule ],
  exports: [ HelpBarComponent ]
})
export class HelpBarModule { }
