import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { MatSnackBarModule, MatSnackBar, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { RevealComponent } from './reveal.component';
// import { RevealService } from './reveal.service'; // circular
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { RevealService } from './reveal.service';
// import { SnackBarService } from '../facts/snack-bar.service';
// import { SnackBarService } from './snack-bar.service';



@NgModule({
  declarations: [RevealComponent],
  providers: [ RevealService ],
  // providers: [ SnackBarService, RevealService, MatSnackBar, MatDialog ],
  // providers: [ MatSnackBar, MatDialog ],
  entryComponents: [ RevealComponent ],
  imports: [ CommonModule, MatDialogModule, FormsModule ] // MatSnackBarModule, BrowserAnimationsModule, MatButtonModule, BrowserModule, 
})
export class RevealModule { 
  // sbs: SnackBarService;

  constructor(@Optional() @SkipSelf() parentModule: RevealModule) {
  // constructor(@Optional() @SkipSelf() parentModule: RevealModule, sbs: SnackBarService) {
    if (parentModule) { throw new Error('RevealModule is already loaded. Import it in the AppModule only'); }
    console.log("instantiating RevealModule.forRoot");
  }

  static forRoot( ): ModuleWithProviders {
    return {
      ngModule: RevealModule,
      providers: [
        // forRoot will try to push these values to the class(es), use same property name of the classes
        // { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500} },
        // { provide: MatSnackBar }, 
        { provide: MatDialog }
      ]
    };
  }

}

