import { NgModule, Optional, SkipSelf } from '@angular/core'; // , ModuleWithProviders // this does not work here.
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CommonModule } from '@angular/common';
import { SnackBarService } from './snack-bar.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
// import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PureMathService } from 'src/app/numeric-library/operator/pure-math.service';


// Factum // one row of Fact, that goes with an equation, or a step, or a stand alone argument
// not used - Factlet // ???
// Factsheet // table of Facta (Factums)
// Factsier // dossier of Factsheets 
// Factopia // a book, or clipboard of Factsiers, of different chapters/subjects
// FactoryService extends Factopia, with a bookmark 

// authorId: 0-unknown, 1-stein, 2-system

export interface FactIdty { // identify/identity
  // readonly type: 'identity';
  id?: number; // userid
  uname?: string; // username for author, reader, person. // a lookup?
  name?: string; // username for everything else 
  sname?: string; // screenname // a lookup?
  type?: string; 
  note?: string;
  // title?: string; 
}

export class Bookmark {
  // readonly type: 'bookmark';
  reader?: FactIdty; // user
  book?: FactIdty; // factopia / book
  factsier?: FactIdty; // factsier / chapter / report
  sheet?: FactIdty; // factsheet
  fact?: FactIdty; // factum, row

  constructor(input: { reader?: FactIdty, book?: FactIdty, factsier?: FactIdty, sheet?: FactIdty, fact?: FactIdty }) {
    this.reader = (input.reader)?input.reader:{};
    this.book = (input.book)?input.book:{};
    this.factsier = (input.factsier)?input.factsier:{};
    this.sheet = (input.sheet)?input.sheet:{};
    this.fact = (input.fact)?input.fact:{};
  }

  dcopy(): Bookmark { return new Bookmark( {reader: { ...this.reader }, book: { ...this.book }, factsier: { ...this.factsier }, sheet: { ...this.sheet }, fact: { ...this.fact } } ); }
}

export class FactBasic {
  // common attributes and basic methods
  author: FactIdty; 
  title: string; 
  topicA: string[];
  sysDesc: string; // description by system
  userDesc: string; // description by user
  cdate: Date; // creation date
  mdate: Date; // modified date
  bkmark: Bookmark; // to propagate to Factum, Factsheet, Factsier, Factopia. 

  constructor(input: any) { // input can have author: {FactIdty} attribute, or authodId: number, or autherName: string, or authorSName: string
    this.author = this.getFactIdty( 'author', input ); 
    this.title = ( input.title )? this.getStr(input.title) : '' ;
    this.topicA = ( input.topicA )? this.getStrA( input.topicA ) : [] ;
    this.sysDesc = ( input.sysDesc )? this.getStr(input.sysDesc) : '' ;
    this.userDesc = ( input.userDesc )? this.getStr(input.userDesc) : '' ;
    this.cdate = ( input.cdate ) ? this.getDate(input.cdate) : this.getDate('') ; 
    this.mdate = ( input.mdate ) ? this.getDate(input.mdate) : this.getDate('') ; 
    this.bkmark = (input.bkmark) ? input.bkmark.dcopy() : null; 
  }

  dcopy(): FactBasic { return new FactBasic( { author: { ...this.author }, title: this.title, topicA: [ ...this.topicA ], sysDesc: this.sysDesc, userDesc: this.userDesc, cdate: new Date(this.cdate.valueOf()), mdate: new Date(this.mdate.valueOf()), bkmark: this.bkmark.dcopy() } ); }

  getStr(s: any ): string { 
    if ( !s || s=='') return '';
    if ( typeof(s)=='string' || (s instanceof String) ) return <string>s.trim(); 
    return '';
  } 

  getNum(x: any ): number { 
    if ( !x || x=='') return 0;
    if ( typeof(x)=='boolean' || (x instanceof Boolean) ) return (x)?1:0; 
    if ( PureMathService.isNumeric(x) ) return <number>x;
    if ( typeof(x)=='number' || (x instanceof Number) ) return <number>x; 
    return -1;
  } 

  getDate(d: string | Date | number ): Date { 
    if (d instanceof Date) return new Date(d);
    if (!d || d == null ) return new Date();
    return new Date(d);
  } 

  getStrA(a: any) : string[] {
    if (a instanceof Array) return a;
    let arr = [];
    if ( typeof(a)=='string' || (a instanceof String) ) { arr.push(a); return arr; }
    return arr;
  }

  getNumA(a: any) : number[] {
    if (a instanceof Array) return a;
    let arr = [];
    if ( typeof(a)=='number' || (a instanceof Number) ) { arr.push(a); return arr; }
    return arr;
  }

  getArr(a: any) : any[] {
    if (a instanceof Array) return a;
    let arr = []; arr.push(a); return arr;
  }

  getFactIdty( field: string, input: any): FactIdty { // field can be 'author', 'sheet', 'factsier', 'book', etc
    let res = <FactIdty>{ id: 0, name: '' }
    let inp = input[field];
    // do some DB lookup later
    // const fid=field+'Id'; 
    // const fname=field+'Name'; 
    // const fsname=field+'SName'; 
    // const ftype=field+'Type'; 
    // const fnote=field+'Note'; 
    if (field == 'author' || field == 'reader') {
      // need lookup and some other rules
      // order: id, name, sname
      // authorId: 0-unknown, 1-stein, 2-system 
      // authorName (username): 'unknown', 'Einstein', 'PhysLand system', 
      // authorSName (screenName): 'no-name', '1stein', 'physlands'

      // res[fid] = ( inp[fid] )? this.getNum(inp[fid]) : null ;
      // res[fname] = ( inp[fname] )? this.getStr(inp[fname]) : null ;
      // res[fsname] = ( inp[fsname] )? this.getStr(inp[fsname]) : null ;
      res = <FactIdty>Object.assign(res, {uname: '', sname: ''});
      res.id = ( inp.id )? this.getNum(inp.id) : null ;
      res.name = ( inp.name )? this.getStr(inp.name) : null ;
      res.sname = ( inp.sname )? this.getStr(inp.sname) : null ;

      if (res.id == 0) { res.uname = 'unknown'; res.name = 'unknown'; res.sname = 'no-name' } else 
      if (res.id == 1) { res.uname = 'einstein'; res.name = 'Einstein'; res.sname = '1stein' } else 
      if (res.id == 2) { res.uname = 'system'; res.name = 'PhysLand system'; res.sname = 'physlands' } else 
      if (res.uname == 'unknown') { res.id = 0; res.name = 'unknown'; res.sname = 'no-name' } else 
      if (res.uname == 'einstein') { res.id = 1; res.name = 'Einstein'; res.sname = '1stein' } else 
      if (res.uname == 'system') { res.id = 2; res.name = 'PhysLand system'; res.sname = 'physlands' } // else 
      return res;
    }
    // default, all other fields except author
    res = <FactIdty>Object.assign(res, { type: '', note: '' });
    res.id = ( inp.id )? this.getNum(inp.id) : 0 ;

    let defname: string; let deftype: string; let defnote: string;
    if (field=='book') { defname='no-name'; deftype='system'; defnote = 'My Factopia'} else 
    if (field=='sheet' || field=='factsier') { defname='no-name'; deftype='solution'; defnote = 'solution'} 
    else { defname='no-name'; deftype=''; defnote = '' } 
    
    // res.name = ( inp[fname] )? this.getStr(inp[fname]) : defname ;
    // res.type = ( inp[ftype] )? this.getStr(inp[ftype]) : deftype ;
    // res.note = ( inp[fnote] )? this.getStr(inp[fnote]) : defnote ;
    res.name = ( inp.name )? this.getStr(inp.name) : defname ;
    res.type = ( inp.type )? this.getStr(inp.type) : deftype ;
    res.note = ( inp.note )? this.getStr(inp.note) : defnote ;
    return res;
  }
}

export class Factum extends FactBasic{
  fact: FactIdty; // id, name, type (warning, success, error, info, like sweetalert)
  importance: number; // (1-10), complement to reveal level of 0-9
  eqn: FactIdty; // lookup??
  // authorId: number; 
  // authorSName: string; // screenName, should be a lookup
  // title: string; 
  // topicA: string[];
  // sysDesc: string; // description by system
  // userDesc: string; // description by user
  // cdate: Date; // creation date
  // mdate: Date; // modified date
  // bkmark: Bookmark;

  constructor(input: any) {
    super(input)
    this.fact = this.getFactIdty( 'fact', input );
    this.importance = ( input.importance ) ? this.getNum(input.importance) : 1 ; // importance: (1-10), complement to reveal level of 0-9 
    this.eqn = this.getFactIdty( 'eqn', input ); 
    // this.bkmark = (input.bkmark) ? input.bkmark.dcopy() : null; 
  }

  // dcopy(): Factum { return new Factum( Object.assign( super.dcopy(), {fact: Object.assign({}, this.fact), eqn: Object.assign({},this.eqn)} ) ); }
  // dcopy(): Factum { return new Factum( { ...super.dcopy(), fact: { ...this.fact }, importance: this.importance, eqn: { ...this.eqn }, bkmark: this.bkmark.dcopy() } ); }
  dcopy(): Factum { return new Factum( { ...super.dcopy(), fact: { ...this.fact }, importance: this.importance, eqn: { ...this.eqn } } ); }

}

// export class Factlet {
//   // export class Factlet extends Factum {
//   // Factum vs Factlet - factum is mainly for an equation, a step, while factlet is for part of a solution to a question, or alternative solution
//   row: number; // as a row in a table, of a Factsheet
//   name: string; // unique?
//   authorId: number; 
//   authorSName: string; // screenName, should be a lookup
//   title: string; 
//   topicA: string[];
//   eqnId: number;
//   qIdA: number[];
//   qTypeA: string[]; // question Type: system, Quiz Answer, Exam Answer, ?? Level? 
//   systemDescA: Factum[];
//   altDescA: Factum[];
//   solnIdA: number[];
//   cdate: Date; // creation date 
//   mdate: Date; // modified date

//   constructor(input: any) {
//     this.row = (input.row && PureMathService.isNumeric(input.row))?input.row:0;
//     this.authorId = input.authorId;
//     // .......
//   }

//   dcopy(): Factlet { return new Factlet(this); }
//   addFactum(f:Factum, type: string = 'system'): void { 
//     if (type=='system') { this.systemDescA.push(f); } else { this.altDescA.push(f);} // or push f.dcopy() ???
//   }

// }

export class Factsheet extends FactBasic{
  sheet: FactIdty; // use in Factopia, as in sheet number within an encylcopedia
  // sheetName: string; // unique?
  // sheetType: string; // 
  // sheetNote: string; 
  // each topic (see Factsier next) on physics.land would have 1 or more factsheet(s) for instant calculations. (About tab has none.)
  solnId: number; // as a standalone solution sheet to some problems.
  qIdA: number[]; // this could provide solution to a list of questions
  qTypeA: string[];
  
  factA: Factum[];
  // bkmark: Bookmark;

  constructor(input: any) { 
    super(input)
    this.sheet = this.getFactIdty( 'sheet', input ); 
    // this.sheetId = ( input.sheetId )? this.getNum(input.sheetId) : 0 ; // sheetId: 0-unknown, 
    // this.sheetName = ( input.sheetName )? this.getStr(input.sheetName) : 'no-name' ;
    // this.sheetType = ( input.sheetType )? this.getStr(input.sheetType) : 'solution' ;
    // this.sheetNote = ( input.sheetNote )? this.getStr(input.sheetNote) : 'solution' ;

    this.solnId = ( input.solnId )? this.getNum(input.solnId) : 0 ; // solnId: 0-unknown, 
    this.qIdA = ( input.qIdA )? this.getNumA(input.qIdA) : [] ; 
    this.qTypeA = ( input.qTypeA )? this.getStrA(input.qTypeA) : [] ; 

    this.factA = ( input.factA )? <Factum[]>this.getArr(input.factA) : <Factum[]>[] ; 
    // this.bkmark = (input.bkmark) ? input.bkmark.dcopy() : null; 
    // start with blank sheet, empty list factA
    // factA is an ordered list of steps and reasonings. Entire sheet should be created and erased together.
  }

  dcopy(): Factsheet { 
    let arr: Factum[];
    this.factA.forEach(function(f: Factum){ arr.push(f.dcopy()); });
    return new Factsheet( { ...super.dcopy(), sheet: { ...this.sheet }, solnId: this.solnId, qIdA: [ ...this.qIdA ], qTypeA: [ ...this.qTypeA ], factA: arr } ); 
  }


}

export class Factsier extends FactBasic { // dossier of Factsheets
  factsier: FactIdty; // id, like a chapter/report, could be a section or a test/exam, 
  // factsierName: string; // for physics.land defaults, it's the topic for each tab. 
  // factsierType: string; 
  // factsierNote: string;
  // each topic on physics.land has a dossier to capture the calculations on that topic page
  solnId: number;
  examIdA: number[];
  examTypeA: string[];

  factsheetA: Factsheet[];
  // bkmark: Bookmark;

  constructor(input: any) { 
    super(input);
    this.factsier = this.getFactIdty( 'factsier', input ); 
    // this.factsierId = ( input.factsierId )? this.getNum(input.factsierId) : 0 ; // factsierId: 0-unknown, 
    // this.factsierName = ( input.factsierName )? this.getStr(input.factsierName) : 'no-name' ;
    // this.factsierType = ( input.factsierType )? this.getStr(input.factsierType) : 'solution' ;
    // this.factsierNote = ( input.factsierNote )? this.getStr(input.factsierNote) : 'solution' ;

    this.solnId = ( input.solnId )? this.getNum(input.solnId) : 0 ; // solnId: 0-unknown, 
    this.examIdA = ( input.examIdA )? this.getNumA(input.examIdA) : [] ; 
    this.examTypeA = ( input.examTypeA )? this.getStrA(input.examTypeA) : [] ; 

    this.factsheetA = ( input.factsheetA )? <Factsheet[]>this.getArr(input.factsheetA) : <Factsheet[]>[] ; 
    // start with blank dossier, empty list factsheetA
    // factsheetA contains different factsheets, which can be retrived, and/or removed individually. 
    // this.bkmark = (input.bkmark) ? input.bkmark.dcopy() : null; 
  }

  dcopy(): Factsier { 
    let arr: Factsheet[];
    this.factsheetA.forEach(function(f: Factsheet){ arr.push(f.dcopy()); });
    return new Factsier( { ...super.dcopy(), factsier: { ...this.factsier }, solnId: this.solnId, examIdA: [ ...this.examIdA ], examTypeA: [ ...this.examTypeA ], factsheetA: arr } ); 
  }

  findFactsheet(search: FactIdty): Factsheet {
    let res: Factsheet;
    this.factsheetA.forEach(function(d: Factsheet){
      if (res) return; // skip function forEach iteration, taking the first result only
      if (search.name && search.name == d.sheet.name) { res=d; } else 
      if (search.id && search.id > -1 && d.sheet.id === search.id) { res=d; } // else 
    });
    return res; 
  }

  addFactsheet(s:Factsheet): void { 
    // if exist, merge, otherwise insert
    let match = this.findFactsheet(s.sheet);
    if (!match) { this.factsheetA.push(s); } 
    else { 
      console.log(`Factsheet already exist. Not added. id= ${match.sheet.id}, name= ${match.sheet.name}`);
    }
  } 

  replaceFactsheet(s: Factsheet, search: FactIdty): void {
    let match = 0; //
    this.factsheetA.forEach(function(d: Factsheet, i: number){
      if (match>0) return; // skip function forEach iteration, taking the first result only
      if (search.name && search.name == d.sheet.name) { this.factsheetA[i] = s; match++;  } else 
      if (search.id && search.id > -1 && d.sheet.id===search.id) { this.factsheetA[i] = s; match++; } // else 
    }.bind(this)); // can use this.factsheetA as this, no need to bind?
    return; 
  }

}

export class Factopia extends FactBasic {
  book: FactIdty; // id
  // bookName: string; // unique?
  // bookType: string;
  // bookNote: string;

  solnId: number;

  factsierA: Factsier[];
  // bkmark: Bookmark;

  constructor(input: any) { 
    super(input);
    this.book = this.getFactIdty( 'book', input ); 

    this.solnId = ( input.solnId )? this.getNum(input.solnId) : 0 ; // solnId: 0-unknown, 

    this.factsierA = ( input.factsheetA )? <Factsier[]>this.getArr(input.factsheetA) : <Factsier[]>[] ; 
    // start with blank book, empty list factsierA
    // this.bkmark = (input.bkmark) ? input.bkmark.dcopy() : null; 
  }

  dcopy(): Factopia { 
    let arr: Factsier[];
    this.factsierA.forEach(function(f: Factsier){ arr.push(f.dcopy()); });
    return new Factopia( { ...super.dcopy(), book: { ...this.book }, solnId: this.solnId, factsierA: arr } ); 
  }

  findFactsier(search: FactIdty): Factsier {
    let res: Factsier;
    this.factsierA.forEach(function(d: Factsier){
      if (res) return; // skip function forEach iteration, taking the first result only
      if (search.name && search.name == d.factsier.name) { res=d; } else 
      if (search.id && search.id > -1 && d.factsier.id===search.id) { res=d; } // else 
    });
    return res;
  }

  addFactsier(s:Factsier): void { 
    // if exist, merge, otherwise insert
    let match = this.findFactsier(s.factsier);

    if (!match) { 
      console.log(`Factsier no match.  s has id= ${s.factsier.id}, name= ${s.factsier.name}`);
      console.log(s);
      this.factsierA.push(s); 
    } else { 
      console.log(`Factsier already exist. Not added. id= ${match.factsier.id}, name= ${match.factsier.name}`);
    }
  } 

  replaceFactsier(s: Factsier, search: FactIdty): void {
    let match = 0; //
    this.factsierA.forEach(function(d: Factsier, i: number){
      if (match>0) return; // skip function forEach iteration, taking the first result only
      if (search.name && search.name == d.factsier.name) { this.factsierA[i] = s; match++;  } else 
      if (search.id && search.id > -1 && d.factsier.id===search.id) { this.factsierA[i] = s; match++; } // else 
    }.bind(this)); // can use this.factsierA as this, no need to bind?
    return; 
  }

}



@NgModule({
  declarations: [],
  providers: [ SnackBarService ],
  imports: [ CommonModule, MatSnackBarModule, BrowserAnimationsModule ] // MatDialogModule, FormsModule, MatButtonModule, BrowserModule, 
})
export class FactsModule { 
  constructor(@Optional() @SkipSelf() parentModule: FactsModule) {
  // constructor(@Optional() @SkipSelf() parentModule: FactsModule, sbs: SnackBarService) {
    if (parentModule) { throw new Error('FactsModule is already loaded. Import it in the AppModule only'); }
    console.log("instantiating FactsModule.forRoot");
  }
  
  static forRoot( ): ModuleWithProviders {
    return {
      ngModule: FactsModule,
      providers: [
        // forRoot will try to push these values to the class(es), use same property name of the classes
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500} },
        { provide: MatSnackBar } // , 
        // { provide: MatDialog }
      ]
    };
  }
  
}
