import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms'; // <-- NgModel lives here
import { AppRoutingModule } from './app-routing.module';
import { GlobalVarsModule } from './service/global-vars/global-vars.module';
// import { HttpClientModule } from '@angular/common/http';
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { InMemoryDataService } from './in-memory-data.service';

import { AppComponent } from './app.component';
import { setAppInjector } from './app-injector';
import { FactoryService } from './service/facts/factory.service';
import { TopnavComponent } from './common/topnav/topnav.component';
// import { SysSettingsComponent } from './common/sys-settings/sys-settings.component';
import { SysSettingsModule } from './common/sys-settings/sys-settings.module';
import { ClipboardComponent } from './common/clipboard/clipboard.component';
// import { PhysConstantsComponent } from './common/phys-constants/phys-constants.component';
import { PhysConstantsModule } from './common/phys-constants/phys-constants.module';
import { AboutComponent } from './topics/about/about.component';
import { GlobalDigitService } from './service/global-vars/global-digit.service';
import { GlobalRevealLevelService } from './service/global-vars/global-reveal-level.service';
import { GlobalSigfigService } from './service/global-vars/global-sigfig.service';
import { GlobalSystemService } from './service/global-vars/global-system.service';
import { PureMathService } from './numeric-library/operator/pure-math.service';
import { PqtyMathService } from './numeric-library/operator/pqty-math.service';
import { BbbModule } from './numeric-library/bbb/bbb.module';
import { GlobalPhysUnitsService } from './numeric-library/invariant/global-phys-units.service';
import { CommonPipeModule } from './pipe/common-pipe/common-pipe.module';
import { ScratchPadModule } from './common/scratch-pad/scratch-pad.module';
// import { ScratchPadComponent } from './topics/scratch-pad/scratch-pad.component'; // in scratchPadModule now
import { GlobalPhysConstsService } from './numeric-library/invariant/global-phys-consts.service';
import { GlobalClipboardService, GlobalClipboardConfig } from './common/scratch-pad/global-clipboard.service';
import { TensorModule } from './numeric-library/tensor/tensor.module';
// import { TensorOpService } from './numeric-library/tensor/tensor-op.service';
import { UniConModule } from './topics/unicon/unicon.module';
// import { UniconComponent } from './topics/unicon/unicon.component'; // in UniConModule now
import { FigureSvgModule } from './visual/figure-svg/figure-svg.module';
// import { SvgDefsService } from './visual/figure-svg/svg-defs.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FactsModule } from './service/facts/facts.module';
import { RevealModule } from './service/reveal/reveal.module';
// import { HelpBarComponent } from './common/help-bar/help-bar.component'; // in HelpBarModule now
import { HelpBarModule } from './common/help-bar/help-bar.module';
import { TrigsModule } from './topics/trigs/trigs.module';
import { EeeeService } from './service/eqns/eeee.service';
// import { LinearalgebraComponent } from './topics/linearalgebra/linearalgebra.component';
import { LinearalgebraModule } from './topics/linearalgebra/linearalgebra.module';
import { KinematicsModule } from './topics/kinematics/kinematics.module';
import { GlobalSiteTitleService } from './service/global-vars/global-site-title.service';
import { MonologBoxModule } from './service/monolog-box/monolog-box.module';
import { QuadraticModule } from './topics/quadratic/quadratic.module';



@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BbbModule.forRoot(),
    GlobalVarsModule.forRoot(), // initial values are set by individual services below(GlobalDigitService, GlobalRevealLevelService, etc). Cannot be inside GlobalVarsModule because of circular dependencies.
    FactsModule.forRoot(),
    RevealModule.forRoot(),
    MonologBoxModule,
    HelpBarModule,
    // MatToolbarModule,
    // MatIconModule,
    // MatButtonModule,
    PhysConstantsModule,
    SysSettingsModule,
    ScratchPadModule.forRoot(<GlobalClipboardConfig>{maxlen: 50}), // maxlen, pconlist?
    CommonPipeModule,
    FigureSvgModule,
    UniConModule.forRoot(),
    BrowserAnimationsModule,
    TrigsModule.forRoot(),
    QuadraticModule,
    TensorModule.forRoot(),
    LinearalgebraModule.forRoot(),
    KinematicsModule.forRoot()
    // HttpClientModule,
    // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // and returns simulated server responses.
    // Remove it when a real server is ready to receive requests.
    // HttpClientInMemoryWebApiModule.forRoot(
    //   InMemoryDataService, { dataEncapsulation: false }
    // )
  ],
  declarations: [
    AppComponent,
    TopnavComponent,
    ClipboardComponent,
    // PhysConstantsComponent, // in PhysConstantsModule now
    // SysSettingsComponent, // in SysSettingsModule now
    AboutComponent,
    // HelpBarComponent, // in HelpBarModule now
    // ScratchPadComponent, // in ScratchPadModule now
    // UniconComponent, // in UniConModule now
    // LinearalgebraComponent, // in LinearalgebraModule now
    // Kinematics1dComponent, // in Kinematics1dModule now
  ],
  providers: [
    FactoryService,
    { provide: GlobalSiteTitleService, useValue: { gTitle: "Physics.Land 101" } },
    // GlobalSiteTitleService,
    { provide: GlobalDigitService, useValue: { gDigit: 4 } },
    // GlobalDigitService,
    { provide: GlobalRevealLevelService, useValue: { gReveal: 6 } },
    // GlobalRevealLevelService,
    { provide: GlobalSigfigService, useValue: { gSigfig: 'sig.fig.' } },
    // GlobalSigfigService,
    { provide: GlobalSystemService, useValue: { gSystem: 'SI' } },
    // GlobalSystemService,
    MatSnackBar,
    MatDialog,
    PureMathService,
    GlobalPhysUnitsService,
    GlobalPhysConstsService,
    GlobalClipboardService,
    PqtyMathService,
    EeeeService
    // TensorOpService,
    // SvgDefsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  /**
   * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
   * This is good to prevent injecting the service as constructor parameter, which needs to be passed onto descendant classes
   */
  constructor(injector: Injector) {
    setAppInjector(injector);
    console.warn(`loading appModule`);
  }
}

