import { Component, OnInit, Input } from '@angular/core';
import { Vector2d } from 'src/app/numeric-library/tensor/tensor.module';
import { FigureSvg, FigureProps } from '../figure-svg';

class Vec2dSumFigureSvg extends FigureSvg {
  vecs: Vector2d[];

  constructor( input: {width:number, height:number, vecs: Vector2d[], depth?:number} ) { 
    // const d = Math.floor(input['depth']); // for 3-D rendering in the future
    super({ width: Math.floor(input['width']), height: Math.floor(input['height']), xs: [], ys: [], zs: [] }); // input { width, height, depth, xs: number[], ys, zs

    this.vecs = input.vecs;
    this.setXsYs();
  }

  // copied from vec2d-sum.component.ts
  private _vecValidChk(vec:Vector2d): boolean { return (vec && vec.x && vec.y && vec.r && vec.p && vec.x.value!==null && vec.y.value!==null && vec.r.value!==null && vec.p.value!==null && vec.p.valueSI >=0 && vec.p.valueSI < 2*Math.PI) ; }

	setXsYs(): void { 
    // scale = min( maxwidth/xrange, maxheight/yrange)   maxwidth = 0.86 width = r*width, 
    // Shift min/max to 0.12 and 0.88 of height  //  NOT Shift cm to width/2, height/2  (shift w/2-cmx, h/2-cmy). CM is at 1/3 from base

    let xtot = 0;
    let ytot = 0;
    this.xs.push(0);
    this.ys.push(0); 

    this.vecs.forEach(function(vec:Vector2d, i:number){
      if(i>0 && this._vecValidChk(vec)){ 
        xtot +=vec.x.valueSI; 
        this.xs.push(xtot); 
        ytot -=vec.y.valueSI; // y is positive downwards
        this.ys.push(ytot); 
      } 
    }.bind(this));

    const r=0.95;
    const scale = Math.min(r*this.width/this.xrange, r*this.height/this.yrange);
    this.scaleXY(scale);
    this.shiftX( (this.width-this.xmin-this.xmax)/2 );
    this.shiftY( (this.height-this.ymin-this.ymax)/2 );
    return;
  }
  
}

@Component({
  selector: 'app-vec2d-sum-figure-svg',
  templateUrl: './vec2d-sum-figure-svg.component.svg',
  styleUrls: ['./vec2d-sum-figure-svg.component.scss']
})
export class Vec2dSumFigureSvgComponent implements OnInit {
  @Input() vecs: Vector2d[];
  @Input() colorVector: string[];
  figure: Vec2dSumFigureSvg;

  get validVecs(): Vector2d[] { const vchk = this._vecValidChk; return this.vecs.filter( vec => vchk(vec) ); }
  get validColors(): string[] { 
    // generate sequence, last one is the sum, always black, others need to cycle through
    const res: string[] =[];
    this.vecs.forEach(function(vec:Vector2d, i:number){
      if (i==0) return;
      if (this._vecValidChk(vec)) { res.push(this.colorVector[i%7]); } // cycle through the 7 choices available. Notice the first one used is index 1, ie. the second in colorVector list, as dictated in ved2d-sum component
    }.bind(this));
    res.push('rgba(55, 55, 55, 1)');
    return res;
    // return this.colorVector.filter( function(c:string, i:number){ return this._vecValidChk(this.vecs[i]); }.bind(this) ); 
  }

  readonly figwidth = 400;
  readonly figheight = 400;

  constructor() { }

  ngOnInit() { this.figRender();  }
  ngDoCheck() { this.figRender(); }
  figRender(): void {
    this.figure = new Vec2dSumFigureSvg( { width: this.figsize().width, height: this.figsize().height, vecs: this.validVecs } );
    return;
  }

  figsize(): FigureProps { 
    const tw=document.getElementById("divVec2dSumFigSvg").clientWidth; 
    const th = Math.max(Math.min(tw, 550),400); // setting max and min height
    return <FigureProps>{ width: tw, height: th }; 
  }

  // copied from vec2d-sum.component.ts
  private _vecValidChk(vec:Vector2d): boolean { return (vec && vec.x && vec.y && vec.r && vec.p && vec.x.value!==null && vec.y.value!==null && vec.r.value!==null && vec.p.value!==null && vec.p.valueSI >=0 && vec.p.valueSI < 2*Math.PI) ; }
}
