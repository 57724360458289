import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { FigureSvg, FigureProps } from '../figure-svg';
import { Parabola } from 'src/app/numeric-library/geometry/geometry.module';

class ParabolaFigureSvg extends FigureSvg {
  /* Basic construction takes three pairs of (x,y). 
  The two outside ones are the end points. The middle one together with the outside two 
  determines the "handle" of the Bezier curve, basically where the two tangents meet.
  Detailed calc:
  // assume (0,0) and (x2,y2) are the outside points. Need to shift (x0,y0) in the end. 
  // First, the handle (xc,yc) is given by 
  // xc = x2 + (b-m)/2/a  where m is the slope between points 0 and 2 (the outside points). Slope m is invariant under translation
  // yc = b*xc = b(x2 + (b-m)/2/a)
  // The translation gives 
  // x -> x+x0
  // y -> y+y0
  // a -> a
  // b -> b-2a*x0
  // c=0 -> ax0^2 - bx_0 + y0

  Using a direct method now. See bx and by.
  */
  parabola: Parabola;
  ss: number[]; // corresponding slopes, which is invariant under translations, and XYscale (not X or Y scale by itself.)
  colormap: {f:string, xi:string, xf:string, v:string, r1:string, r2:string } = {f:"#000000", xi:"#0000ff", xf:"#ff0000", v:"#000000", r1:"rgba(153, 102, 255, 1)", r2:"#ff00ff" } ; // "#7755bb"
  // from vec2d-sum  colorVector = [ 'rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(105, 212, 64, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)' ]; 


  // functions and values for building xs, ys
  private _xwin: number[]; // record the current xmin/max values along the steps
  private _ywin: number[]; // record the current xmin/max values along the steps
  private get _xwinrange(): number { return this._xwin[1]-this._xwin[0]; }
  private get _ywinrange(): number { return this._ywin[1]-this._ywin[0]; }
  private get _xwinavg(): number { return (this._xwin[1]+this._xwin[0])/2; }
  private get _ywinavg(): number { return (this._ywin[1]+this._ywin[0])/2; }
  private _threshold: number = 1.2; // 10% each side
  private _xinchk(v:number):boolean { return 2*Math.abs(v-this._xwinavg) <= this._threshold*this._xwinrange; } // check v is within xrange
  private _yinchk(v:number):boolean { return 2*Math.abs(v-this._ywinavg) <= this._threshold*this._ywinrange; } // check v is within yrange

  constructor( input: { parabola: Parabola, width:number, height:number, depth?:number, colormap?: {f:string, xi:string, xf:string, v:string, r1:string, r2:string } } ) { 
    super({ width: Math.floor(input['width']), height: Math.floor(input['height']), xs: [], ys: [], zs: [] }); // input { width, height, depth, xs: number[], ys, zs
    this.parabola = input.parabola;
    if (input.colormap) { this.colormap = { ...input.colormap }; }
    this.setXsYs();
  }

  setXsYs(): void {
    this.parabola.sortXYs(); // just make sure
    const x=this.parabola.xs; const y=this.parabola.ys; const s=this.parabola.ss; let ii=1; // whether to use x[1] or x[2], depends on 'data'
    // console.log(x);
    // console.log(y);
    // console.log(s);
    if (this.parabola.inptype=='d'||this.parabola.inptype=='data') {
      ii = (x[2] && x[2].value != null) ? 2 : 1; 
      // console.log(x);
      // console.log(y);
      // console.log(s);
    }
    /*
    These are the points potentially will be needed
    color   xs   ys      notes   criteria 
    ---------------------------------------------
    blue    x0   y0             
    red     x1   y1   
    green   h    k       vertex   if h within 10% of xrange. If include this, update yrange (but not xrange)
    magenta r1   0       root1    if r1 within 10% of xrange AND 0 within 10% of yrange
    orange  r2   0       root2    if r2 within 10% of xrange AND 0 within 10% of yrange
    black  x2/x1 0       x-axis   if 0 within 10% of yrange
    black   0   y1/y2/h  y-axis   if 0 within 10% of xrange
    black   curve itself
    blue    x1  0/ymin  dash-vert   (from x1,0 to x1, y1, if x-axis present. Otherwise, from x1, ymin/ymax to x1,y1 )
    red     x2  0/ymin  dash-vert

    keep track of xmin, ymin, xmax, ymax after every step
    */

    if (x[0].value==null || x[ii].value==null) { return; }

    this.xs=[x[0].valueSI, x[ii].valueSI]; // assuming that x[0] < x[ii] from parabola
    this._xwin=[x[0].valueSI, x[ii].valueSI]; // for the min/max showing in the window. 
    // this.xs=[x[0].valueSI, x[ii].valueSI, this.parabola.h.valueSI];
    this.ys=[-y[0].valueSI, -y[ii].valueSI];
    this._ywin=[Math.min(-y[0].valueSI, -y[ii].valueSI), Math.max(-y[0].valueSI, -y[ii].valueSI)];
    // this.ys=[-y[0].valueSI, -y[ii].valueSI, -this.parabola.k.valueSI];
    this.ss=[-s[0].valueSI, -s[ii].valueSI];

    // check vertex
    if (this._xinchk(this.parabola.h.valueSI)) { 
      this.xs.push(this.parabola.h.valueSI);
      const k = -this.parabola.k.valueSI;
      this.ys.push(k);
      if (k>this._ywin[1]) { this._ywin[1] = k; } else 
      if (k<this._ywin[0]) { this._ywin[0] = k; } 
    } else { this.xs.push(null); this.ys.push(null); }

    // check r1/r2
    let r1: number; let r2: number;
    if (this.parabola.r1.value != null && this.parabola.r2.value != null) { 
      // make sure r1 <= r2
      r2 = this.parabola.r2.valueSI; 
      if ( this.parabola.r1.valueSI > r2 ) { r1 = r2; r2 = this.parabola.r1.valueSI; } else { r1 = this.parabola.r1.valueSI; }
      // r1 and r2 both set now
      if (this._xinchk(this.parabola.r1.valueSI) && this._yinchk(0)) { this.xs.push(this.parabola.r1.valueSI); this.ys.push(0); } 
      else { this.xs.push(null); this.ys.push(null); }
      if (this._xinchk(this.parabola.r2.valueSI) && this._yinchk(0)) { this.xs.push(this.parabola.r2.valueSI); this.ys.push(0); } 
      else { this.xs.push(null); this.ys.push(null); }
    } else { this.xs.push(null); this.ys.push(null); this.xs.push(null); this.ys.push(null); }

    // check x-axis
    if (this._yinchk(0)) { this.xs.push(this.xmax); this.ys.push(0); } else { this.xs.push(null); this.ys.push(null); }

    // check y-axis
    if (this._xinchk(0)) { this.xs.push(0); this.ys.push(this.ymax); } else { this.xs.push(null); this.ys.push(null); }

    // add starting and ending verticle lines
    this.xs.push(this.xs[0]);
    if (this._yinchk(0)) { this.ys.push(0); } else if (this.ys[0]>0) { this.ys.push(this.ymin); } else { this.ys.push(this.ymax); }
    this.xs.push(this.xs[1]);
    if (this._yinchk(0)) { this.ys.push(0); } else if (this.ys[1]>0) { this.ys.push(this.ymin); } else { this.ys.push(this.ymax); }


    // const p=0.2; const q=0.8; 
    const r=0.94;
    const xscale = r*this.width/this.xrange; 
    const yscale = r*this.height/this.yrange;
    this.scaleXS(xscale);
    this.scaleYS(yscale);
    this.shiftX( (this.width-this.xmin-this.xmax)/2 );
    this.shiftY( (this.height-this.ymin-this.ymax)/2 );

    // re-adjust x- and y-axis to make them a tad longer. currently would be at r=0.94 of full width
    if (this.xs[5] != null) { this.xs[5]=0.99*this.width; }
    if (this.ys[6] != null) { this.ys[6]=0.99*this.height; }
    // add vertical lines at x1 and x2
    this.xs.push(this.xs[0]); 
    this.xs.push(this.xs[1]); 
    if (this.xs[5] != null) { 
      this.ys.push(this.ys[5]); 
      this.ys.push(this.ys[5]); 
    } else {
      if (this.ys[0] > 0) { this.ys.push(1); } else { this.ys.push(0.99*this.height); }
      if (this.ys[1] > 0) { this.ys.push(1); } else { this.ys.push(0.99*this.height); }
    }

    return;
  }

  scaleS(s:number): void { this.ss.forEach(function(v:number,i:number){ this.ss[i] = (v==null)?null:v*s; }.bind(this)); }
  scaleXS(s: number): void { if (s>0 || s<0) { this.scaleX(s); this.scaleS(1/s); } }
  scaleYS(s: number): void { if (s>0 || s<0) { this.scaleY(s); this.scaleS(s); } }
  
  get bx(): number { // Bézier curve control point, (x,y), where the two tangent meet 
    const x1 = this.xs[0];
    const y1 = this.ys[0];
    const s1 = this.ss[0];
    const x2 = this.xs[1];
    const y2 = this.ys[1];
    const s2 = this.ss[1];
    return (y1-y2+x2*s2-x1*s1)/(s2-s1); // we do not care about uncertainties and errors in the plots here
  }
  get by(): number { // Bézier curve control point, (x,y), where the two tangent meet 
    const x1 = this.xs[0];
    const y1 = this.ys[0];
    const s1 = this.ss[0];
    const x2 = this.xs[1];
    const y2 = this.ys[1];
    const s2 = this.ss[1];
    return (s1*s2*(x1-x2)+(s1*y2-s2*y1))/(s1-s2); // we do not care about uncertainties and errors in the plots here
  }
}

@Component({
  selector: 'app-parabola-figure-svg',
  templateUrl: './parabola-figure-svg.component.svg',
  styleUrls: ['./parabola-figure-svg.component.scss']
})
export class ParabolaFigureSvgComponent implements OnInit {
// export class ParabolaFigureSvgComponent implements OnInit, OnChanges {
  @Input() parabola: Parabola; // Need 2-3 (x,y) pairs. 
  // @Input() figwidth: number;
  figure: ParabolaFigureSvg;
  showPlot: boolean;
  showPlotButton: string;

  constructor() { }

  get parapath(): string { 
    const x=this.figure.xs; 
    const y=this.figure.ys; 
    return "M"+x[0]+","+y[0]+" Q"+this.figure.bx+","+this.figure.by+" "+x[1]+","+y[1]; 
  }




  ngOnInit() { this.showPlot=false; this.showPlotButton="Show"; this.figRender(); }
  ngDoCheck() { this.figRender(); }
  // ngOnChanges() { this.figRender(); }

  figRender(): void { this.figure = new ParabolaFigureSvg( {width: this.figsize().width, height: this.figsize().height, parabola: this.parabola } ); return; }

  figsize(): FigureProps { 
    const tw=document.getElementById("divParabolaFigSvg").clientWidth; 
    const th = Math.min(tw*0.7, 500); // setting max height to 500px
    // const th=document.getElementById("divParabolaFig").clientHeight; 
    return <FigureProps>{ width: tw, height: th }; 
  }

  togglePlot(): void { this.showPlot = !this.showPlot; this.showPlotButton=(this.showPlot)?"Hide":"Show"; }
  get showMin(): boolean { return this.showPlot && this.parabola.a.valueSI>0; }
  get showMax(): boolean { return this.showPlot && this.parabola.a.valueSI<0; }
  get showFcn(): boolean { 
    const x=this.figure.xs; const y=this.figure.ys; const ty=this.parabola.inptype; const xp=this.parabola.xs; const yp=this.parabola.ys; 
    return (ty!='d'&&x[0]!=null&&y[1]!=null)||(xp[0].value!=null&&yp[0].value!=null&&xp[1].value!=null&&yp[1].value!=null&&xp[2].value!=null&&yp[2].value!=null); 
  }
  get showVertex(): boolean { return (this.showMin || this.showMax) && this.showFcn; }
  get showXaxis(): boolean { return this.figure.xs[5] && this.figure.xs[5]!=null && this.showFcn; }
  get showYaxis(): boolean { return this.figure.ys[6]!=null && this.showFcn; }
  get showr1(): boolean { return this.figure.xs[3] != null && this.showFcn; }
  get showr2(): boolean { return this.figure.xs[4] != null && this.showFcn; }
  get need2xs(): boolean { return this.showPlot && !this.showFcn && this.parabola.inptype!='d'; }
  get need3xys(): boolean { return this.showPlot && !this.showFcn && this.parabola.inptype=='d'; }

  tboxparam(v:string, c:string):number { 
    // v: variable, c: component
    const r=0.94; // should match figure.setXsYs
    const boxwidthmax = 300; let boxwidth: number;
    boxwidth = Math.min(this.figure.width/2.2, boxwidthmax); 
    const boxheightmax = 100; let boxheight: number;
    boxheight = Math.min(this.figure.height/2, boxheightmax); 
    if (v=='x1') {
      if (c=='x') { return (1-r)/2*this.figure.width; } else 
      if (c=='y') { return (1+r)/2*this.figure.height-boxheight; } else 
      if (c=='w') { return boxwidth; } else 
      if (c=='h') { return boxheight; } 
      return;
    }
    if (v=='x2') {
      if (c=='x') { return (1+r)/2*this.figure.width-boxwidth; } else 
      if (c=='y') { return (1+r)/2*this.figure.height-boxheight; } else 
      if (c=='w') { return boxwidth; } else 
      if (c=='h') { return boxheight; } 
      return;
    }
    if (v=='r1') {
      if (c=='x') { return this.figure.xs[3]-boxwidth/2; } else 
      if (c=='y') { return this.figure.ys[3]+15; } else 
      if (c=='w') { return boxwidth; } else 
      if (c=='h') { return 30; } 
      return;
    }
    if (v=='r2') {
      if (c=='x') { return this.figure.xs[4]-boxwidth/2; } else 
      if (c=='y') { return this.figure.ys[4]+15; } else 
      if (c=='w') { return boxwidth; } else 
      if (c=='h') { return 30; } 
      return;
    }
    console.warn('Unknown Error. tboxparam not found.');
    return; 
  }


}
