import { Injectable, Optional } from '@angular/core';

// let nextId = 1; 
// currently this is set as a global variable only. Can be extended to different modules, when say a user is taking a quiz, etc

@Injectable({
  providedIn: 'root'
})
export class GlobalSigfigService {
  // idcnt = nextId++;
  gSigfig: string;

  constructor() {
    this.gSigfig= ( this.gSigfig=='d' || this.gSigfig=='deci' || this.gSigfig=='decimal' || this.gSigfig=='decimals') ? 'decimals' : 'sig.fig.' ;
  }
  // constructor(@Optional() sf: string ) { 
  //   // forRoot() will overide these... this constructor is not called
  //   this.gSigfig= ( sf=='d' || sf=='deci' || sf=='decimal' || sf=='decimals') ? 'decimals' : 'sig.fig.' ;
  //   // console.log("instantiating GlobalSigfigService.");
  //   // console.log("the idcnt = "+ this.idcnt);
  //   // console.log(this);
  // }

  // setSigfig(x: string): void { this.gSigfig = ( x=='d' || x=='deci' || x=='decimal' || x=='decimals')?'decimals':'sig.fig.' ;}
}
