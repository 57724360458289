import { Component, OnInit, Inject } from '@angular/core';
import { ScratchPadList, GlobalClipboardService } from 'src/app/common/scratch-pad/global-clipboard.service';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';
import { PhysCon } from 'src/app/numeric-library/bbb/bbb.module';
// import { RevealModule } from 'src/app/service/reveal.module';
import { RevealService } from 'src/app/service/reveal/reveal.service';
import { FactoryService } from 'src/app/service/facts/factory.service';
import { Factopia, Factsier, Factsheet, Factum, Bookmark } from 'src/app/service/facts/facts.module';
import { MatDialogRef } from '@angular/material/dialog';
import { RevealComponent } from 'src/app/service/reveal/reveal.component';

@Component({
  selector: 'app-clipboard',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.scss']
})
export class ClipboardComponent implements OnInit {
  gspad: ScratchPadList;
  // revs: RevealService; 
  // cp: CommonPipeModule;
  // rem: RevealModule;

  // gconlist: PhysCon[];

  constructor(private _revs: RevealService, gcb: GlobalClipboardService) { 
  // constructor(revs: RevealService, gcb: GlobalClipboardService, cp: CommonPipeModule) { 
  // constructor(revs: RevealService, rem: RevealModule, gcb: GlobalClipboardService, cp: CommonPipeModule) { 
    this.gspad = gcb.spad;
    // this.cp = cp;
    // this._revs = _revs;
    // this.rem = rem;
    // this.gconlist = gcb.spad.list;
  }

  ngOnInit() {
  }

  get clipb(): PhysCon { return this.gspad.list[this.gspad.list.length-1]; }

  reveal(): MatDialogRef<RevealComponent>  {
  // reveal(facts?: FactoryService | Factopia | Factsier | Factsheet | Factum, bkmark?: Bookmark): MatDialogRef<RevealComponent>  {
    // return this._revs.reveal(facts,bkmark);
    return this._revs.reveal({}); // { facts?: FactoryService | Factopia | Factsier | Factsheet | Factum, bkmark?: Bookmark, override?: RevealOverride, additionalDialogConfigData?: any }
  }

}
