import { Component, OnInit } from '@angular/core';
import { GlobalClipboardService } from 'src/app/common/scratch-pad/global-clipboard.service';

@Component({
  selector: 'app-quadratic',
  templateUrl: './quadratic.component.html',
  styleUrls: ['./quadratic.component.scss']
})
export class QuadraticComponent implements OnInit {
  quadCompState: object;
  buttontext: string;

  constructor( private _gcb: GlobalClipboardService ) { }

  ngOnInit() {
    const svars = this._gcb.stateCfgs.g('quadLanding');
    if (svars) { this.quadCompState = svars.vars.qstate; } 
    else {
      this.quadCompState = { modeSel: "c"}; // c-complete ; s-simple
      this._gcb.stateCfgs.list.push({stateName:'quadLanding', vars:{ qstate: this.quadCompState } });
    }
    this.buttontext = (this.quadCompState['modeSel']=="c") ? "Switch to Simple" : "Switch to Complete";
  }

  toggleMode(): void {
    if (this.quadCompState['modeSel'] == "c") { this.quadCompState['modeSel'] = "s"; this.buttontext = "Switch to Complete"; } 
    else { this.quadCompState['modeSel'] = "c"; this.buttontext = "Switch to Simple"; }
    return;
  }

}
