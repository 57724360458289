import { Component, OnInit, Inject } from '@angular/core';
import { GlobalPhysUnitsService, PhysConShell, unitDropdownList, siPrefixes, PhysDimComp, PhysDimCompForm, CatDropdownOption, SiPrefix } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { PhysQty } from 'src/app/numeric-library/bbb/bbb.module';
import { GlobalClipboardService } from '../../common/scratch-pad/global-clipboard.service';
import { RevealModule } from 'src/app/service/reveal/reveal.module';
import { SnackBarService, SnackBarMsg } from 'src/app/service/facts/snack-bar.service';
import { FactoryService } from 'src/app/service/facts/factory.service';
import { Factsier, Factsheet } from 'src/app/service/facts/facts.module';
import { PrefixDropListPipe } from 'src/app/pipe/common-pipe/prefix-drop-list.pipe';
import { PureMathService } from 'src/app/numeric-library/operator/pure-math.service';

@Component({
  selector: 'app-unicon',
  templateUrl: './unicon.component.html',
  styleUrls: ['./unicon.component.scss']
})
export class UniconComponent implements OnInit {
  angle: PhysQty;
  readonly angleFigWidth = 400;
  readonly angleFigHeight = 400;

  temperature: PhysQty;
  temperatureD: PhysQty;
  // _temperatureMsg: string;
  pqty: PhysQty;
  pqty2: PhysQty;
  catA: CatDropdownOption[]; // sample output- [ ... , { pdimId: 8, title="luminous intensity | luminous flux : [Lum Int]", category:"luminous intensity", comp:{M:0, L:0, T:0, t:0, td:0, N:0, I:0, J:0}, compDisp: "[Length]/[Time]", unitSI: "m/s" } , ... ]
  catID: number; // the pdimId of the category selected
  catCompDisp: string; // the basic dimension of the current category, eg. [Length]/[Time]
  catUnitSI: string;
  // catComp: PhysDimComp;
  private _lastsnack: number;

  // general unit conversions
  get pqUnitA(): unitDropdownList[] { return this._gpu.physUnitAList4dropdown( { pdimId: this.catID } ); } // pdimId: 20, Force

  constructor(private _facts: FactoryService, private _gpu: GlobalPhysUnitsService, private _gcb: GlobalClipboardService, private _sbs: SnackBarService) {
    // this._lastsnack = 0;
  }

  ngOnInit() {
    // set up factsier/report and factsheet for the reveal module
    this._facts.addFactsier( new Factsier({factsier: {id:2, name:'unicon', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Unit Conversions', topicA: ['unicon'] }));
    this._facts.bkmark.factsier = {id:2, name:'unicon'}; // either one should do. name is the overriding criteria
    this._facts.findFactsier({name: 'unicon'}).addFactsheet( new Factsheet({sheet: {id:0, name:'mainUnicon', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Unit Conversions', topicA: ['unicon'] }));

    this._lastsnack =0;
    this.catA = this._gpu.physDimAList; // for dropdown manual
    this.catA.shift(); // remove first undefined element

    // global state var
    const svars = this._gcb.stateCfgs.g('unicon');
    if (svars) {
      this.angle = <PhysQty>svars.vars.angle;
      this.temperature = <PhysQty>svars.vars.temperature;
      this.temperatureD = <PhysQty>svars.vars.temperatureD;
      this.pqty = <PhysQty>svars.vars.pqty;
      this.pqty2 = <PhysQty>svars.vars.pqty2;
    } else {
      this.angle = new PhysQty ( new PhysConShell({ value: 21.45, exact: true, name: "angle1", nameHtml: "&theta;<sub>1</sub>", system: "SI", category: "angle", unit: "radian", prefix: 0, punitId: 1, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0} }));
      this.temperature = new PhysQty (new PhysConShell({ value: 20, stdErr: 0, stdErrRel: 0, exact: true, name: "Temp1", nameHtml: "<i>T</i><sub>1</sub>", system: "SI", category: "temperature", unit: "Celsius", prefix: 0, punitId: 19, comp: {M:0,T:0,L:0,t:1,N:0,I:0,J:0,td:0} }));
      this.temperatureD = new PhysQty (new PhysConShell({ value: 1, stdErr: 0, stdErrRel: 0, exact: true, name: "TempD", nameHtml: "&Delta;<i>T</i>", system: "SI", category: "temperature difference", unit: "Celsius", prefix: 0, punitId: 21, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:1} }));
      // this._temperatureMsg = "";
      // let thisCatPu = this._gpu.findPhysUnit( {comp: {M:1, L:1, T:-2, t:0, td:0, N:0, I:0, J:0}} ); //  id=20 for force;
      this.catID = 20 // thisCatPu.pdimId;

      const pqsys: string = this.pqUnitA[0]['systemA'][0]; // using getter will refresh model in ngFor loops etc. // these are from the dropdown list
      const pqunit: string = this.pqUnitA[0]['unit']; // these are from the dropdown list
      const pqpunitid: number = this.pqUnitA[0]['punitId']; // these are from the dropdown list
      // const catCompDisp: string = this._gpu.findPhysUnitA({pdimId: this.catID})[0]; this.findPhysUnitA['compDisp']; }
      this.pqty= new PhysQty(<PhysConShell>{ value: 1, pdimId: this.catID, system: pqsys, unit: pqunit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: pqpunitid })
      this.pqty2= new PhysQty(<PhysConShell>{ value: 1, pdimId: this.catID, system: pqsys, unit: pqunit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: pqpunitid })

      this._gcb.stateCfgs.list.push({stateName:'unicon', vars:{ angle: this.angle, temperature: this.temperature, temperatureD: this.temperatureD, pqty: this.pqty, pqty2: this.pqty2 } });
    }

    this.catID = this.pqty.pdimId; // duplicate step if starting from new, but needed if global state vars is present.
    this.catCompDisp = this.pqty.compDisp;
    this.catUnitSI = this.pqty.unitSI;

  }

  // readonly pfxDropList = siPrefixes.copylist(); //: SiPrefix[];
  pfxDropList(pq: PhysQty): SiPrefix[] {
    const pipe: PrefixDropListPipe = new PrefixDropListPipe();
    return pipe.transform(pq);
  }

  tempChgValue(event): void {
    const val: number = Number(event.target.value);
    this.temperature.setNewValue(val);
    this._temperatureMsg();
    if (this.temperature.valueSI < 0) { this.temperature.setNewValueSI(0); event.target.value=0; }
  }
  tempChgUnit(uni:string): void {
    if (uni=='Kelvin' || uni=='K') { this.temperature.chgSysUnitID(<PhysConShell>{punitId: 18, unit: uni, category: 'temperature', system: 'SI' }); }
    else if (uni=='Celsius' || uni=="°C") { this.temperature.chgSysUnitID(<PhysConShell>{punitId: 19, unit: uni, category: 'temperature', system: 'SI' }); }
    else if (uni=='Fahrenheit' || uni=="°F") { this.temperature.chgSysUnitID(<PhysConShell>{punitId: 20, unit: uni, category: 'temperature', system: 'imperial' }); }
  }

  tempDChgValue(event): void { this.temperatureD.setNewValue(Number(event.target.value)); }
  tempDChgUnit(uni:string): void {
    if (uni=='Kelvin' || uni=='K' || uni=='Celsius' || uni=="°C") { this.temperatureD.chgSysUnitID(<PhysConShell>{punitId: 21, unit: uni, category: 'temperature difference', system: 'SI' }); }
    else if (uni=='Fahrenheit' || uni=="°F") { this.temperatureD.chgSysUnitID(<PhysConShell>{punitId: 22, unit: uni, category: 'temperature difference', system: 'imperial' }); }
  }

  angleChgValue(event): void {
    const val: number = Number(event.target.value);
    this.angle.setNewValue(val);
  }
  angleChgUnit(uni:string): void {
    if (uni=='radian' || uni=='rad' || uni==='' ) { this.angle.chgSysUnitID(<PhysConShell>{punitId: 1, unit: 'radian', category: 'angle', system: 'SI' }); }
    else if (uni=='degree' || uni=="°" || uni=="deg") { this.angle.chgSysUnitID(<PhysConShell>{punitId: 2, unit: 'degree', category: 'angle', system: 'SI' }); }
    else if (uni=='pi' || uni=="π") { this.angle.chgSysUnitID(<PhysConShell>{punitId: 3, unit: 'pi', category: 'angle', system: 'SI' }); }
  }

  highlightUnit(type: string, uni: string): string { return (this[type].unit == uni)?'yellow':''; }

    // get temperatureInfo(): boolean {
    //   return (this._temperatureMsg =="")?false:true ;
    // }

  private _temperatureMsg(): void {
    const t = this.temperature.valueSI;
    if (t<0) { if(this._lastsnack==1){return;}else{this._lastsnack=1; this._sbs.now( <SnackBarMsg> { importance: 5 , message: "Nothing gets below ABSOLUTE ZERO.", action: "Freeze!", duration: 4000 }); } } // default set at 2500 from facts.module
    else if (t>=0 && t<0.5001 ) { if(this._lastsnack==2){return;}else{this._lastsnack=2; this._sbs.now( <SnackBarMsg> { importance: 5 , message: "You are approaching ABSOLUTE ZERO. Nothing moves, nothing vibrates, and all brains freeze, including Einstein's.", action:"Cool!"}); } }
    else if (t > 3 && t < 5) { if(this._lastsnack==3){return;}else{this._lastsnack=3; this._sbs.now( <SnackBarMsg> { importance: 5 , message: "Near Liquid Helium boiling point (4K) at standard pressure.", action:"Fact - FYI"}); } }
    else if (t > 62.5 && t < 63.5) { if(this._lastsnack==4){return;}else{this._lastsnack=4; this._sbs.now( <SnackBarMsg> { importance: 5 , message: "Liquid Nitrogen freezing point/melting point (63K) at standard pressure.", action:"Fact - FYI"}); } }
    else if (t > 76.9 && t < 77.9) { if(this._lastsnack==5){return;}else{this._lastsnack=5; this._sbs.now( <SnackBarMsg> { importance: 5 , message: "Liquid Nitrogen boiling point (77K) at standard pressure.", action:"Fact - FYI"}); } }
    else if (t > 255 && t < 256) { if(this._lastsnack==6){return;}else{this._lastsnack=6; this._sbs.now( <SnackBarMsg> { importance: 5 , message: "Similar to how zero Celsius was defined, the zero Fahrenheit is (roughly) the freezing/melting point of salted water. Imagine on a snow packed day below 0&deg;F, you can forget about salting the driveway as it won't melt the ice.", action:"For Snow days", duration: 4000}); } }
    else if (t > 272.14 && t < 273.65) { if(this._lastsnack==7){return;}else{this._lastsnack=7; this._sbs.now( <SnackBarMsg> { importance: 5 , message: "Temperature (0 Celsius) near/at the freezing point of water under STP.", action:"Icing on the brain"}); } }
    else if (t >= 273.65 && t < 277.15) { if(this._lastsnack==8){return;}else{this._lastsnack=8; this._sbs.now( <SnackBarMsg> { importance: 5 , message: "Water undergo anomalous expansion upon cooling (0 - 4 Celsius).", action:"Weird"}); } }
    else if (t >= 372.15 && t < 374.16) { if(this._lastsnack==9){return;}else{this._lastsnack=9; this._sbs.now( <SnackBarMsg> { importance: 5 , message: "Temperature near/at the boiling point of water (100 Celsius) under STP.", action:"Fact - FYI"}); } }
    else if (t > 5799 && t < 6101) { if(this._lastsnack==10){return;}else{this._lastsnack=10; this._sbs.now( <SnackBarMsg> { importance: 5 , message: "Temperature around the surface of the Sun (5900K).", action:"Hot!"}); } } else { this._lastsnack = 0; }
    return;
  }

  get temperatureMsg(): void { return this._temperatureMsg(); }

  pqtyChgCat(): void {
    let pu = this._gpu.findPhysUnitA({pdimId: this.catID})[0]; // make sure catID is value, especially for pasting, rule out not-in-system scenario
    this.catCompDisp = pu.compDisp;
    this.catUnitSI = pu.unitSI;
    //
    this.pqty.update({pdimId: this.catID, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) });
    this.pqty.setPrefixVals(0);
    this.pqty.setNewValue(this.pqty.value);
    //
    this.pqty2.update({pdimId: this.catID, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) });
    this.pqty2.setPrefixVals(0);
    this.pqty2.setNewValueSI(this.pqty.valueSI);
    return;
  }

  pqtyChgVal(event): void {
    // const val: number = (event==0||event>0||event<0)?event:Number(event.target.value);
    const val: number = Number(event.target.value);
    this.pqty.setNewValue(val); // set value and valueSI of pqty
    this.pqty2.setNewValueSI(this.pqty.valueSI);
    return;
  }

  pqtyChgPfx(pq:PhysQty): void {
    pq.setPrefixVals(pq.prepower);
    pq.setNewValueSI();
    return;
  }

  pqtyChgUnit(pq:PhysQty): void {
    pq.setPrefixVals(pq.prepower); // in case it's changed
    pq.chgSysUnitID(<PhysConShell>{ punitId: pq.punitId });
    return;
  }

  paste2input(type: string = 'g'): void {
    // let sbarMsg: object = null;
    const spadlen = this._gcb.spad.list.length;
    // check clipboard
    if ( spadlen<1 ) {
      this._sbs.now(<SnackBarMsg>{ importance: 10, message: "Clipboard is empty. Nothing to paste.", action: "Error", duration: 4000 });
      return;
    }

    // check compatible category/dimension
    const cb = this._gcb.spad.list[spadlen-1];

    if (type == 'a' && cb.pdimId != 1) {
      this._sbs.now(<SnackBarMsg>{ importance: 10, message: "Clipboard element is not an angle. Please fix and try again.", action: "Error", duration: 5000 });
      return;
    }
    if ( type=='t' && !cb.comp.compcomps(0,0,0,1,0,0,0,0) ) {
      this._sbs.now(<SnackBarMsg>{ importance: 10, message: "Clipboard element is not a temperature. Please fix and try again.", action: "Error", duration: 5000 });
      return;
    }
    if ( type=='td' && !cb.comp.compcomps(0,0,0,0,1,0,0,0) ) {
      this._sbs.now(<SnackBarMsg>{ importance: 10, message: "Clipboard element is not a temperature-difference. Please fix and try again.", action: "Error", duration: 5000 });
      return;
    }
    if ( cb.pdimId < 1 ) {
      this._sbs.now(<SnackBarMsg>{ importance: 10, message: "Clipboard element is not of a known physical unit. Please fix and try again.", action: "Error", duration: 5000 });
      return;
    }

    // passed the basic checks
    // actual pasting
    if (type=='a') {
      this.angle.update(cb); // = cb.dcopy();
      this.angleChgValue({target: {value: cb.value}});
      this.angleChgUnit(cb.unit);
      this._sbs.now(<SnackBarMsg>{ importance: 4, message: "Pasting the angle from clipboard successful.", action: "Success", duration: 4000 });
    } else
    if (type=='td') {
      this.temperatureD.update(cb); // = cb.dcopy();
      this.tempDChgValue({target: {value: cb.value}});
      this.tempDChgUnit(cb.unit);
      this._sbs.now(<SnackBarMsg>{ importance: 4, message: "Pasting the temperature difference from clipboard successful.", action: "Success", duration: 4000 });
    } else
    if (type=='t') {
      if (cb.valueSI < 0) {
        this._sbs.now(<SnackBarMsg>{ importance: 10, message: "Cannot paste clipboard temperature here. It is below absolute zero (Kelvin). Please fix and try again.", action: "Fail", duration: 5000 });
      } else {
        this.temperature.update(cb); // = cb.dcopy();
        this.tempChgValue({target: {value: cb.value}});
        this.tempChgUnit(cb.unit);
        this._sbs.now(<SnackBarMsg>{ importance: 4, message: "Pasting the temperature from clipboard successful.", action: "Success", duration: 4000 });
      }
    } else { // general conversion scenario
      if (cb.pdimId < 1) {
        this._sbs.now(<SnackBarMsg>{ importance: 10, message: "Cannot paste from clipboard. The category is not known in our system.", action: "Fail", duration: 5000 });
      } else {
        this.catID = cb.pdimId;
        this.pqtyChgCat();
        this.pqty.update(cb); // = cb.dcopy();
        this.pqty2.update(cb); // = cb.dcopy();
        this.pqtyChgVal({target: {value: cb.value}})
        this.pqtyChgUnit(cb);
        this._sbs.now(<SnackBarMsg>{ importance: 4, message: "Pasting from clipboard successful. Units are reset to "+cb.unitSI+".", action: "Success", duration: 4000 });
      }
    }

    return;
  }
  addPqty(pq: PhysQty): void {  this._gcb.spad.addPqty(pq); }

  // was in unicon-angle-figure-svg.components.ts
  get msgQuad(): string {
    let res:string = "The angle is ";
    const q = this.angle.quad;
    if(q==1){res+="in Quadrant &#8544;";}
    else if(q==2){res+="in Quadrant &#8545;";}
    else if(q==3){res+="in Quadrant &#8546;";}
    else if(q==4){res+="in Quadrant &#8547;";}
    else if(q==1.5){res+="between Quadrants &#8544; &amp; &#8545;";}
    else if(q==2.5){res+="between Quadrants &#8545; &amp; &#8546;";}
    else if(q==3.5){res+="between Quadrants &#8546; &amp; &#8547;";}
    else if(q==0.5){res+="between Quadrants &#8544; &amp; &#8547;";}
    return res;
  }
  get showPrinAngle(): boolean { return (this.angle.valueSI<0 || this.angle.valueSI>2*Math.PI); }
  get showRefAngle(): boolean { return (this.angle.valueSI<0 || this.angle.valueSI>Math.PI/2); }



}
