import { NgModule, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalPhysUnitsService, PhysConShell, PhysDimComp } from '../invariant/global-phys-units.service';
import { PhysQty0, PhysQty } from '../bbb/bbb.module';
import { PureMathService } from '../operator/pure-math.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


// Vector object
export class Vector2d { // scaled down from Vector3d
  cosys: string; // coordinate system.  rect: r/rec/rect/rectangular/car/ca   //   cyl: c/cy/cyl/polar/p    //  In 2d, spherical same as cylindrical. Use cylindrical instead.   shp: s/sp/sph
  name: string;
  nameHtml: string;
  dimId: number;
  x: PhysQty ;
  y: PhysQty ;
  r: PhysQty ; // same as rho in 2D
  p: PhysQty ; // angle
  comps4: string[] = ['x','y','r','p']; //

	constructor(input: any) {
    this.dimId = -1; // TBD, 0: undef, 1: dimensionless,
    // if (input.dimId && input.dimId >0) { this.dimId = input.dimId } else
    // need at least one exist
    if (input.p && input.p.pdimId != 1) { console.log("Vector2d instantiate error. Angle not with incorrect pdimId."); return; }

    let first = '';
    if (input.x && input.x.pdimId>0) { this.dimId = input.x.pdimId; first = 'x'; } else
    if (input.y && input.y.pdimId>0) { this.dimId = input.y.pdimId; first = 'y'; } else
    if (input.r && input.r.pdimId>0) { this.dimId = input.r.pdimId; first = 'r'; } else
    { console.log("Vector2d instantiate error. No dimId found."); return; }

    if (first=='x' && input.y && input.y.pdimId != this.dimId) { console.log("Vector2d instantiate error. Inconsistent dimId found."); return; }
    if (first=='x' && input.z && input.z.pdimId != this.dimId) { console.log("Vector2d instantiate error. Inconsistent dimId found."); return; }
    if (first=='y' && input.z && input.z.pdimId != this.dimId) { console.log("Vector2d instantiate error. Inconsistent dimId found."); return; }

    // now at least they are all consistent.
    const pq: PhysQty = input[first].dcopy();
    pq.setPrefixVals(0); pq.setNewValueSI(0); pq.value=null; pq.name=""; pq.nameHtml="";
    this.x = (input.x)? input.x.dcopy(): pq.dcopy();
    this.y = (input.y)? input.y.dcopy(): pq.dcopy();
    this.r = (input.r)? input.r.dcopy(): pq.dcopy();
    if ( this.x.name=="no_name" || !this.x.name || this.x.name.length<1 ) { this.x.name = 'v_x'; this.x.nameHtml = '<i>v<sub>x</sub></i>'; }
    if ( this.y.name=="no_name" || !this.y.name || this.y.name.length<1 ) { this.y.name = 'v_y'; this.y.nameHtml = '<i>v<sub>y</sub></i>'; }
    if ( this.r.name=="no_name" || !this.r.name || this.r.name.length<1 ) { this.r.name = 'r'; this.r.nameHtml = '<i>r</i>'; }

    if (input.p) { this.p = input.p.dcopy(); }
    else { this.p = new PhysQty(<PhysConShell>{pdimId: 1, punitId: 1, unit: '', system: 'SI', category: 'angle', cu2si: 1, cu2siInt: 0, comp: <PhysDimComp>{t:0} } );
      this.p.setPrefixVals(0); // reset all prefixes to 0
      this.p.setNewValueSI(0);
      this.p.value=null;
    }
    if ( this.p.name=="no_name" || !this.p.name || this.p.name.length<1 ) { this.p.name = 'phi'; this.p.nameHtml = '<i>&phi;</i>'; }

    // input should have { cosys: "cosys" }, then two of the four x, y, r, p  as appropriate Eqty objects.
    // if cosys=rectangular, x,y,z should be given, but also supplied
		this.cosys = (input.cosys)?input.cosys:'r'; // x,y,z for car/tesian; r,theta,phi for sph/erical, r,theta,z for polar/cyl/indrical (7 variables)
		this.name = (input.name)?input.name:'vec2D';
    this.nameHtml = (input.nameHtml)?input.nameHtml:'v';

		// x and y, only for rect // can be length or any consistent dimensions
		// r >= 0, same as magnitude of a vector, for sph  // can be length or any consistent dimensions
		// r.value or r.valueSI can also be used as the norm/magnitude of the vector, instead of dotp(v1,v1) and sqrt
		// p (phi) angle, [0,2pi] in 2D, c.f. (-pi, pi] in 3d for sph and polar/cyl  // must be one of the three angle measurements
		// if (this.cosys=="s" || this.cosys=="sp" || this.cosys=="sph") { // spherical
    //   this.cosys = 's';

    this.setAllVals2D();

  }

  setAllVals2D(): void {
    if (this.dimId <0) { console.warn('error for dimensions: '+this.name+' ; dimId= '+this.dimId); }
    const x = this.x; const y=this.y; const r=this.r; const p=this.p;
    // if p angle not in [0,2pi), take mod
    if (p&&p.value!==null &&p.pdimId==1){ if(p.value<0||p.valueSI>=2*Math.PI){ p.setNewValueSI( (((p.valueSI/2/Math.PI)%1 +1)%1)*2*Math.PI ); } }

    if (x && x.value !==null && y && y.value !==null && x.pdimId == y.pdimId) { this.setWRectangular2D(); return; }
    if (r && r.value !==null && p && p.pdimId == 1 && p.value !==null && p.value>=0 && p.valueSI<2*Math.PI) { this.setWCylindrical2D(); return; }
    if (x && x.value !==null && r && r.value !==null && x.pdimId == r.pdimId) { this.setWrx2D(); return; }
    if (y && y.value !==null && r && r.value !==null && y.pdimId == r.pdimId) { this.setWry2D(); return; }
    if (x && x.value !==null && p && p.pdimId == 1 && p.value !==null && p.value>=0 && p.valueSI<2*Math.PI) { this.setWxphi2D(); return; }
    if (y && y.value !==null && p && p.pdimId == 1 && p.value !==null && p.value>=0 && p.valueSI<2*Math.PI) { this.setWyphi2D(); return; }
    // other combinations not a valid config
    return;
  }

  setWRectangular2D(): void {
    this.cosys = 'r';
    this.r.setNewValueSI( Math.sqrt(this.x.valueSI*this.x.valueSI+this.y.valueSI*this.y.valueSI) );
    let phi = Math.atan(this.y.valueSI/this.x.valueSI); // fix quadrant II/III and IV
    if (this.x.valueSI <0) { phi += Math.PI; } else if (this.y.valueSI<0) { phi += 2*Math.PI; }
    this.p.setNewValueSI(phi);
  }

  setWCylindrical2D(): void {   // in vec 3D, the variable r here is o/rho
    this.cosys = 'c'; //'s';
    this.x.setNewValueSI( this.r.valueSI * Math.cos(this.p.valueSI) );
    this.y.setNewValueSI( this.r.valueSI * Math.sin(this.p.valueSI) );
  }

  // other four cases, given xr, yr, xphi, yphi, solve and then reset them to rectangular system
  setWxphi2D(): void {
    this.cosys = 'c'; //'s';
    // make sure angle not 90 or 270
    // allow r<0 in 2D, so we'll have unique answer here always. Unlike 3D case.
    const c = this.p.valueSI;
    if (PureMathService.pchk(c,0, 0.0001)) {
      this.r.setNewValueSI( this.x.valueSI );
      this.y.setNewValueSI( 0 );
    } else {
      this.r.setNewValueSI( this.x.valueSI / Math.cos(c) );
      this.y.setNewValueSI( this.r.valueSI * Math.sin(c) );
    }
  }

  setWyphi2D(): void {
    this.cosys = 'c'; //'s';
    // make sure angle not 90 or 270
    // allow r<0 in 2D, so we'll have unique answer here always. Unlike 3D case.
    const c = this.p.valueSI;
    if (PureMathService.pchk(c,0, 0.0001)) {
      this.r.setNewValueSI( this.y.valueSI );
      this.x.setNewValueSI( 0 );
    } else {
      this.r.setNewValueSI( this.y.valueSI / Math.sin(c) );
      this.x.setNewValueSI( this.r.valueSI * Math.cos(c) );
    }
  }

  setWrx2D(): void {
    // impossible if x>r
    // two possible solution, return quadrant I/II solution (positive y), instead of III/IV
    this.cosys = 'r';
    if (Math.abs(this.x.valueSI) > Math.abs(this.r.valueSI)) { this.p.setNewValueSI(0); this.p.value = null; this.y.setNewValueSI(0); this.y.value = null; return; }
    const phi = Math.acos(this.x.valueSI/this.r.valueSI); // fix quadrant II/III and IV
    this.p.setNewValueSI( phi );
    this.y.setNewValueSI( this.r.valueSI * Math.sin(phi) );
  }

  setWry2D(): void {
    // impossible if x>r
    // two possible solution, return quadrant I/IV solution (positive x), instead of II/III
    this.cosys = 'r';
    if (Math.abs(this.y.valueSI) > Math.abs(this.r.valueSI)) { this.p.setNewValueSI(0); this.p.value = null; this.x.setNewValueSI(0); this.x.value = null; return; }
    let phi = Math.asin(this.y.valueSI/this.r.valueSI); // fix quadrant II/III and IV
    if (phi<0) { phi += 2*Math.PI; }
    this.p.setNewValueSI(phi);
    this.x.setNewValueSI( this.r.valueSI * Math.cos(phi) );
  }

  private resetAll4SI(): void { this.comps4.forEach(function(c:string){ this[c].setNewValueSI(0); }.bind(this)); return; }

  /**
   * Add/Subtract the Vector2d with another
   * @remarks This method CHANGES this/self
   * @param v - The Vector2d to be added/subtracted. Must have same dimension as this Vector2d.
   * @param subtract - Indicate subtraction (true) or regular addition (false)
   * @returns this: The resulting sum or difference
   */
  adds(v: Vector2d, subtract = false): Vector2d {
    if (this.dimId != v.dimId) { console.log("Cannot add two vectors of different dimensions."); return; }
    this.x.adds(v.x,subtract);
    this.y.adds(v.y,subtract);
    this.setWRectangular2D();
    return this;
  }
  subtracts(v: Vector2d): Vector2d { return this.adds(v,true); }

  get slopeq(): PhysQty {
    // TODO put in errors
    if (this.x.valueSI == 0) { console.log(`Slopeq undefined for ${this.nameHtml}`); return null; }
    return new PhysQty(<PhysConShell>{ pdimId:1, punitId:1, valueSI: Math.tan(this.p.valueSI)});
  }

  get slope(): number {
    if (this.x.valueSI == 0) { console.log(`Slope undefined for ${this.nameHtml}`); return null; }
    return Math.tan(this.p.valueSI);
  }

  rmVecOverhead(name:string): string { return name.replace('<var class="vector">','').replace('<span>&#8407;</span></var>',''); }

  dcopy(): Vector2d { return new Vector2d({ sys:'r', x:this.x.dcopy(), y:this.y.dcopy(), r: this.r.dcopy(), p:this.p.dcopy(), name: this.name, nameHtml: this.nameHtml }); }

} // end Vector2d

export class Vector3d {
  cosys: string; // coordinate system.  rect: r/rec/rect/rectangular/car/ca   //   cyl: c/cy/cyl/polar/p    //    shp: s/sp/sph
  name: string;
  nameHtml: string;
  dimId: number;
  x: PhysQty ;
  y: PhysQty ;
  z: PhysQty ;
  r: PhysQty ;
  o: PhysQty ; // rho, same dim as x,y,z
  t: PhysQty ; // angle // theta
  p: PhysQty ; // angle // phi
  comps7: string[] = ['x','y','z','r','o','t','p']; //

	constructor(input: any) {
    // input should have { cosys: "cosys" }, then three of the seven x, y, z, r, rho, t, p  as appropriate Eqty objects.
    // if cosys=rectangular, x,y,z should be given, but also supplied
		this.cosys = (input.cosys)?input.cosys:'r'; // x,y,z for car/tesian; r,theta,phi for sph/erical, r,theta,z for polar/cyl/indrical (7 variables)
		this.name = (input.name)?input.name:'vec3D';
    this.nameHtml = (input.nameHtml)?input.nameHtml:'v';
    this.dimId = -1; // TBD, 0: undef, 1: dimensionless,
		// x and y, only for rect // can be length or any consistent dimensions
		// z both rect and polar/cyl // can be length or any consistent dimensions
		// r >= 0, same as magnitude of a vector, for sph  // can be length or any consistent dimensions
		// r.value or r.valueSI can also be used as the norm/magnitude of the vector, instead of dotp(v1,v1) and sqrt
		// o >= 0, for polar/cyl  // must be one of the three angle measurements
		// p (phi) angle, (-pi, pi] for sph and polar/cyl  // must be one of the three angle measurements
		// t (theta) angle, [0,pi) fpr sph // must be one of the three angle measurements
		// if (this.cosys=="s" || this.cosys=="sp" || this.cosys=="sph") { // spherical
    //   this.cosys = 's';

    this.setAllVals(input);

  }

  setAllVals(input:any): void { // assumes input with 3 component values specified
    if (this.cosys=="s" || this.cosys=="sp" || this.cosys=="sph") { this.setSpherical(input);  // spherical
    } else if (this.cosys=="c" || this.cosys=="cy" || this.cosys=="cyl" || this.cosys=="polar" || this.cosys=="p") { this.setCylindrical(input); // cylindrical
    } else { this.setRectangular(input); // default rectangular // if (this.cosys=="r" || this.cosys=="rec" || this.cosys=="rect" || this.cosys=="rectangular" || this.cosys=="car" || this.cosys=="ca" ) { // cartesian
    }
    if (this.dimId <0) {
      console.warn('error for dimensions: '+input.name+' ; dimId= '+this.dimId);
    }
  }

  setOtherVals(newcomp: string=null): void { // assume only 1 of 7 values changed
    if (newcomp && this[newcomp]) { this[newcomp].setNewValue(this[newcomp].value); }
    if (this.cosys=="s" || this.cosys=="sp" || this.cosys=="sph") { this.setWSpherical(); // spherical
    } else if (this.cosys=="c" || this.cosys=="cy" || this.cosys=="cyl" || this.cosys=="polar" || this.cosys=="p") { this.setWCylindrical(); // cylindrical
    } else { this.setWRectangular(); // default rectangular
    }
  }

  setRectangular(input: any): void {
    this.cosys = 'r';
    if (!input.x) { input.x = 0 }
    if (!input.y) { input.y = 0 }
    if (!input.z) { input.z = 0 }
    if (typeof(input.x) == 'number') { this.x = new PhysQty(<PhysConShell>{ name: this.name+"_x", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>x</i></sub>", value: input.x, system: 'SI', category: 'dimensionless', unit: '', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 1, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, cu2siInt: 0, cu2si: 1 }); }
    else { this.x = input.x; this.x.name = this.name+"_x"; this.x.nameHtml = this.rmVecOverhead(this.nameHtml)+"<sub><i>x</i></sub>"; }
    if (typeof(input.y) == 'number') { this.y = new PhysQty(<PhysConShell>{ name: this.name+"_y", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>y</i></sub>", value: input.y, system: 'SI', category: 'dimensionless', unit: '', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 1, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, cu2siInt: 0, cu2si: 1 }); }
    else { this.y = input.y; this.y.name = this.name+"_y"; this.y.nameHtml = this.rmVecOverhead(this.nameHtml)+"<sub><i>y</i></sub>"; }
    if (typeof(input.z) == 'number') { this.z = new PhysQty(<PhysConShell>{ name: this.name+"_z", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>z</i></sub>", value: input.z, system: 'SI', category: 'dimensionless', unit: '', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 1, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, cu2siInt: 0, cu2si: 1 }); }
    else { this.z = input.z; this.z.name = this.name+"_z"; this.z.nameHtml = this.rmVecOverhead(this.nameHtml)+"<sub><i>z</i></sub>"; }

    this.r = new PhysQty (<PhysConShell>{ name: this.name+"_r", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>r</i></sub>", value: 0, system: this.x.system, category: this.x.category, unit: this.x.unit, prefix: this.x.prefix, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: this.x.punitId, comp: Object.assign({},this.x.comp), cu2siInt: this.x.cu2siInt, cu2si: this.x.cu2si, categoryA: this.x.categoryA, systemA: this.x.systemA, unitA: this.x.unitA }); // if punitId=-1, need comp and cu2si
    this.o = new PhysQty (<PhysConShell>{ name: this.name+"_rho", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub>&rho;<sub>", value: 0, system: this.x.system, category: this.x.category, unit: this.x.unit, prefix: this.x.prefix, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: this.x.punitId, comp: Object.assign({},this.x.comp), cu2siInt: this.x.cu2siInt, cu2si: this.x.cu2si, categoryA: this.x.categoryA, systemA: this.x.systemA, unitA: this.x.unitA });
    this.t = new PhysQty (<PhysConShell>{ name: this.name+"_theta", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub>&theta;<sub>", value: 0, system: "SI", category: "angle", unit: 'rad', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 1 });
    this.p = new PhysQty (<PhysConShell>{ name: this.name+"_phi", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub>&phi;<sub>", value: 0, system: "SI", category: "angle", unit: 'rad', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 1 });

    if (this.x.pdimId != this.z.pdimId || this.y.pdimId != this.z.pdimId  ) { this.resetAll7SI(); this.dimId = -1; return; }
    this.dimId = this.x.pdimId;

    this.setWRectangular();
  }

  setWRectangular(): void {
    this.cosys = 'r';
    this.r.setNewValueSI( Math.sqrt(this.x.valueSI*this.x.valueSI+this.y.valueSI*this.y.valueSI+this.z.valueSI*this.z.valueSI) );
    this.o.setNewValueSI( Math.sqrt(this.x.valueSI*this.x.valueSI+this.y.valueSI*this.y.valueSI) );
    this.t.setNewValueSI( Math.acos(this.z.valueSI/this.r.valueSI) );
    let phi = Math.atan(this.y.valueSI/this.x.valueSI); // fix quadrant II/III and IV
    if (this.x.valueSI <0) { phi += Math.PI; } else if (this.y.valueSI<0) { phi += 2*Math.PI; }
    this.p.setNewValueSI(phi);
  }

  setCylindrical(input: any): void {
    this.cosys = 'c';
    if (!input.o) { input.o = 0 }
    if (!input.z) { input.z = 0 }
    if (!input.p) { input.p = 0 }
    if (typeof(input.o) == 'number') { this.o = new PhysQty(<PhysConShell>{ name: this.name+"_rho", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub>&rho;</sub>", value: input.o, system: 'SI', category: 'dimensionless', unit: '', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 1, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, cu2siInt: 0, cu2si: 1 }); }
    else { this.o = input.o; this.o.name = this.name+"_rho"; this.o.nameHtml = this.rmVecOverhead(this.nameHtml)+"<sub>&rho;</sub>"; }
    if (typeof(input.z) == 'number') { this.z = new PhysQty(<PhysConShell>{ name: this.name+"_z", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>z</i></sub>", value: input.z, system: 'SI', category: 'dimensionless', unit: '', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 1, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, cu2siInt: 0, cu2si: 1 }); }
    else { this.z = input.z; this.z.name = this.name+"_z"; this.z.nameHtml = this.rmVecOverhead(this.nameHtml)+"<sub><i>z</i></sub>"; }
    if (typeof(input.p) == 'number') { this.p = new PhysQty(<PhysConShell>{ name: this.name+"_phi", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub>&phi;</sub>", value: input.p, system: 'SI', category: 'angle', unit: 'rad', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 1, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, cu2siInt: 0, cu2si: 1 }); }
    else { this.p = input.p; this.p.name = this.name+"_phi"; this.p.nameHtml = this.rmVecOverhead(this.nameHtml)+"<sub>&phi;</sub>"; }

    this.x = new PhysQty (<PhysConShell>{ name: this.name+"_x", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>x</i><<sub>", value: 0, system: this.o.system, category: this.o.category, unit: this.o.unit, prefix: this.o.prefix, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: this.o.punitId, comp: Object.assign({},this.o.comp), cu2siInt: this.o.cu2siInt, cu2si: this.o.cu2si, categoryA: this.o.categoryA, systemA: this.o.systemA, unitA: this.o.unitA });
    this.y = new PhysQty (<PhysConShell>{ name: this.name+"_y", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>y</i><<sub>", value: 0, system: this.o.system, category: this.o.category, unit: this.o.unit, prefix: this.o.prefix, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: this.o.punitId, comp: Object.assign({},this.o.comp), cu2siInt: this.o.cu2siInt, cu2si: this.o.cu2si, categoryA: this.o.categoryA, systemA: this.o.systemA, unitA: this.o.unitA });
    this.r = new PhysQty (<PhysConShell>{ name: this.name+"_r", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>r</i></sub>", value: 0, system: this.o.system, category: this.o.category, unit: this.o.unit, prefix: this.o.prefix, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: this.o.punitId, comp: Object.assign({},this.o.comp), cu2siInt: this.o.cu2siInt, cu2si: this.o.cu2si, categoryA: this.o.categoryA, systemA: this.o.systemA, unitA: this.o.unitA });
    this.t = new PhysQty (<PhysConShell>{ name: this.name+"_theta", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub>&theta;<sub>", system: this.p.system, category: this.p.category, unit: this.p.unit, prefix: this.p.prefix, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: this.p.punitId, comp: Object.assign({},this.p.comp), cu2siInt: this.p.cu2siInt, cu2si: this.p.cu2si, categoryA: this.p.categoryA, systemA: this.p.systemA, unitA: this.p.unitA });

    if (this.o.pdimId != this.z.pdimId || this.p.pdimId != 1) { this.resetAll7SI(); this.dimId = -1; return; }
    this.dimId = this.o.pdimId;

    this.setWCylindrical();
  }

  setWCylindrical(): void {
    this.cosys = 'c';
    this.x.setNewValueSI( this.o.valueSI * Math.cos(this.p.valueSI) );
    this.y.setNewValueSI( this.o.valueSI * Math.sin(this.p.valueSI) );
    this.r.setNewValueSI( Math.sqrt(this.o.valueSI*this.o.valueSI+this.z.valueSI*this.z.valueSI) );
    this.t.setNewValueSI( Math.atan(this.o.valueSI/this.z.valueSI) );
  }


  setSpherical(input: any): void {
    this.cosys = 's';
    if (!input.r) { input.r = 0 }
    if (!input.t) { input.t = Math.PI/2 }
    if (!input.p) { input.p = 0 }
    if (typeof(input.r) == 'number') { this.r = new PhysQty(<PhysConShell>{ name: this.name+"_r", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>r</i></sub>", value: input.r, system: 'SI', category: 'dimensionless', unit: '', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 1, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, cu2siInt: 0, cu2si: 1 }); }
    else { this.r = input.r; this.r.name = this.name+"_r"; this.r.nameHtml = this.rmVecOverhead(this.nameHtml)+"<sub><i>r</i></sub>"; }
    if (typeof(input.t) == 'number') { this.t = new PhysQty(<PhysConShell>{ name: this.name+"_theta", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub>&theta;</sub>", value: input.t, system: 'SI', category: 'angle', unit: 'rad', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 1, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, cu2siInt: 0, cu2si: 1 }); }
    else { this.t = input.t; this.t.name = this.name+"_theta"; this.t.nameHtml = this.rmVecOverhead(this.nameHtml)+"<sub>&theta;</sub>"; }
    if (typeof(input.p) == 'number') { this.p = new PhysQty(<PhysConShell>{ name: this.name+"_phi", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub>&phi;</sub>", value: input.p, system: 'SI', category: 'angle', unit: 'rad', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 1, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, cu2siInt: 0, cu2si: 1 }); }
    else { this.p = input.p; this.p.name = this.name+"_phi"; this.p.nameHtml = this.rmVecOverhead(this.nameHtml)+"<sub>&phi;</sub>"; }

    this.x = new PhysQty (<PhysConShell>{ name: this.name+"_x", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>x</i><<sub>", value: 0, system: this.r.system, category: this.r.category, unit: this.r.unit, prefix: this.r.prefix, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: this.r.punitId, comp: Object.assign({},this.r.comp), cu2siInt: this.r.cu2siInt, cu2si: this.r.cu2si, categoryA: this.r.categoryA, systemA: this.r.systemA, unitA: this.r.unitA });
    this.y = new PhysQty (<PhysConShell>{ name: this.name+"_y", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>y</i><<sub>", value: 0, system: this.r.system, category: this.r.category, unit: this.r.unit, prefix: this.r.prefix, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: this.r.punitId, comp: Object.assign({},this.r.comp), cu2siInt: this.r.cu2siInt, cu2si: this.r.cu2si, categoryA: this.r.categoryA, systemA: this.r.systemA, unitA: this.r.unitA });
    this.z = new PhysQty (<PhysConShell>{ name: this.name+"_z", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub><i>z</i></sub>", value: 0, system: this.r.system, category: this.r.category, unit: this.r.unit, prefix: this.r.prefix, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: this.r.punitId, comp: Object.assign({},this.r.comp), cu2siInt: this.r.cu2siInt, cu2si: this.r.cu2si, categoryA: this.r.categoryA, systemA: this.r.systemA, unitA: this.r.unitA });
    this.o = new PhysQty (<PhysConShell>{ name: this.name+"_rho", nameHtml: this.rmVecOverhead(this.nameHtml)+"<sub>&rho;<sub>", value: 0, system: this.r.system, category: this.r.category, unit: this.r.unit, prefix: this.r.prefix, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: this.r.punitId, comp: Object.assign({},this.r.comp), cu2siInt: this.r.cu2siInt, cu2si: this.r.cu2si, categoryA: this.r.categoryA, systemA: this.r.systemA, unitA: this.r.unitA });

    if (this.t.pdimId != 1 || this.p.pdimId != 1) { this.resetAll7SI(); this.dimId = -1; return; }
    this.dimId = this.r.pdimId;

    this.setWSpherical();
  }

  setWSpherical(): void {
    this.cosys = 's';
    this.x.setNewValueSI( this.r.valueSI * Math.sin(this.t.valueSI) * Math.cos(this.p.valueSI) );
    this.y.setNewValueSI( this.r.valueSI * Math.sin(this.t.valueSI) * Math.sin(this.p.valueSI) );
    this.z.setNewValueSI( this.r.valueSI * Math.cos(this.t.valueSI) );
    this.o.setNewValueSI( this.r.valueSI * Math.sin(this.t.valueSI) );
  }

  private resetAll7SI(): void { this.comps7.forEach(function(c:string){ this[c].setNewValueSI(0); }.bind(this)); return; }

  /**
   * Add/Subtract the Vector2d with another
   * @remarks This method CHANGES this/self
   * @param v - The Vector3d to be added/subtracted. Must have same dimension as this Vector3d.
   * @param subtract - Indicate subtraction (true) or regular addition (false)
   * @returns this: The resulting sum or difference
   */
  adds(v: Vector3d, subtract = false): Vector3d {
    if (this.dimId != v.dimId) { console.log("Cannot add two vectors of different dimensions."); return; }
    this.x.adds(v.x,subtract);
    this.y.adds(v.y,subtract);
    this.z.adds(v.z,subtract);
    this.setWRectangular();
    return this;
  }
  subtracts(v: Vector3d): Vector3d { return this.adds(v,true); }

  /**
   * Dot/Scalar product with another Vector3d
   * @remarks This does not alter the original vector
   * @param v - The Vector3d to be dot with. Can be of any dimension.
   * @returns the scalar
   */
  dot(v: Vector3d): PhysQty {
    const res: PhysQty = this.x.dcopy();
    res.name = this.name+" dot "+ v.name;
    res.nameHtml = this.nameHtml+" &bull; "+v.nameHtml;
    return res.times(v.x).adds(this.y.dcopy().times(v.y)).adds(this.z.dcopy().times(v.z));
  }
  scalar(v: Vector3d): PhysQty { return this.dot(v); }

  /**
   * Angle bewteen the two vectors using Dot/Scalar product
   * @remarks This does not alter the original vector
   * @param v - The Vector3d to be compared with. Can be of any dimension.
   * @returns the angle in radian
   */
  dottheta(v: Vector3d): PhysQty {
    let a12 = this.dot(v).valueSI;
    a12 = Math.acos(a12 / this.r.valueSI / v.r.valueSI);
    a12 = (a12<0)?a12+Math.PI:a12;
    // TODO include uncertainty
    return new PhysQty(<PhysConShell>{ name: "angle "+this.name+"_"+v.name, nameHtml: "angle "+v.nameHtml+"_"+v.nameHtml, value: a12, system: "SI", category: "angle", unit: "", prepower: 0, stdErr: 0, exact: true, punitId: 1 });
  }

  /**
   * Cross/Vector product with another Vector3d
   * @remarks This does not alter the original vector
   * @param v - The Vector3d to be dot with. Can be of any dimension.
   * @returns the Vector3d cross product
   */
  cross(v: Vector3d): Vector3d {
    // TODO include uncertainty
    const xc = this.y.dcopy().times(v.z).subtracts(this.z.dcopy().times(v.y));
    const yc = this.z.dcopy().times(v.x).subtracts(this.x.dcopy().times(v.z));
    const zc = this.x.dcopy().times(v.y).subtracts(this.y.dcopy().times(v.x));
    const input = {
      cosys: "r",
      x: xc,
      y: yc,
      z: zc,
      name: this.name+" cross "+ v.name,
      nameHtml: this.nameHtml+" &times; "+ v.nameHtml
    }
    return new Vector3d(input);
  }


  rmVecOverhead(name:string): string { return name.replace('<var class="vector">','').replace('<span>&#8407;</span></var>',''); }

  dcopy(): Vector3d { return new Vector3d({sys:'r', x:this.x.dcopy(), y:this.y.dcopy(), z:this.z.dcopy()}); } // will set all others at instantiation

} // end Vector3d




@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class TensorModule {
  constructor(@Optional() @SkipSelf() parentModule: TensorModule) {
    if (parentModule) { throw new Error('TensorModule is already loaded. Import it in the AppModule only'); }
  }

  static forRoot(): ModuleWithProviders {
    // console.log("instantiating TensorModule.forRoot");
    return {
      ngModule: TensorModule,
      // providers: [ PhysDim, PhysUnit, PhysQty0, PhysQty, PhysCon ]
      providers: [ ]
    };
  }
}
