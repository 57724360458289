import { Injectable, Optional } from '@angular/core';

// let nextId = 1; 

@Injectable({ providedIn: 'root' })
export class GlobalDigitService {
  // idcnt = nextId++;
  gDigit: number;

  constructor() {
    this.gDigit = (this.gDigit && this.gDigit >=2 && this.gDigit <= 12)? Math.floor(this.gDigit) : 4; 
  }
  // constructor(@Optional() dg: number ) { 
  //   // forRoot() will overide these... this constructor is not called
  //   this.gDigit = (dg && dg >=2 && dg <= 12)? Math.floor(dg) : 5; 
  //   // console.log("instantiating GlobalDigitService.");
  //   // console.log(`the idcnt = ${this.idcnt}.`);
  //   // console.log(this);
  // }

  // setDigit(x: number): void { 
  //   if (x && x >=2 && x <= 12) {this.gDigit = Math.floor(x);}else if(x<2){this.gDigit=2;}else if(x>12){this.gDigit=12;}else{this.gDigit=4;} 
  // }
}
