import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalDigitService } from './global-digit.service';
import { GlobalRevealLevelService } from './global-reveal-level.service';
import { GlobalSigfigService } from './global-sigfig.service';
import { GlobalSystemService } from './global-system.service';
import { GlobalSiteTitleService } from './global-site-title.service';
import { GlobalClipboardConfig } from 'src/app/common/scratch-pad/global-clipboard.service';
// import { BasicMathModule } from 'src/app/numericLibrary/basic-math/basic-math.module';


@NgModule({
  declarations: [],
  providers: [
    // GlobalDigitService, 
    // GlobalRevealLevelService, 
    // GlobalSigfigService, 
    // GlobalSystemService,
  ],
  imports: [
    CommonModule,
  ],
  exports: []
})
export class GlobalVarsModule { 
  constructor(@Optional() @SkipSelf() parentModule: GlobalVarsModule, private _gds: GlobalDigitService, private _gsys: GlobalSystemService, private _grev: GlobalRevealLevelService, private _gsig: GlobalSigfigService, private _gtitle: GlobalSiteTitleService) {
    if (parentModule) { throw new Error('GlobalVarsModule is already loaded. Import it in the AppModule only'); }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: GlobalVarsModule,
      providers: [
      ]
    };
  }

  setDigit(x: number): void { 
    if (x && x >=2 && x <= 12) {this._gds.gDigit = Math.floor(x);}else if(x<2){this._gds.gDigit=2;}else if(x>12){this._gds.gDigit=12;}else{this._gds.gDigit=4;} 
  }

  setSystem(x: string): void { this._gsys.gSystem = (x==null||!x||x==''||x=='si'||x=='S.I.'||x=='s.i.'||x=='Si')?'SI':x; }

  setReveal(x: number): void { 
    if (x >=0 && x <= 9) {this._grev.gReveal = Math.floor(x);}else if(x<0){this._grev.gReveal=0;}else if(x>9){this._grev.gReveal=9;}else{this._grev.gReveal=9;}; 
  }

  setSigfig(x: string): void { this._gsig.gSigfig = ( x=='d' || x=='deci' || x=='decimal' || x=='decimals')?'decimals':'sig.fig.' ;}

  setSiteTitle(x: string): void { this._gtitle.gTitle = (x) ? x : 'Physics' ; }

}
