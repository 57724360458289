import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vector3d-figure-legend-svg',
  templateUrl: './vector3d-figure-legend-svg.component.svg',
  styleUrls: ['./vector3d-figure-legend-svg.component.scss']
})
export class Vector3dFigureLegendSvgComponent implements OnInit {
  @Input() ftype: string; // sum or product 's', 'p'
  thiswidth: number;
  y: number[]; 
  x0: number;
  x1: number;
  x2: number;
  txtwidth: number;
  readonly figwidth = 260;
  readonly figheight = 320;

  constructor() { }

  ngOnInit() {
    this.thiswidth = 270;
    // this.y = [...Array(9).keys()].map(x => 50+30*x);; // array of 9 numbers, for positioning y, 50, 80, 110, 140, ...
    this.y = [50,80,120,160,190,225,260,290,255]; // last 
		this.x0 = 0.05*this.thiswidth; 
		this.x1 = 0.30*this.thiswidth;
		this.x2 = 0.33*this.thiswidth;
		this.txtwidth = 0.62*this.thiswidth;
  }

  nameVec(symbol:string ,sub:string =null,sup:string =null){ 
    let htm = "";
    htm += "<var class=\"vector\"><i>"+symbol+"</i><span>&#8407;</span></var>";
    htm += (sub)? "<sub>"+sub+"</sub>" : "";
    htm += (sup)? "<sup>"+sup+"</sup>" : "";
    return htm; 
  }

  get dsector(): string { return "M "+this.x0+" "+(this.y[2]+18)+" L "+this.x1+" "+(this.y[2]+18)+" A "+40+" "+40+" 0 0 0 "+(this.x0+48)+" "+(this.y[2]-15)+ " z"; } // these coordinates make a 3-4-5 triangle, with base of length/radius 40, height 27, and 32 is the perpendicular position of high point
  get dsm4gram(): string { return "M "+this.x0+" "+(this.y[7]-14)+" L "+(0.35*this.x0+0.65*this.x1)+" "+(this.y[7]-14)+" L "+this.x1+" "+(this.y[7]+14)+" L "+(0.65*this.x0+0.35*this.x1)+" "+(this.y[7]+14)+ " z"; } // data polygon: 4 sided parallogram, shaded

}
