import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MonologComponent } from 'src/app/service/monolog-box/monolog/monolog.component';
import { MonologService } from 'src/app/service/monolog-box/monolog.service';

@Component({
  selector: 'app-help-bar',
  templateUrl: './help-bar.component.html',
  styleUrls: ['./help-bar.component.scss']
})
export class HelpBarComponent implements OnInit {

  constructor(private _mono: MonologService ) { }

  ngOnInit() {
  }

  showDiag(type: string): MatDialogRef<MonologComponent>  {
  // reveal(facts?: FactoryService | Factopia | Factsier | Factsheet | Factum, bkmark?: Bookmark): MatDialogRef<RevealComponent>  {
    // return this._mono.openlog({ override: {title: 'Notes', message: 'special message' } }); 
    return this._mono.openlog({ helper: type }); 
    // return this._revs.reveal(facts,bkmark);
    // return this._revs.reveal({}); // { facts?: FactoryService | Factopia | Factsier | Factsheet | Factum, bkmark?: Bookmark, override?: RevealOverride, additionalDialogConfigData?: any }
  }
  

}
