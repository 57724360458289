import { Pipe, PipeTransform } from '@angular/core';
import { PhysQty, PhysQty0 } from 'src/app/numeric-library/bbb/bbb.module';
import { ValueDispPipe } from './value-disp.pipe';
import { PfxUnitDispPipe } from './pfx-unit-disp.pipe';
import { PhysConShell } from 'src/app/numeric-library/invariant/global-phys-units.service';

@Pipe({ name: 'valueUnitDisp', pure: false })
export class ValueUnitDispPipe implements PipeTransform {
  transform(pq: PhysQty|PhysQty0|PhysConShell): string {
    const vdp = new ValueDispPipe();
    const pudp = new PfxUnitDispPipe;
    return vdp.transform(pq)+" "+pudp.transform(pq);
  }
}
