import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhysQty, PhysUnit } from '../bbb/bbb.module';
import { GlobalPhysUnitsService, PhysConShell, PhysDimComp } from '../invariant/global-phys-units.service';
import { AppInjector } from 'src/app/app-injector';
import { PureMathService } from '../operator/pure-math.service';
import { PqtyMathService } from '../operator/pqty-math.service';
import { FactoryService, UsrCom, UsrComForm } from 'src/app/service/facts/factory.service';
import { Factopia, Factsier, Factsheet, Bookmark } from 'src/app/service/facts/facts.module';
import { SnackBarService, SnackBarMsg } from 'src/app/service/facts/snack-bar.service';

export interface Pt2d {
  // readonly type: 'pt2d';
  x: PhysQty;
  y: PhysQty;
}

/**
 * When computing parabolas, for example, from data points, it is useful to have the quantity a = (m_31 - m_21)/(x_3 - x_2)
 * where m_31 is the slope between points 3-1 and so forth. In other words, the coefficient a is literally the second derivative
 * if you view (x_3, m_31) and (x_2, m_21) are the slope/secant vectors or the slope fields.
 * unlike Vector2d, x and y can have different dimensions, hence no polar or shperical forms.
 * Slopes will have calculated dimensions.
 */
export class Point2d  {
  x: PhysQty;
  y: PhysQty;
  usrcom: UsrCom; // popup: boolean; reveal: boolean; notebook: FactoryService | Factopia | Factsier | Factsheet; notepad: Factsheet; private _sbs: SnackBarService;

  constructor(input: Pt2d, usrcom?: UsrComForm ) {
    this.x = input.x.dcopy();
    this.y = input.y.dcopy();
    this.usrcom = (usrcom) ? new UsrCom(usrcom) : null;
  }

  /**
   * Add/Subtract the Point2d with another
   * @remarks This method CHANGES this/self
   * @param pt - The Point2d to be added/subtracted. Must have same dimension as this Point2d in x and y.
   * @param subtract - Indicate subtraction (true) or regular addition (false)
   * @returns this: The resulting sum or difference
   */
  adds(pt: Point2d, subtract: boolean = false): Point2d {
    if (!this.x.compcomp(pt.x) || !this.y.compcomp(pt.y)) { console.log("Failed adding two Point2d. Dimensions incompatible."); return this; }
    this.x.adds(pt.x, subtract);
    this.y.adds(pt.y, subtract);
    return this;
  }
  subtracts(pt: Point2d): Point2d { return this.adds(pt, true); }

  /**
   * The slope of the point with origin O.
   */
  slope0(): PhysQty {
    if (PureMathService.pchk(this.x.valueSI, 0, 0.00001)) { console.log(`Slope undefined.`); return null; }
    return this.y.dcopy().divides(this.x);
  }

  /**
   * The slope of the point with arbitrary origin P.
   * @param pt - The reference point instead of the origin to calculate the slope. Must have same dimension as this Point2d in x and y.
   */
  slopeP(pt: Point2d): PhysQty {
    if (PureMathService.pchk(this.x.valueSI, pt.x.valueSI, 0.00001)) { console.log(`Slope undefined for slope1().`); return null; }
    return this.dcopy().subtracts(pt).slope0();
  }


  /**
   * This will calculate the "average velocity" for a finite 'time' difference, w.r.t. the origin O.
   * @returns the resulting average velocity 'vector' or 'Point2d', with dimensions (x, y/x)
   */
  avgVel0(): Point2d {
    const slope = this.slope0();
    if (!slope) { console.log(`avgVel0 undefined because slope0 undefined.`); return null; }
    return new Point2d( { x: this.x.dcopy() , y: slope } );
  }

  /**
   * This will calculate the "average velocity" for a finite 'time' difference with arbitrary reference point P.
   * @param pt The new reference point, as in the origin O.
   * @returns the resulting average velocity 'vector' or 'Point2d', with dimensions (x, y/x)
   */
  avgVelP(pt: Point2d): Point2d {
    const slope = this.dcopy().slopeP(pt);
    if (!slope) { console.log(`avgVelP undefined because slopeP undefined.`); return null; }
    return new Point2d( { x: this.x.dcopy().subtracts(pt.x) , y: slope } );  // The Point2d returned is ( delta x, delta y / delta x )
  }

  dcopy(): Point2d { return new Point2d( { x: this.x.dcopy(), y: this.y.dcopy() } ); }

}


// generic Triangle object
export class Triangle {
  readonly comp6: string[] = ['a','b','c','A','B','C'];
  readonly comp3s: string[] = ['a','b','c'];
  readonly comp3a: string[] = ['A','B','C'];
  private _gpu: GlobalPhysUnitsService;
  name: string;
  nameHtml: string;
  dimId: number;
  a: PhysQty ;
  b: PhysQty ;
  c: PhysQty ;
  A: PhysQty ; // angle
  B: PhysQty ; // angle
  C: PhysQty ; // angle
  valid: boolean; // need some outside checks to validate

	constructor( input: {a?: PhysQty, b?: PhysQty, c?: PhysQty, A?: PhysQty, B?: PhysQty, C?: PhysQty, name?: string, nameHtml?: string, dimId?: number, valid?: boolean} ) {
    this._gpu = AppInjector.get(GlobalPhysUnitsService);
		this.name = (input.name)?input.name:'triangle';
    this.nameHtml = (input.nameHtml)?input.nameHtml:this.name;
    this.nameHtml = (!input.nameHtml && !input.name)?'&#9651;' : this.nameHtml; // if nothing given, use the special character rather than 'triangle'
    this.dimId = (input.dimId) ? input.dimId : this.dimId ;
    this.valid = (input.valid) ? input.valid : false ;
    this.a = (input.a)? input.a : null;
    this.b = (input.b)? input.b : null;
    this.c = (input.c)? input.c : null;
    this.A = (input.A)? input.A : null;
    this.B = (input.B)? input.B : null;
    this.C = (input.C)? input.C : null;

    this.setvsides();
    this.setvangles();

  }

  // get sideCnt(): number { let cnt=0; this.comp3s.forEach(function(s:string){cnt+=(this[s])?1:0;}.bind(this)); return cnt; } // count # of non-null sides
  // get angleCnt(): number { let cnt=0; this.comp3a.forEach(function(s:string){cnt+=(this[s])?1:0;}.bind(this)); return cnt; } // count # of non-null angles
  // get valueCnt(): number { return this.sideCnt + this.angleCnt; } // count # of non-nulls
  // get valCnt(): number { let cnt=0; this.comp6.forEach(function(s:string){cnt+=(this[s])?1:0;}.bind(this)); return cnt; } // count # of non-nulls

  // get validSideCnt(): number { // valid side count, make sure lengths >0. Units are not checked. Those are reflected in this.valid
  //   let cnt=0;
  //   let curpid: number = null;
  //   this.comp3s.forEach(function(s:string){
  //     if (this[s] && this[s].valueSI > 0) { // check dimension
  //       if (curpid===null) { cnt++; curpid = this[s].pdimId; this.dimId = curpid; } else
  //       if (curpid == this[s].pdimId) { cnt++; }
  //       else { this[s] = null; console.warn(`Triangle input has inconsistent dimensions pdimId ${curpid} and ${this[s].pdimId}.`); }
  //     }
  //   }.bind(this));
  //   return cnt;
  // }
  // get validAngleCnt(): number { // valid angle count, make sure angles pdimId == 1, and valueSI between 0 and pi.
  //   let cnt=0;
  //   this.comp3a.forEach(function(s:string){ cnt+=(this[s] && this[s].valueSI > 0 && this[s].valueSI < Math.PI && this[s].pdimId ==1)?1:0; }.bind(this));
  //   return cnt;
  // }
  // get validValCnt(): number {
  //   const scnt = this.validSideCnt;
  //   const tot = scnt + this.validAngleCnt;
  //   if (tot<3) { this.valid=false; } else
  //   if (tot==3 && scnt ==0) { this.valid = false; }
  //   return tot;
  // } // if less than 3 knowns, or case AAA, set valid = false

  get validValCnt(): number { return this.validSideCnt+this.validAngleCnt; }

  get vsides(): string[] { let ans=[]; this.comp3s.forEach(function(s:string){ if( this[s] && this[s].value !== null ) { ans.push(s); } }.bind(this)); return ans; } // get the sides that are valid (non-null)
  get validSideCnt(): number { return this.vsides.length; }
  setvsides(): void { // set valid sides
    const arr = this.vsides; // this might change after setvsides, at least we can fix dimId easily with this.vsides
    if (arr.length ==0) { this.dimId = (this.dimId >0) ? this.dimId : 1 } // set dimensionless if no previous value
    else { this.dimId = this[ arr[0] ].pdimId; } // take the first one as guide

    this.comp3s.forEach(function(s:string){
      if ( !this[s] || this[s].pdimId != this.dimId ) { // give it a blank null value with the correct dimension
        if (this[s] && this[s].pdimId != this.dimId) { console.warn(`Triangle input has inconsistent dimensions pdimId ${this.dimId} and ${this[s].pdimId}.`); }
        const pu = this._gpu.findPhysUnit({pdimId: this.dimId});
        this[s] = new PhysQty( <PhysConShell>Object.assign(pu,{ name: s, nameHtml: '<i>'+s+'</i>', value: 0 }) );
        this[s].value = null; // valueSI =0, but value = null, to be used as a null input
      } else
      if ( this[s].valueSI <0 ) { this[s].setNewValue(0); this[s].value = null; }
    }.bind(this));
    return;
  }

  get vangles(): string[] { let ans=[]; this.comp3a.forEach(function(s:string){ if(this[s] && this[s].value !==null ) { ans.push(s); } }.bind(this)); return ans; }  // get the angles that are valid (non-null)
  get validAngleCnt(): number { return this.vangles.length; }
  setvangles(): void { // set valid angles
    this.comp3a.forEach(function(s:string){
      if ( !this[s] || this[s].pdimId != 1 ) { // give it a blank null value with the correct dimension
        if (this[s] && this[s].pdimId != 1) { console.warn(`Triangle input angles has wrong dimensions pdimId: ${this[s].pdimId}.`); }
        const pu = this._gpu.findPhysUnit({punitId: 1});
        this[s] = new PhysQty( <PhysConShell>Object.assign(pu,{ name: s, nameHtml: '<i>'+s+'</i>', value: 0 }) );
        this[s].value = null; // valueSI =0, but value = null, to be used as a null input
      } else
      if ( this[s].valueSI < 0 && this[s].valueSI > Math.PI  ) { this[s].setNewValue(0); this[s].value = null; }
    }.bind(this));
    return;
  }

  makeSvg(): void {
    if (this.valid) {
      // send to TriangleFigureSVG to visualize
      // return SVG?
    }
  }
} // end Triangle

export interface ParabolarForm {
  // readonly type: 'parabolarform';
  inptype: string; // abc/a, or vertex/v, or roots/r, or data/d
  name?: string;
  nameHtml?: string;
  bdimId?: number; // dimension of [b], fundamental, also same as slopes ss[]
  ydimId?: number; // same as [c] dimension, also f (the function) and k the vertex
  // next two are calculated. Could have been set up as methods or getters.
  // xdimId?: number; // xdim determined by [y]/[b] , also applies to r1, r2 (roots), and h of vertex.
  // adimId?: number; // adim determined by [b^2]/[y]

  complex?: boolean; // default?: true

  a?: PhysQty; // should not be zero

  b?: PhysQty;
  c?: PhysQty;
  h?: PhysQty;
  k?: PhysQty;
  r1?: PhysQty;
  r2?: PhysQty;

  // vecs?: Point2d[]; // need at least two to pass to parabolaFigureSvg
  xs?: PhysQty[] | number[];
  ys?: PhysQty[] | number[];
  ss?: PhysQty[] | number[]; // slopes at these points
}

/**
 * General Parabola object
 * @param input a,b,c, h,k, r1,r2, xs,ys:PhysQty[], inptype: 'abc'|'vertex'|'roots'|'data' , bdimId,ydimId, name, nameHtml, complex: boolean,
 */
export class Parabola {
  readonly comp7: string[] = ['a','b','c','h','k','r1','r2'];
  readonly comp7names: object = { a: {name: 'a', nameHtml: '<i>a</i>'}, b: {name: 'b', nameHtml: '<i>b</i>'}, c: {name: 'c', nameHtml: '<i>c</i>'}, h: {name: 'h', nameHtml: '<i>h</i>'}, k:{name: 'k', nameHtml: '<i>k</i>'}, r1: {name: 'r1', nameHtml: '<i>r</i><sub>1</sub>'}, r2: {name: 'r2', nameHtml: '<i>r</i><sub>2</sub>'} }; // the names 'a', 'b', etc can be changed per user, in the saveAll7Names function, run at end of constructor.
  // readonly comp7namehtml: object = { a:'<i>a</i>', b:'<i>b</i>', c:'<i>c</i>', h:'<i>h</i>', k:'<i>k</i>', r1:'<i>r</i><sub>1</sub>', r2:'<i>r</i><sub>2</sub>' };
  readonly comp3abc: string[] = ['a','b','c'];
  readonly comp3ahk: string[] = ['a','h','k'];
  readonly comp3r: string[] = ['a','r1','r2'];
  private _nullpq: PhysQty = new PhysQty(<PhysConShell>{ comp: new PhysDimComp({M:0}), valueSI: 0, exact: true });

  usrcom: UsrCom;

  // service variables (use AppInjector instead of DI-dependency injection to allow easy instantiation)
  // _sbs: SnackBarService;
  // _facts: FactoryService;
  // facts0: Factopia | Factsier | Factsheet; // from constructor input, or from singleton _facts
  // bkmark: Bookmark; // either from constructor input if there is altfacts, or from singleton _facts
  // solnsheet: Factsheet;
  // private _pqm: PqtyMathService;
  // private _eeee: EeeeService;
  private _gpu: GlobalPhysUnitsService;
  // private _gcb: GlobalClipboardService;

  name: string;
  nameHtml: string;
  bdimId: number; // dimension of [b], fundamental, also same as slopes ss[]
  ydimId: number; // same as [c] dimension, also f (the function) and k the vertex
  // next two are calculated. Could have been set up as methods or getters.
  xdimId: number; // xdim determined by [y]/[b] , also applies to r1, r2 (roots), and h of vertex.
  adimId: number; // adim determined by [b^2]/[y]

  solnCnt: number[]; // length 2 array, indicating number of real and complex solutions. [2,0], [1,0], [0,2] etc. [1,1] is rare, only if coefficients are complex.

  inptype: string; // abc/a, or vertex/v, or roots/r, or data/d
  complex: boolean; // default: true

  a: PhysQty; // should not be zero

  b: PhysQty;
  c: PhysQty;

  h: PhysQty;
  k: PhysQty;

  r1: PhysQty;
  r2: PhysQty;

  // vecs: Vector2d[]; // need at least two to pass to parabolaFigureSvg
  xs: PhysQty[];
  ys: PhysQty[];
  ss: PhysQty[]; // The corresponding slopes at those points, same dimension as b


	constructor( input: ParabolarForm, usrcom?: UsrComForm ) {
    // this._pqm = AppInjector.get(PqtyMathService);
    // this._eeee = AppInjector.get(EeeeService);
    this._gpu = AppInjector.get(GlobalPhysUnitsService);
    // this._gcb = AppInjector.get(GlobalClipboardService);
    // this._facts = AppInjector.get(FactoryService);
    // this._sbs = AppInjector.get(SnackBarService);
    // this.facts0 = (input.facts0) ? input.facts0 : this._facts;
    // this.bkmark = (input.bkmark) ? input.bkmark : this._facts.bkmark;
    this.usrcom = (usrcom) ? new UsrCom(usrcom) : null;
    // this.solnsheet = this.facts0.

    this.name = (input.name)?input.name:'parabola';
    this.nameHtml = (input.nameHtml)?input.nameHtml:this.name;
    // this.nameHtml = (!input.nameHtml && !input.name)?'&#9651;' : this.nameHtml; // if nothing given, use the special character rather than 'triangle'
    this.complex = (input.complex) ? input.complex : false ; // default no complex solutions
    this.solnCnt = [2,0]; // default

    this.bdimId = (input.bdimId) ? input.bdimId : this.bdimId ;
    this.ydimId = (input.ydimId) ? input.ydimId : this.ydimId ;
    this.inptype = (input.inptype) ? input.inptype : 'a' ; // default abc inptype

    // const nullpq: PhysQty = new PhysQty(<PhysConShell>{ comp: new PhysDimComp({M:0}), valueSI: 0, exact: true });
    this._nullpq.value = null;

    // if xs and or ys are numbers instead of PhysQty, make them scalar PhysQty first. Will deal with them later. // regardless inptype = 'data' or not
    this.xs = [];
    const xsl = (input.xs) ? input.xs.length : 0;
    this.ys = [];
    const ysl = (input.ys) ? input.ys.length : 0;
    this.ss = [];
    const ssl = (input.ss) ? input.ss.length : 0;
    // fix number[] inputs into PhysQty[]
    for (var i=0; i<xsl; i++) { if ( !(input.xs[i] instanceof PhysQty) ) this.xs.push(new PhysQty( <PhysConShell>{ comp: new PhysDimComp({M:0}), valueSI: input.xs[i], name:'x', nameHtml:'<i>x</i>', exact: true } ) ); }
    for (var i=0; i<ysl; i++) { if ( !(input.ys[i] instanceof PhysQty) ) this.ys.push(new PhysQty( <PhysConShell>{ comp: new PhysDimComp({M:0}), valueSI: input.ys[i], name:'y', nameHtml:'<i>y</i>', exact: true } ) ); }
    for (var i=0; i<ssl; i++) { if ( !(input.ss[i] instanceof PhysQty) ) this.ss.push(new PhysQty( <PhysConShell>{ comp: new PhysDimComp({M:0}), valueSI: input.ss[i], name:'s', nameHtml:'<i>s</i>', exact: true } ) ); }

    if (this.inptype == 'a' || this.inptype == 'abc') {
      this.a = (input.a) ? this.a = input.a.dcopy() : null;
      this.b = (input.b) ? this.b = input.b.dcopy() : null;
      this.c = (input.c) ? this.c = input.c.dcopy() : null;
    } else if (this.inptype == 'v' || this.inptype == 'vertex') {
      this.a = (input.a) ? this.a = input.a.dcopy() : null;
      this.h = (input.h) ? this.h = input.h.dcopy() : null;
      this.k = (input.k) ? this.k = input.k.dcopy() : null;
    } else if (this.inptype == 'r' || this.inptype == 'root' || this.inptype == 'roots') {
      this.a = (input.a) ? this.a = input.a.dcopy() : null;
      this.r1 = (input.r1) ? this.r1 = input.r1.dcopy() : null;
      this.r2 = (input.r2) ? this.r2 = input.r2.dcopy() : null;
    }

    let failed = false;
    failed = failed || this.setPdimIds() ;
    failed = failed || this.chkXsYs();
    // if ( !this.setPdimIds() ) { console.log('Given physical dimensions have errors. Please fix.'); return; }
    // if ( !this.chkXsYs() ) { console.log('Given Xs and Ys values have errors. Please fix.'); return; } // need xdimId already set from setPdimIds

    this.setAllVals();
    this.saveAll7Names();

  } // end constructor

  /*
  f = a x^2 + b x + c   (abc form)
  f = a (x-h)^2 + k     (vertex form)
  f = a (x-r1)(x-r2)    (roots form)
  three xs, ys          (data form)
  */

  /*
  We first decided on this ring of compuations, between abc -> vertex form -> roots --> abc
  And abc <-> data
  NO NO NO, before we implement complex solutions, this chain is not safe.
  If starting from vertex form -> no real solution, then cannot get the abc form.

  NEED to use abc as the canonical central hub.
  */

  /**
   * The quadratic function f(x). Result will follow unit and prefix set from this.k if available.
   * @param x
   */
  f(x: PhysQty): PhysQty {
    let res: PhysQty;
    if ( x.pdimId != this.xdimId ) { const oval = x.valueSI; x = this.h.dcopy(); x.setNewValueSI(oval); }
    // if wrong pdimId, just take the SI value and assume that's what is intended.

    if (this.inptype=='v'|| this.inptype=='vertex'){
      res = x.dcopy().subtracts(this.h).power(2).times(this.a).adds(this.k);
      // ans.setNewValueSI( this.a.valueSI*((xv - this.h.valueSI)**2)+this.k.valueSI ); // using this will not compute error propagation in the future.
    } else if (this.inptype=='r' || this.inptype=='roots' || this.inptype=='root' ){
      const tmp = x.dcopy();
      res = x.dcopy().subtracts(this.r1).times(this.a).times( tmp.subtracts(this.r2) );
      // ans.setNewValueSI( this.a.valueSI*xv*xv + this.b.valueSI*xv +this.c.valueSI );
    } else { // if (this.inptype == 'a' || this.inptype=='abc') { // default case, assume 'abc' or 'a'
      res = x.dcopy().times(this.a).adds(this.b).times(x).adds(this.c);
      // ans.setNewValueSI( this.a.valueSI*(xv - this.r1.valueSI)*(xv - this.r2.valueSI) );
    }
    // if given say form 'abc', but you first calculate form 'vertex', then use that function, it could increase error progation.
    res.name = "f(x)";
    res.nameHtml = "<i>f</i>(<i>x</i>)";
    if (x.value == null) { res.value = null; } // if x only has valueSI, but value=null, only concern with the units/dimensions
    // try to follow units from this.k
    if (this.k) { res.chgSysUnitID(this.k); res.setPrefixVals(this.k.prepower); res.setNewValueSI(null, true); }
    return res;
  }

  /**
   * f', the slope function. Result will follow unit and prefix set from this.b if available.
   * @param x
   */
  fp(x: PhysQty): PhysQty {
    let res: PhysQty;
    if ( x.pdimId != this.xdimId ) { const oval = x.valueSI; x = this.h.dcopy(); x.setNewValueSI(oval); }
    // if wrong pdimId, just take the SI value and assume that's what is intended.

    if (this.inptype=='v'|| this.inptype=='vertex'){
      res = x.dcopy().subtracts(this.h).stimes(2).times(this.a);
    } else if (this.inptype=='r' || this.inptype=='roots' || this.inptype=='root' ){
      res = x.dcopy().stimes(2).subtracts(this.r1).subtracts(this.r2).times(this.a);
    } else { // if (this.inptype == 'a' || this.inptype=='abc') { // default case, assume 'abc' or 'a'
      res = x.dcopy().stimes(2).times(this.a).adds(this.b);
    }
    // if given say form 'abc', but you first calculate form 'vertex', then use that function, it could increase error progation.
    res.name = "f'(x)";
    res.nameHtml = "<i>f'</i>(<i>x</i>)";
    if (x.value == null) { res.value = null; } // if x only has valueSI, but value=null, only concern with the units/dimensions
    // try to follow units from this.b
    if (this.b) { res.chgSysUnitID(this.b); res.setPrefixVals(this.b.prepower); res.setNewValueSI(null, true); }
    return res;
  }

  // bdimId: number; // dimension of [b], fundamental, also the same as ss (slope) dimensions
  // ydimId: number; // same as [c] dimension, also f (the function) and k the vertex
  // // next two are calculated. Could have been set up as methods or getters.
  // xdimId: number; // xdim determined by [y]/[b] , also applies to r1, r2 (roots), and h of vertex.
  // adimId: number; // adim determined by [b^2]/[y]
  setPdimIds(): boolean {
    let failed = false;
    if ( this.inptype=='r' || this.inptype=='root' ||this.inptype=='roots' ) {
      if (!this.a) {
        console.log("No given 'a' in quadratic. Assume it is dimensionless for now.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "No given 'a' in quadratic. Assume it is dimensionless for now.", action: "Error", duration: 3500 }); }
        this.a = this._nullpq.dcopy(); this.a.name = "a"; this.a.nameHtml = "<i>a</i>";
        failed = true;
      }
      if (!this.r1) {
        console.log("Root 1 is not given in quadratic. Assume it is dimensionless for now.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "No given 'r1' in quadratic. Assume it is dimensionless for now.", action: "Error", duration: 3500 }); }
        this.r1 = this._nullpq.dcopy(); this.r1.name = "r1"; this.a.nameHtml = "<i>r1</i>";
        failed = true;
      }
      if (!this.r2) {
        console.log("Root 2 is not given in quadratic. Assume it is dimensionless for now.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "No given 'r2' in quadratic. Assume it is dimensionless for now.", action: "Error", duration: 3500 }); }
        this.r2 = this._nullpq.dcopy(); this.r2.name = "r2"; this.a.nameHtml = "<i>r2</i>";
        failed = true;
      }
      if (this.r1.pdimId != this.r2.pdimId) {
        const t = this.r2.value;
        this.r2.updatePunits( this.r1 ) ;
        console.log("The two roots are of incompatible dimensions in quadratic.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "The two roots are of incompatible dimensions in quadratic. Fixed r2 to match r1 for now.", action: "Error", duration: 3500 }); }
        failed = true;
      }
      this.xdimId = this.r1.pdimId; // find xdimId, adimId, then calculate bdimId, ydimId
      this.adimId = this.a.pdimId;
      this.bdimId = this.a.dcopy().dtimes( this.r1 ).pdimId; // b = a x
      this.ydimId = this.r1.dcopy().dpower(2).dtimes(this.a).pdimId; // y = ax^2 = bx
    } else if ( this.inptype=='v' || this.inptype=='vertex' ) {
      if (!this.a) {
        console.log("No given 'a' in quadratic. Assume it is dimensionless for now.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "No given 'a' in quadratic. Assume it is dimensionless for now.", action: "Error", duration: 3500 }); }
        this.a = this._nullpq.dcopy(); this.a.name = "a"; this.a.nameHtml = "<i>a</i>";
        failed = true;
      }
      if (!this.h) {
        console.log("No given 'h' in quadratic. Assume it is dimensionless for now.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "No given 'h' in quadratic. Assume it is dimensionless for now.", action: "Error", duration: 3500 }); }
        this.h = this._nullpq.dcopy(); this.h.name = "h"; this.h.nameHtml = "<i>h</i>";
        failed = true;
      }
      if (!this.k) {
        console.log("No given 'k' in quadratic. Assume it is dimensionless for now.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "No given 'k' in quadratic. Assume it is dimensionless for now.", action: "Error", duration: 3500 }); }
        this.k = this._nullpq.dcopy(); this.k.name = "k"; this.k.nameHtml = "<i>k</i>";
        failed = true;
      }
      if (this.h.dcopy().dpower(2).dtimes(this.a).pdimId !== this.k.pdimId) {
        console.log("Inconsistent input. Dimensions should satisfy [a][h^2] = [k] in vertex form of quadratic.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "Inconsistent input. Dimensions should satisfy [a][h^2] = [k] in vertex form of quadratic.", action: "Error", duration: 3500 }); }
        failed = true;
      }
      this.adimId = this.a.pdimId;
      this.xdimId = this.h.pdimId;
      this.ydimId = this.k.pdimId;
      this.bdimId = this.h.dcopy().dtimes(this.a).pdimId; // b = ax
    } else if ( this.inptype !='d' && this.inptype !='data' ) { // default case 'a' or 'abc', if not data at this point
      if (!this.a) {
        console.log("No given 'a' in quadratic. Assume it is dimensionless for now.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "No given 'a' in quadratic. Assume it is dimensionless for now.", action: "Error", duration: 3500 }); }
        this.a = this._nullpq.dcopy(); this.a.name = "a"; this.a.nameHtml = "<i>a</i>";
        failed = true;
      }
      if (!this.b) {
        console.log("No given 'b' in quadratic. Assume it is dimensionless for now.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "No given 'b' in quadratic. Assume it is dimensionless for now.", action: "Error", duration: 3500 }); }
        this.b = this._nullpq.dcopy(); this.b.name = "b"; this.b.nameHtml = "<i>b</i>";
        failed = true;
      }
      if (!this.c) {
        console.log("No given 'c' in quadratic. Assume it is dimensionless for now.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "No given 'c' in quadratic. Assume it is dimensionless for now.", action: "Error", duration: 3500 }); }
        this.c = this._nullpq.dcopy(); this.c.name = "c"; this.c.nameHtml = "<i>c</i>";
        failed = true;
      }
      if (this.b.dcopy().dpower(2).ddivides(this.a).pdimId !== this.c.pdimId) {
        console.log("Inconsistent input. Dimensions should satisfy [b^2] = [a][c] in quadratic.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "Inconsistent input. Dimensions should satisfy [b^2] = [a][c] in quadratic.", action: "Error", duration: 3500 }); }
        failed = true;
      }
      this.adimId = this.a.pdimId;
      this.bdimId = this.b.pdimId;
      this.ydimId = this.c.pdimId;
      this.xdimId = this.b.dcopy().ddivides(this.a).pdimId;
    }


    // for data, needs three pairs of xs,ys, and use them to set pdimIds
    // for others, can have up two 2 xs, and ys will be calcuated from there.
    // check lengths
    const xsl = (this.xs) ? this.xs.length : 0;
    const ysl = (this.ys) ? this.ys.length : 0;
    const ssl = (this.ss) ? this.ss.length : 0;

    if ( this.inptype=='d' || this.inptype=="data" ) {
      // take care of xs
      if (xsl > 2) { this.xs.length = 3; } else
      if (xsl > 0) {
        const tmpx = this.xs[0].dcopy();
        tmpx.name="xi"; tmpx.nameHtml="<i>x<sub>i</sub></i>"; tmpx.setNewValueSI(0); tmpx.value=null;
        for (let i=0; i<3-xsl; i++) { this.xs.push( tmpx.dcopy() ); }
        console.log("Not enough x values given. Please fix.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "Not enough x values given. Please fix.", action: "Error", duration: 3500 }); }
        failed = true;
      } else { // xsl = 0
        this._nullpq.name="xi"; this._nullpq.nameHtml="<i>x<sub>i</sub></i>"; this._nullpq.value=null;
        for (let i=0; i<3; i++) { this.xs.push( this._nullpq.dcopy() ); }
        console.log("Not enough x values given. Please fix.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "Not enough x values given. Please fix.", action: "Error", duration: 3500 }); }
        failed = true;
      }
      // take care of ys
      if (ysl > 2) { this.ys.length = 3; } else
      if (ysl > 0) {
        const tmpy = this.ys[0].dcopy();
        tmpy.name="yi"; tmpy.nameHtml="<i>y<sub>i</sub></i>"; tmpy.setNewValueSI(0); tmpy.value=null;
        for (let i=0; i<3-ysl; i++) { this.ys.push( tmpy.dcopy() ); }
        console.log("Not enough y values given. Please fix.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "Not enough y values given. Please fix.", action: "Error", duration: 3500 }); }
        failed = true;
      } else { // ysl = 0
        this._nullpq.name="yi"; this._nullpq.nameHtml="<i>y<sub>i</sub></i>"; this._nullpq.value=null;
        for (let i=0; i<3; i++) { this.ys.push( this._nullpq.dcopy() ); }
        console.log("Not enough y values given. Please fix.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "Not enough y values given. Please fix.", action: "Error", duration: 3500 }); }
        failed = true;
      }
      // take care of ss
      if (ssl > 2) { this.ss.length = 3; } else
      if (ssl > 0) {
        const tmps = this.ss[0].dcopy();
        tmps.name="yi"; tmps.nameHtml="<i>s<sub>i</sub></i>"; tmps.setNewValueSI(0); tmps.value=null;
        for (let i=0; i<3-ssl; i++) { this.ss.push( tmps.dcopy() ); }
        console.log("Not enough y values given. Please fix.");
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "Not enough y values given. Please fix.", action: "Error", duration: 3500 }); }
        failed = true;
      } else { // ssl = 0
        this._nullpq.name="si"; this._nullpq.nameHtml="<i>s<sub>i</sub></i>"; this._nullpq.value=null;
        for (let i=0; i<3; i++) { this.ss.push( this._nullpq.dcopy() ); }
        // failed = true;
      }
      this.sortXYs();

      // now check for pdimId consistency
      const xdid = this.xs[0].pdimId; // if xdid==1, assume the given xs are scalars. If not inptype=='d', then actual xdim will be determined by xdimId. But at least all should be consistent.
      const ydid = this.ys[0].pdimId; // likewise, if ydid==1. if not inptype=='d', then determine by others. But then, if not inptype=='d', no need to give ys.
      const sdid = this.ss[0].pdimId; // This should be same as x/y, which is also bdimId. even 'data' case, we might later allow instead of input 3 data points, to have 2 data points plus one slope. It's like standard boundary value problems in DE or, just initial velocity given in kinematics.
      for(let i=1;i<this.xs.length; i++){ // length should be all fixed at 3 now
        if (this.xs[i].pdimId != xdid) {
          console.log("xs do not have consistent pdimIds");
          if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "xs do not have consistent pdimIds. Used the first given units.", action: "Error", duration: 3500 }); }
          this.xs[i].updatePunits(this.xs[0]);
          failed = true;
        };
        if (this.ys[i].pdimId != ydid) {
          console.log("ys do not have consistent pdimIds");
          if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "ys do not have consistent pdimIds. Used the first given units.", action: "Error", duration: 3500 }); }
          this.ys[i].updatePunits(this.ys[0]);
          failed = true;
        };
      }
      this.xdimId = xdid; // find xdimId, ydimId, then calculate bdimId, adimId.
      this.ydimId = ydid;
      const tmp1 = this.xs[0].dcopy(); tmp1.setNewValueSI(1);
      const tmp = this.ys[0].dcopy().divides(tmp1);
      this.bdimId = tmp.pdimId; // b = y/x
      for(let i=1;i<this.xs.length; i++){ // do that again, now for slopes ss
        if (this.ss[i].pdimId != this.bdimId) {
          console.log("ss do not have consistent pdimIds");
          if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "ss do not have consistent pdimIds. It has been fixed, but please double check.", action: "Error", duration: 3500 }); }
          this.ss[i].updatePunits(tmp);
          failed = true;
        };
      }
      this.adimId = tmp.ddivides(this.xs[0]).pdimId; // a = y/x^2
    } else { // inptype != 'data'
      // the next lines for xs, and when checking pdimId consistency
      let tmpx: PhysQty; // = this.xs[0].dcopy();
      const inp = this.inptype;
      if(inp=='v'||inp=='vertex'){tmpx=this.h.dcopy();}else if(inp=='r'||inp=='root'||inp=='roots'){tmpx=this.r1.dcopy();}else{tmpx=this.b.dcopy().divides(this.a);} // if a.valueSI =0, should still gives null, with the correct dimension
      tmpx.name="xi"; tmpx.nameHtml="<i>x<sub>i</sub></i>"; tmpx.setNewValueSI(0); tmpx.value=null;

      // take care of xs
      if (xsl > 2) { this.xs = (this.xs[2].value != null ) ? [ this.xs[0], this.xs[2] ] : this.xs.slice(0,2); } else
      if (xsl == 2) { }
      else { // xsl == 0 or 1
        for (let i=0; i<2-xsl; i++) { this.xs.push( tmpx.dcopy() ); }
        if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "Not enough x values given to set the graph.", action: "Error", duration: 3500 }); }
        failed = true;
      }

      // now check for pdimId consistency
      for (let i=0; i<2; i++) {
        if (this.xs[i].pdimId != this.xdimId) {
          console.log("xs do not have consistent pdimIds");
          if (this.usrcom) { this.usrcom.sbs.now(<SnackBarMsg> { importance: 3, message: "xs do not have consistent pdimIds. Used the one inferred from the given constants.", action: "Error", duration: 3500 }); }
          this.xs[i].updatePunits(tmpx);
          failed = true;
        };
      }

      // now take care of ys
      this.ys=[];
      for (let i=0; i<2; i++) { this.ys.push( this.f(this.xs[i]) ); }
      // and now take care of slopes ss
      this.ss=[];
      for (let i=0; i<2; i++) { this.ss.push( this.fp(this.xs[i]) ); }
      this.sortXYs();
    }

    return !failed;
  }

  /**
   * Check xs and ys lengths make sense: either ( xs.length == ys.length <=3 ), or ( ys=[] and xs.length=0 or 2 ).
   */
  chkXsYs(): boolean {
    // check dimensions
    // check lengths
    const xsl = (this.xs) ? this.xs.length : 0;
    const ysl = (this.ys) ? this.ys.length : 0;
    const ssl = (this.ss) ? this.ss.length : 0;
    const minl = Math.min(xsl,ysl,ssl);
    // if input xs are numbers, would have been fixed to dimensionless PhysQty. If inptype = 'data', then dimensionless is the pdimId. Otherwise, will need to fix pdimId with xpdimId here after setPdimId

    let failed = false;

    if ( this.inptype=="d" || this.inptype=="data" ) {
      // same as setPdimIds, will also check pdims, will return true or false regardless
      if ( xsl != ysl || xsl != ssl) {
        if (this.xs) { this.xs.length = minl; }
        if (this.ys) { this.ys.length = minl; }
        if (this.ss) { this.ss.length = minl; }
        console.log("x and y data points have different lengths. Truncated now to same length.");
        failed = true;
      }
      // the two lengths should be minl now.
      this.sortXYs();
      if (!this.xs || minl<3) { // later might allow 2 points, if given a slope
        console.log("Not enough x-data points given.");
        failed = true;
      }
      // if (!this.ys || ysl<3) { console.log("Not enough y-data points given."); failed = true; }
      const xdid = this.xs[0].pdimId ;
      const ydid = this.ys[0].pdimId ;
      const sdid = this.ss[0].pdimId ;
      // const ydid = this.ys[0].pdimId;
      for(let i=1;i<xsl; i++){
        if ( (this.xs[i] instanceof PhysQty) && (<PhysQty>this.xs[i]).pdimId != xdid) { console.log("xs does not have consistent pdimIds"); failed = true; };
        if ( (this.ys[i] instanceof PhysQty) && (<PhysQty>this.ys[i]).pdimId != ydid) { console.log("ys does not have consistent pdimIds"); failed = true; };
      }
      this.xdimId = xdid; // find xdimId, ydimId, then calculate bdimId, adimId
      this.ydimId = ydid;
      const tmp1 = this.xs[0].dcopy(); tmp1.setNewValueSI(1);
      const tmp = this.ys[0].dcopy().divides(tmp1);
      this.bdimId = tmp.pdimId; // b = y/x
      for(let i=1;i<this.xs.length; i++){ // do that again, now for slopes ss
        if (this.ss[i].pdimId != this.bdimId) {
          console.log("ss do not have consistent pdimIds");
          this.ss[i].updatePunits(tmp);
          failed = true;
        };
      }
      this.adimId = tmp.ddivides(this.xs[0]).pdimId; // a = y/x^2
      return this.sortXYs();
    }

    if ( ysl==0 && xsl==0 ) { return true; } // okay for inptype != 'data'
    if ( ysl != 0 ) { console.log("ys are not allowed with the inptype."); failed = true; }
    if ( ssl != 0 ) { console.log("ss are not allowed with the inptype."); failed = true; }
    if ( xsl >2 ) { console.log("Only two xs are allowed as the range for the inptype."); failed = true; }
    if ( xsl==1 ) { console.log("Two xs are typically needed for this inptype. Please fix."); failed = true; }

    if ( xsl==2 ) {
      // check xdimIds
      // if xs pdimId == 1 and inptype != 'data', xs can be updated with this.xdimId
      if ( this.xs[0].pdimId != this.xs[1].pdimId ) { console.log("xs does not have consistent pdimIds"); failed = true; };
      // the two xs have at least the same pdimId // assume setPdimId is done
      if ( this.xs[0].pdimId != this.xdimId && this.xs[0].pdimId != 1 ) { console.log("xs does not have consistent pdimId with other given info"); failed = true; };
      // two xs have the same pdimId == 1, not == xdimId. So update
      if ( this.xs[0].pdimId == 1 && this.xdimId != 1 ) {
        // if 'abc', use b/a (or c/b), if 'vertex', use h, if roots, use r1 or r2
        let xsample: PhysQty;
        if (this.inptype=='a' || this.inptype=='abc') { if (this.a.valueSI ==0) { console.log("a cannot be zero here."); failed = true; } else { xsample = this.b.dcopy().divides(this.a); } }
        else if (this.inptype=='v' || this.inptype=='vertex') { xsample = this.h.dcopy(); }
        else if (this.inptype=='r' || this.inptype=='root' || this.inptype=='roots') { xsample = this.r1.dcopy(); }
        // xsample.setErrors( ??? )
        xsample.setNewValueSI( this.xs[0].valueSI );
        xsample.setPrefixVals( {prepower: this.xs[0].prepower} );
        this.xs[0] = xsample.dcopy();
        // xsample.setErrors( ??? )
        xsample.setNewValueSI( this.xs[1].valueSI );
        xsample.setPrefixVals( {prepower: this.xs[1].prepower} );
        this.xs[1] = xsample.dcopy();
      }
      // sort
    if (this.xs[0].valueSI == this.xs[1].valueSI) { failed = true; }

    failed = failed || !this.sortXs();
    return !failed;

    }

    console.log("chkXsYs failed. Reason unknown.");
    return false;
  }

  sortXs(): boolean {
    this.xs.sort( (a,b) => (a.value==null || b.value==null) ? -(Math.abs(a.value) - Math.abs(b.value)) : (a.valueSI - b.valueSI) ); // put nulls at the end if a = null, or if b = null, put b at the end.
    // check distinct
    let currentxSI = NaN;
    // for (let i=0; i< this.xs.length; i++) {
    //   if (this.xs[i].valueSI <= currentxSI) {
    //     console.log("It seems some x-values are identical. Please fix.");
    //     return false;
    //   } else { currentxSI = this.xs[i].valueSI; }
    // }
    // return true;
    let result = true;
    this.xs.forEach(function(x:PhysQty){ if (x.valueSI <= currentxSI) { result = false; } else { currentxSI = x.valueSI; } });
    return result;
  }

  /**
   * sort Xs and Ys simultaneously, according to x-values. Also make sure it's strictly acsending.
   */
  sortXYs(): boolean {
    // should have 3x and 3y, but function is generic for n pairs
    // zip them up
    const zip = this.xs.map( (x,i) => [x, this.ys[i], this.ss[i] ] );
    // sort by x
    zip.sort( (a,b) => (a[0].value==null || b[0].value==null) ? -(Math.abs(a[0].value) - Math.abs(b[0].value)) : a[0].valueSI - b[0].valueSI ); // put nulls at the end if a = null, or if b = null, put b at the end.
    // check distinct
    let currentxSI = NaN;
    for (let i=0; i< zip.length; i++) { if (zip[i][0].valueSI <= currentxSI) { return false; } else { currentxSI = zip[i][0].valueSI; } }
    // save back to this.xs, this.ys
    // this.xs = [].concat(zip[0][0], zip[1][0], zip[2][0]);
    this.xs = zip.map( x=>x[0] );
    // this.ys = [].concat(zip[0][1], zip[1][1], zip[2][1]);
    this.ys = zip.map( x=>x[1] );
    this.ss = zip.map( x=>x[2] );
    return true;
  }

  /**
   * Save all name/nameHtml after init, or when desired. These will be used for name-reset after each calculation.
   */
  saveAll7Names( input?: { a?: {name?: string, nameHtml?: string}, b?: {name?: string, nameHtml?: string}, c?: {name?: string, nameHtml?: string}, h?: {name?: string, nameHtml?: string}, k?: {name?: string, nameHtml?: string}, r1?: {name?: string, nameHtml?: string}, r2?: {name?: string, nameHtml?: string} } ): void {
    this.comp7.forEach(function(c:string){
      this.comp7names[c].name = (input && input[c] && input[c]['name']) ? input[c]['name'] : this[c].name ;
      this.comp7names[c].nameHtml = (input && input[c] && input[c]['nameHtml']) ? input[c]['nameHtml'] : this[c].nameHtml ;
    }.bind(this));
    return;
  }

  /**
   * After every calcuation, names might be changed. Need to reset
   */
  resetAllNames(): void {
    this.comp7.forEach(function(c:string){ if (this[c]) { this[c].name = this.comp7names[c].name; this[c].nameHtml = this.comp7names[c].nameHtml; } }.bind(this));
    this.xs.forEach(function(x:PhysQty){x.name='xi';x.nameHtml='<i>x<sub>i</sub></i>';});
    this.ys.forEach(function(y:PhysQty){y.name='yi';y.nameHtml='<i>y<sub>i</sub></i>';});
    this.ss.forEach(function(s:PhysQty){s.name='si';s.nameHtml='<i>s<sub>i</sub></i>';});
    return;
  }

  get discrim(): PhysQty {
    let res: PhysQty;
    if (this.inptype=='v'|| this.inptype=='vertex'){ // equals to -4ka in vertex form
      res = this.a.dcopy().stimes(-4).times(this.k);
    } else if (this.inptype=='r' || this.inptype=='roots' || this.inptype=='root' ){ // equals to (a^2 (r1-r2)^2 ) in factored form
      res = this.r1.dcopy().subtracts(this.r2).times(this.a).power(2);
    } else { // if (this.inptype == 'a' || this.inptype=='abc') { // else assume abc are known, either abc case or data case // b^2 - 4ac
      res = this.b.dcopy().power(2).subtracts( this.a.dcopy().stimes(4).times(this.c) );
    }
    res.name = "discrim";
    res.nameHtml = "&Delta;";
    return res;
  }

  get rootsum(): PhysQty {
    let res: PhysQty;
    if (this.inptype=='v'|| this.inptype=='vertex'){ // equals to 2h in vertex form
      res = this.h.dcopy().stimes(2);
    } else if (this.inptype=='r' || this.inptype=='roots' || this.inptype=='root' ){ // r1+r2 in factored form
      res = this.r1.dcopy().adds(this.r2);
    } else { // if (this.inptype == 'a' || this.inptype=='abc') { // else assume abc are known, either abc case or data case // -b/a
      res = this.b.dcopy().stimes(-1).divides(this.a) ;
    }
    res.name = "root_sum";
    res.nameHtml = "&Sigma;(roots)";
    return res;
  }

  get rootprod(): PhysQty {
    let res: PhysQty;
    if (this.inptype=='v'|| this.inptype=='vertex'){ // equals to h^2 + k/a in vertex form
      res = this.k.dcopy().divides(this.a).adds( this.h.dcopy().power(2) );
    } else if (this.inptype=='r' || this.inptype=='roots' || this.inptype=='root' ){ // r1*r2 in factored form
      res = this.r1.dcopy().times(this.r2);
    } else { // if (this.inptype == 'a' || this.inptype=='abc') { // else assume abc are known, either abc case or data case // c/a
      res = this.c.dcopy().divides(this.a) ;
    }
    res.name = "root_prod";
    res.nameHtml = "&Pi;(roots)";
    return res;
  }

  /**
   * Set the PhysQty value for a component a,b,c,h,k,r1,r2. Typically from a calculated value.
   * @param comp
   * @param calc
   */
  setPvalue(comp:string, calc:PhysQty): void {
    if (!this[comp]) { this[comp] = calc.dcopy(); } else
    if ( this[comp].pdimId != calc.pdimId) { this[comp] = calc.dcopy(); console.log(this[comp].name+ " pdimId different from calcuated value of "+calc.pdimId); }
    else { this[comp].updateValFrom(calc); }
  }

  setVertexFromAbc(): void {
    // h = -b/2a, k = f(h), f is safe to use as Abc are known.
    if (!PureMathService.pchk(this.a.valueSI,0,0.00001)) {
      this.setPvalue( 'h', this.b.dcopy().stimes(-1/2).divides(this.a)); // h = -b/2a
      // this.h.setNewValueSI( this.b.dcopy().stimes(-1/2).divides(this.a).valueSI ); // this will not get the error/uncertaintly
      this.setPvalue( 'k', this.b.dcopy().power(2).stimes(-1/4).divides(this.a).adds(this.c)); // k = f(h) = -b^2/4/a + c // using f(h) might have higher uncertainty than it should
      // this.k.setNewValueSI( this.f(this.h).valueSI ); // this will not get the error/uncertaintly
    } else { // a==0, there is no h nor k
      const tmp=this.a.dcopy(); tmp.setNewValueSI(1);
      this.setPvalue( 'h', this.b.dcopy().divides(tmp)); this.h.value = null; this.h.valueSI = null;
      this.setPvalue( 'k', this.c.dcopy() ); this.k.value = null; this.k.valueSI = null;
    }
    return;
  }
  // done

  setAbcFromVertex(): void {
    this.setPvalue( 'b', this.a.dcopy().stimes(-2).times(this.h)); // b = -2ah
    this.setPvalue( 'c', this.h.dcopy().power(2).times(this.a).adds(this.k)); // c = ah^2+k
    return;
  }
  // done

  setRootsFromAbc(): void { // make sure setVertex is already done. Might need this.h if needed.
    // h = -b/2a, k = f(h), f is safe to use as Abc are known.
    if (!PureMathService.pchk(this.a.valueSI,0,0.00001)) {
      const discrimSI = this.discrim.valueSI;
      if (discrimSI < 0) {
        console.log("Complex solution not yet implemented.");
        this.setPvalue( 'r1', this.h ); this.r1.value = null; this.r1.valueSI = null;
        this.setPvalue( 'r2', this.h ); this.r2.value = null; this.r2.valueSI = null;
        this.solnCnt = [0,2]; // can be [0,1] if complex coefficients are allowed
      } else if (PureMathService.pchk(discrimSI,0,0.00001)) {
        this.setPvalue( 'r1', this.b.dcopy().stimes(-1/2).divides(this.a)); // r1 = -b/2a
        this.setPvalue( 'r2', this.r1);
        this.solnCnt = [1,0];
      } else {
        const discrimsqrt = this.discrim.sqrt();
        // try to keep r1 <= r2
        this.setPvalue( 'r1', this.b.dcopy().stimes(-1).adds( discrimsqrt[0], this.a.valueSI>0 ).stimes(1/2).divides(this.a)); // r1 = (-b + sqrt-discrim)/2/a
        this.setPvalue( 'r2', this.b.dcopy().stimes(-1).adds( discrimsqrt[0], this.a.valueSI<0 ).stimes(1/2).divides(this.a)); // r1 = (-b - sqrt-discrim)/2/a
        this.solnCnt = [2,0];
      }
    } else { // this.a.valueSI ==0
      if (PureMathService.pchk(this.b.valueSI,0,0.00001)) {
        this.solnCnt = [0,0]; // could be infinite solutions
        this.setPvalue( 'r1', this.h ); this.r1.value = null; this.r1.valueSI = null;
        this.setPvalue( 'r2', this.h ); this.r2.value = null; this.r2.valueSI = null;
      } else {
        this.solnCnt = [1,0];
        this.setPvalue( 'r1', this.c.dcopy().stimes(-1).divides(this.b));
        this.setPvalue( 'r2', this.h ); this.r2.value = null; this.r2.valueSI = null;
      }
    }
    return;
  }
  // done

  setAbcFromRoots(): void {
    // rootsum rootprod are already dcopies
    this.setPvalue( 'b', this.rootsum.times(this.a).stimes(-1)); // b = -a(r1+r2)  // re-do if complex??
    // this.b = this.r1.dcopy().adds(this.r2.dcopy()).times(this.a.dcopy()).stimes(-1);
    this.setPvalue( 'c', this.rootprod.times(this.a)); // c = a*r1*r2
    // this.c = this.r1.dcopy().times(this.r2.dcopy()).times(this.a.dcopy());
    return;
  }
  // done

  setRootsFromVertex(): void {
    // probably will not use this. Avoid. uncertainty might be overstated.
    // x = h +- sqrt(-k/a), , f is safe to use as Abc are known.
    if (PureMathService.pchk(this.a.valueSI,0,0.0001)) {
      const discrimSI = this.discrim.valueSI; // for vertex form, discrim = -4ka
      if (discrimSI < 0) {
        console.log("Complex solution not yet implemented.");
        this.setPvalue( 'r1', this.h ); this.r1.value = null; this.r1.valueSI = null;
        this.setPvalue( 'r2', this.h ); this.r2.value = null; this.r2.valueSI = null;
        this.solnCnt = [0,2]; // can be [0,1] if complex coefficients are allowed
      } else if (PureMathService.pchk(discrimSI,0,0.0001)) { // since a != 0 already, so k=0 here.
        this.setPvalue( 'r1', this.h); // r1 = h
        this.setPvalue( 'r2', this.h);
        this.solnCnt = [1,0];
      } else {
        // const discrimsqrt = this.discrim.sqrt();
        const deltasq = this.k.dcopy().stimes(-1).divides(this.a).sqrt(); // sqrt(-k/a)
        this.setPvalue( 'r1', this.h.dcopy().subtracts(deltasq[0])); // r1 = h - sqrt(-k/a)
        this.setPvalue( 'r2', this.h.dcopy().adds(deltasq[0])); // r2 = h + sqrt(-k/a)
        this.solnCnt = [2,0];
      }
    } else { // this.a.valueSI ==0
      this.setPvalue( 'r1', this.h ); this.r1.value = null; this.r1.valueSI = null;
      this.setPvalue( 'r2', this.h ); this.r2.value = null; this.r2.valueSI = null;
      this.solnCnt = [0,0]; // can be infinite solutions
    }
    return;
  }
  // done

  setVertexFromRoots(): void {
    // h = (r1+r2)/2
    // k = f(h) = -a(r1-r2)^2 / 4
    this.setPvalue( 'h', this.r1.dcopy().adds(this.r2).stimes(0.5));
    this.setPvalue( 'k', this.r1.dcopy().subtracts(this.r2).power(2).times(this.a).stimes(-0.25));
    return;
  }

  setAbcFromData(): void {
    // should have already checked xs and ys lengths. Just in case
    const xsl = this.xs.length; // const ysl = this.ys.length;
    if ( !this.chkXsYs ) { return; } // xs and ys are now checked and also sorted
    if (xsl != 3) { return; }

    // a = (m31 - m21)/(x3 - x2)  // order 3 in uncertainty
    // b =  (m21 - a(x2+x1))   // or  b = (m21(x3+x1) - m31(x2+x1)) / (x3-x2)   // both order 4 in uncertainty
    // c = y1 - a x1^2 - b x1   // order 5 in uncertainty
    const p1 = new Point2d({x: this.xs[0], y: this.ys[0]});
    const p2 = new Point2d({x: this.xs[Math.floor(xsl/2)], y: this.ys[Math.floor(xsl/2)]}); // index should be 1. Allow later implementation to take the middle of the dataset.
    const p3 = new Point2d({x: this.xs[xsl-1], y: this.ys[xsl-1]}); // index should be 2.
    // a = (m31 - m21)/(x3 - x2)
    this.setPvalue( 'a', p3.avgVelP(p1).slopeP(p2.avgVelP(p1)));
    // b =  (m21 - a(x2+x1))
    this.setPvalue( 'b', p2.slopeP(p1).subtracts( this.a.dcopy().times( p2.x.dcopy().adds( p1.x ))));
    // c = y1 - a x1^2 - b x1
    this.setPvalue( 'c', p1.y.dcopy().subtracts( this.a.dcopy().times( p1.x.dcopy().power(2) ) ).subtracts( this.b.dcopy().times( p1.x ) ));
    return;
  }
  // done

  setDataFromOthers(): void{
    // can be from Abc or Vertex or roots, just use this.f()
    // with this.xs.length >0
    if (!this.xs || this.xs.length ==0) { return; }
    // should have been done, but double check to ensure all x are sorted and distinct
    if (!this.sortXs()) { console.log("Cannot setDataFromAbc with xs not sortable."); return; }
    // get a new y for each x.
    this.ys=[]; this.ss=[];
    this.xs.forEach(function(x:PhysQty){ this.ys.push(this.f(x)); this.ss.push(this.fp(x)); }.bind(this));
    return;
  }
  // done

  setValsFromAbc(): void {
    this.inptype = 'a';
    this.setVertexFromAbc();
    this.setRootsFromAbc(); // remember to have vertex set first, so that can use this.h if needed
    this.setDataFromOthers();
    this.resetAllNames();
    return;
  }

  setValsFromVertex(): void {
    this.inptype = 'v';
    this.setAbcFromVertex();
    this.setRootsFromAbc(); // AVOID using setRootsFromVertex, unless complex module is complete
    this.setDataFromOthers();
    this.resetAllNames();
    return;
  }

  setValsFromRoots(): void {
    this.inptype = 'r';
    this.setAbcFromRoots();
    this.setVertexFromRoots();
    this.setDataFromOthers();
    this.resetAllNames();
    return;
  }

  setValsFromData(): void {
    this.inptype = 'd';
    this.setAbcFromData();
    this.setVertexFromAbc();
    // setVertexFromData not developed directly
    // a = (m31-m21)/(x3-x2) ; h = (m31(x2+x1) - m21(x3+x1))/(2(m31-m21)) ; k = very high order in uncertainty. no simple formula
    this.setRootsFromAbc(); // setRootsFromData not developed directly
    this.resetAllNames();
    return;
  }

  setXsYs(): void {
    const xsl = (this.xs) ? this.xs.length : 0;
    const ysl = (this.ys) ? this.ys.length : 0;
    const ssl = (this.ss) ? this.ss.length : 0;
    if (this.inptype=="d" || this.inptype=="data") {
      // Xs and Ys should have been checked and set in setPdimId
      // take care of Ss (slopes, new)
      if (xsl !=3) { console.log('setXsYs Error. length not = 3.'); return; }
      this.ss = [];
      for(let i=0; i<3; i++) {
        this.ss.push( this.fp(this.xs[i]));
        if (this.xs[i].value==null || this.ys[i].value==null) { this.ss[i].value=null; }
      }
      return;
    }

    const tmp = this.h.dcopy();
    if (xsl==2) {
      this.sortXs();
      this.ys=[];
      this.ss=[];
      if (this.xs[0].pdimId != this.xdimId) {
        console.log("xs[0] has incompatible unit. It has been fixed.");
        tmp.setNewValueSI(this.xs[0].valueSI);
        this.xs[0] = tmp.dcopy();
      }
      this.ys.push(this.f(this.xs[0]));
      this.ss.push(this.fp(this.xs[0]));
      if (this.xs[1].pdimId != this.xdimId) {
        console.log("xs[1] has incompatible unit. It has been fixed.");
        tmp.setNewValueSI(this.xs[1].valueSI);
        this.xs[1] = tmp.dcopy();
      }
      this.ys.push(this.f(this.xs[1]));
      this.ss.push(this.fp(this.xs[1]));
    } else if (xsl==1 || xsl > 2) {
      // shouldn't happen // must have already rejected
    } else { // xsl==0
      this.xs=[];
      this.xs.push(tmp.dcopy()); this.xs[0].setNewValueSI(0); this.xs[0].value = null; // value==null means no input
      this.xs.push(tmp.dcopy()); this.xs[1].setNewValueSI(0); this.xs[1].value = null; // value==null means no input
      this.ys.push(this.k.dcopy()); this.ys[0].setNewValueSI(0); this.ys[0].value = null; // value==null means no input
      this.ys.push(this.k.dcopy()); this.ys[1].setNewValueSI(0); this.ys[1].value = null; // value==null means no input
      this.ss.push(this.b.dcopy()); this.ss[0].setNewValueSI(0); this.ss[0].value = null; // value==null means no input
      this.ss.push(this.b.dcopy()); this.ss[1].setNewValueSI(0); this.ss[1].value = null; // value==null means no input
    }
    return;
  }

  setAllVals(): void {
    if ( this.inptype=='d' || this.inptype=="data" ) { this.setValsFromData(); } else
    if ( this.inptype=='r' || this.inptype=='root' || this.inptype=='roots' ) { this.setValsFromRoots(); } else
    if ( this.inptype=='v' || this.inptype=='vertex' ) {  this.setValsFromVertex(); }
    else { this.setValsFromAbc(); } // default, assume Abc, if ( this.inptype=='a' || this.inptype=="abc" )
    this.setXsYs();
    this.resetAllNames();
    return;
  }

  inpTypeChg(t:string): void {
    if (t=="d"||t=="data") { this.inptype='d'; } else
    if (t=="v"||t=="vertex") { this.inptype='v'; } else
    if (t=="r"||t=="root"||t=="roots") { this.inptype='r'; }
    else { this.inptype='a'; }
    this.setPdimIds(); // this is where the length of xs, ys are set (case 'data'), for now.
    this.chkXsYs();
    this.setXsYs();
    return;
  }

  prefixChg(comp: string): void {
    if (comp=="h"||comp.slice(0,2)=="xs") {
      const p = (comp=="h") ? this.h.prepower : this.xs[comp.slice(2)].prepower;
      this.h.setPrefixVals(p); this.h.setNewValueSI(null,true);
      this.xs.forEach(function(x:PhysQty){ x.setPrefixVals(p); x.setNewValueSI(null,true); });
    }
    else if (comp=="k"||comp.slice(0,2)=="ys") {
      const p = (comp=="k") ? this.k.prepower : this.ys[comp.slice(2)].prepower;
      this.k.setPrefixVals(p); this.k.setNewValueSI(null,true);
      this.ys.forEach(function(y:PhysQty){ y.setPrefixVals(p); y.setNewValueSI(null,true); });
    }
    else if (comp=="b"||comp.slice(0,2)=="ss") {
      const p = (comp=="b") ? this.b.prepower : this.ss[comp.slice(2)].prepower;
      this.b.setPrefixVals(p); this.b.setNewValueSI(null,true);
      this.ss.forEach(function(s:PhysQty){ s.setPrefixVals(p); s.setNewValueSI(null,true); });
    }
    else { this[comp].setPrefixVals(this[comp].prepower); this[comp].setNewValueSI(null,true); }
    return;
  }

  unitChg(comp: string): void {
    this.prefixChg(comp); // in case it's changed as some non-SI units force prefix to 0.
    // next change units
    if (comp=="h"||comp.slice(0,2)=="xs") {
      const p = (comp=="h") ? this.h.punitId : this.xs[comp.slice(2)].punitId;
      this.h.chgSysUnitID(<PhysConShell>{ punitId: p });
      this.xs.forEach(function(x:PhysQty){ x.chgSysUnitID(<PhysConShell>{ punitId: p }); x.setPrefixVals(x.prepower); x.setNewValueSI(null, true); });
    }
    else if (comp=="k"||comp.slice(0,2)=="ys") {
      // const p = this.k['punitId'];
      const p = (comp=="k") ? this.k.punitId : this.ys[comp.slice(2)].punitId;
      this.k.chgSysUnitID(<PhysConShell>{ punitId: p });
      this.ys.forEach(function(y:PhysQty){ y.chgSysUnitID(<PhysConShell>{ punitId: p }); y.setPrefixVals(y.prepower); y.setNewValueSI(null, true); });
    }
    else if (comp=="b"||comp.slice(0,2)=="ss") {
      // const p = this.b['punitId'];
      const p = (comp=="b") ? this.b.punitId : this.ss[comp.slice(2)].punitId;
      this.b.chgSysUnitID(<PhysConShell>{ punitId: p });
      this.ss.forEach(function(s:PhysQty){ s.chgSysUnitID(<PhysConShell>{ punitId: p }); s.setPrefixVals(s.prepower); s.setNewValueSI(null, true); });
    }
    else { this[comp].chgSysUnitID(<PhysConShell>{punitId: this[comp]['punitId'] }); this[comp].setPrefixVals(this[comp].prepower); this[comp].setNewValueSI(null, true); }
    return;
  }

  updatePu(comp:string, pu: PhysUnit | PhysConShell): void {
    if (comp=='a'){
      this[comp+'dimId'] = pu.pdimId;
      this[comp].updatePunits(pu);
    } else if (comp=='b'||comp=='s'||comp.slice(0,2)=='ss'){
      this.bdimId = pu.pdimId;
      this.b.updatePunits(pu);
      this.ss.forEach(function(s:PhysQty){s.updatePunits(pu);});
    } else if (comp=='c'||comp=='k'||comp=='y'||comp=='f'||comp.slice(0,2)=='ys'){
      this.ydimId = pu.pdimId;
      this.c.updatePunits(pu);
      this.k.updatePunits(pu);
      this.ys.forEach(function(y:PhysQty){y.updatePunits(pu);});
    } else if (comp=='h'||comp=='r1'||comp=='r2'||comp=='x'||comp.slice(0,2)=='xs'){
      this.xdimId = pu.pdimId;
      this.h.updatePunits(pu);
      this.r1.updatePunits(pu);
      this.r2.updatePunits(pu);
      this.xs.forEach(function(x:PhysQty){x.updatePunits(pu);});
    } else {
      console.log("updatePu failed. No match found for "+comp);
    }
  }

  catChg(v: string): void {
    // inptype abc,
    // if b changed, assume a stays, find (c,y,k) and (h,x,r1,r2)
    // otherwise, assume b stays, find the other two (thus avoid sqrt of ac = b)
    // a+b -> c,h
    // b+a -> c,h
    // c+b -> a,h
    //
    // inptype roots
    // only have a and (h,x,r1,r2), find b and (c,y,k)
    // a,h -> b,c
    //
    // inptype vertex
    // if (h,x,r1,r2) change, assume a stays, find b and (c,y,k)
    // otherwise, assume (h,x,r1,r2) stays, find the other two
    // a+h -> b,c
    // h+a -> b,c
    // c+h -> a,b
    //
    // inptype data
    // only have (h,x,r1,r2) and (c,y,k), find a and b
    // h,c -> a,b
    //
    // all these boils down to 4 scenarios, using (a,b) or (b,c) or (a,h)) or (c,h) to find other two.
    // These two combo never comes up (a,c) and (b,h) of the 4C2 combinations.
    //
    // first determine solve type:
    let sol: string;
    if (this.inptype=='d'||this.inptype=='data'){ sol = 'ch'; } else
    if (this.inptype=='r'||this.inptype=='root'||this.inptype=='roots'){ sol = 'ah'; } else
    if (this.inptype=='v'||this.inptype=='vertex'){ sol = (v=='k') ? 'ch' : 'ah'; }
    else { sol = (v=='c') ? 'bc' : 'ab'; }

    // next, make sure the new one changes similar types
    const pu = this._gpu.findPhysUnitA({pdimId: this[v].pdimId})[0];
    this.updatePu(v,pu);

    // finally, use the two chosen one to update the other two
    // cases ab, bc, ah, ch
    if (sol=='ab') { // h,x,r1,r2 =b/a ; c,k,y = b^2/a
      const acp = this.a.dcopy(); acp.setNewValueSI(1);
      const pu = this.b.dcopy().divides(acp);
      this.updatePu('h',pu);
      pu.times(this.b);
      this.updatePu('c',pu);
    } else if (sol=='bc') { // a = b^2/c ; h,x,r1,r2 = c/b ;
      const bcp = this.b.dcopy(); bcp.setNewValueSI(1);
      const ccp = this.c.dcopy(); ccp.setNewValueSI(1);
      ccp.divides(bcp); // ccp is now c/b
      this.updatePu('h',ccp);
      bcp.divides(ccp);
      this.updatePu('a',bcp);
    } else if (sol=='ah') { // b=ah; c=ah^2
      const pu = this.a.dcopy().times(this.h);
      this.updatePu('b',pu);
      pu.times(this.h);
      this.updatePu('c',pu);
    } else if (sol=='ch') { // b=c/h; a=c/h^2
      const hcp = this.h.dcopy(); hcp.setNewValueSI(1);
      const pu = this.c.dcopy().divides(this.h);
      this.updatePu('b',pu);
      pu.divides(this.h);
      this.updatePu('a',pu);
    }

    this.setAllVals();
    return;
  }

  valueChg(comp: string, val: number): void {
    if (comp.slice(0,2)=="xs"||comp.slice(0,2)=="ys"){ // ||comp.slice(0,2)=="ss"
      this[comp.slice(0,2)][comp.slice(2)].setNewValue(val);
      this.sortXYs();
    } else {
      this[comp].setNewValue(val);
    }
    this.setAllVals();
    return;
  }

  makeSvg(): void {
    if (true) {
      // send to FigureSvg to visualize
      // return SVG?
    }
  }
} // end Parabola

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class GeometryModule { }
