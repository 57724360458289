import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Kinematics1dComponent } from './kinematics1d/kinematics1d.component';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material';
import { Kinematics1dFigureSvgComponent } from 'src/app/visual/figure-svg/kinematics1d-figure-svg/kinematics1d-figure-svg.component';


@NgModule({
  declarations: [ Kinematics1dComponent, Kinematics1dFigureSvgComponent ],
  imports: [ CommonModule, CommonPipeModule, FormsModule, MatTableModule ] // , KatexModule
})
export class KinematicsModule { 
  constructor(@Optional() @SkipSelf() parentModule: KinematicsModule) {
    if (parentModule) { throw new Error('Kinematics1dModule is already loaded. Import it in the AppModule only'); }
  }

  static forRoot(): ModuleWithProviders {
    // console.log("instantiating LinearalgebraModule.forRoot");
    return {
      ngModule: KinematicsModule,
      providers: [ ]
    };
  }

}

