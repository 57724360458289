import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinearalgebraComponent } from './linearalgebra.component';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';
import { FormsModule } from '@angular/forms';
import { Vector3dFigureSvgComponent } from './vector3d-figure-svg/vector3d-figure-svg.component';
import { Vector3dFigureLegendSvgComponent } from './vector3d-figure-legend-svg/vector3d-figure-legend-svg.component';
import { Vec2dSumComponent } from './vec2d-sum/vec2d-sum.component';
import { FigureSvgModule } from 'src/app/visual/figure-svg/figure-svg.module';
// import { KatexModule } from 'ng-katex';


@NgModule({
  declarations: [ LinearalgebraComponent, Vector3dFigureSvgComponent, Vector3dFigureLegendSvgComponent, Vec2dSumComponent ],
  imports: [ CommonModule, CommonPipeModule, FormsModule, FigureSvgModule ], // , KatexModule
  exports: [ Vec2dSumComponent ], // export these so that other modules/components can use them standalone
  providers: []
})
export class LinearalgebraModule { 
  constructor(@Optional() @SkipSelf() parentModule: LinearalgebraModule) {
    if (parentModule) { throw new Error('LinearalgebraModule is already loaded. Import it in the AppModule only'); }
  }

  static forRoot(): ModuleWithProviders {
    // console.log("instantiating LinearalgebraModule.forRoot");
    return {
      ngModule: LinearalgebraModule,
      providers: [ ]
    };
  }

}

