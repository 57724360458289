import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { EeeeService, EquationInfo } from 'src/app/service/eqns/eeee.service';
import { siPrefixes, PhysConShell, unitDropdownList, GlobalPhysUnitsService, SiPrefix } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { PhysQty, PhysDim } from 'src/app/numeric-library/bbb/bbb.module';
import { SnackBarService, SnackBarMsg } from 'src/app/service/facts/snack-bar.service';
import { GlobalClipboardService } from '../../../common/scratch-pad/global-clipboard.service';
import { FactoryService } from 'src/app/service/facts/factory.service';
import { Factsier, Factsheet, Factum, FactIdty } from 'src/app/service/facts/facts.module';
import { AppInjector } from 'src/app/app-injector';
import { MatDialogRef } from '@angular/material/dialog';
import { PrefixDropListPipe } from 'src/app/pipe/common-pipe/prefix-drop-list.pipe';
import { MonologService } from 'src/app/service/monolog-box/monolog.service';
import { MonologComponent } from 'src/app/service/monolog-box/monolog/monolog.component';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NameHtmlDispPipe } from 'src/app/pipe/common-pipe/name-html-disp.pipe';

export class Kinematics1dQtet {
  readonly comp5 = ['v1','d','v2','a','t'];
  readonly comp8 = ['v1','v1a','d','v2','v2a','a','t','ta']; // allow two possible solutions (alternative solutions) for v1, v2, and t
  readonly colorScheme = {highlight: '#ffff0099', highlight2: '#55dd1190', otherborder: '#ff0000' };
  readonly color5comp = {a: "#e66c09", d: "#000000", t: "#743181", v1: "#d959f3", v2: "#47cfe7"};
  readonly borderstyle5comp = {a: "dashed", d: "solid", t: "dashed", v1: "solid", v2: "solid"};
  private _eeee: EeeeService;
  readonly _zerov = new PhysQty(<PhysConShell>{ name: 'v0', nameHtml: '<i>v0</i>', value: 0, pdimId: 10, system: 'SI', unit: 'm/s', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 26 });
  readonly _zerot = new PhysQty(<PhysConShell>{ name: 't0', nameHtml: '&Delta;<i>t0</i>', value: 0, pdimId: 3, system: 'SI', unit: 's', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 10 });
  readonly _zerod = new PhysQty(<PhysConShell>{ name: 'd0', nameHtml: '&Delta;<i>x0</i>', value: 0, pdimId: 4, system: 'SI', unit: 'm', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 14 });
  readonly _zeroa = new PhysQty(<PhysConShell>{ name: 'a0', nameHtml: '<i>a0</i>', value: 0, pdimId: 11, system: 'SI', unit: 'm/s^2', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 29 });

  // private _eeee: EeeeService;
  solnsheet: Factsheet;
  v1: PhysQty; // pdimId = 10, angular 29
  v1a: PhysQty; // pdimId = 10
  v2: PhysQty; // pdimId = 10
  v2a: PhysQty; // pdimId = 10
  d: PhysQty; // pdimId = 4, angular 1
  a: PhysQty; // pdimId = 11, angular 30
  t: PhysQty; // pdimId = 3
  ta: PhysQty; // pdimId = 3
  solnCnt: number; // 0, 1, or 2
  inpNull: {v1:boolean,v2:boolean,a:boolean,t:boolean,d:boolean}; // track which ones are null/not-touched/clean, which ones are non-null/touched/dirty
  // inp: {v1:boolean,v1a:boolean,v2:boolean,v2a:boolean,a:boolean,t:boolean,ta:boolean,d:boolean};
  inpHide: {v1a:boolean,v2a:boolean,ta:boolean};
  // inpHide: {v1:boolean,v1a:boolean,v2:boolean,v2a:boolean,a:boolean,t:boolean,ta:boolean,d:boolean}; // v1, v2, d, a, t, always False
  inpReadonly: {v1:boolean,v2:boolean,a:boolean,t:boolean,d:boolean};
  inpColor: {v1:string,v2:string,a:string,t:string,d:string};
  eqnColor: {v1:string,v2:string,a:string,t:string,d:string};
  // inpReadonly: {v1:boolean,v1a:boolean,v2:boolean,v2a:boolean,a:boolean,t:boolean,ta:boolean,d:boolean}; // v1a, v2a, ta, always readonly True

  // constructor(private _eeee: EeeeService) {
  constructor(factsheet?: Factsheet ) {
    this._eeee = AppInjector.get(EeeeService);
    // this._facts = AppInjector.get(FactoryService);
    this.solnsheet = factsheet;
    this.v1 = this._zerov.dcopy(); this.v1.name='v1'; this.v1.nameHtml='<i>v</i><sub>1</sub>'; this.v1.setNewValue(5);
    // this.v1 = new PhysQty(<PhysConShell>{ name: 'v1', nameHtml: '<i>v</i><sub>1</sub>', value: 5, pdimId: 10, system: 'SI', unit: 'm/s', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 26 });
    this.v1a = this._zerov.dcopy(); this.v1a.name='v1a'; this.v1a.nameHtml='<i>v</i><sub>1a</sub>'; this.v1a.setNewValue(0);
    // this.v1a = new PhysQty(<PhysConShell>{ name: 'v1a', nameHtml: '<i>v</i><sub>1</sub>', value: 0, pdimId: 10, system: 'SI', unit: 'm/s', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 26 });
    this.v2 = this._zerov.dcopy(); this.v2.name='v2'; this.v2.nameHtml='<i>v</i><sub>2</sub>'; this.v2.setNewValue(2);
    // this.v2 = new PhysQty(<PhysConShell>{ name: 'v2', nameHtml: '<i>v</i><sub>2</sub>', value: 2, pdimId: 10, system: 'SI', unit: 'm/s', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 26 });
    this.v2a = this._zerov.dcopy(); this.v2a.name='v2a'; this.v2a.nameHtml='<i>v</i><sub>2a</sub>'; this.v2a.setNewValue(0);
    // this.v2a = new PhysQty(<PhysConShell>{ name: 'v2a', nameHtml: '<i>v</i><sub>2a</sub>', value: 0, pdimId: 10, system: 'SI', unit: 'm/s', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 26 });
    this.t = this._zerot.dcopy(); this.t.name='t'; this.t.nameHtml='&Delta;<i>t</i>'; this.t.setNewValue(3);
    // this.t = new PhysQty(<PhysConShell>{ name: 't', nameHtml: '&Delta;<i>t</i>', value: 3, pdimId: 3, system: 'SI', unit: 's', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 10 });
    this.ta = this._zerot.dcopy(); this.ta.name='ta'; this.ta.nameHtml='&Delta;<i>t</i><sub>a</sub>'; this.ta.setNewValue(0);
    // this.ta = new PhysQty(<PhysConShell>{ name: 'ta', nameHtml: '&Delta;<i>t</i><sub>a</sub>', value: 0, pdimId: 3, system: 'SI', unit: 's', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 10 });
    this.d = this._zerod.dcopy(); this.d.name='d'; this.d.nameHtml='&Delta;<i>x</i>'; this.d.setNewValue(2.5);
    // this.d = new PhysQty(<PhysConShell>{ name: 'd', nameHtml: '&Delta;<i>x</i>', value: 2.5, pdimId: 4, system: 'SI', unit: 'm', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 14 });
    this.a = this._zeroa.dcopy(); this.a.name='a'; this.a.nameHtml='<i>a</i>'; this.a.setNewValue(9.80);
    // this.a = new PhysQty(<PhysConShell>{ name: 'a', nameHtml: '<i>a</i>', value: 9.81, pdimId: 11, system: 'SI', unit: 'm/s^2', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 29 });
    this.inpNull = {v1:false,v2:true,a:false,t:true,d:false}; // use three (false) to calculate other two (true)
    this.inpHide = {v1a:true,v2a:true,ta:true};
    this.inpReadonly = {v1:false,v2:false,a:false,t:false,d:false};
    // this.inpColor = {v1:'none',v2:'none',a:'none',t:'none',d:'none'}; // doesn't reset the color highlight
    this.inpColor = {v1:'white',v2:'white',a:'white',t:'white',d:'white'};
    this.eqnColor = {v1:'white',v2:'white',a:'white',t:'white',d:'white'};
    this.solve();
  }

  get inpNullCnt(): number { let sum=0; this.comp5.forEach( function(v){ sum += (this.inpNull[v])?1:0;}.bind(this) ); return sum; }

  solve(): void {
    const highlight = this.colorScheme.highlight;
    const highlight2 = this.colorScheme.highlight2;
    this.solnCnt = 0;
    this.v1a.value=null;
    this.v2a.value=null;
    this.ta.value=null;
    this.resetHide();
    this.resetReadonly();
    this.solnsheet.factA = <Factum[]>[]; // reset solution to clean sheet
    let tmpinfo: EquationInfo;
    // if (this.inpNullCnt > 2) return;
    if (this.inpNullCnt > 2) {
      // this.solnsheet.factA.push(new Factum({  // template
      //   author: <FactIdty>{ uname: 'system' },
      //   topicA: ['kinematics1d'],
      //   cdate: null,
      //   mdate: null,
      //   userDesc: '',
      //   sysDesc: '',
      //   title: '', // end FactBasic
      //   fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
      //   importance: 1,
      //   eqn: <FactIdty>{}
      // }));
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Too few input values. Need three inputs.',
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'error', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 2,
        eqn: <FactIdty>{}
      }));
      return;
    }

    if (this.inpNullCnt < 2) {
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Too many input values. Only three are allowed here.',
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'error', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 2,
        eqn: <FactIdty>{}
      }));
      return;
    }

    // t-d-a-i-f  5C2 = 10 combinations (time-disp-acc-vi-vf)
    this.solnCnt = 1;
		// t-d-a
		if (this.inpNull.v1 && this.inpNull.v2) {
      this.inpReadonly.v1 = true; this.inpReadonly.v2 = true;
      this.inpColor.v1 = highlight; this.inpColor.v2 = highlight2;
      this.eqnColor.v1 = highlight2; this.eqnColor.v2 = highlight;
			// this.facts.addFactlet({title: "Given t, d, and a", explain: "Need to solve for v1 and v2.", typecolor: "info", difficulty: 1, category: "trial", eqn: "" });
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Given &Delta;<i>t</i>, &Delta;<i>x</i>, and <i>a</i>. Need to solve for <i>v</i><sub>1</sub> and <i>v</i><sub>2</sub>.',
        title: 'Given &Delta;<i>t</i>, &Delta;<i>x</i>, and <i>a</i>', // end FactBasic
        fact: <FactIdty>{ type: 'info', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));

      this.v1.updateValFrom( this._eeee.findEqn("eqKin1Dfi").eqn(this.t,this.d,this.a) );
      // this.v1.name = 'v1'; this.v1.nameHtml = '<i>v</i><sub>1</sub>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Dfi").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Dfi" }
      }));

      this.v2.updateValFrom( this._eeee.findEqn("eqKin1Dif").eqn(this.t,this.d,this.a) );
      // this.v2.name = 'v2'; this.v2.nameHtml = '<i>v</i><sub>2</sub>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Dif").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Dif" }
      }));

      return;
    }

		// t-d-i
		if (this.inpNull.a && this.inpNull.v2) {
      this.inpReadonly.a = true; this.inpReadonly.v2 = true;
      this.inpColor.a = highlight; this.inpColor.v2 = highlight2;
      this.eqnColor.a = highlight2; this.eqnColor.v2 = highlight;
			// this.facts.addFactlet({title: "Given t, x, and v1", explain: "Need to solve for a and v2.", typecolor: "info", difficulty: 1, category: "trial", eqn: "" });
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Given &Delta;<i>t</i>, &Delta;<i>x</i>, and <i>v</i><sub>1</sub>. Need to solve for <i>a</i> and <i>v</i><sub>2</sub>.',
        title: 'Given &Delta;<i>t</i>, &Delta;<i>x</i>, and <i>v</i><sub>1</sub>.', // end FactBasic
        fact: <FactIdty>{ type: 'info', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));

      this.a.updateValFrom( this._eeee.findEqn("eqKin1Dfa").eqn(this.t,this.d,this.v1) );
      // this.a.name = 'a'; this.a.nameHtml = '<i>a</i>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Dfa").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Dfa" }
      }));

      this.v2.updateValFrom( this._eeee.findEqn("eqKin1Daf").eqn(this.t,this.d,this.v1) );
      // this.v2.name = 'v2'; this.v2.nameHtml = '<i>v</i><sub>2</sub>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Daf").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Daf" }
      }));

			return;
    }

		// t-d-f
		if (this.inpNull.a && this.inpNull.v1) {
      this.inpReadonly.a = true; this.inpReadonly.v1 = true;
      this.inpColor.a = highlight; this.inpColor.v1 = highlight2;
      this.eqnColor.a = highlight2; this.eqnColor.v1 = highlight;
			// this.facts.addFactlet({title: "Given t, x, and v2", explain: "Need to solve for v1 and a.", typecolor: "info", difficulty: 1, category: "trial", eqn: "" });
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Given &Delta;<i>t</i>, &Delta;<i>x</i>, and <i>v</i><sub>2</sub>. Need to solve for <i>v</i><sub>1</sub> and <i>a</i>.',
        title: 'Given &Delta;<i>t</i>, &Delta;<i>x</i>, and <i>v</i><sub>2</sub>.', // end FactBasic
        fact: <FactIdty>{ type: 'info', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));

      this.v1.updateValFrom( this._eeee.findEqn("eqKin1Dai").eqn(this.t,this.d,this.v2) );
      // this.v1.name = 'v1'; this.v1.nameHtml = '<i>v</i><sub>1</sub>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Dai").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Dai" }
      }));

      this.a.updateValFrom( this._eeee.findEqn("eqKin1Dia").eqn(this.t,this.d,this.v2) );
      // this.a.name = 'a'; this.a.nameHtml = '<i>a</i>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Dia").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Dia" }
      }));

			return;
    }

		// t-a-i
		if (this.inpNull.d && this.inpNull.v2) {
      this.inpReadonly.d = true; this.inpReadonly.v2 = true;
      this.inpColor.d = highlight; this.inpColor.v2 = highlight2;
      this.eqnColor.d = highlight2; this.eqnColor.v2 = highlight;
			// this.facts.addFactlet({title: "Given t, v1, and a", explain: "Need to solve for x and v2.", typecolor: "info", difficulty: 1, category: "trial", eqn: "" });
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Given &Delta;<i>t</i>, <i>v</i><sub>1</sub>, and <i>a</i>. Need to solve for &Delta;<i>x</i> and <i>v</i><sub>2</sub>.',
        title: 'Given &Delta;<i>t</i>, <i>v</i><sub>1</sub>, and <i>a</i>.', // end FactBasic
        fact: <FactIdty>{ type: 'info', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));

      this.d.updateValFrom( this._eeee.findEqn("eqKin1Dfd").eqn(this.t,this.a,this.v1) );
      // this.d.name = 'd'; this.d.nameHtml = '&Delta;<i>x</i>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Dfd").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Dfd" }
      }));

      this.v2.updateValFrom( this._eeee.findEqn("eqKin1Ddf").eqn(this.t,this.a,this.v1) );
      // this.v2.name = 'v2'; this.v2.nameHtml = '<i>v</i><sub>2</sub>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Ddf").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Ddf" }
      }));

			return;
    }

		// t-a-f
		if (this.inpNull.d && this.inpNull.v1) {
      this.inpReadonly.d = true; this.inpReadonly.v1 = true;
      this.inpColor.d = highlight; this.inpColor.v1 = highlight2;
      this.eqnColor.d = highlight2; this.eqnColor.v1 = highlight;
			// this.facts.addFactlet({title: "Given t, v2, and a", explain: "Need to solve for v1 and x.", typecolor: "info", difficulty: 1, category: "trial", eqn: "" });
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Given &Delta;<i>t</i>, <i>v</i><sub>2</sub>, and <i>a</i>. Need to solve for <i>v</i><sub>1</sub> and &Delta;<i>x</i>.',
        title: 'Given &Delta;<i>t</i>, <i>v</i><sub>2</sub>, and <i>a</i>.', // end FactBasic
        fact: <FactIdty>{ type: 'info', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));

      this.d.updateValFrom( this._eeee.findEqn("eqKin1Did").eqn(this.t,this.a,this.v2) );
      // this.d.name = 'd'; this.d.nameHtml = '&Delta;<i>x</i>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Did").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Did" }
      }));

      this.v1.updateValFrom( this._eeee.findEqn("eqKin1Ddi").eqn(this.t,this.a,this.v2) );
      // this.v1.name = 'v1'; this.v1.nameHtml = '<i>v</i><sub>1</sub>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Ddi").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Ddi" }
      }));

			return;
    }

		// t-i-f
		if (this.inpNull.a && this.inpNull.d) {
      this.inpReadonly.a = true; this.inpReadonly.d = true;
      this.inpColor.a = highlight; this.inpColor.d = highlight2;
      this.eqnColor.a = highlight2; this.eqnColor.d = highlight;
			// this.facts.addFactlet({title: "Given t, v1, and v2", explain: "Need to solve for a and x.", typecolor: "info", difficulty: 1, category: "trial", eqn: "" });
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Given &Delta;<i>t</i>, <i>v</i><sub>1</sub> and <i>v</i><sub>2</sub>. Need to solve for &Delta;<i>x</i>, and <i>a</i>.',
        title: 'Given &Delta;<i>t</i>, <i>v</i><sub>1</sub> and <i>v</i><sub>2</sub>.', // end FactBasic
        fact: <FactIdty>{ type: 'info', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));

      this.d.updateValFrom( this._eeee.findEqn("eqKin1Dad").eqn(this.t,this.v1,this.v2) );
      // this.d.name = 'd'; this.d.nameHtml = '&Delta;<i>x</i>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Dad").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Dad" }
      }));

      this.a.updateValFrom( this._eeee.findEqn("eqKin1Dda").eqn(this.t,this.v1,this.v2) );
      // this.a.name = 'a'; this.a.nameHtml = '<i>a</i>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Dda").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Dda" }
      }));

			return;
    }

		// d-i-f
		if (this.inpNull.a && this.inpNull.t) {
      this.inpReadonly.a = true; this.inpReadonly.t = true;
      this.inpColor.a = highlight; this.inpColor.t = highlight2;
      this.eqnColor.a = highlight2; this.eqnColor.t = highlight;
			// this.facts.addFactlet({title: "Given x, v1, and v2", explain: "Need to solve for a and t.", typecolor: "info", difficulty: 1, category: "trial", eqn: "" });
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Given &Delta;<i>x</i>, <i>v</i><sub>1</sub> and <i>v</i><sub>2</sub>. Need to solve for <i>a</i> and &Delta;<i>t</i>.',
        title: 'Given &Delta;<i>x</i>, <i>v</i><sub>1</sub> and <i>v</i><sub>2</sub>.', // end FactBasic
        fact: <FactIdty>{ type: 'info', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));

      this.t.updateValFrom( this._eeee.findEqn("eqKin1Dat").eqn(this.d,this.v1,this.v2) );
      // this.t.name = 't'; this.t.nameHtml = '&Delta;<i>t</i>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Dat").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Dat" }
      }));

      this.a.updateValFrom( this._eeee.findEqn("eqKin1Dta").eqn(this.d,this.v1,this.v2) );
      // this.a.name = 'a'; this.a.nameHtml = '<i>a</i>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Dta").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Dta" }
      }));

			return;
    }

		// a-i-f
		if (this.inpNull.t && this.inpNull.d) {
      this.inpReadonly.t = true; this.inpReadonly.d = true;
      this.inpColor.t = highlight; this.inpColor.d = highlight2;
      this.eqnColor.t = highlight2; this.eqnColor.d = highlight;
			// this.facts.addFactlet({title: "Given v1, v2, and a", explain: "Need to solve for t and x.", typecolor: "info", difficulty: 1, category: "trial", eqn: "" });
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Given <i>v</i><sub>1</sub>, <i>v</i><sub>2</sub>, and <i>a</i>. Need to solve for &Delta;<i>t</i> and &Delta;<i>x</i>.',
        title: 'Given <i>v</i><sub>1</sub>, <i>v</i><sub>2</sub>, and <i>a</i>.', // end FactBasic
        fact: <FactIdty>{ type: 'info', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));

      this.t.updateValFrom( this._eeee.findEqn("eqKin1Ddt").eqn(this.a,this.v1,this.v2) );
      // this.t.name = 't'; this.t.nameHtml = '&Delta;<i>t</i>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Ddt").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Ddt" }
      }));

      this.d.updateValFrom( this._eeee.findEqn("eqKin1Dtd").eqn(this.a,this.v1,this.v2) );
      // this.d.name = 'd'; this.d.nameHtml = '&Delta;<i>x</i>';
      // also change valueMin/Max this.i.setNewValueSI(newMinValue,"Min");
      tmpinfo = this._eeee.findEqn("eqKin1Dtd").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc,
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: "eqKin1Dtd" }
      }));

			return;
    }

    // SPECIAL CASES
    // solnCnt = 0, 1, or 2
		// d-a-i (special case, avoid using two eqQuadratics)
		if (this.inpNull.t && this.inpNull.v2) {
      this.inpReadonly.t = true; this.inpReadonly.v2 = true;
      this.inpColor.t = highlight; this.inpColor.v2 = highlight2;
      this.eqnColor.t = highlight2; this.eqnColor.v2 = highlight;
      // this.facts.addFactlet({title: "Given v1, x, and a", explain: "Need to solve for v2 and t.", typecolor: "info", difficulty: 1, category: "trial", eqn: "" });
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Given <i>v</i><sub>1</sub>, &Delta;<i>x</i>, and <i>a</i>. Need to solve for &Delta;<i>t</i> and <i>v</i><sub>2</sub>.',
        title: 'Given <i>v</i><sub>1</sub>, &Delta;<i>x</i>, and <i>a</i>.', // end FactBasic
        fact: <FactIdty>{ type: 'info', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));

      // using just sqrt function
      let v2arr: PhysQty[];
      if (this.a.valueSI == 0) { v2arr = []; v2arr.push( this.v1.dcopy() ); }
      else { v2arr = this._eeee.findEqn("eqKin1Dtf").eqn(this.d,this.a,this.v1); }
      // special case when a=0, don't use the quadratic eqn, seems to have 2 solutions for v2 = +/- v1. Should be only v2=v1, and t=0
      tmpinfo = this._eeee.findEqn("eqKin1Dtf").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc + ' Notice that solving this equation in velocity <i>v</i><sub>2</sub> can yield 0, 1, or 2 solutions.',
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 9,
        eqn: <FactIdty>{ name: "eqKin1Dtf" }
      }));

      if (v2arr.length == 0) {
        this.solnCnt = 0;
        this.solnsheet.factA.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
          sysDesc: 'The equation in velocity <i>v</i><sub>2</sub> yields no solution here.',
          title: 'No solution.', // end FactBasic
          fact: <FactIdty>{ type: 'error', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
          importance: 9,
          eqn: <FactIdty>{}
        }));

        return; // end with no solution
      }

      // this is for both 1 or 2 solutions
      this.v2.updateValFrom( v2arr[0] );
      // this.v2.name = 'v2'; this.v2.nameHtml = '<i>v</i><sub>2</sub>';
      if (this.a.valueSI != 0) { this.t.updateValFrom( this._eeee.findEqn("eqKin1Ddt").eqn(this.a,this.v1,this.v2) ); } else
      if (this.v1.valueSI != -this.v2.valueSI) { this.t.updateValFrom( this._eeee.findEqn("eqKin1Dat").eqn(this.d,this.v1,this.v2) ); }
      else { this.t.updateValFrom( this._zerot ); }  // both a=0 (so v1=v2 already) and v1=0 and v2=0 ??
      // this.t.name = 't'; this.t.nameHtml = '&Delta;<i>t</i>';

      if (v2arr.length == 1) {
        this.solnCnt = 1;
        this.solnsheet.factA.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
          sysDesc: 'The equation in velocity <i>v</i><sub>2</sub> yields a unique solution here.',
          title: 'One unique solution (from quadratic).', // end FactBasic
          fact: <FactIdty>{ type: 'warn', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
          importance: 9,
          eqn: <FactIdty>{}
        }));

        tmpinfo = this._eeee.findEqn("eqKin1Dft").info;
        this.solnsheet.factA.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
          sysDesc: tmpinfo.desc + ' This quadratic equation in time &Delta;<i>t</i> also yield a unique solution.',
          title: tmpinfo.cname, // end FactBasic
          fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
          importance: 6,
          eqn: <FactIdty>{ name: "eqKin1Dft" }
        }));

        return; // end with one unique solution
      }

      // this is for 2 solutions
      this.solnCnt = 2;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'The equation in velocity <i>v</i><sub>2</sub> yields two distinct solutions here.',
        title: 'Two possible solutions.', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 9,
        eqn: <FactIdty>{}
      }));

      // tmpinfo = this._eeee.findEqn("eqKin1Dft").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Typically after we solved one unknown (<i>v</i><sub>2</sub> here), we still prefer to solve the second unknown (&Delta;<i>t</i>) from an equation involving only the given variables <i>v</i><sub>1</sub>, &Delta;<i>x</i>, <i>a</i> (hence missing &Delta;<i>t</i>). This however will leave us with two possible solutions again, and we will need to match up the corresponding solutions between time &Delta;<i>t</i> and velocity <i>v</i><sub>2</sub> with an extra step. So in practice, we will simply use another simpler (linear) equation involving <i>v</i><sub>2</sub> (and &Delta;<i>t</i>) to solve for the corresponding time &Delta;<i>t</i> instead for the two possible solutions.',  // TO-BE-FINISHED // Switch to sqrt function
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 8,
        eqn: <FactIdty>{ }
      }));

      this.v2a.updateValFrom( v2arr[1]);
      // this.v2a.name = 'v2a'; this.v2a.nameHtml = '<i>v</i><sub>2a</sub>';
      this.inpHide.v2a = false;
      if (this.a.valueSI != 0) { this.ta.updateValFrom( this._eeee.findEqn("eqKin1Ddt").eqn(this.a,this.v1,this.v2a) ); } else
      if (this.v1.valueSI != -this.v2a.valueSI) { this.ta.updateValFrom( this._eeee.findEqn("eqKin1Dat").eqn(this.d,this.v1,this.v2a) ); }
      else { this.ta.updateValFrom( this._zerot ); }  // both a=0 (so v1=v2 already) and v1=0 and v2=0 ??
      // eqKin1Dat does not work if d=0  since t = 2d/(vi+vf) = 0/0, eqKin1Ddt on the other hand doesn't work if a=0
      this.inpHide.ta = false;
      // this.ta.name = 'ta'; this.ta.nameHtml = '&Delta;<i>t</i><sub>a</sub>';
      return; // end with 2 possible solutions


      /*
      // can use quadratic function instead  // reveal\explanations TO-BE-FINISHED // Switch to sqrt function
      const tarr = this._eeee.findEqn("eqKin1Dft").eqn(this.d,this.a,this.v1);
      ...

      */

    }

		// d-a-f (special case, avoid using two eqQuadratics)
		if (this.inpNull.t && this.inpNull.v1) {
      this.inpReadonly.t = true; this.inpReadonly.v1 = true;
      this.inpColor.t = highlight; this.inpColor.v1 = highlight2;
      this.eqnColor.t = highlight2; this.eqnColor.v1 = highlight;
      // this.facts.addFactlet({title: "Given v2, x, and a", explain: "Need to solve for v1 and t.", typecolor: "info", difficulty: 1, category: "trial", eqn: "" });
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Given <i>v</i><sub>2</sub>, &Delta;<i>x</i>, and <i>a</i>. Need to solve for <i>v</i><sub>1</sub> and &Delta;<i>t</i>.',
        title: 'Given <i>v</i><sub>2</sub>, &Delta;<i>x</i>, and <i>a</i>.', // end FactBasic
        fact: <FactIdty>{ type: 'info', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));

      // using just sqrt function
      let v1arr: PhysQty[];
      if (this.a.valueSI == 0) { v1arr = []; v1arr.push( this.v2.dcopy() ); }
      else { v1arr = this._eeee.findEqn("eqKin1Dti").eqn(this.d,this.a,this.v2); }
      // special case when a=0, don't use the quadratic eqn, seems to have 2 solutions for v1 = +/- v2. Should be only v1=v2, and t=0
      // const v1arr = this._eeee.findEqn("eqKin1Dti").eqn(this.d,this.a,this.v2);
      tmpinfo = this._eeee.findEqn("eqKin1Dti").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: tmpinfo.desc + ' Notice that solving this equation in velocity <i>v</i><sub>1</sub> can yield 0, 1, or 2 solutions.',
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 9,
        eqn: <FactIdty>{ name: "eqKin1Dti" }
      }));

      if (v1arr.length == 0) {
        this.solnCnt = 0;
        this.solnsheet.factA.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
          sysDesc: 'The equation in velocity <i>v</i><sub>1</sub> yields no solution here.',
          title: 'No solution.', // end FactBasic
          fact: <FactIdty>{ type: 'error', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
          importance: 9,
          eqn: <FactIdty>{}
        }));

        return; // end with no solution
      }

      // this is for both 1 or 2 solutions
      this.v1.updateValFrom( v1arr[0] );
      // this.v1.name = 'v1'; this.v1.nameHtml = '<i>v</i><sub>1</sub>';
      if (this.a.valueSI != 0) { this.t.updateValFrom( this._eeee.findEqn("eqKin1Ddt").eqn(this.a,this.v1,this.v2) ); } else
      if (this.v1.valueSI != -this.v2.valueSI) { this.t.updateValFrom( this._eeee.findEqn("eqKin1Dat").eqn(this.d,this.v1,this.v2) ); }
      else { this.t.updateValFrom( this._zerot ); }  // both a=0 (so v1=v2 already) and v1=0 and v2=0 ??
      // this.t.name = 't'; this.t.nameHtml = '&Delta;<i>t</i>';

      if (v1arr.length == 1) {
        this.solnCnt = 1;
        this.solnsheet.factA.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
          sysDesc: 'The equation in velocity <i>v</i><sub>1</sub> yields a unique solution here.',
          title: 'One unique solution (from quadratic).', // end FactBasic
          fact: <FactIdty>{ type: 'warn', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
          importance: 9,
          eqn: <FactIdty>{}
        }));

        tmpinfo = this._eeee.findEqn("eqKin1Dit").info;
        this.solnsheet.factA.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
          sysDesc: tmpinfo.desc + ' This quadratic equation in time &Delta;<i>t</i> also yield a unique solution.',
          title: tmpinfo.cname, // end FactBasic
          fact: <FactIdty>{ type: 'success', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
          importance: 6,
          eqn: <FactIdty>{ name: "eqKin1Dit" }
        }));

        return; // end with one unique solution
      }

      // this is for 2 solutions
      this.solnCnt = 2;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'The equation in velocity <i>v</i><sub>1</sub> yields two distinct solutions here.',
        title: 'Two possible solutions.', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 9,
        eqn: <FactIdty>{}
      }));

      // tmpinfo = this._eeee.findEqn("eqKin1Dit").info;
      this.solnsheet.factA.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['kinematics1d'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Typically after we solved one unknown (<i>v</i><sub>1</sub> here), we still prefer to solve the second unknown (&Delta;<i>t</i>) from an equation involving only the given variables <i>v</i><sub>2</sub>, &Delta;<i>x</i>, <i>a</i> (hence missing &Delta;<i>t</i>). This however will leave us with two possible solutions again, and we will need to match up the corresponding solutions between time &Delta;<i>t</i> and velocity <i>v</i><sub>1</sub> with an extra step. So in practice, we will simply use another simpler (linear) equation involving <i>v</i><sub>1</sub> (and &Delta;<i>t</i>) to solve for the corresponding time &Delta;<i>t</i> instead for the two possible solutions.',  // TO-BE-FINISHED // Switch to sqrt function
        title: tmpinfo.cname, // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: this.solnsheet.factA.length , note: '' }, // type: success, error, warn, info
        importance: 8,
        eqn: <FactIdty>{ }
      }));
      this.v1a.updateValFrom( v1arr[1] );
      // this.v1a.name = 'v1a'; this.v1a.nameHtml = '<i>v</i><sub>1a</sub>';
      this.inpHide.v1a = false;
      if (this.a.valueSI != 0) { this.ta.updateValFrom( this._eeee.findEqn("eqKin1Ddt").eqn(this.a,this.v1a,this.v2) ); } else
      if (this.v1.valueSI != -this.v2a.valueSI) { this.ta.updateValFrom( this._eeee.findEqn("eqKin1Dat").eqn(this.d,this.v1a,this.v2) ); }
      else { this.ta.updateValFrom( this._zerot ); }  // both a=0 (so v1=v2 already) and v1=0 and v2=0 ??
      // eqKin1Dat does not work if d=0  since t = 2d/(vi+vf) = 0/0, eqKin1Ddt on the other hand doesn't work if a=0
      this.inpHide.ta = false;
      // this.ta.name = 'ta'; this.ta.nameHtml = '&Delta;<i>t</i><sub>a</sub>';
      return; // end with 2 possible solutions

      /* //  can use quadratic function instead, though reveal\expanations not implemented.
      // using quadratic function
      const tarr = this._eeee.findEqn("eqKin1Dit").eqn(this.d,this.a,this.v2);
      if (tarr.length == 0) { this.solnCnt = 0; return; }
      ....
      */
		}

    return;
  }

  resetNull(): void { this.inpNull = {v1:true,v2:true,a:true,t:true,d:true}; return; }
  resetHide(): void { this.inpHide = {v1a:true,v2a:true,ta:true}; return; }
  resetReadonly(): void {
    this.inpReadonly= {v1:false,v2:false,a:false,t:false,d:false};
    this.inpColor={v1:'white',v2:'white',a:'white',t:'white',d:'white'}; // 'none' doen't work.
    this.eqnColor={v1:'white',v2:'white',a:'white',t:'white',d:'white'}; // 'none' doen't work.
    return;
  }
  resetVals(): void {
    this.solnCnt = 0;
    // reset all units
    // TODO
    // reset all prefixes
    this.comp8.forEach(function(v:string){this[v].setPrefixVals(0);}.bind(this));
    // reset all five values, and SI values
    this.comp8.forEach(function(v:string){this[v].setNewValue(0);this[v].value=null;}.bind(this));
    this.resetNull(); this.resetHide(); this.resetReadonly();
    return;
  }

  valueChg(comp: string, event): void {
    let v = event.target.value;
    this[comp].setNewValue(v); // this will change the valueSI and everything.
    if (v==''||v===null) { // for chrome, blank is '', not null.
      this.inpNull[comp] = true;
      this[comp].value = null;
      this.resetReadonly();
    } else {
      this.inpNull[comp] = false;
    }
    // reset all calcuated values
    this.comp5.forEach(function(c){ if(this.inpNull[c]&& c!=comp){this[c].setNewValue(0); this[c].value=null;}}.bind(this));

    // now solve, regardless. the solver will handle different number of inputs differently
    this.solve();

    return;
  }

  unitChg(comp: string): void {
    this.prefixChg(comp); // in case it's changed as some non-SI units force prefix to 0.
    this[comp].chgSysUnitID(<PhysConShell>{punitId: this[comp]['punitId'] });
    if ( comp=='v1' || comp=='v2' || comp=='t' ) {
      this[comp+'a'].chgSysUnitID(<PhysConShell>{punitId: this[comp]['punitId'] });
    }
    if (this.inpNull[comp] && !this.inpReadonly[comp]) this[comp].value = null; // if inpNull is true, AND not readonly (calculated results showing), keep blank input blank, otherwise will show as zero.
    return;
  }
  prefixChg(comp: string): void {
    this[comp].setPrefixVals(this[comp].prepower);
    this[comp].setNewValueSI();
    if ( comp=='v1' || comp=='v2' || comp=='t' ) { // change altenative solution accordingly if applicable
      this[comp+'a'].setPrefixVals(this[comp].prepower);
      this[comp+'a'].setNewValueSI();
    }
    if (this.inpNull[comp] && !this.inpReadonly[comp]) this[comp].value = null; // if inpNull is true, AND not readonly (calculated results showing), keep blank input blank, otherwise will show as zero.
    return;
  }

}


@Component({
  selector: 'app-kinematics1d',
  templateUrl: './kinematics1d.component.html',
  styleUrls: ['./kinematics1d.component.scss']
})
export class Kinematics1dComponent implements OnInit {
  readonly comp5 = [ {name: 'a', html:'<i>a</i>'}, {name: 'd', html:'&Delta;<i>x</i>'}, {name: 't', html:'&Delta;<i>t</i>'}, {name: 'v1', html:'<i>v</i><sub>1</sub>'}, {name: 'v2', html:'<i>v</i><sub>2</sub>'} ];
  readonly comp2ad = [ {name: 'a', html:'<i>a</i>'}, {name: 'd', html:'&Delta;<i>x</i>'} ];
  readonly comp3vt = [ {name: 't', html:'&Delta;<i>t</i>'}, {name: 'v1', html:'<i>v</i><sub>1</sub>'}, {name: 'v2', html:'<i>v</i><sub>2</sub>'} ];
  readonly comp3vta = [ {name: 't', aname: 'ta', html:'&Delta;<i>t</i><sub>a</sub>'}, {name: 'v1', aname: 'v1a', html:'<i>v</i><sub>1a</sub>'}, {name: 'v2', aname: 'v2a', html:'<i>v</i><sub>2a</sub>'} ];
  readonly comp5eqn = [ { vmiss:'v2', eqname: 'eqKin1Dfd' }, {vmiss:'d', eqname: 'eqKin1Ddi'}, { vmiss:'t', eqname: 'eqKin1Dti' }, { vmiss:'a', eqname: 'eqKin1Dai' }, { vmiss:'v1', eqname: 'eqKin1Did' } ];
  // readonly comp5 = [{name: 'v1', html:'<i>v</i><sub>1</sub>', pdimId: 10}, {name: 'd', html:'&Delta;<i>x</i>', pdimId: 4}, {name: 'v2', html:'<i>v</i><sub>2</sub>', pdimId: 10}, {name: 'a', html:'<i>a</i>', pdimId: 11}, {name: 't', html:'&Delta;<i>t</i>', pdimId: 3} ];
  instructionHtml: string;
  qtet: Kinematics1dQtet;
  displayedColumns: string[] = ['eqn', 'vm'];
  dataSource: MatTableDataSource<object> ;

  constructor(private _facts: FactoryService, private _eeee: EeeeService, private _sbs: SnackBarService, private _gcb: GlobalClipboardService, private _gpu: GlobalPhysUnitsService, private _mono: MonologService ) { }

  ngOnInit() {
    // set up factsier/report and factsheet for the reveal module
    this._facts.addFactsier( new Factsier({factsier: {id:3, name:'kinematics1d', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Kinematics 1D', topicA: ['kinematics1d'] }));
    this._facts.bkmark.factsier = {id:3, name:'kinematics1d'}; // either one should do. name is the overriding criteria
    this._facts.findFactsier({name: 'kinematics1d'}).addFactsheet( new Factsheet({sheet: {id:0, name:'main1d', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Kinematics 1D', topicA: ['kinematics1d'] }));

    // global state var
    // set up quintet object
    const svars = this._gcb.stateCfgs.g('kinematics1d');
    if (svars) {
      this.qtet = <Kinematics1dQtet>svars.vars.qtet;
    } else {
      this.qtet = new Kinematics1dQtet(this._facts.findFactsier({name: 'kinematics1d'}).findFactsheet({name: 'main1d'}) );
      this._gcb.stateCfgs.list.push({stateName:'kinematics1d', vars:{ qtet: this.qtet } });
    }

    this.instructionHtml = "<br><p>Technically, kinematics is just math, not physics. It is about relations between quantities like position, velocity, acceleration, etc. These are simply tautology by defintions. Nothing new. Unlike <i>F</i>=<i>ma</i> or <i>E</i>=<i>mc</i><sup>2</sup>, which connects different physical quantities that are otherwise unrelated. Nonetheless, physics has certainly given kinematics much meaning and color.</p><p>Overall speaking, these constant acceleration motions (1-D) always have 5 variables. We usually denote them as <br>"+ this.qtet.d.nameHtml + " (=<i>x<sub>2</sub> &minus; x<sub>1 </sub></i>, or <i>d</i>), "+ this.qtet.v1.nameHtml + " (or <i>v<sub>0 </sub></i>, <i>v</i><sub>i</sub>), "+ this.qtet.v2.nameHtml + " (or just <i>v</i>, <i>v<sub>f</sub></i>), "+ this.qtet.a.nameHtml+ ", and "+ this.qtet.t.nameHtml + " (or just <i>t</i>).</p><p><b>Fill in any three of them</b> to try calculate the other two as unknowns.</p><p>We prefer to use subscripts 1 and 2 instead of <i>i</i> and <i>f</i> to allow proper interpretation when the calculated solution is having a negative duration &Delta;<i>t</i>, which means point 2 really is the initial/earlier instance, and point 1 is the final/later instance.</p>";

    let KineEqn_Data: object[]=[]; const nhd = new NameHtmlDispPipe();
    this.comp5eqn.forEach(function(o:object){ const a = {v: o['vmiss'] , eqn: this.eqnDisplay(o['eqname']), vm: nhd.transform(this.qtet[o['vmiss']]) }; KineEqn_Data.push(a); }.bind(this));
    this.dataSource = new MatTableDataSource(KineEqn_Data);
    // = [
    //   {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
    //   {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
    //   {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
    //   {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
    //   {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
    //   {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
    //   {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
    //   {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
    //   {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
    //   {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
    // ];

  }

  // readonly pfxDropList = siPrefixes.copylist(); //: SiPrefix[];
  pfxDropList(pq: PhysQty): SiPrefix[] {
    const pipe: PrefixDropListPipe = new PrefixDropListPipe();
    return pipe.transform(pq);
  }

  altcomps(v:string): boolean { return (v=='t'||v=='v1'||v=='v2'); }

  revealInstructions(): MatDialogRef<MonologComponent>  {
    return this._mono.openlog({ override: {title: 'Kinematics Module Instructions', message: this.instructionHtml } });
  }

  paste2input(tvar:string): void {
    // let sbarMsg: object = null;
    let spadlen = this._gcb.spad.list.length;
    const sbs = this._sbs;
    // check clipboard
    if ( spadlen<1 ) {
      sbs.now( <SnackBarMsg> { importance: 10, message: "Clipboard is empty. Nothing to paste.", action: "Error", duration: 4000 });
      return;
    }

    // check compatible category/dimension
    const cb = this._gcb.spad.list[spadlen-1].dcopy();
    const pq: PhysQty = this.qtet[tvar];
    if ( !cb.compcomp(pq) ) {
      sbs.now( <SnackBarMsg> { importance: 10, message: "Clipboard element is of category "+cb.category+", incompatible with "+pq.category+".", action: "Error", duration: 5000 });
      return;
    }

    // correct category.
    pq.update(cb);
    this.qtet.unitChg(tvar); // this might change prefix back to zero by default for safety
    pq.setPrefixVals(cb.prepower); // if it's really non-zero in cb, change it again.
    this.qtet.prefixChg(tvar);
    // change cb to original chosen prefix and unit, then do valueChange, to refresh solutions.
    // cb.chgSysUnitID(<PhysConShell>{punitId: pq['punitId'] });
    // cb.setPrefixVals(pq['prepower']);
    this.qtet.valueChg(tvar, {target: {value: cb.value}}); // fake an $event with event.target.value

    sbs.now( <SnackBarMsg> { importance: 4, message: "Paste from clipboard successful.", action: "Success", duration: 4000 });

    return;
  }

  addPqty(pq: PhysQty): void {  this._gcb.spad.addPqty(pq);  }

  eqnDisplay(eq:string | number): string {
    let eqn = this._eeee.findEqn(eq);
    if (!eqn) return '';
    if (eqn.info.parentEqn == null) { return eqn.info.eqnHtml; }
    // if not null, go find the parent
    return this.eqnDisplay(eqn.info.parentEqn);
  }

  // general unit conversions
  pqUnitA(v: string): unitDropdownList[] { return this._gpu.physUnitAList4dropdown( { pdimId: this.qtet[v].pdimId } ); } // pdimId: 20, Force
  pqsys(v: string): string { return this.pqUnitA(v)[0]['systemA'][0]; } // using getter will refresh model in ngFor loops etc.
  pqunit(v: string): string { return this.pqUnitA(v)[0]['unit']; }
  pqpunitid(v: string): number { return this.pqUnitA(v)[0]['punitId']; }

}
