import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SysSettingsComponent } from './sys-settings.component';
import { FormsModule } from '@angular/forms';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';



@NgModule({
  declarations: [ SysSettingsComponent ],
  imports: [ CommonModule, FormsModule, CommonPipeModule ],
  exports: [ SysSettingsComponent ]
})
export class SysSettingsModule { }
