import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScratchPadComponent } from './common/scratch-pad/scratch-pad.component';
import { UniconComponent } from './topics/unicon/unicon.component';
import { LinearalgebraComponent } from './topics/linearalgebra/linearalgebra.component';
import { Kinematics1dComponent } from './topics/kinematics/kinematics1d/kinematics1d.component';
// import { GravityComponent } from './topics/gravity/gravity.component';
import { AboutComponent } from './topics/about/about.component';
import { SineCosineLawComponent } from './topics/trigs/sine-cosine-law/sine-cosine-law.component';
import { Vec2dSumComponent } from './topics/linearalgebra/vec2d-sum/vec2d-sum.component';
import { QuadraticComponent } from './topics/quadratic/quadratic.component';


export const routes: Routes = [ 
  { path: '', redirectTo: 'about', pathMatch: 'full' },
  { path: 'vec2d-sum', component: Vec2dSumComponent },
  // { path: 'scratch', component: ScratchPadComponent },
  // { path: 'scratch-pad', component: ScratchPadComponent },
  { path: 'quadratic', component: QuadraticComponent },
  { path: 'sine-cosine-law', component: SineCosineLawComponent },
  { path: 'linearalgebra', component: LinearalgebraComponent },
  { path: 'kinematics1d', component: Kinematics1dComponent },
  // { path: 'gravity', component: GravityComponent },
  { path: 'unicon', component: UniconComponent },
  { path: 'about', component: AboutComponent } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
