import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScratchPadComponent } from './scratch-pad.component';
import { GlobalClipboardConfig, GlobalClipboardService } from './global-clipboard.service';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';

@NgModule({
  declarations: [ ScratchPadComponent ],
  providers: [ 
    // GlobalClipboardService, 
  ],
  imports: [ CommonModule, CommonPipeModule ], 
  exports: [ ScratchPadComponent ]
})
export class ScratchPadModule { 
  constructor(@Optional() @SkipSelf() parentModule: ScratchPadModule) {
    if (parentModule) { throw new Error('ScratchPadModule is already loaded. Import it in the AppModule only'); }
  }

  static forRoot(@Optional() config?: GlobalClipboardConfig ): ModuleWithProviders {
    // console.log("instantiating ScratchPadModule.forRoot");
    // config = (config) ? config : new GlobalClipboardConfig ({ maxlen: 0, physconlist: null }); // let constructor decide // angular wants forRoot to be absolutely static, not allowing other functions in here.
    return {
      ngModule: ScratchPadModule,
      providers: [ 
        { provide: GlobalClipboardConfig, useValue: config } // set clipboard/scratchPad max length 
      ]
    };
  }

}
