import { Pipe, PipeTransform } from '@angular/core';
import { PhysDim } from 'src/app/numeric-library/bbb/bbb.module';
import { PhysConShell } from 'src/app/numeric-library/invariant/global-phys-units.service';

@Pipe({ name: 'unitDisp' })
export class UnitDispPipe implements PipeTransform {
  transform( pq: PhysDim|PhysConShell|string, si:boolean=false ): string {
    let str: string;
    if(si){str=pq['unitSI'];}else if(pq.hasOwnProperty('unit')){str=pq['unit'];}else{str = <string> pq;}
    // if (pq.hasOwnProperty('unitA')) { str=pq['unitA'][0]; } else 
    // { str=pq.unitSI; }
    str = str.trim();
    str = str.replace(/\^([0-9]+)/g, "<sup>$1</sup>");
    str = str.replace(' /','/');
    str = str.replace('/ ','/');
    str = str.replace(' ','&sdot;');
    if (str=='rad') str=''; // force 'rad' as blank 
    return str;
  }
}
