import { Pipe, PipeTransform } from '@angular/core';
import { PhysQty0 } from 'src/app/numeric-library/bbb/bbb.module';
import { ValueDispPipe } from './value-disp.pipe';

@Pipe({ name: 'temperatureDisp', pure: false })
export class TemperatureDispPipe implements PipeTransform {
  transform(pq: PhysQty0, unit: string, gdig?, gsig?): string {
    // only works for temperature of temperature difference PhysQtys
    let result: string = '';
    let unitdisp: string = '';
    if (pq.pdimId != 5 && pq.pdimId != 6) { console.warn(`temperatureDisp can only apply to temperature and temperature difference quantities, not for id= ${pq.pdimId}.`); return result; }
    const vd: ValueDispPipe = new ValueDispPipe() ;
    let val: number;
    if (unit=='Kelvin'||unit=='kelvin'||unit=='K'||unit=='k') { 
      val = pq.valueSI; unitdisp = ' K'; 
    } else if (unit=='Celsius'||unit=='celsius'||unit=='&deg;C'||unit=='C'||unit=='&deg;c'||unit=='c') { 
      unitdisp = '&deg;C'; 
      val=(pq.pdimId==5)? pq.valueSI-273.15 : pq.valueSI ; 
    } else if (unit=='Fahrenheit'||unit=='fahrenheit'||unit=='&deg;F'||unit=='&deg;f'||unit=='F'||unit=='f') { 
      unitdisp = '&deg;F'; 
      val=(pq.pdimId==5)? (pq.valueSI-273.15)*9/5+32 : pq.valueSI*9/5 ; 
    }
    result = vd.transform(val,gdig,gsig);
    return result+unitdisp;
  }

}
