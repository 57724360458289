import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
// import { Vector3d } from 'src/app/numeric-library/tensor/tensor.module';
import { GlobalClipboardService } from '../../../common/scratch-pad/global-clipboard.service';
import { FigureSvg } from 'src/app/visual/figure-svg/figure-svg';
import { PhysQty } from 'src/app/numeric-library/bbb/bbb.module';
import { PureMathService } from 'src/app/numeric-library/operator/pure-math.service';

export class Vector3dFigureSvg extends FigureSvg {
  v1: PhysQty; // radial component of the vector
  v2: PhysQty; // radial component of the vector
  a12: PhysQty; // angle between v1, v2
  type: string; // sum or product


  constructor(input: object) { // input: { width, height, depth (z), vector1, vector2, angle12, type
    const w = Math.floor(input['width']); 
    const h = Math.floor(input['height']); 
    // const d = Math.floor(input['depth']); // for 3-D rendering in the future
    super({ width: w, height: h, xs: [0,0,0,0,0,0], ys: [0,0,0,0,0,0], zs: [0,0,0,0,0,0] }); // input { width, height, depth, xs: number[], ys, zs
		// six points are  (0) origin, (1) v1, (2) small fraction of v1 for the angle, (3) v2, 
		// (4) small fractino of v2 for the angle, (5) v1+v2, 
    // Need two more points, but are redundant: (6) centroid (midpt of v1v2, or midpt of O and v1+v2), (7) v2 projection on v1

		this.v1 = input['v1'];
		this.v2 = input['v2'];
		this.a12 = input['a12'];
    this.type = input['type'];

  }

	setXsYs(): void {
		// if same units/category, use exact values, if not, scale v1 to 1.25 times v2.r.valueSI
		let v1r = this.v1.valueSI;
    let v2r = this.v2.valueSI;
    let angle = this.a12.valueSI;

    // let fv1r = function

    let v1x = ( this.v1.pdimId == this.v2.pdimId || v2r == 0) ? v1r : v2r * 1.25;
		let angleradius = Math.min(v1x,v2r) * 0.2; 
		let v2x = v2r * Math.cos(angle);
		// let v1y = 0;
		let v2y = -v2r * Math.sin(angle);

		this.xs = [ 0, v1x, angleradius, v2x, angleradius*Math.cos(angle), v1x+v2x ];
    this.ys = [ 0, 0, 0, v2y, -angleradius*Math.sin(angle), v2y ];

    return;
	}

  setScale(): number { 
    let s = null; const xrange = this.xrange; const yrange = this.yrange;
		if (xrange==0 && yrange==0) { s=1; } else 
		if (xrange==0) { s=this.height/yrange; } else 
		if (yrange==0) { s=this.width/xrange; } else { s=Math.min(this.width/xrange,this.height/yrange); }
    return s;
  }

  setQuadVertices(): void {
		const s = this.setScale();
		this.scaleXY(0.88*s);
    this.shiftX(Math.floor(this.width/2-this.xmid)); 
    this.shiftY(Math.floor(this.height/2-this.ymid)); 
		return;
  }
  

  // get path1(): string { return 'M '+this.xs[0]+' '+this.ys[0]+' L '+this.xs[1]+' '+this.ys[1]+' L '+this.xs[5]+' '+this.ys[5]+' L '+this.xs[3]+' '+this.ys[3]+' z'; } // path: parallogram
  get d4gram(): string { return this.xs[0]+','+this.ys[0]+' '+this.xs[1]+','+this.ys[1]+' '+this.xs[5]+','+this.ys[5]+' '+this.xs[3]+','+this.ys[3];; } // data polygon: 4 sided parallogram, shaded
  get dsector(): string { const aradius: number = 0.2*(this.xs[1]-this.xs[0]); return "M "+this.xs[0]+" "+this.ys[0]+" L "+this.xs[2]+" "+this.ys[2]+ " A "+aradius+" "+aradius+" 0 0 0 "+this.xs[4]+" "+this.ys[4]; } // data: angle sector, colored
  get v2yoffset(): number { return (this.xs[3] < this.xs[0]) ? this.ys[0] : this.ys[0]-3; } // vector v2 allow overlap
  get v2perpPChk(): boolean { return ( this.type=='p' && !PureMathService.pchk(this.xs[3],this.xs[0],0.1) && !PureMathService.pchk(this.ys[3],this.ys[0],0.1) ); } // also check type==product
  get v1ArrowMarker(): string { return (this.type=="s")?"url(#arrowR)":"url(#arrow)"; }

}



@Component({
  selector: 'app-vector3d-figure-svg',
  templateUrl: './vector3d-figure-svg.component.svg',
  styleUrls: ['./vector3d-figure-svg.component.scss']
})
export class Vector3dFigureSvgComponent implements OnInit, OnChanges {
  // @ViewChild('poly1', {static: false}) poly1: ElementRef;
  @Input() v1: PhysQty; // only length is needed (and maybe unit)
  @Input() v2: PhysQty; // only length is needed (and maybe unit)
  @Input() a12: number; // angle between v1 and v2, in rad
  @Input() ftype: string; // sum or product 's', 'p'
  figure: Vector3dFigureSvg;
  readonly figwidth = 440;
  readonly figheight = 320;

  constructor() { }

  ngOnInit() { this.figRender(); }

  ngOnChanges() { this.figRender(); }

  figRender(): void {
    // this.ftype = 'p';
    this.figure = new Vector3dFigureSvg ({width: this.figwidth, height: this.figheight, v1: this.v1, v2: this.v2, a12: this.a12, type: this.ftype });
    this.figure.setXsYs();
    this.figure.setQuadVertices();
    return;
  }
  

}
