import { Pipe, PipeTransform } from '@angular/core';
import { PhysUnit } from 'src/app/numeric-library/bbb/bbb.module';
import { GlobalPhysUnitsService, SiPrefix, siPrefixes } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { AppInjector } from 'src/app/app-injector';

// filter prefixes for drop menu

@Pipe({ name: 'prefixDropList', pure: false })
export class PrefixDropListPipe implements PipeTransform {
  private _gpu: GlobalPhysUnitsService;
  
  constructor() { this._gpu = AppInjector.get(GlobalPhysUnitsService); }
  
  transform( unit: PhysUnit ): SiPrefix[] { // return dropdownlist of allowed prefixes
    const prefixlist: SiPrefix[] = siPrefixes.copylist(); 
    return prefixlist.filter( siprefix => this._gpu.prefixAllow(unit) || !siprefix.prepower ); // if allow, all prepower can go, if not allow, only prepower==0 kept
  }
  // transform( unit: PhysUnit ): SiPrefix[] { return (this._gpu.prefixAllow(unit)) ? siPrefixes.list : [ siPrefixes.the0 ];  }
}
