import { NgModule, ModuleWithProviders, Optional, SkipSelf, Inject, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PureMathService } from '../operator/pure-math.service';
import { physDimCompNames, PhysDimComp, PhysConShell, GlobalPhysUnitsService, siPrefixes, SiPrefix, PhysDimCompForm, physDimCompNull } from '../invariant/global-phys-units.service';
import { SnackBarService } from 'src/app/service/facts/snack-bar.service';
import { AppModule } from 'src/app/app.module';
import { AppInjector } from 'src/app/app-injector';

// Basic-Building-Block module

// @Injectable()
export class PhysDim {
  // physical unit dimensions // independent of SI/Imperial/system // level 0 base class
  // structure: pdimId, for use in gvars.physDims array, or DB entries.
  pdimId: number; // -1 reserved for unknown, when two multiplied together, for example. 0 is for undefined. (JSON stringify does not handle undefined or Infinity well.) 1 is for radian.
  comp: PhysDimComp; // {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0} // 7+1 base dimensions/components
  categoryA: string[];
  compDisp: string;
  unitSI: string;
  private _gpu: GlobalPhysUnitsService;

  // constructor() {
  constructor(input: PhysConShell ) {
    // standard way to instantiate PhysDim can start with either ({ T:2 } or { M:0, T:0, L:0, t:0, N:0, I:0, J:0, td:0 }) or ({category: "angle"} or {categoryA: ["angle","dimensionless"]}) or  {pdimId: n} in this order of search
    // these are already handled by PhysQtyList.findPhysDim() // eventually those should be from DB
    // this.pdimId = input.pdimId;
    // this.comp = new PhysDimComp(input.comp);
    // this.categoryA = [].concat(input.categoryA);
    // let gpu: GlobalPhysUnitsService = new GlobalPhysUnitsService();
    this._gpu = AppInjector.get(GlobalPhysUnitsService);
    this.setPdimValue(this._gpu.findPhysDim(input));
    this.unitSI = "";
    this.compDisp = "";
    this._setUnitSI(); // and compDisp
    // comp (composition, for dimensional analysis, for example: {M:1,T:1.5,L:-1,t:0,N:0,I:0,J:0,td:0} ) // unique except temperature and temperature difference, but can have duplicate common names
    // category (length, mass, time, power, work, etc)
    // unitSI (from the 7 base units) and compsum (sum of abs of components. 0 and 1 are considered basic.)
  } // end constructor

  get gpu(): GlobalPhysUnitsService { return this._gpu; } // expose this private property to desendents for read access
  // copyGivenPdim(pd: PhysDim): PhysDim { return this._copyGivenPdim(pd); } // expose this private property to desendents for read access

  get compsum(): number { let sum: number=0; physDimCompNames.forEach(function(v:string){sum+=Math.abs(this.comp[v]);}.bind(this)); return (isNaN(sum) || !isFinite(sum)) ? Infinity : sum; }

  setPdimValue(input: PhysConShell): void {
    this.pdimId = input['pdimId'];
    this.comp = new PhysDimComp(input['comp']);
    this.categoryA = [].concat(input['categoryA']);
    return;
  }

  // duplicate from PhysConShell, get compsum and setUnitSI()
  private _setUnitSI(): void {
    // set this.unitSI and this.compDisp
    let denom: string =""; let numerator: string =""; let uname: string ="";
    let tmp: number = 0;
    let cdenom: string =""; let cnumerator: string =""; let cname: string ="";
    if ( !(PureMathService.isNumeric(this.compsum))) { this.unitSI = "undefined"; this.compDisp = "undefined"; return; }
    physDimCompNames.forEach(function(t){
      if(t=="M"){uname="kg";cname="[Mass]";}else
      if(t=="T"){uname="s";cname="[Time]"}else
      if(t=="L"){uname="m";cname="[Length]"}else
      if(t=="t"||t=="theta"||t=="td"){uname="K";cname="[Temp]"}else
      if(t=="N"){uname="mol";cname="[Mol]"}else
      if(t=="I"){uname="A";cname="[Current]"}else
      if(t=="J"){uname="cd";cname="[Lum Int]"}
      tmp = this.comp[t];
      if (tmp==1){numerator += uname +" ";cnumerator += cname +" ";}else
      if(tmp>0){numerator += uname+"^"+tmp+" ";cnumerator += cname+"^"+tmp+" ";}else
      if(tmp== -1){denom += uname+" ";cdenom += cname+" ";}else
      if(tmp<0){denom += uname+"^"+(-tmp)+" ";cdenom += cname+"^"+(-tmp)+" ";}
    }.bind(this));
    if(denom=="" && numerator=="") { this.unitSI = ""; this.compDisp = ""; return; }
    denom = denom.trim(); numerator = numerator.trim(); cdenom = cdenom.trim(); cnumerator = cnumerator.trim();
    if(denom=="") { this.unitSI = numerator; this.compDisp = cnumerator; return; }
    if(numerator=="") { this.unitSI = "1 / "+denom; this.compDisp = "1 / "+cdenom; return; }
    this.unitSI = numerator+" / "+denom;
    this.compDisp = cnumerator+" / "+cdenom;
    return;
  } // end this.unitsi

  compcomps(M: number|string,T: number|string,L: number|string,t: number|string,N: number|string,I: number|string,J: number|string,td: number|string): boolean { return this.comp.compcomps(M,T,L,t,N,I,J,td); } // compare 7+1 components
  compcomp(pd: PhysDim): boolean { return this.comp.compcomp(pd.comp); } // compare component as object, pu.comp must exist
  addCategory(cat: string): void { if (!(cat in this.categoryA)) { this.categoryA.push(cat); }; }

  // adding/moving basic uniary/binary operations from pqty-math.services to here
  private _copyGivenPdim(pdim: PhysDim): PhysDim {
    this.pdimId = pdim.pdimId;
    this.compDisp = pdim.compDisp;
    this.unitSI = pdim.unitSI;
    this.categoryA = [ ...pdim.categoryA ];
    this.comp = new PhysDimComp( { ...pdim.comp } ); // spread operator does not copy the methods, which are needed here
    return this;
  }

  /**
   * Multiply/Divide the PhysDim with another, returns the changed PhysDim
   * @remarks This method CHANGES this/self
   * @param pdim - The PhysDim to be multiplied by, or to be divided by. Can be a number (pdimId) or object like PhysConShell
   * @param divide - Indicate division (true) or regular multiplication (false)
   * @returns this: The product or quotient of the resulting PhysDim object
   */
	dtimes(pdim: PhysDim | number | object, divide: boolean =false) : PhysDim { // PhysDims multiply
    // pdim can be PhysDim object, or pdimId, or {pdimId: n} or {L:0, M:1}, for division, use divide = true
    const prod = <PhysDimCompForm>{M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0};
    let dpdim = ( typeof(pdim) == 'number' )? Object.assign( {}, prod ) : pdim;
    if (!(dpdim instanceof PhysDim)) { dpdim = this._gpu.findPhysDim(dpdim); }
    physDimCompNames.forEach(function(c: string){
      if (isNaN(this.comp[c]) || isNaN(dpdim['comp'][c])) { prod[c] = physDimCompNull[c]; } else { prod[c] = (divide)? this.comp[c] - dpdim['comp'][c] : this.comp[c] + dpdim['comp'][c]; }
    }.bind(this));
    return this._copyGivenPdim( new PhysDim(this._gpu.findPhysDim(prod)) );
  }
  ddivides(pdim: PhysDim | number | object) : PhysDim { return this.dtimes(pdim, true); }

  /**
   * Unary operator, dimension sqrt
   * @remarks This method CHANGES this/self
   * @returns this: The square root of the PhysDim
   */
	dsqrt() : PhysDim { // square-root of PhysDim, dimensions only
    const res = <PhysDimCompForm>{M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0};
    physDimCompNames.forEach(function(c:string){ res[c] = (isNaN(this.comp[c])) ? physDimCompNull[c] : this.comp[c]/2; }.bind(this));
    return this._copyGivenPdim( new PhysDim(this._gpu.findPhysDim(res)) );
  }
  dpower(n: number) : PhysDim { // n-th power PhysDim, integers, dimensions only
    n = Math.floor(n);
    const res = <PhysDimCompForm>{M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0};
    physDimCompNames.forEach(function(c:string){ res[c] = (isNaN(this.comp[c])) ? physDimCompNull[c] : this.comp[c]*n; }.bind(this));
    return this._copyGivenPdim( new PhysDim(this._gpu.findPhysDim(res)) );
  }

  dcopy(): PhysDim { return new PhysDim( new PhysConShell( { ...this } ) ); }
} // end PhysDim

// @Injectable()
export class PhysUnit extends PhysDim {
  // physical unit // level 1 class // PhysDim input={comp: {M:1,T:-3,L:0,t:0,N:0,I:0,J:0,td:0},categoryA: ["intensity","radiance","heat flux density","irradiance"]}
  punitId : number; // -1 reserved for unknown,  (JSON stringify does not handle undefined or Infinity well.) 1 is for radian.
  cu2si: number; // conversion to SI unit, scale factor
  cu2siInt: number; // conversion to SI unit, y-intercept, for Celsius as an example. Note that temperature change should not use the y-intercept
  level: number;
  systemA: string[];
  unitA: string[]; // all the units in unitA must have the same cu2si (convert-unit-to-si) value.

  constructor( input: PhysConShell ) {
    super(input); // compsum and unitsi are also re-set,
    this.setPhysUnitVals(this.gpu.findPhysUnit(input)); // cu2si, level, systemA (arr), unitA (arr)
  }

  setPhysUnitVals(pu: PhysConShell) {
    this.punitId = pu.punitId;
    this.cu2si = Number(pu.cu2si);
    this.cu2siInt = Number(pu.cu2siInt);
    this.level = Number(pu.level);
    this.systemA = [ ...pu.systemA ];
    this.unitA = [ ...pu.unitA ];
    // level = 0 for fundamental (m,kg,s,A,mol,K,cd), level = 1 for accepted alternative, especially for the units outside the base units, N=kg m/s^2, for example, is level = 1; or 1 min is SI, and level=1. Arguably 1 decade is still SI, but level = 2. Other units such as us fl oz could be level=2
    // note: if system includes SI, AND level=0 (only with compsum = 0 or 1), then cu2si = 1
  }

  addSystem(s: string) { if (!(s in this.systemA)) { this.systemA.push(s); }; return; }
  addUnit(u: string) { if (!(u in this.unitA)) { this.unitA.push(u); }; return; }

  dcopy(): PhysUnit { return new PhysUnit( new PhysConShell( { ...this } ) ); }
} // end PhysUnit

// @Injectable()
export class PhysQty0 extends PhysUnit {
  // physical value, with units (dimensions), precise, no uncertainty // level 2 class
  qid: number; // for unique id in scratchpad
  cbid: number; // for clipboard current position
  name: string;
  nameHtml: string;
  value: number;
  valueSI: number;
  system: string;
  category: string;
  unit: string;
  prefix: string;
  prename: string;
  prepower: number;
  prehtml: string;
  // private _gpu: GlobalPhysUnitsService;

  constructor(input: PhysConShell){
    super(input); // set PhysUnit parent

    this.qid = -1;
    this.cbid = -1;
    this.setPrefixVals(input);
    this.setPqtyVals(input);
    if (input['valueSI'] === null || input['valueSI'] === undefined) { this.setNewValue(Number(input['value'])); } else { this.setNewValueSI(Number(input['valueSI'])); } // valueSI is invariant, never change for this Pqty0 // make sure conversion factors are set
    // added line below so that if value==null, it means input not set. Keep it that way all the time, including dcopy() etc.
    if (input['value'] === null || isNaN(input['value']) ) { this.value = null; } // normally, give value = something when instantiate. Otherwise, use setNewValueSI after instantiation.
  } // end contructor

  private get _cp2s(): number { return Number(1+'e'+this.prepower); }
  // get cunit(): string { return this.prehtml+this.unit; } // complete unit with prefix // need to change for illegal use of prefix, eg, Mkg not allowed?

  otherUnits(excl: string =this.unit,sep: string =", "): string {
    let h=sep;
    this.unitA.forEach(function(u: string){h+=(u==excl)?"":u+sep;}.bind(this));
    h=h.replace(sep,""); // remove first occurance
    if (h.endsWith(sep)) { return h.substr(0,h.lastIndexOf(sep)).trim(); }
    return "";
  }

  setPrefixVals(input: any): void {
    let tpfx = siPrefixes.findSiPrefix(input);
    this.prefix = tpfx.prefix;
    this.prepower = tpfx.prepower;
    this.prename = tpfx.prename;
    this.prehtml = tpfx.prehtml;
    return;
  }

  setPqtyVals(inp: PhysConShell):void {
    // set value "to si" or "from si"
    // this.setPrefixVals(inp.prefix);
    this.name = (inp.name)?inp.name:((this.name)?this.name:"no_name"); // displacement, initial pressure, etc
    this.nameHtml = (inp.nameHtml)?inp.nameHtml:((this.nameHtml)?this.nameHtml:"x"); // x, y, p_x, etc
    this.system = (inp.system)?inp.system:((this.system)?this.system:"SI"); // set default "SI", check DB record
    this.category = (inp.category)?inp.category:((this.category)?this.category:this.categoryA[0]); // categoryA should have been set at PhysUnit parent class. Check DB record
    this.unit = (inp.unit)?inp.unit:((this.unit)?this.unit:""); // set default "", check DB record
  }

  /**
   * setNewValueSI always assume the prefix is 0 for the SIval in the argument, and set other value etc. If sival is not given, use the present valueSI.
   * @param sival
   * @param keepnull : if true, original value==null will remain null. If not null, then set the new Value from ValueSI
   */
  setNewValueSI(sival?: number, keepnull?: boolean): void { // type = null/"Min"/"Max"
    // if no sivalue given, then we are assuming valueSI is already set (say now changing unit or chaning prefix), and set other values from the current SI value
    // TODO  set errors
    if ( !isNaN(sival) && sival!==null ) { this.valueSI = sival; }
    this.value = (keepnull && this.value==null) ? null : this.csi2val(this.valueSI);
    return;
  }

  /**
   * setNewValue assumes the prefix is set already, then set valueSI etc. If val is not given, use the present value.
   * @param sival
   */
  setNewValue(val?: number): void { // type = null/"Min"/"Max"
    // TODO  set errors
    if ( !isNaN(val) && val!==null ) { this.value = val; } else { this.value = null; }
    this.valueSI = this.cval2si(this.value);
    return;
  }

  /**
   * Convert value to SI value
   * @param val
   */
  cval2si(val: number): number { return Number(val*this._cp2s*this.cu2si+this.cu2siInt); } // need to put in value * this._cp2s or value / this._cp2s as argument, depending on situations

  /**
   * Convert SI value to value in current selected unit and prefix
   * @param sival
   */
  csi2val(sival: number): number { return Number( (sival-this.cu2siInt)/this.cu2si/this._cp2s ); }
   // might need to *this._cp2s or /this._cp2s afterwards, depending on situations, as well as put in sival*thiscp2s or sival/cp2s as argument

  // need DB lookup mechanism to change unit.
  // for now, use the collection of PhysUnits to find the one
  chgSysUnitID(sys: PhysUnit|number|PhysConShell): void {
    // sys can be a PhysUnit + system, or an object { system | systemA: , unit|unitA: } in this order
    // if the resulting PhysUnit only has systemA.length==1, then okay.
    let pu: PhysUnit|PhysConShell;
    if (sys instanceof PhysUnit) { pu = sys; sys = { system: '' } as PhysConShell; }
    else if (!isNaN(<number>sys)) { pu = this.gpu.findPhysUnit({punitId: sys}); sys = { system: '' } as PhysConShell; }
    else { pu = this.gpu.findPhysUnit( <PhysConShell>sys); }

    if (!pu || pu['punitId']<1) { console.warn("chgSysUnitID failed."); console.log(sys); return; }
    if ( !this.compcomp(<PhysDim>pu) ) { console.warn(`chgSysUnitID failed. Not compatible components: ${this.category} vs ${pu["category"]} / ${pu["categoryA"]}`); return; }
    // all passed

    // change PhysUnit keys // PhysDim keys not changed
    this.setPhysUnitVals(<PhysConShell>pu); // set punitId, cu2si, cu2siint, level, systemA, unitA (PhysUnit level class)
    this.setPrefixVals(0); // reset default prefix
    // change Pqty0 keys,
    // pu from findPhysUnit should have everything except possibly unit, system, category, prefixes, and exact, stdErr, valueMin, valueMax
    // const toadd = ['unit', 'system', 'category', 'prefixes', 'exact', 'stdErr', 'valueMin', 'valueMax'];
    if (sys['unit']) { this.unit = sys['unit']; } else if ( !this.unitA.includes(this.unit) ) { this.unit = this.unitA[0]; }
    if (sys['system']) { this.system = sys['system']; } else if ( !this.systemA.includes(this.system) ) { this.system = this.systemA[0]; }
    if (sys['category']) { this.category = sys['category']; } else if ( !this.categoryA.includes(this.category) ) { this.category = this.categoryA[0]; }
    if (sys['prefix']) { this.setPrefixVals(sys['prefix']); }
    if (sys['prepower']) { this.setPrefixVals(sys['prepower']); }
    //  ----------------- fix below  ----------------- //

    // this.setPqtyVals(<PhysConShell> Object.assign({},pu,sys)); // cannot have sys overwriting pu
    // set new value and valuemin etc
    this.setNewValueSI(null,true); // with null argument, it assumes SI values are already set, just change the other values depending on the choice of unit.
    // this.value = this.csi2val(this.valueSI); // make sure both new conversion factors are set already. // like setNewValue(this.valueSI), but opposite
    // if (this instanceof PhysQty) {
    //   this.valueMin = this.csi2val(this.valueMinSI);
    //   this.valueMax = this.csi2val(this.valueMaxSI);
    //   this.stdErr = this.csi2val(this.stdErrSI);
    //   // stdErrRel??
    // }
    return;
  } // end chgSysUnitID

  // next four functions are for angles only
  // TODO set errors
  get npi(): number { return ( !this.comp.compcomp( new PhysDimComp({M:0}) ) ) ? null : this.valueSI/Math.PI ; }
  get quad(): number {
    if ( !this.comp.compcomp( new PhysDimComp({M:0}) ) ) { return null; }
    if ( PureMathService.pchk(0,(2*this.npi)%1,0.001) ) { return Math.round( ((2*this.npi)%4+4)%4 )+0.5; } // return 0.5 for 0deg, 1.5 for 90deg, 2.5 for 180 deg, 3.5 for 270deg
    return (Math.floor(2*this.npi)%4+4)%4+1; // return 1, 2, 3 or 4
  }
  get prinAngle(): PhysQty { if ( !this.comp.compcomp( new PhysDimComp({M:0}) ) ) { return null; }; const pa = new PhysQty( new PhysConShell({ name: "theta_p", nameHtml: "&theta;<sub>p</sub>", value: (this.valueSI/2/Math.PI%1+1)%1*2*Math.PI, system: "SI", category: "angle", unit: 'rad', prefix: 0, punitId: 1, exact: true })); return pa; }
  get refAngle(): PhysQty { if ( !this.comp.compcomp( new PhysDimComp({M:0}) ) ) { return null; }; const ra = new PhysQty( new PhysConShell({ name: "theta_ref", nameHtml: "&theta;<sub>ref</sub>", value: (0.5 - Math.abs(this.prinAngle.valueSI/Math.PI%1 -0.5))*Math.PI, system: "SI", category: "angle", unit: 'rad', prefix: 0, punitId: 1, exact: true })); return ra; }

  dcopy(): PhysQty0 {return new PhysQty0( new PhysConShell( { ...this } )); }
} // end PhysQty0

// @Injectable()
export class PhysQty extends PhysQty0 { // experimental values, = physical values plus error/uncertainty // level 3 class
  exact: boolean;
  stdErr: number;
  stdErrSI: number;
  stdErrRel: number;
  valueMin: number;
  valueMax: number;
  valueMinSI: number;
  valueMaxSI: number;

  constructor(input: PhysConShell){ // much like Pqty0 (with four extra keys { valueMin: 1, valueMax: 1, stdErr: 0, exact: true }
    // arg0 = { name: "", nameHtml: "", value: 1, valueMin: 1, valueMax: 1, stdErr: 0, stdErrRel: 0, system: "SI", category: "dimensionless", unit: "", pf: 0 } or some variation
    // arg1 can be { punitId: id } or id or { punitId: -1, ... }
    super(input);

    this.setPqtyVals(input);
    this.valueMinSI = this.cval2si(this.valueMin); // invariant, never change for this Pqty
    this.valueMaxSI = this.cval2si(this.valueMax);  // invariant, never change for this Pqty
    this.stdErrSI = this.cval2si(this.stdErr); // invariant, never change for this Pqty
    // check min and max from stdErr/stdErrRel ?
    // find error propagation?
  } // end contructor

  setPqtyVals(inp: PhysConShell): void {
    super.setPqtyVals(inp);
    this.setErrors(inp);
    return;
  } // end setPqtyVals

  setErrors(inp: PhysConShell): void {
    // flow (note when scaling between value and valueSI, do not use intercept.)
    // if exact, or StdErrRel ===0, or StdErr === 0, or StdErrSI ===0, then assume exact
    // if stdErrRel >0, use this to set everything.
    // if StdErr >0, use this to set everything
    // if StdErrSI >0, use this to set everything
    // if ValueMin >0 or ValueMax >0, use
    // if ValueMinSI >0 or ValueMaxSI > 0, use

    if (inp.exact) { this.setExact(); return; }
    if (inp.stdErrRel=== 0 || inp.stdErr===0 || inp.stdErrSI ===0 ) { this.setExact(); return; }
    if (inp['exact']==null && inp['stdErr']==null && inp['stdErrSI']==null && inp['stdErrRel']==null && inp['valueMin']==null && inp['valueMax']==null && inp['valueMinSI']==null && inp['valueMaxSI']==null ) { this.setExact(); return; }

    const stderrrel: number = (Number(inp.stdErrRel)||0); // make them 0 if null
    if (stderrrel > 0) {  this.setRelErr0(inp, stderrrel); return; } // typically, stdErrRel has 1-3 sig figs only. Most likely 2 sif figs.

    // stdErrRel null or undefined
    if (inp.stdErrSI > 0) { this.setRelErr(inp, this.stdErrRel, 'SI'); return; }

    // stdErrRel null or undefined AND stdErrSI null or undefined
    if (inp.stdErr > 0) { this.setRelErr(inp, this.stdErrRel); return; }

    // all stdErrRel, stdErr and stdErrSI are null or undefined, but both minSI and maxSI not null...
    if (inp.valueMinSI != 0 && inp.valueMinSI != null && inp.valueMaxSI != 0 && inp.valueMaxSI != null ) { this.setMinMaxRelErr(inp, 'SI'); return; }

    // all stdErrRel, stdErr and stdErrSI are null or undefined, but both min and max not null...
    if (inp.valueMin != 0 && inp.valueMin != null && inp.valueMax != 0 && inp.valueMax != null ) { this.setMinMaxRelErr(inp); return; }

    // just in case
    this.setExact();
    return;
  } // end setErrors

  setExact(): void { this.exact = true; this.stdErrRel = 0; this.stdErr = 0; this.stdErrSI = 0; this.valueMin = this.value; this.valueMax = this.value; this.valueMinSI = this.valueSI; this.valueMaxSI = this.valueSI; return; }
  setMinMax(inp: PhysConShell, type:string=''): void {
    const val: string = 'value'+type; const sder: string = "stdErr"+type; const vmin: string = 'valueMin'+type; const vmax: string = 'valueMax'+type;
    const min: number = (Number(inp[vmin])||0);
    const max: number = (Number(inp[vmax])||0);
    this[vmin] = (inp[vmin] && PureMathService.pchk(min,this[val] - this[sder], 0.1)) ? min : this[val] - this[sder]; // allow 10% difference between stdErr and stdErrRel
    this[vmax] = (inp[vmax] && PureMathService.pchk(max,this[val] + this[sder], 0.1)) ? max : this[val] + this[sder]; // allow 10% difference between stdErr and stdErrRel
    return;
  }
  setRelErr0(inp: PhysConShell, errRel: number): void { // not sure how to handle errors in temperature in difference scales with different zeros.
    this.stdErrRel = errRel;
    this.exact = false;
    const stderr: number = (Number(inp.stdErr)||0);
    const stderrsi: number = (Number(inp.stdErrSI)||0);
    // fix stdErrSI
    if (!(PureMathService.pchk(stderrsi/this.valueSI, errRel, 0.1)) || inp.stdErrSI == null || inp.stdErrSI ==0) { this.stdErrSI = this.valueSI*errRel; this.stdErr = this.value*errRel; // allow 10% difference between stdErrSI and stdErrRel
    } else { this.stdErrSI = stderrsi; }
    // fix stdErr
    if (!(PureMathService.pchk(stderr/this.value, errRel, 0.1)) || inp.stdErr == null || inp.stdErr ==0) { this.stdErr = this.value*errRel; // allow 10% difference between stdErr and stdErrRel
    } else { this.stdErr = stderr; }
    this.setMinMax(inp,'SI'); this.setMinMax(inp);
    return;
  }
  setRelErr(inp: PhysConShell, errRel: number, type: string = '' ): void {
    this['stdErr'+type] = inp['stdErr'+type];
    this.stdErrRel = this['stdErr'+type]/this['value'+type];
    this.setRelErr0(inp, errRel);
    return;
  }
  setMinMaxRelErr(inp: PhysConShell, type: string = '' ): void {
    this['valueMin'+type] = inp['valueMin'+type];
    this['valueMax'+type] = inp['valueMax'+type];
    this.setRelErr( inp, Math.max( this['value'+type] - inp['valueMin'+type], inp['valueMax'+type] - this['value'+type] ), type );
    return;
  }

  // setNewValueSI always assume the prefix is 0 for that SI value in the argument. Then set the value from conversion, and scale by prefix. If val is not given, use the present value.
  // setNewValue assumes the input value itself, with the prefix input, determines the overall value. Then set valueSI accordingly. If val is not given, use the present value.
  setNewValueSI(val?: number, keepnull?: boolean): void { // type = null/"Min"/"Max"
  // if no value given, then we are assuming valueSI is already set (say now changing unit or chaning prefix), and set other values from the current SI value
    super.setNewValueSI(val, keepnull);
    this.setErrors(<PhysConShell>{ exact: this.exact, stdErrRel: this.stdErrRel, stdErr: this.stdErr, stdErrSI: this.stdErrSI });
    return;
  }
  setNewValue(val?: number): void { // type = null/"Min"/"Max"
    super.setNewValue(val);
    this.setErrors(<PhysConShell>{ exact: this.exact, stdErrRel: this.stdErrRel, stdErr: this.stdErr, stdErrSI: this.stdErrSI });
    return;
  }

  /**
   * Can change category, unit, etc. Becareful when used. Does not provide integrity checks
   * @param input
   * @param names update name and nameHtml (true) or not (false, default)
   */
  update(input:any, names: boolean = false): void {
    // can change category, unit, anything
    // if not in input, then leave alone
    // intersectin of two arrays: array1.filter(value => array2.includes(value))
    let common = Object.keys(this).filter(key => Object.keys(input).includes(key));
    common.forEach(function(key:string){
      if (key === 'comp') { this.comp = input.comp.dcopy(); } else // this.comp = new PhysDimComp(input[key]);
      // if (key === 'altPunitA') { this[key] = JSON.parse(JSON.stringify(input[key])); } else  // in PhysConShell.merge, not here
      if (this[key] instanceof Array) { this[key] = [].concat(input[key]); } else
      if ( ( key !=='name' && key !=='nameHtml' ) || names ) { this[key] = input[key]; }
    }.bind(this));
    return;
  }

  /**
   * Update this PhysQty value/valueSI from another (calculated) quantity, without change name and prefix, etc. Cannot change dimension.
   * @param q
   */
  updateValFrom(q: PhysQty): void {
    if ( !q || !q.compcomp(this) ) { this.setNewValueSI(0); this.value = null; console.log('UpdateValFrom failed. Inconsistent dimensions.'); return; }
    this.setNewValueSI(q.valueSI);
    // TODO also update errors
    return;
  }

  /**
   * Update this PhysQty pdimId, punitId, units etc from a PhysUnit or PhysConShell like a PhysUnit,
   * @param pu : typically found from GlobalPhysUnitService.findPhysUnitA() or similar
   */
  updatePunits(pu: PhysUnit | PhysConShell ): void {
    pu = pu.dcopy();
    this.update({pdimId: pu.pdimId, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) });
    this.setPrefixVals(0); // typically changing units, no guarantee prefix are allowed.
    this.setNewValueSI(null,true);
    return;
  }

  // /**
  //  * Update this PhysQty pdimId, punitId, units etc from another (calculated) quantity, without change name and prefix, etc. Will change dimension, but keep SI value numerically unchanged.
  //  * @param q
  //  */
  // updatePunitsFrom(q: PhysQty): void {
  //   const tmp = q.dcopy();
  //   tmp.setNewValueSI(this.valueSI);
  //   if (this.value == null) { tmp.value = null; }
  //   tmp.name = this.name; tmp.nameHtml = this.nameHtml; tmp.exact = this.exact; tmp.stdErrRel = this.stdErrRel; tmp.stdErrSI = this.stdErrSI; tmp.valueMinSI = this.valueMinSI; tmp.valueMaxSI = this.valueMaxSI;
  //   this.update( tmp );
  //   return;
  // }

  // adding/moving basic uniary/binary operations from pqty-math.services to here
  private _copyGivenQty( input: PhysConShell | PhysQty ): PhysQty { // expect object has unitA, categoryA, systemA, as in PhysUnit
    this.punitId = input.punitId;
    this.systemA = [ ...input.systemA ];
    this.system = this.systemA[0];
    this.categoryA = [ ...input.categoryA ]; // already determined in dtimes?
    this.category = this.categoryA[0];
    this.unitA = [ ...input.unitA ];
    this.unit = this.unitA[0];
    this.setPrefixVals(0);
    // set Error values?
    return this;
  }

  /**
   * Multiply/Divide the PhysQty with a number
   * @remarks This method CHANGES this/self
   * @param s - The number to be multiplied by, or to be divided by.
   * @param divide - Indicate division (true) or regular multiplication (false), to blend with other times()/divides() functions
   * @returns this: The resulting product or quotient
   */
  stimes(s: number, divide: boolean =false): PhysQty { // scalar times // having the divide option to make times/divides applications uniform
    const newval = (divide)?this.valueSI/s:this.valueSI*s;
    if (isNaN(newval)) { this.setNewValue(null); return this; } // keep SI value but set value=null
    this.setNewValueSI(newval); // this will also set errors, with stdErrRel as guide
    return this;
  }

  /**
   * Multiply/Divide the PhysQty with another
   * @remarks This method CHANGES this/self
   * @param pq - The PhysQty to be multiplied by, or to be divided by. If just a number, will use stimes()
   * @param divide - Indicate division (true) or regular multiplication (false)
   * @returns this: The resulting product or quotient
   */
  times(pq: PhysQty | number, divide: boolean =false): PhysQty {
    if (pq===Infinity || pq === NaN) { console.log('Invalid PhysQty in times(): '+pq); return null; }
    if (pq===null) { pq = 0; } // pass to next steps
    if ( typeof(pq)=="number" ) { return this.stimes(pq,divide); } // assume pq is just a number then.
    // } else if (!(pq instanceof PhysQty)) { e2 = new PhysQty( new PhysConShell( Object.assign({},pq,{exact: true}) ) );
    const pq2 = (<PhysQty>pq).dcopy();

    this.dtimes(pq2,divide); // pdimId, compDisp, unitSI, categoryA, comp changed.
    // determine what system to use eventually
    let ressystem = (this.system===pq2.system)?this.system:"SI";

    // set final SI value right away, and temporarily set to SI unit, regardless of ressystem.
    const newSIval=(divide)?this.valueSI/pq2.valueSI:this.valueSI*pq2.valueSI;
    this.unit = this.unitSI; // punitId probably is still incorrect.
    if ( isNaN(newSIval) || !isFinite(newSIval) ) { console.log('PhysQty.times with NaN: '+newSIval); this.value = null; this.valueSI = null; }
    else { this.setNewValueSI(newSIval); } // will set errors. need new rules for error propagation

    // next determine what unit to use? punits.unitA has the allowed units.
    const respunit = this.gpu.findPhysUnitA( { comp: { ...this.comp }, pdimId: this.pdimId, system: ressystem } ) ; // array of punits with matching pdimId
    respunit[0].system = (respunit[0].systemA.includes(ressystem))? ressystem : respunit[0].systemA[0]; // just in case inconsistent
    this._copyGivenQty( respunit[0] );
    // need to set Error values??

    // reset names with generic descriptions
    this.name += (divide) ? "/" : "*" ;
    this.name += pq2.name;
    this.nameHtml += (divide) ? "/" : "&bull;" ;
    this.nameHtml += pq2.nameHtml;

    return this;
  }
  divides(pq: PhysQty) : PhysQty { return this.times(pq, true); }

  /**
   * Add/Subtract the PhysQty with another
   * @remarks This method CHANGES this/self
   * @param pq - The PhysQty to be added/subtracted. Must have same dimension as this PhysQty. If it is just a number, assume it is in SI, same dimension as this/self.
   * @param subtract - Indicate subtraction (true) or regular addition (false)
   * @returns this: The resulting sum or difference
   */
  adds(pq: PhysQty | number, subtract: boolean =false): PhysQty {
    let newSIval = this.valueSI;
    if (pq===Infinity || pq === NaN) { console.log('Invalid PhysQty in adds(): '+pq); return null; }
    if (pq===null) { pq = 0; return this; }
    if ( typeof(pq)=="number" ) { newSIval+=(subtract)?(-1)*pq:pq; this.setNewValueSI( newSIval ); return this; } // Is error set correctly? Rel err or Abs err??
    const pq2 = (<PhysQty>pq).dcopy();

    // pq must be PhysQty now
    if ( !this.compcomp(pq2) ) { console.warn('adds failed. Dimensions incompatible: '+this.pdimId+' and '+pq2.pdimId); return this; }
    if (pq2.valueSI===Infinity || pq2.valueSI === NaN) { console.log('Invalid PhysQty in adds(): pq2 valueSI= '+pq2.valueSI); return null; }
    if (pq2.valueSI===null) { return this; }
    newSIval += (subtract) ? (-1)*pq2.valueSI : pq2.valueSI;
    this.setNewValueSI( newSIval );

    // reset names with generic descriptions
    this.name += (subtract) ? "-" : "+" ;
    this.name += pq2.name;
    this.nameHtml += (subtract) ? "-" : "+" ;
    this.nameHtml += pq2.nameHtml;
    return this;
  }
  subtracts(pq: PhysQty): PhysQty { return this.adds(pq, true); }

  /**
   * Unary, sqrt of a PhysQty
   * @remarks This  may results in half fractional powers of length/time/mass etc.
   * @param complex - whether complex answers allowed. To-be-implemented.
   * @returns this: The square-rooted quantity
   */
  sqrt(complex: boolean = false) : PhysQty[] {
    const res: PhysQty[] = [];
    const resdim = this.dcopy(); // PhysQty
    resdim.dsqrt(); // this changed the comp and pdimId, unitSI, compDisp, categoryA // can be undefined

    // determine what system to use
    const ressystem: string = this.system; // if not working, use "SI";
    const respunit = this.gpu.findPhysUnitA( { comp: { ...resdim.comp }, pdimId: resdim.pdimId, system: ressystem } ) ; // array of punits with matching pdimId
    respunit[0].system = (respunit[0].systemA.includes(ressystem))? ressystem : respunit[0].systemA[0]; // just in case inconsistent

    resdim._copyGivenQty( respunit[0] );
    resdim.name = "sqrt-"+resdim.name;
    resdim.nameHtml = "&radic;"+resdim.nameHtml;

    // set final SI value right away, and temporarily set to SI unit, regardless of ressystem.
    if ( this.valueSI < 0 ) { // complex case
    // if (this.valueSI < 0 || this.cu2siInt!=0) { // complex case, but only if there is an absolute zero?
      if ( complex ) { console.log("Complex case not yet implemented."); }
      else { console.log("No real solution."); }
    } else if (isNaN(this.valueSI) || !isFinite(this.valueSI) ) {
      console.log("valueSI not a number");
    } else if ( this.valueSI == 0 ) { // res array length = 1
      res.push( resdim ); // this is essentiall zero, no need to take sqrt
    } else { // res array length = 2
      resdim.setNewValueSI( Math.sqrt(this.valueSI) );
      res.push( resdim.dcopy() );
      resdim.stimes(-1);
      res.push( resdim.dcopy() );
    }
    // TODO set errors

    return res;
  }

  /**
   * Unary, n-th power of a PhysQty
   * @remarks This method CHANGES this/self
   * @param n - The n-th power, integers (+/-).
   * @returns this: The resulting power
   */
  power(n: number) : PhysQty {
    if (n===Infinity || n === NaN) { console.log('Invalid n value in power(): '+n); return null; }
    if (n===null) { return this; }
    // return this;
    n=Math.floor(n); let sgn = Math.sign(n); n=Math.abs(n);
    const orig = this.dcopy();
    // set this to unity, then multiply/divide orig n times
    if (this.valueSI == 0) { this.setNewValueSI(1); }
    this.times(this, true);
    this.setErrors(<PhysConShell>{ exact: true, stdErrRel: 0, stdErr: 0, stdErrSI: 0 });

    // let ans = new PhysQty( <PhysConShell>{ name: this.name, nameHtml: this.nameHtml, value: 1, system: "SI", category: "dimensionless", unit: "", prepower: 0, stdErr: 0, exact: true, punitId: 1 });
    for (let i=0; i<n; i++) { this.times(orig, sgn==-1 ); } // ans is all set now
    // update this now
    // this.copyGivenPdim( ans );
    // super.super()._copyGivenPdim( ans );
    // this.unit = ans.unit; // punitId probably is still incorrect.
    if (isNaN(this.valueSI)) { console.log('PhysQty.power ends with NaN: '+this.valueSI); this.value = null; this.valueSI = null; return this; }
    // else { this.setNewValueSI(ans.valueSI); } // will set errors. need new rules for error propagation
    // this._copyGivenQty( ans );

    // reset names with generic descriptions
    this.name += "^"+n ;
    this.nameHtml += "<sup>"+n+"</sup>";
    return this;
  }

  dcopy(): PhysQty { return new PhysQty( new PhysConShell( { ...this } )); }
} // end Pqty

// @Injectable()
export class PhysCon extends PhysQty { // physical constants, not changeable, with additional info // level 4 class
  pconID: number;
  desc: string;
  source: string;
  sourceUrl: string;
  rtvdate: string;
  topicA: string[];
  altPunitA: object[];
  constructor(input: PhysConShell){
    // (with many extra keys { pconID: -1, desc: "", source: "NIST", sourceUrl: "", rtvdate: "20000101", topicA: [], altPunitA: [], valueMin: 1, valueMax: 1, stdErr: 0, stdErrRel: 0, exact: true }
    // altPunitA: [ {punitId: id, unit: "m/s", system: "SI"}, {}, ...]
    super(input);
    this.pconID = input['pconID'];
    this.desc = input['desc'];
    this.source = input['source'];
    this.sourceUrl = input['sourceUrl'];
    this.rtvdate = input['rtvdate'];
    this.topicA = (input['topicA']) ? [ ...input.topicA ] : [] ;
    this.altPunitA = (input['altPunitA']) ? [ ...input.altPunitA ] : [] ;
  } // end contructor

  // loadSys(sys) { for (let i=0; i<this.altPunitA.length; i++) { if (this.altPunitA[i].system == sys) { this.chgSysUnitID(this.altPunitA[i]); return; } } } // sys is single value
  dcopy(): PhysCon { return new PhysCon( new PhysConShell( { ...this } )); }

} // end Pcon


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class BbbModule {
  constructor(@Optional() @SkipSelf() parentModule: BbbModule) {
    if (parentModule) { throw new Error('BbbModule is already loaded. Import it in the AppModule only'); }
  }

  static forRoot(): ModuleWithProviders {
    // console.log("instantiating BbbModule.forRoot");
    return {
      ngModule: BbbModule,
      // providers: [ PhysDim, PhysUnit, PhysQty0, PhysQty, PhysCon ]
      providers: [ ]
    };
  }
}

