import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhysConstantsComponent } from './phys-constants.component';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';



@NgModule({
  declarations: [ PhysConstantsComponent ],
  imports: [ CommonModule, CommonPipeModule ],
  exports: [ PhysConstantsComponent ]
})
export class PhysConstantsModule { }
