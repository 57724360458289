export interface FigureProps {
  width?: number; //x
  height?: number; // y
  depth?: number; // z
  xs?: number[];
  ys?: number[];
  zs?: number[];
}

export class FigureSvg {
  width=500; // x
  height=500; // y
  depth=500; // z
  xs: number[]=[];
  ys: number[]=[];
  zs: number[]=[];

  constructor(input: FigureProps) {
    this.width = (input['width'])?input['width']:500;
    this.height = (input['height'])?input['height']:500;
    this.depth = (input['depth'])?input['depth']:500;
    this.xs = [].concat(input['xs']); // array of x-coordinates
    this.ys = [].concat(input['ys']); // array of y-coordinates
    this.zs = [].concat(input['zs']); // array of z-coordinates
  }

  scaleX(s:number): void { this.xs.forEach(function(v:number,i:number){ this.xs[i] = (v==null)?null:v*s; }.bind(this)); }
  scaleY(s:number): void { this.ys.forEach(function(v:number,i:number){ this.ys[i] = (v==null)?null:v*s; }.bind(this)); }
  scaleZ(s:number): void { this.zs.forEach(function(v:number,i:number){ this.zs[i] = (v==null)?null:v*s; }.bind(this)); }
  scaleXY(s:number): void { this.scaleX(s); this.scaleY(s); }
  // scaleXYZ(s:number): void { this.scaleX(s); this.scaleY(s); this.scaleZ(s); }
  shiftX(s:number): void { this.xs.forEach(function(v:number ,i:number){ this.xs[i] = (v==null)?null:v+s; }.bind(this)); }
  shiftY(s:number): void { this.ys.forEach(function(v:number ,i:number){ this.ys[i] = (v==null)?null:v+s; }.bind(this)); }
  shiftZ(s:number): void { this.zs.forEach(function(v:number ,i:number){ this.zs[i] = (v==null)?null:v+s; }.bind(this)); }
  get xavg():number { let sum=0; this.xs.forEach(function(v:number ){sum+=v;}); return sum/this.xs.length; } // these avg functions still treat null as zeros
  get yavg():number  { let sum=0; this.ys.forEach(function(v:number ){sum+=v;}); return sum/this.ys.length; } 
  get xmax():number  { return Math.max(...this.xs.filter(v => v!=null)); } // Math.max(...this.xs); consider nulls as zeros
  get xmin():number  { return Math.min(...this.xs.filter(v => v!=null)); }
  get xmid():number  { return (this.xmax+this.xmin)/2; }
  get ymax():number  { return Math.max(...this.ys.filter(v => v!=null)); }
  get ymin():number  { return Math.min(...this.ys.filter(v => v!=null)); }
  get ymid():number  { return (this.ymax+this.ymin)/2; }
  get xrange():number  { return this.xmax - this.xmin; }
  get yrange():number  { return this.ymax - this.ymin; }
} // end class FigureSvg
