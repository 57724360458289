import { Injectable } from '@angular/core';
import { RevealModule } from './reveal.module';
import { GlobalRevealLevelService } from '../global-vars/global-reveal-level.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FactoryService } from '../facts/factory.service';
import { Factopia, Factsheet, Factsier, Factum, Bookmark } from '../facts/facts.module';
import { RevealComponent, RevealDialogData } from './reveal.component';

export interface RevealOverride {
  // readonly type: 'revealoverride';
  title?: string;
  message?: string;
  showCancelBtn?: boolean;
  showOKBtn?: boolean;
}

@Injectable() // providers in RevealModule instead, reversing dependency arrow
// @Injectable({ providedIn: RevealModule })
// @Injectable({ providedIn: 'root' })
export class RevealService {
  // private _rlevel: number;
  private _dialogRef: MatDialogRef<RevealComponent>;
  // mdl: MatDialog;

  constructor(private _mdl: MatDialog, private _facts: FactoryService ) { 
    // constructor(private _mdl: MatDialog, private _facts: FactoryService, private _grev: GlobalRevealLevelService ) { 
    // this._rlevel = this._grev.gReveal;
  }

  reveal(input: { override?: RevealOverride, additionalDialogConfigData?: any, facts?: FactoryService | Factopia | Factsier | Factsheet | Factum, bkmark?: Bookmark}): MatDialogRef<RevealComponent>  { // show dialog box
    // material design dialog box has only title and message, 2 attributes. Use JSON if needed to pass more info
    // scenarios
    // no facts nor bookmark -> use router/url to determine current sheet, then read singleton FactoryService, use factsheet
    // given factsheet or factlet or factum, without bookmark, process just that
    // bookmark present, use lowest level, find sheet etc, then process
    // helper: globalsettings (gsettings), physicalConstants (physcons), scractPad (scratch)
    const data = new RevealDialogData();

    if ( input.override && input.override.title &&  input.override.title.trim() !='' && input.override.message && input.override.message.trim() !='' ) { data.title = input.override.title; data.message = JSON.stringify({type:'override', msg: input.override.message}); } else 
    if ( input.facts && input.bkmark ) { data.title = input.facts.title; data.message = JSON.stringify({type:'with_facts_bkmark', facts: input.facts, bkmark: input.bkmark}); } else 
    if ( input.facts ) { data.title = input.facts.title; data.message = JSON.stringify({type:'with_facts', facts: input.facts}); } 
    else { data.title = 'Factsheet'; data.message = JSON.stringify({type:'physland_principia'}); }  

    // if (input.override && input.override.title) { data.title = input.override.title; } 
    // else if (input.facts && input.facts.title) { data.title = input.facts.title; } 
    // else { data.title="Material Design Dialog Box"; }

    // if (input.override && input.override.message) { data.message = JSON.stringify({type:'override', message: input.override.message}); }  
    // else if (input.facts) { data.message = JSON.stringify(input.facts); } 
    // else { data.message = JSON.stringify({type:'default', message: 'Default message'}); }

    data.showCancelBtn = (input.override && input.override.showCancelBtn)?true:false;
    data.showOKBtn = (input.override && input.override.showOKBtn===false)?false:true;

    return this._openDialog(data, input.additionalDialogConfigData);

  }

  private _openDialog(data: RevealDialogData, additionalDialogConfigData?: any): MatDialogRef<RevealComponent> {

    if (this._dialogRef) { this._dialogRef.close(); }
    this._dialogRef = this._mdl.open(RevealComponent, {
      width: '900px',
      data,
      ...additionalDialogConfigData
    });

    this._dialogRef.afterClosed().subscribe( result => { } );

    return this._dialogRef;
  } // end _openDialog() // Before creating the dialog component, we check to see whether there is another dialog open, and close it first.

}
