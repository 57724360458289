import { Pipe, PipeTransform } from '@angular/core';
import { PhysQty0 } from 'src/app/numeric-library/bbb/bbb.module';
import { UnitDispPipe } from './unit-disp.pipe';
import { PfxDispPipe } from './pfx-disp.pipe';
import { PhysConShell } from 'src/app/numeric-library/invariant/global-phys-units.service';

@Pipe({ name: 'pfxUnitDisp', pure: false })
export class PfxUnitDispPipe implements PipeTransform {
  transform(pq: PhysQty0|PhysConShell): string {
    const udp = new UnitDispPipe;
    const pdp = new PfxDispPipe;
    return pdp.transform(pq)+udp.transform(pq);
  }
}
