import { Injectable, Optional, Inject } from '@angular/core';
import { PhysCon, PhysQty } from '../../numeric-library/bbb/bbb.module';
import { PhysConShell, GlobalPhysUnitsService } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { SnackBarService, SnackBarMsg } from 'src/app/service/facts/snack-bar.service';
import { AppInjector } from 'src/app/app-injector';

export class ScratchPadList {
  // for clipboard scratchPad numbers, similar to PhysUnitList/PhysDimList, but these are actual PhysQty, not just PhysQtyShell
  list: PhysCon[];
  maxCnt: number;
  private cqid: number =0; // current Qty Id,
  private _sbs: SnackBarService;

  // constructor() { this.list = new Array(); } // array of Punit
  constructor() { // array of Eqty here instead of Punits
    this._sbs = AppInjector.get(SnackBarService);
    this.maxCnt = 26; // for 26 alphabets, for example // overriden by first loading of Module.forRoot()
    this.cqid = 0; // to generate unique id for the list this is the current (next) to-be-id value
  }

  addPqty(pq: PhysQty): void { // pu: Pdim Object // neeed to check duplicate, then update
    if ( !(pq instanceof PhysQty) ) {
      this._sbs.now( <SnackBarMsg>{importance: 10, message: "Object is not a Physical Quantity.", action: "Error", duration: 6500 } );
      return;
    } // Pqty without error/uncertatinty info is okay
    if ( this.list.length >= this.maxCnt ) {
      this._sbs.now( <SnackBarMsg>{importance: 10, message: "Please remove some unused entries there and retry.", action: "Scratch Pad is full!", duration: 6500 });
      // this._sbalert();
      return;
    }
    let neweq = new PhysQty(Object.assign(<PhysConShell>{},pq)); // duplicate an independent copy of eq
    neweq.qid = this.cqid;
    this.cqid++;
    neweq.cbid = this.list.length;
    this.list.push(<PhysCon>neweq);
    if ( this.list.length == this.maxCnt ) {
      this._sbs.now( <SnackBarMsg>{importance: 10, message: "The physical quantity has been added to scratch pad, which is now full. Please consider remoing some unused entries there for future use.", action: "Scratch Pad is now full!", duration: 6500 });
      return;
    }
    this._sbs.now( <SnackBarMsg>{importance: 5, message: "The physical quantity has been added to scratch pad.", action: "Success!" });
    return;
  } // end addPqty

  sort(field:string ='cbid',asc:boolean =true): void {
      if (asc) { this.list.sort(function(a,b){return a[field] - b[field];}); } else { this.list.sort(function(a,b){return a[field] - b[field];}); }
  }

  mv2last(i:number): void {
      // move i in list to last place;
      this.list[i]['cbid'] = this.list.length -1;
      for (let j=i+1; j< this.list.length; j++) { this.list[j].cbid--; }
      this.sort();
  }

  remove(i:number) { this.list.splice(i,1); }

  get qCnt(): number { return this.list.length; }
}


export class GlobalClipboardConfig {
  maxlen?: number;
  physconlist?: PhysCon[];

  constructor( input?: {maxlen?: number, physconlist?: PhysCon[]}){
    if (input) { this.maxlen = input['maxlen']; this.physconlist = input['physconlist']; }
  }
}

interface StateCfg {
  stateName: string;
  vars: any;
}

class StateCfgs {
  list: StateCfg[];
  constructor(){ this.list = []; }

  g(name:string): StateCfg { // to get and connect the Cfg
    let found: StateCfg = null;
    this.list.forEach(function(cfg: StateCfg){ if (cfg.stateName == name) { found=cfg; } })
    return found;
  }

}

let nextId = 1;
// currently this is set as a global variable only. Can be extended to different modules, when say a user is taking a quiz, etc

@Injectable({ providedIn: 'root' })
export class GlobalClipboardService {
  private idcnt = nextId++;
  spad: ScratchPadList;
  stateCfgs: StateCfgs;
  // maxlen: number;
  // physconlist: PhysCon[];

  constructor(@Optional() config?: GlobalClipboardConfig) {
    this.spad = new ScratchPadList();
    if (config) { this.spad.maxCnt = config.maxlen; this.spad.list = config.physconlist; }
    this.spad.maxCnt = (this.spad.maxCnt >10 && this.spad.maxCnt < 151)? Math.floor(this.spad.maxCnt) : 30;
    if (!this.spad.list) { this.spad.list = [] as PhysCon[]; }
    if (this.idcnt>1) { console.warn('GlobalClipboardService is already loaded. Import it in the AppModule only');  }
    console.warn(`this is GlobalClipboardService idcnt= ${this.idcnt}.`)
    // console.log(`maxCnt = ${this.spad.maxCnt}`);
    this.stateCfgs = new StateCfgs();
  } // end constructor
}
