import { Component, OnInit } from '@angular/core';
import { siPrefixes, PhysConShell, unitDropdownList, GlobalPhysUnitsService, PhysDimComp, SiPrefix, CatDropdownOption } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { FactoryService } from 'src/app/service/facts/factory.service';
import { Factsheet, Factum, FactIdty, Factsier } from 'src/app/service/facts/facts.module';
import { PhysQty, PhysDim } from 'src/app/numeric-library/bbb/bbb.module';
import { EeeeService, EquationInfo } from 'src/app/service/eqns/eeee.service';
import { PqtyMathService } from 'src/app/numeric-library/operator/pqty-math.service';
import { SnackBarService, SnackBarMsg } from 'src/app/service/facts/snack-bar.service';
import { GlobalClipboardService } from '../../../common/scratch-pad/global-clipboard.service';
import { AppInjector } from 'src/app/app-injector';
import { MatDialogRef } from '@angular/material/dialog';
import { PrefixDropListPipe } from 'src/app/pipe/common-pipe/prefix-drop-list.pipe';
import { MonologService } from 'src/app/service/monolog-box/monolog.service';
import { MonologComponent } from 'src/app/service/monolog-box/monolog/monolog.component';

// ************************ ToDo
// combine t and td !!!!!
// check units

class QuadraticAbc {
  readonly c5 = ['a','b','c','r1','r2'];
  private _pqm: PqtyMathService;
  private _sbs: SnackBarService;
  private _eeee: EeeeService;
  private _gpu: GlobalPhysUnitsService;
  private _gcb: GlobalClipboardService;

  solnsheet: Factsheet;
  // note: ac and b^2 should have same units, and we'll equate temp as temp.diff. here
  // the answer x should have unit c/b or b/a
  c: PhysQty; // pdimId = 4 (length), angular 1
  b: PhysQty; // pdimId = 10 (velocity), angular 29
  a: PhysQty; // pdimId = 11 (acceleration), angular 30
  r1: PhysQty; // pdimId = 3 (time)
  r2: PhysQty; // pdimId = 3 (time)
  // solns: PhysQty[]; // solutions
  solnCnt: number; // 0, 1, or 2

  constructor(factsheet?: Factsheet ) {
    this._sbs = AppInjector.get(SnackBarService);
    this._eeee = AppInjector.get(EeeeService);
    this._gpu = AppInjector.get(GlobalPhysUnitsService);
    this._gcb = AppInjector.get(GlobalClipboardService);
    this.solnsheet = factsheet;

    // global state var
    if (this._gcb.stateCfgs.g('quadraticBasic')) {
      const vars = this._gcb.stateCfgs.g('quadraticBasic').vars;
      this.a = <PhysQty>vars.a;
      this.b = <PhysQty>vars.b;
      this.c = <PhysQty>vars.c;
      this.r1 = <PhysQty>vars.r1;
      this.r2 = <PhysQty>vars.r2;
    } else {
      this.a = new PhysQty(<PhysConShell>{ name: 'a', nameHtml: '<i>a</i>', value: -32, pdimId: 11, system: 'imperial', unit: 'ft/s^2', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 30 });
      this.b = new PhysQty(<PhysConShell>{ name: 'b', nameHtml: '<i>b</i>', value: 4, pdimId: 10, system: 'SI', unit: 'm/s', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 26 });
      this.c = new PhysQty(<PhysConShell>{ name: 'c', nameHtml: '<i>c</i>', value: 2, pdimId: 4, system: 'SI', unit: 'm', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 14 });
      this.r1 = new PhysQty(<PhysConShell>{ name: 'r1', nameHtml: '<i>x</i><sub>1</sub>', value: 3, pdimId: 3, system: 'SI', unit: 's', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 10 });
      this.r2 = new PhysQty(<PhysConShell>{ name: 'r2', nameHtml: '<i>x</i><sub>2</sub>', value: 3, pdimId: 3, system: 'SI', unit: 's', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 10 });

      this._gcb.stateCfgs.list.push({stateName:'quadraticBasic', vars:{ a: this.a, b: this.b, c: this.c, r1: this.r1, r2: this.r2 } });
    }

    this.solve();
  }

  quadfcn(x: PhysQty): PhysQty {
    // need to check x has the correct dimension
    const res: PhysQty = this.a.dcopy();
    res.times(x).times(x).adds(this.b.dcopy().times(x)).adds(this.c);
    return res;
  }

  get discrim(): PhysQty { // b^2 - 4ac
    const res: PhysQty = this.b.dcopy().power(2);
    res.subtracts(this.a.dcopy().times(4).times(this.c));
    return res;
  }
  get rootsum(): PhysQty { return this.b.dcopy().times(-1).divides(this.a); } // return -b/a
  get rootprod(): PhysQty { return this.c.dcopy().divides(this.a); } // return c/a
  get vertexX(): PhysQty { return this.rootsum.times(0.5); } // return rootsum/2
  get vertexY(): PhysQty { return this.quadfcn(this.vertexX); }


  // get inpNullCnt(): number { let sum=0; this.comp3.forEach( function(v){ sum += (this.inpNull[v])?1:0;}.bind(this) ); return sum; }

  solve(): void {
    // let tmpinfo: EquationInfo;
    this.solnsheet.factA = <Factum[]>[]; // reset solution to clean sheet

    let sbarMsg: object = null;
    const xarr = this._eeee.findEqn("eqQuadratic").eqn(this.a,this.b,this.c);
    this.solnCnt = xarr.length;

    // case: no real solution
    if ( this.solnCnt == 0) {
      this.r1.setNewValueSI(NaN);
      this.r2.setNewValueSI(NaN);
      if (this.a.valueSI==0 && this.b.valueSI==0 && this.c.valueSI==0) {
        this._sbs.now(<SnackBarMsg>{ importance: 10, message: "No equation was given. There are infinite solutions.", action: "Success", duration: 4000 });
      } else if (this.a.valueSI ==0 && this.b.valueSI==0) {
        this._sbs.now(<SnackBarMsg>{ importance: 10, message: "The given condition is impossible. No solution.", action: "Error", duration: 4000 });
      } else {
        this._sbs.now(<SnackBarMsg>{ importance: 10, message: "There is no real solution. Discriminant is less than zero.", action: "Error", duration: 4000 });
      }
      return;
    }

    // case: both 1 or 2 solutions
    this.r1 = xarr[0]; this.r1.name = 'r1'; this.r1.nameHtml = '<i>x</i><sub>1</sub>';

    // case: double root
    if ( this.solnCnt == 1) {
      this.r2 = this.r1; // shallow copy
      if (this.a.valueSI ==0) {
        this._sbs.now(<SnackBarMsg>{ importance: 10, message: "The equation is actually linear (a=0). There is only one solution.", action: "Success", duration: 4000 });
      } else {
        this._sbs.now(<SnackBarMsg>{ importance: 10, message: "This is a double root. Discriminant equals to zero.", action: "Success", duration: 4000 });
      }
      return;
    }

    // case: general distinct solutions
    // if ( this.solnCnt == 2) {
      this.r2 = xarr[1]; this.r2.name = 'r2'; this.r2.nameHtml = '<i>x</i><sub>2</sub>';
    // }

    return;
  }

  resetVals(): void {
    // this.solnCnt = 0;
    // reset all units
    // TODO
    // reset all prefixes
    this.c5.forEach(function(v:string){this[v].setPrefixVals(0);}.bind(this));
    // reset all five values, and SI values
    this.c5.forEach(function(v:string){this[v].setNewValue(0);this[v].value=null;}.bind(this));
    // this.resetNull(); this.resetHide(); this.resetReadonly();
    return;
  }

  valueChg(comp: string, event): void {
    let v = event.target.value;
    this[comp].setNewValue(v); // this will change the valueSI and everything.
    // now solve
    this.solve();

    return;
  }

  unitChg(comp: string): void {
    this.prefixChg(comp); // in case it's changed as some non-SI units force prefix to 0.
    this[comp].chgSysUnitID(<PhysConShell>{punitId: this[comp]['punitId'] });
    return;
  }

  prefixChg(comp: string): void {
    this[comp].setPrefixVals(this[comp].prepower);
    this[comp].setNewValueSI();
    return;
  }

  catChg(v: string): void {
    // check if pdimId changed
    // let v = this.vec1; // Vector3d
    // let c = this.v1cat; // dimId // newly changed
    // if (i==2) { v = this.vec2; c = this.v2cat; }
    // if (c==v.dimId) return;

    let pu = this._gpu.findPhysUnitA({pdimId: this[v].pdimId})[0];
    this[v].update({pdimId: this[v].pdimId, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) })
    this[v].setPrefixVals(0); // set prefixes to 0
    this[v].setNewValueSI(); // leave numerical SI values unchanged, so that the angles will be preserved

    // if a or b changed, fix c, and x
    // if c changed, fix a, and x.
    let pd: PhysDim;
    if (v=='a' || v=='b') {
      // change c (=b^2/a), and x (= b/a)
      pd = this.b.dcopy().dtimes(this.a,true); // this is the new dimension for x
      pu = this._gpu.findPhysUnitA({pdimId: pd.pdimId})[0];
      // change r1,r2 first, and reset prefix
      this.r1.update({pdimId: pd.pdimId, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) })
      this.r1.setPrefixVals(0); // set prefixes to 0
      this.r2.update({pdimId: pd.pdimId, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) })
      this.r2.setPrefixVals(0); // set prefixes to 0
      // now change c
      pd = pd.dtimes( this.b ); // this is the new dimension for c
      pu = this._gpu.findPhysUnitA({pdimId: pd.pdimId})[0];
      this.c.update({pdimId: pd.pdimId, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) })
      this.c.setPrefixVals(0); // set prefixes to 0
      this.c.setNewValueSI(); // leave numerical SI values unchanged
    } else {
      // change a (=b^2/c), and x (=c/b)
      pd = this.c.dcopy().dtimes(this.b,true); // this is the new dimension for x
      pu = this._gpu.findPhysUnitA({pdimId: pd.pdimId})[0];
      // change r1,r2 first, and reset prefix
      this.r1.update({pdimId: pd.pdimId, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) })
      this.r1.setPrefixVals(0); // set prefixes to 0
      this.r2.update({pdimId: pd.pdimId, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) })
      this.r2.setPrefixVals(0); // set prefixes to 0
      // now change a
      pd = this.b.dcopy().dtimes(pd,true); // this is the new dimension for a
      pu = this._gpu.findPhysUnitA({pdimId: pd.pdimId})[0];
      this.a.update({pdimId: pd.pdimId, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) })
      this.a.setPrefixVals(0); // set prefixes to 0
      this.a.setNewValueSI(); // leave numerical SI values unchanged
    }

    this.solve();

    return;
  }

}


@Component({
  selector: 'app-quad-basic',
  templateUrl: './quad-basic.component.html',
  styleUrls: ['./quad-basic.component.scss']
})
export class QuadBasicComponent implements OnInit {
  // readonly comp3 = ['a','b','c'];
  readonly comp3 = [ {name: 'a', html:'<i>a</i>'}, {name: 'b', html:'<i>b</i>'}, {name: 'c', html:'<i>c</i>'} ];
  readonly comp2 = [ {name: 'r1', html:'<i>x</i><sub>1</sub>'}, {name: 'r2', html:'<i>x</i><sub>2</sub>'} ];
  // readonly comp5names = ['r', 'o', 'x', 'y', 'z'];
  // completeUnitList: unitDropdownList[];
  catA: CatDropdownOption[]; // example- { pdimId:8, title="luminous intensity / luminous flux : [Lum Int]", label:"luminous intensity", comp:{M:0, L:0, T:0, t:0, td:0, N:0, I:0, J:0} }
  // cata: number; // pdimId for a, if changed, use this to reset a
  // catb: number; // pdimId for b, if changed, use this to reset b
  // catc: number; // pdimId for c, if changed, use this to reset c
  // catx: number; // pdimId for x, if changed, use this to reset x

  qtplet: QuadraticAbc;

  catV= { a: 1, b: 1, c: 1, r1: 1, r2: 1 }; // r1 = r2 always

  instructionHtml: string;


  constructor(private _facts: FactoryService, private _sbs: SnackBarService, private _gcb: GlobalClipboardService, private _gpu: GlobalPhysUnitsService, private _mono: MonologService ) { }

  ngOnInit() {
    // set up factsier/report and factsheet for the reveal module
    this._facts.addFactsier( new Factsier({factsier: {id:7, name:'quadratic', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Quadratic Solver', topicA: ['quadratic'] }));
    this._facts.bkmark.factsier = {id:7, name:'quadratic'}; // either one should do. name is the overriding criteria
    this._facts.findFactsier({name: 'quadratic'}).addFactsheet( new Factsheet({sheet: {id:0, name:'nofrillsQuadratic', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Quadratic scratch paper', topicA: ['quadratic'] }));

    // set up quadratic triplet qtplet object
    this.qtplet = new QuadraticAbc(this._facts.findFactsier({name: 'quadratic'}).findFactsheet({name: 'nofrillsQuadratic'}) );

    this.catA = this._gpu.physDimAList; // for dropdown manual
    this.catA.shift(); // remove first undefined element

    this.comp3.forEach(function(v){this.catV[v.name] = this.qtplet[v.name].pdimId}.bind(this));
    this.comp2.forEach(function(v){this.catV[v.name] = this.qtplet[v.name].pdimId}.bind(this));

    this.instructionHtml = "<br><p>The basic no-frills version of the quadratic solver, will give you the solutions (roots) for the standard quadratic equation:<br><i>f</i>(<i>x</i>) = <i>a</i> <i>x</i><sup>2</sup> + <i>b</i> <i>x</i> + <i>c</i> = 0 <br>The extended version (to-be-implemented) will allow inputs for different forms (such as <i>f</i>(<i>x</i>) = <i>a</i> (<i>x</i> &minus; <i>h</i>)<sup>2</sup> + <i>k</i>), as well as calculating other results like min/max/vertex, etc.</p><p>The dimensions must statisfy the condition [<i>a</i>] [<i>c</i>] = [<i>b</i>]<sup>2</sup>, <br> and the solution for <i>x</i> will have dimension of [<i>b</i>]/[<i>a</i>] (or equivalently [<i>c</i>]/[<i>b</i>]).<br>As far as these calculations are concerned, units for 'temperature' and 'temperature difference' are interchangeable.</p> <p>When you change the category (dimension) for the constant <i>a</i>, the category (dimension) and units for the constant <i>c</i> will change automatically (and vice versa), assuming the constant <i>b</i> is unchanged. When you change the category (dimension) for <i>b</i>, we assume <i>a</i> stays the same, and adjust <i>c</i> accordingly.</p><p>As a result, it is most convenient if you change the neccessary categories (dimensions) in the <b>order of <i>a</i>, <i>b</i>, and then <i>c</i></b>.</p> <p>One final note: in the good 'ol study of conics sections, all parabolas have eccentricity of unity (<i>e</i> = 1).</p>";
  }

  // readonly pfxDropList = siPrefixes.copylist(); //: SiPrefix[];
  pfxDropList(pq: PhysQty): SiPrefix[] {
    const pipe: PrefixDropListPipe = new PrefixDropListPipe();
    return pipe.transform(pq);
  }

  revealInstructions(): MatDialogRef<MonologComponent>  {
    return this._mono.openlog({ override: {title: 'Quadratic (basic) Module Instructions', message: this.instructionHtml } });
  }
  paste2input(tvar:string): void {
    let sbarMsg: object = null;
    let spadlen = this._gcb.spad.list.length;
    // check clipboard
    if ( spadlen<1 ) {
      this._sbs.now(<SnackBarMsg> { importance: 10, message: "Clipboard is empty. Nothing to paste.", action: "Error", duration: 4000 });
      return;
    }

    // check compatible category/dimension
    const cb = this._gcb.spad.list[spadlen-1].dcopy();
    if (cb.pdimId != this.qtplet[tvar].pdimId) {
      this._sbs.now(<SnackBarMsg> { importance: 10, message: "Clipboard element is of category "+cb.category+", incompatible with "+this.qtplet[tvar].category+".", action: "Error", duration: 5000 });
      return;
    }

    // right category.
    // change cb to original chosen prefix and unit, then do valueChange, to refresh solutions.
    cb.chgSysUnitID(<PhysConShell>{punitId: this.qtplet[tvar]['punitId'] });
    cb.setPrefixVals(this.qtplet[tvar]['prepower']);
    this.qtplet.valueChg(tvar, {target: {value: cb.value}}); // fake an $event with event.target.value

    this._sbs.now(<SnackBarMsg> { importance: 4, message: "Paste from clipboard successful.", action: "Success", duration: 4000 });

    return;
  }

  addPqty(pq: PhysQty): void {  this._gcb.spad.addPqty(pq);  }

  // general unit conversions
  pqUnitA(v: string): unitDropdownList[] { return this._gpu.physUnitAList4dropdown( { pdimId: this.qtplet[v].pdimId } ); } // pdimId: 20, Force
  pqsys(v: string): string { return this.pqUnitA(v)[0]['systemA'][0]; } // using getter will refresh model in ngFor loops etc.
  pqunit(v: string): string { return this.pqUnitA(v)[0]['unit']; }
  pqpunitid(v: string): number { return this.pqUnitA(v)[0]['punitId']; }

  copyComplete(): void {
    const compvars = this._gcb.stateCfgs.g('quadraticAdv');
    if (compvars) {
      const comp = compvars.vars.para;
      this.qtplet.a.update( comp.a );
      this.qtplet.b.update( comp.b );
      this.qtplet.c.update( comp.c );
      this.qtplet.r1.update( comp.r1 );
      this.qtplet.r2.update( comp.r2 );
    }
    return;
  }

}
