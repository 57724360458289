import { Injectable } from '@angular/core';
import { GlobalPhysUnitsService, PhysConShell, PhysDimComp, physDimCompNames, physDimCompNull, PhysDimCompForm } from '../invariant/global-phys-units.service';
import { PhysQty0, PhysQty, PhysDim } from '../bbb/bbb.module';
import { PureMathService } from './pure-math.service';
import { AppInjector } from 'src/app/app-injector';
// import { GlobalDigitService } from 'src/app/service/global-vars/global-digit.service';
// import { GlobalSigfigService } from 'src/app/service/global-vars/global-sigfig.service';

@Injectable({ providedIn: 'root' })
export class PqtyMathService {
  private _gpu: GlobalPhysUnitsService;
	// math functions on PhysQty objects, as opposed to regular numbers

  constructor() {
    this._gpu = AppInjector.get(GlobalPhysUnitsService);
  }
/*
	// unitaryOp: {
	droot(pd: PhysDim | number) : PhysDim { // square-root of PhysDim, dimensions only
    let res = <PhysDimCompForm>{M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0};
    if ( (pd instanceof PhysDim) ) { physDimCompNames.forEach(function(t){ res[t] = (isNaN(pd.comp[t])) ? physDimCompNull[t] : pd.comp[t]/2; }); }
    return new PhysDim(this._gpu.findPhysDim(res));
  }
  qpower(pqty: PhysQty | number, r: number ) : PhysQty { // floor(r), can only do integers
    r=Math.floor(r); let sgn = Math.sign(r); r=Math.abs(r);
    let ans = new PhysQty( <PhysConShell>{ name: "ans", nameHtml: "ans", value: 1, system: "SI", category: "dimensionless", unit: "", prepower: 0, stdErr: 0, exact: true, punitId: 1 });
    for (let i=0; i<r; i++) { ans = this.qtimes(ans,pqty,sgn==-1)}
    return ans;
  } // npower
  // qroot(pqty: PhysQty | number, n: number, real:boolean=true ) : PhysQty { return null; }  // TBD with Eqty objects
  qsqrt(pqty: PhysQty | number, real:boolean=true) : PhysQty[]  { // sqrt
    let pq: PhysQty;
    if (pqty instanceof PhysQty) { pq = pqty.dcopy(); } else { pq = new PhysQty(<PhysConShell>{ name: 'x', nameHtml: '<i>x</i>', value: Number(pqty), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 }); }
    const respdim = this.droot(pq);
    // determine what system to use
    let ressystem: string = (pq.system)?pq.system:"SI";
    // next determine what unit to use? punits.unitA has the allowed units.
    const respunit = this._gpu.findPhysUnitA(Object.assign( respdim,{ system: ressystem })) ; // array of punits with matching pdimId
    // and determine what category to use
    const rescat = respunit[0].categoryA[0]; // could be "not in system"

    ressystem = (respunit[0].systemA.includes(ressystem))? ressystem : respunit[0].systemA[0];
    const punit = respunit[0].unitA[0];
    const pid = respunit[0].punitId;
    // initialize up to 2 possible answers
    let ans:PhysQty[] = [];
    let ans1 = new PhysQty(<PhysConShell>{name: "root-"+pq["name"], nameHtml: "root"+pq["nameHtml"], value: 1, system: ressystem, category: rescat, unit: punit, prepower: 0, stdErr: 0, exact: true, punitId: pid } );
    let ans2 = new PhysQty(<PhysConShell>{name: "root-"+pq["name"], nameHtml: "root"+pq["nameHtml"], value: 1, system: ressystem, category: rescat, unit: punit, prepower: 0, stdErr: 0, exact: true, punitId: pid } );
    let ansval = (pq instanceof PhysQty)?pq.valueSI:pq;

    if (!real || ansval < 0) { console.log('imaginary answer. To-be-implemented.'); return ans; }

    ansval = Math.sqrt(ansval);
    ans1.setNewValueSI(ansval);
    ans.push(ans1);
    if (ansval >0) { ans2.setNewValueSI(-1*ansval); ans.push(ans2); }
    return ans;
  }
  // end unitaryOp

	// binaryOp: {
	dtimes(d1: PhysDim | number | object, d2: PhysDim | number | object, divide: boolean =false) : PhysDim { // PhysDims multiply
    // d1, d2 are PhysDim objects, or pdimId, or {pdimId: n} or {L:0, M:1}, for division, use divide = true
    const prod = <PhysDimCompForm>{M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0};
    let dd1 = ( typeof(d1) == 'number' )? Object.assign({},prod) : d1;
    let dd2 = ( typeof(d2) == 'number' )? Object.assign({},prod) : d2;
    // let ans = new PhysDim( <PhysConShell>{ pdimId:-1, comp: prod, categoryA: ["undefined"] } ); // only if pdims undefined
    if (!(dd1 instanceof PhysDim)) { dd1 = this._gpu.findPhysDim(dd1); }
    if (!(dd2 instanceof PhysDim)) { dd2 = this._gpu.findPhysDim(dd2); }
    physDimCompNames.forEach(function(t){
      if (isNaN(dd1['comp'][t]) || isNaN(dd2['comp'][t])) { prod[t] = physDimCompNull[t]; } else { prod[t] = (divide)? dd1['comp'][t] - dd2['comp'][t] : dd1['comp'][t] + dd2['comp'][t]; }
    });
    return new PhysDim(this._gpu.findPhysDim(prod));
  }

  // utimes: function(u1,u2,divide=false,u1pre=0,u1u=null,u2pre=0,u2u=null) {
  // 	// u1, u2 are Puint objects, u1pref is preferred unit ()
  // 	// multiply their dimensions, if both are using the same system, choose the lowest of that resulting system. Otherwise, use default SI
  // 	// new Punit( { punitId: 0, level: 0, cu2si: 1, cu2siInt:0, systemA: ["SI","imperial"], unitA: ["undefined"], pdimId: 0 } )
  // 	let prod = { M:0, T:0, L:0, t:0, N:0, I:0, J:0 };
  // 	p1.bus.forEach(function(t){ prod[t] += p1['comp'][t] + p2['comp'][t]; });
  // 	return this.findPdimId(prod);
  // }

  stimes(pq: PhysQty0, s: number, divide: boolean =false): PhysQty { // scalar times
    let p: PhysQty;
    if (!(pq instanceof PhysQty)) { p = new PhysQty( new PhysConShell( Object.assign({},pq,{exact: true}) ) ); } else {p = pq.dcopy();}
    const newval = (divide)?p.valueSI/s:p.valueSI*s;
    if (isNaN(newval)) { return null; }
    p.setNewValueSI(newval); // this will also set errors, with stdErrRel as guide
    return p;
  }

  qtimes(pq1: PhysQty0 | number, pq2: PhysQty0 | number, divide: boolean =false): PhysQty {
    // pq1, e2 are PhysQty objects.
    let e1: PhysQty;
    if (!(pq1 instanceof PhysDim)) {if (PureMathService.isNumeric(pq1)) { e1 = new PhysQty(<PhysConShell>{ name:"pq1",nameHtml:"pq<sub>1</sub>",value: pq1,system:"SI",category:"dimensionless",unit:"",prepower:0,stdErr:0,exact: true,punitId:1}); }else{ return null; }
    } else if (!(pq1 instanceof PhysQty)) { e1 = new PhysQty( new PhysConShell( Object.assign({},pq1,{exact: true}) ) );
    } else { e1 = (<PhysQty>pq1).dcopy(); }

    let e2: PhysQty;
    if (!(pq2 instanceof PhysDim)) { return this.stimes(e1,pq2,divide); // assume pq2 is just a number then.
    } else if (!(pq2 instanceof PhysQty)) { e2 = new PhysQty( new PhysConShell( Object.assign({},pq2,{exact: true}) ) );
    } else { e2 = (<PhysQty>pq2).dcopy(); }

    let respdim = this.dtimes(e1,e2,divide);
    // determine what system to use
    let ressystem = (e1.system===e2.system)?e1.system:"SI";
    // next determine what unit to use? punits.unitA has the allowed units.
    const respunit = this._gpu.findPhysUnitA(Object.assign( respdim,{ system: ressystem })) ; // array of punits with matching pdimId
    // and determine what category to use
    const rescat = respunit[0].categoryA[0]; // could be "not in system"

    ressystem = (respunit[0].systemA.includes(ressystem))? ressystem : respunit[0].systemA[0];
    const respcomp: PhysDimComp = new PhysDimComp( respunit[0].comp );
    const punit = respunit[0].unitA[0];
    const pid = respunit[0].punitId;
    let ans = new PhysQty(<PhysConShell>{name: e1.name+" "+e2.name, nameHtml: e1.nameHtml+"&bull;"+e2.nameHtml, value: 1, system: ressystem, comp: respcomp, category: rescat, unit: punit, prepower: 0, stdErr: 0, exact: true, punitId: pid } );
    const newSIval=(divide)?e1.valueSI/e2.valueSI:e1.valueSI*e2.valueSI;
    // if (isNaN(ans.cu2si)) { console.log("NaN here with respunit2:"); console.log(respunit); }
    // ans._setUnitSI(); // to be safe for undefined units
    if (isNaN(newSIval)) return null;
    ans.setNewValueSI(newSIval); // will set errors. need new rules for error propagation
    return ans;
  }

  qdivides(e1: PhysQty0 | number, e2: PhysQty0 | number): PhysQty  { return this.qtimes(e1,e2,true); }

  qadds(e1: PhysQty0 | number, e2: PhysQty0 | number, subtract: boolean =false): PhysQty {
    // assume same system/unit/category for now, gets more complicated to find propagation of error
    if (!(e1 instanceof PhysDim)) {console.log('e1 physdim'); if (PureMathService.isNumeric(e1)) { e1 = new PhysQty(<PhysConShell>{ name:"e1",nameHtml:"e1",value: Number(e1),system:"SI",category:"dimensionless",unit:"",prepower:0,stdErr:0,exact: true,punitId:1} ); }else{ return null; }
    } else if (!(e1 instanceof PhysQty0)) { console.log('e1 PhysQty0'); return null;
    } else if (!(e1 instanceof PhysQty)) { console.log('e1 PhysQty'); e1 = new PhysQty( new PhysConShell( Object.assign({},e1,{exact: true}) ) );
    }
    if (!(e2 instanceof PhysDim)) {console.log('e2 physdim'); if (PureMathService.isNumeric(e2)) { e2= new PhysQty(<PhysConShell>{ name:"e2",nameHtml:"e2",value: Number(e2),system:"SI",category:"dimensionless",unit:"",prepower:0,stdErr:0,exact: true,punitId:1}); }else{ return null; }
    } else if (!(e2 instanceof PhysQty0)) {console.log('e2 PhysQty0');  return null;
    } else if (!(e2 instanceof PhysQty)) {console.log('e2 PhysQty');  e2 = new PhysQty( new PhysConShell( Object.assign({},e2,{exact: true}) ) );
    }

    let ans = new PhysQty( new PhysConShell( Object.assign({},e1,{exact: true}) ) );
    // let e = new PhysQty( Object.assign({},e1) );
    if (subtract) { ans.setNewValueSI(e1.valueSI - e2.valueSI); } else { ans.setNewValueSI(e1.valueSI + e2.valueSI); }
    // need rules to set new min/max/error,
    // might need new algebra involving correlation between two variables, say 0-1 for r^2
    // map to euclidean distance (q=2) and manhattan distance (q=1) respectively.
    ans.name = e1.name+"+"+e2.name;
    ans.nameHtml = e1.nameHtml+"+"+e2.nameHtml;
    return ans;
  }

  qsubtracts(e1: PhysQty0 | number, e2: PhysQty0 | number): PhysQty  { return this.qadds(e1,e2,true); }
*/
  qtrigs(type: string, q: PhysQty | number): PhysQty {
    // type = s(ine),c(osine),t(angent),as(ine),ac(osine),at(angent)
    let p: PhysQty;
    if (q instanceof PhysQty) {
      if (q.pdimId != 1) { console.log(`Trig (${type}) function argument must be dimensionless.`); return null; }
      p = q.dcopy();
      if (p.punitId != 1) p.chgSysUnitID(<PhysConShell>{punitId: 1});
      if (type=='s'||type=='sin'||type=='sine') { p.setNewValueSI(Math.sin(p.valueSI)); } else
      if (type=='c'||type=='cos'||type=='cosine') { p.setNewValueSI(Math.cos(p.valueSI)); } else
      if (type=='t'||type=='tan'||type=='tangent') { p.setNewValueSI(Math.tan(p.valueSI)); } else
      if (type=='at'||type=='atan'||type=='arctan'||type=='atangent'||type=='arctangent') { p.setNewValueSI(Math.atan(p.valueSI)); } else // arcsine arccosine
      if ( p.valueSI > 1 || p.valueSI < -1 ) { console.log('Cannot take arcsine/arccosine with |value| >1'); return null; } else
      if (type=='as'||type=='asin'||type=='arcsin'||type=='asine'||type=='arcsine') { p.setNewValueSI(Math.asin(p.valueSI)); } else
      if (type=='ac'||type=='acos'||type=='arccos'||type=='acosine'||type=='arccosine') { p.setNewValueSI(Math.acos(p.valueSI)); }
      return p;
    }
    // case q is a number
    let res = new PhysQty( this._gpu.findPhysUnit(<PhysConShell>{punitId:1}) );
    if (type=='s'||type=='sin'||type=='sine') { res.setNewValueSI(Math.sin(q)); } else
    if (type=='as'||type=='asin'||type=='arcsin'||type=='asine'||type=='arcsine') { res.setNewValueSI(Math.asin(q)); } else
    if (type=='c'||type=='cos'||type=='cosine') { res.setNewValueSI(Math.cos(q)); } else
    if (type=='ac'||type=='acos'||type=='arccos'||type=='acosine'||type=='arccosine') { res.setNewValueSI(Math.acos(q)); } else
    if (type=='t'||type=='tan'||type=='tangent') { res.setNewValueSI(Math.tan(q)); } else
    if (type=='at'||type=='atan'||type=='arctan'||type=='atangent'||type=='arctangent') { res.setNewValueSI(Math.atan(q)); }
    return res;
  }

  qexp(q: PhysQty | number): PhysQty {
    let p: PhysQty;
    if (q instanceof PhysQty) {
      if (q.pdimId != 1) { console.log('Exponential function argument must be dimensionless.'); return null; }
      p = q.dcopy();
      if (p.punitId != 1) p.chgSysUnitID(<PhysConShell>{punitId: 1});
      p.setNewValueSI(Math.exp(p.valueSI));
      return p;
    }
    // case q is a number
    let res = new PhysQty( this._gpu.findPhysUnit(<PhysConShell>{punitId:1}) );
    res.setNewValueSI(Math.exp(q));
    return res;
  }

}
