import { Component, OnInit } from '@angular/core';
// import { GlobalSiteTitleService } from './service/global-vars/global-site-title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [ ],
   styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
