import { Injectable } from '@angular/core';
import { PureMathService } from 'src/app/numeric-library/operator/pure-math.service';
import { FactoryService } from '../facts/factory.service';
import { Factopia } from '../facts/facts.module';
import { AppInjector } from 'src/app/app-injector';
import { PhysQty } from 'src/app/numeric-library/bbb/bbb.module';
import { PhysConShell } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { PqtyMathService } from 'src/app/numeric-library/operator/pqty-math.service';
import { SnackBarService } from '../facts/snack-bar.service';

export interface EquationInfo {
  eqnId?: number;
  // eqn?: Function; // if function is named, same as fname
  cname?: string; // common name
  fname: string; // function name (unique name of eqn in system)
  eqnHtml?: string;
  eqnLatex?: string;
  parentEqn?: string | number; // either fname or eqnId for the parent equation
  version?: string|number;
  argsmin: number; // basic number of arguments
  argsmax: number; // max number with optional arguments
  argPdimA?: number[]; // PdimId array for arguments. // use -1, -2, ... for any units. Different args with ID=-1 would mean any units, but same with each other.
  outcnt: number; // max number of outputs. array if > 1 usually.
  outPdim: number; // output pdimId.  Undefined is 0, which will be determined by calculation itself. If argPdimA has -1, -2, ... this can also be -1, -2, etc
  desc?: string; // use in Factum??
  topicA?: string[];
  notes?: string;
  systemA?: string[]; // 'any' means no restrictions on system
}

export class Equation {
  info: EquationInfo;
  eqn: Function; // if function is named, same as fname

  constructor( input: { eqn: Function, info: EquationInfo} ) {
    this.info = input.info;
    this.eqn = input.eqn;
  }

  eval(...arg): any {
    // check correct arg length
    // check dim/unit compliance
    return this.eqn(...arg);
    // add dim/unit to make return PhysQty or PhysQty[]
  }

}

export interface EquationList {
  list: Equation[]

  // constructor() {
  //   // this._gsys = AppInjector.get(GlobalSystemService);
  //   this.list = [];
  // }
}



let nextId = 1;

@Injectable({ providedIn: 'root' })
export class EeeeService { // Equations for Everything Ever Existed
  private idcnt = nextId++;
  // private _principia: FactoryService; // opening the book to write explanations onto
  gEqns: Equation[];
  // gEqns: EquationList;

  constructor(private _pqm: PqtyMathService) {
    // constructor(private _pqm: PqtyMathService, private _facts: FactoryService, private _sbs: SnackBarService, private _pms: PureMathService) {
    if (this.idcnt>1) { console.warn('EeeeService is already loaded. Import it in the AppModule only');  }

    // this._principia = AppInjector.get(FactoryService);

    this.gEqns = <Equation[]>[]; // .list = []; // new EquationList();

    //  //////////////   Math and Phys functions    ////////////////////  /
    // each eqn should have an eqn on the DB

    this.addEqn(this.eq0);
    this.addEqn(this.eqId);
    // Quadratic
    this.addEqn(this.eqQuadratic);
    // END Quadratic
    // Trigs
    this.addEqn(this.eqTriangularInequality);
    this.addEqn(this.eqTriangleInteriorSumTest);
    this.addEqn(this.eqTriangleInteriorSum);
    this.addEqn(this.eqSineLaw);
    this.addEqn(this.eqSineLawS);
    this.addEqn(this.eqSineLawA);
    this.addEqn(this.eqCosineLaw);
    this.addEqn(this.eqCosineLawA);
    this.addEqn(this.eqCosineLawS1);
    this.addEqn(this.eqCosineLawS2);
    // this.addEqn(this.eq);
    // END Trigs
    // Kinematics 1D
    this.addEqn(this.eqKin1Dt); // not 1-1 function
    this.addEqn(this.eqKin1Dtd);
    this.addEqn(this.eqKin1Dta);
    this.addEqn(this.eqKin1Dti);
    this.addEqn(this.eqKin1Dtf);
    //
    this.addEqn(this.eqKin1Dd); // not 1-1 function
    this.addEqn(this.eqKin1Ddi);
    this.addEqn(this.eqKin1Ddf);
    this.addEqn(this.eqKin1Ddt);
    this.addEqn(this.eqKin1Dda);
    //
    this.addEqn(this.eqKin1Da); // not 1-1 function
    this.addEqn(this.eqKin1Dat);
    this.addEqn(this.eqKin1Dad);
    this.addEqn(this.eqKin1Dai);
    this.addEqn(this.eqKin1Daf);
    //
    this.addEqn(this.eqKin1Did);
    this.addEqn(this.eqKin1Dit);
    this.addEqn(this.eqKin1Dia);
    this.addEqn(this.eqKin1Dif);
    //
    this.addEqn(this.eqKin1Dfd);
    this.addEqn(this.eqKin1Dft);
    this.addEqn(this.eqKin1Dfa);
    this.addEqn(this.eqKin1Dfi);
    // END Kinematics 1D

    console.log("this is this.gEqns");
    console.log(this.gEqns);
    console.warn(`this is EeeeService idcnt= ${this.idcnt}.`)

  } // end constructor EeeeService


  addEqn(eq: Equation ): void {
    // neeed to check duplicate, then update
    this.gEqns.push( eq ); // use own judgement for now. No checks.
    // this.cid++;
    return;
  }

  findEqn(input: any): Equation { // find and return the equation by fname, or eqnId
    let res: Equation = this.eq0;
    this.gEqns.forEach(function(e){ if (e.info.fname==input || e.info.eqnId==input){res = e; return;} });
    if (res.info.fname == 'eq0') { console.warn("equation not found. returned the null function."); }
    return res;
  }

  // Collection of equations used in all of physics

  // ---------------------------       Null     ----------------------------------- //
  eq0: Equation = new Equation({
    eqn: function (...args: any[]): number { return 0; },
    info: { eqnId: 0, cname: "Null function", fname: "eq0", eqnHtml: "<i>f</i> = 0", eqnLatex: "", parentEqn: null, version: null, argsmin: 1, argsmax: 1, argPdimA: [-1], outcnt: 1, outPdim: 1, desc: "Null function.", topicA: ["all"], notes: "", systemA: ["all"] }
  });
  // ---------------------------     END  Null  END   ----------------------------------- //

  // ---------------------------       Identity     ----------------------------------- //
  eqId: Equation = new Equation({
    eqn: function (...args: any[]): any[] { return args; },
    info: { eqnId: 11, cname: "Identity function", fname: "eqId", eqnHtml: "<i>x</i> = <i>x</i>", eqnLatex: "", parentEqn: null, version: null, argsmin: 1, argsmax: 1, argPdimA: [-1], outcnt: 1, outPdim: -1, desc: "Identity function.", topicA: ["all"], notes: "", systemA: ["all"] }
  });
  // ---------------------------     END  Identity  END   ----------------------------------- //

  // ---------------------------     Quadratic    ----------------------------------- //
  eqQuadratic: Equation = new Equation({
    eqn: function (a:PhysQty|number, b:PhysQty|number, c:PhysQty|number, real:boolean=true): PhysQty[] {
      // real only solution or allow complex. Will handle complex later when Pqty etc can handle complex.
      // return array of possible solutions.

      let ap: PhysQty;
      let bp: PhysQty;
      let cp: PhysQty;

      if (a instanceof PhysQty) { ap = a.dcopy(); } else { ap = new PhysQty(<PhysConShell>{ name: 'a', nameHtml: '<i>a</i>', value: Number(a), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 }); }
      if (b instanceof PhysQty) { bp = b.dcopy(); } else { bp = new PhysQty(<PhysConShell>{ name: 'b', nameHtml: '<i>b</i>', value: Number(b), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 }); }
      if (c instanceof PhysQty) { cp = c.dcopy(); } else { cp = new PhysQty(<PhysConShell>{ name: 'c', nameHtml: '<i>c</i>', value: Number(c), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 }); }

      // NEED TO RE-DO, with pqtymathservice dtimes, etc

      // const a = (apqty instanceof PhysQty) ? (Number(apqty.valueSI)||0) : (Number(apqty)||0);
      real = (real)?true:false;
      let res: PhysQty[] = [];
      let tmp1: PhysQty;
      let tmp2: PhysQty;
      // error checking part
      // if (!PureMathService.isNumeric(a) || !PureMathService.isNumeric(b) || !PureMathService.isNumeric(c)) {
        // if (facts) { facts.addFactlet({title: "Error", explain: "At least one of the three constants is undefined. Please check.", typecolor: "error", difficulty: 9, category: "error", eqn: "eqQuadratic" }); }
        // return res;
      // }
      if (ap.valueSI==0) {
        if (PureMathService.isNumeric(1/bp.valueSI)) {
          // if (facts) { facts.addFactlet({title: "Success", explain: "With <i>a</i>=0, it is realatively easy to solve this linear equation.", typecolor: "success", difficulty: 9, category: "success", eqn: "eqQuadratic" }); }
          tmp1 = cp.dcopy().times(-1); // this. _pqm.stimes(cp,-1);
          tmp1.times(bp,true); // tmp1 = this. _pqm.qtimes(tmp1,bp,true);
          res.push(tmp1); // x = -c/b;
          return res;
        } else {
          // if (facts) { facts.addFactlet({title: "Error", explain: "It seems both <i>a</i> and <i>b</i> are zeros. Please check.", typecolor: "error", difficulty: 9, category: "error", eqn: "eqQuadratic" }); }
          return res;
        }
      }

      // use form (1/2) x^2 + (b/2a) x + (c/2a) = 0, so that sqrt gives array of up to 2 solutions, then just add -b/2a

      tmp1 = bp.dcopy().divides(ap); // this. _pqm.qtimes(bp,ap,true); //  result b/a
      tmp1.stimes(-2,true); //tmp1 = this. _pqm.stimes(tmp1,-2,true); //  result -b/a/2
      //
      tmp2 = cp.dcopy().divides(ap).stimes(-1); // result -c/a // this. _pqm.qtimes(cp,ap,true); // divide  // result c/a
      tmp2.adds(tmp1.dcopy().power(2)); //tmp2 = this. _pqm.qadds( this. _pqm.qtimes(tmp1,tmp1), tmp2, true); // result (b^2/(4a^2) -  (c/a), or the discriminant in the reduced form
      //
      // tmp1 = this. _pqm.qadds(tmp1,tmp2); // d = b*b-4*a*c;
      if (tmp2.valueSI<0) {
        if (real) {
          // if (facts) { facts.addFactlet({title: "Complex", explain: "The discriminant b*b-4*a*c is less than zero. There is no real solutions for this quadratic equation.", typecolor: "error", difficulty: 9, category: "error", eqn: "eqQuadratic" }); }
          console.warn(`Cannot take sqrt of ${tmp2.valueSI}`);
          return res;
        } else {
          // tmp1 = Math.sqrt(-d); // to be finished...
          // tmp1 = this. _pqm.qsqrt(tmp1);
          // res.push(tmp1);
          console.warn(`Complex number not introduced yet.`);
          return res;
        }
      } // last return need to include complex answers
      // if (facts) { facts.addEqnFactlet(facts, "eqQuadratic", "success", 9, "success"); }
      // if (facts) { facts.addFactlet({title: "Success", explain: "We used the quadratic formula here to solve for the two solutions.", typecolor: "success", difficulty: 9, category: "success", eqn: "eqQuadratic" }); }
      //
      // actual formula
      res = tmp2.sqrt(); // root(delta), res is PhyQty[]
      // res = this. _pqm.qsqrt(tmp2); // root(delta), res is PhyQty[]
      // res.forEach(function(pq:PhysQty){ pq = this. _pqm.qadds(pq,tmp1,true); }.bind(this)); // tmp1 is not defined
      for (let i=0; i<res.length; i++) { res[i] = res[i].adds(tmp1); } // adding -b/2a to the sqrt
      //
      return res;
    }, // end eqQuadratic.eqn
    info: {
      eqnId: 20,
      cname: "Quadratic formula",
      fname: "eqQuadratic",
      eqnHtml: "<i>x</i> = (1 / (2<i>a</i>))(&minus;<i>b</i> &pm; (<i>b</i><sup>2</sup> &minus; 4 <i>a</i> <i>c</i>)<sup>1/2</sup>)",
      eqnLatex: "",
      parentEqn: null,
      version: null,
      argsmin: 3,
      argsmax: 4,
      argPdimA: [-1,-2,-3,0], // first/second = second/third, or b*b = a*c . next is boolean for real, and number for punitId
      outcnt: 2,
      outPdim: 1, // default is 1, unless last argument specify an ID.
      desc: "By completing the square, one can derive the \'Quadratic Formula\'. Depending on the sign/polarity of the discriminant &Delta; = (<i>b</i><sup>2</sup> - 4 <i>a</i> <i>c</i>), there might be two, one (double root), or no real solution.", // use for factum?
      topicA: ["basic"],
      notes: "a times c should match the dimension of b^2.",
      systemA: ["all"]
    }
  });
  // ---------------------------     END  Quadratic  END   ----------------------------------- //


  // ---------------------------     Geometry Trigs    ----------------------------------- //
  eqTriangularInequality: Equation = new Equation({
    eqn: function (a:PhysQty|number, b:PhysQty|number, c:PhysQty|number, accuracy?: number): boolean {
      // test if three qty (same category/dimension) satisfy the triangular inequality. // Real quantities only
      // Accuracy: 1 parts in 10^n // if zero, want exact.

      let ap: PhysQty;
      let bp: PhysQty;
      let cp: PhysQty;
      accuracy = (accuracy)?accuracy:0;
      const threshold = (accuracy>0) ? Math.pow(10,-accuracy) : 0;

      if (a instanceof PhysQty) { ap = a.dcopy(); } else { ap = new PhysQty(<PhysConShell>{ name: 'a', nameHtml: '<i>a</i>', value: Number(a), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 }); }
      if (b instanceof PhysQty) { bp = b.dcopy(); } else { bp = new PhysQty(<PhysConShell>{ name: 'b', nameHtml: '<i>b</i>', value: Number(b), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 }); }
      if (c instanceof PhysQty) { cp = c.dcopy(); } else { cp = new PhysQty(<PhysConShell>{ name: 'c', nameHtml: '<i>c</i>', value: Number(c), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 }); }

      if (ap.pdimId != bp.pdimId || bp.pdimId != cp.pdimId) { console.log('Triangular inequality called with incompatible categories/dimensions.'); return false; }
      if (ap.valueSI < 0 || bp.valueSI < 0 || cp.valueSI < 0) { console.log('Triangular inequality should have non-negative arguments.'); return false; }
      return ( PureMathService.plchk(Math.abs(ap.valueSI - bp.valueSI), cp.valueSI, threshold) && PureMathService.plchk(cp.valueSI, ap.valueSI + bp.valueSI, threshold) );  // PureMathService.pchk( cp.valueSI < ap.valueSI + bp.valueSI
    }, // end eqTriangularInequality.eqn
    info: {
      eqnId: 21,
      cname: "Triangular Inequality",
      fname: "eqTriangularInequality",
      eqnHtml: "| <i>a</i> &minus; <i>b</i> | &le; <i>c</i> &le; | <i>a</i> + <i>b</i> |",
      eqnLatex: "",
      parentEqn: null,
      version: null,
      argsmin: 3,
      argsmax: 4,
      argPdimA: [-1,-1,-1,1], // all should be identical, except optional accuracy
      outcnt: 1,
      outPdim: -1, // boolean
      desc: "The triangular inequality represent the possible range of resulting values/lengths when adding two for form a third, as vectors for example.",
      topicA: ["trigs"],
      notes: "a, b, c should be non-negative, and of the same category/dimension.",
      systemA: ["all"]
    }
  });

  eqTriangleInteriorSumTest: Equation = new Equation({
    eqn: function (A:PhysQty|number, B:PhysQty|number, C:PhysQty|number, accuracy?: number): boolean {
      // test if three angles satisfy the triangle interior angle sum condition. // Real quantities only
      // Accuracy: 1 parts in 10^n // if zero, want exact.

      accuracy = (accuracy)?accuracy:0;
      const threshold = (accuracy>0) ? Math.pow(10,-accuracy) : 0;
      const Ap: PhysQty = (A instanceof PhysQty) ? A.dcopy() : new PhysQty(<PhysConShell>{ name: 'A', nameHtml: '&ang;<i>A</i>', value: Number(A), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 });
      const Bp: PhysQty = (B instanceof PhysQty) ? B.dcopy() : new PhysQty(<PhysConShell>{ name: 'B', nameHtml: '&ang;<i>B</i>', value: Number(B), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 });
      const Cp: PhysQty = (C instanceof PhysQty) ? C.dcopy() : new PhysQty(<PhysConShell>{ name: 'C', nameHtml: '&ang;<i>C</i>', value: Number(C), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 });

      if (Ap.pdimId != 1 || Bp.pdimId != 1 || Cp.pdimId != 1) { console.log('Triangle Interior Sum Test has incompatible categories/dimensions.'); return false; }
      if (Ap.valueSI < 0 || Bp.valueSI < 0 || Cp.valueSI < 0) { console.log('Triangle Interior Sum Test should have non-negative arguments.'); return false; }
      return PureMathService.pchk(Math.PI, Ap.valueSI + Bp.valueSI + Cp.valueSI, threshold);
    }, // end eqTriangleInteriorSumTest.eqn
    info: {
      eqnId: 22,
      cname: "Triangle Interior Angle Sum Test",
      fname: "eqTriangleInteriorSumTest",
      eqnHtml: "&ang;<i>A</i> + &ang;<i>B</i> + &ang;<i>C</i> = &pi;",
      eqnLatex: "",
      parentEqn: null,
      version: null,
      argsmin: 3,
      argsmax: 4,
      argPdimA: [1,1,1,1],
      outcnt: 1,
      outPdim: -1, // boolean
      desc: "The triangle interior angles should add to &pi; (180&deg;).",
      topicA: ["trigs"],
      notes: "A, B, C should be non-negative angles.",
      systemA: ["all"]
    }
  });

  eqTriangleInteriorSum: Equation = new Equation({
    eqn: function (A:PhysQty|number, B:PhysQty|number): PhysQty {
      // calculate the third angle of a triangle, given two of them.
      const Pipq: PhysQty = new PhysQty(<PhysConShell>{ name: 'C', nameHtml: '&pi;&minus;&ang;<i>A</i>&minus;&ang;<i>B</i>', value: Math.PI, pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 });
      const Ap: PhysQty = (A instanceof PhysQty) ? A.dcopy() : new PhysQty(<PhysConShell>{ name: 'A', nameHtml: '&ang;<i>A</i>', value: Number(A), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 });
      const Bp: PhysQty = (B instanceof PhysQty) ? B.dcopy() : new PhysQty(<PhysConShell>{ name: 'B', nameHtml: '&ang;<i>B</i>', value: Number(B), pdimId: 1, system: 'SI', unit: '', prepower: 0, stdErr: 0, exact: true, punitId: 1 });

      if (Ap.pdimId != 1 || Bp.pdimId != 1) { console.log('Triangle Interior Sum has incompatible categories/dimensions.'); return null; }
      if (Ap.valueSI < 0 || Bp.valueSI < 0) { console.log('Triangle Interior Sum should have non-negative arguments.'); return null; }
      return Pipq.subtracts(Ap).subtracts(Bp);
    }, // end eqQuadratic.eqn
    info: {
      eqnId: 23,
      cname: "Triangle Interior Angle Sum",
      fname: "eqTriangleInteriorSum",
      eqnHtml: "&ang;<i>C</i> = &pi; &minus; &ang;<i>A</i> &minus; &ang;<i>B</i>",
      eqnLatex: "",
      parentEqn: null,
      version: null,
      argsmin: 2,
      argsmax: 2,
      argPdimA: [1,1],
      outcnt: 1,
      outPdim: 1,
      desc: "The triangle interior angles should add to &pi; (180&deg;). &ang;C = &pi; &minus; &ang;A &minus; &ang;B",
      topicA: ["trigs"],
      notes: "a, b, c should be non-negative angles.",
      systemA: ["all"]
    }
  });

  // eqPolygonExteriorSumTest // 24
  // eqPolygonExteriorSum // 25
  // eqPolygonInteriorSumTest // 26
  // eqPolygonInteriorSum // 27

  // Law of Sine
  eqSineLaw: Equation = new Equation({
    eqn: function (): void { // not really a function. Need to rewrite into one of the two variations
      return;
    }, // end eqSineLaw.eqn
    info: {
      eqnId: 28,
      cname: "Law of Sines equation",
      fname: "eqSineLaw",
      eqnHtml: "sin &ang;<i>A</i> / <i>a</i> = sin &ang;<i>B</i> / <i>b</i> = sin &ang;<i>C</i> / <i>c</i>",
      eqnLatex: "",
      parentEqn: null,
      version: 0,
      argsmin: 0,
      argsmax: 0,
      argPdimA: [],
      outcnt: 0,
      outPdim: 0,
	    desc: "The law of sines apply to the sine of the three angles of a triangle and their lengths.",
      topicA: ["trigs"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqSineLawS: Equation = new Equation({
    eqn: function (a:PhysQty, B:PhysQty, A:PhysQty): PhysQty {
      let res = this.qtrigs('s',B);
      // = this. _pqm.qtimes(a,res);
      // = this. _pqm.qdivides(res, this. _pqm.qtrigs('s',A));
      res.times(a).divides(this.qtrigs('s',A));
      return res; // return b = a sin B / sin A ;
    }.bind(this._pqm), // end eqSineLawS.eqn
    info: {
      eqnId: 29,
      cname: "Law of Sines, to solve for a side length",
      fname: "eqSineLawS",
      eqnHtml: "<i>b</i> = <i>a</i> (sin &ang;<i>B</i> / sin &ang;<i>A</i>)",
      eqnLatex: "",
      parentEqn: 'eqSineLaw',
      version: 1,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [-1,1,1], // any, angle, angle
      outcnt: 1,
      outPdim: -1, // same as input a
	    desc: "The law of sines, solving for a side length.",
      topicA: ["trigs"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqSineLawA: Equation = new Equation({
    eqn: function (a:PhysQty, b:PhysQty, A:PhysQty): PhysQty {
      const res = this.qtrigs('s',A);
      // = this. _pqm.qtimes(res,b);
      // = this. _pqm.qdivides(res,a);
      res.times(b).divides(a);
      return this.qtrigs('as',res); // return B = asin( sin A (b/a))
    }.bind(this._pqm), // end eqSineLawA.eqn
    info: {
      eqnId: 30,
      cname: "Law of Sines, to solve for an angle.",
      fname: "eqSineLawA",
      eqnHtml: "&ang;<i>B</i> = arcsin( sin &ang;<i>A</i> ( <i>b</i>/<i>a</i> ) )",
      eqnLatex: "",
      parentEqn: 'eqSineLaw',
      version: 2,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [-1,-1,1],
      outcnt: 1,
      outPdim: 1,
	    desc: "The law of sines, solving for an angle. The last step involves taking the arcsine function, which typically returns one result in quadrant I and another in quadrant II, since any angles in a triangle can be an obtuse angle up to pi in principle. The result in quadrant II sometimes are non-feasible however. The function here only returns the first solution.",
      topicA: ["trigs"],
      notes: "Only one solution is provided here, which is in quadrant I. In general, arcsine gives the answer in quadrant I or IV, depending on the polarity of the argument.",
      systemA: ["all"]
    }
  });

  // Law of Cosines
  eqCosineLaw: Equation = new Equation({
    eqn: function (): void { // not really a function. Need to rewrite into one of the two variations
      return;
    }, // end eqCosineLaw.eqn
    info: {
      eqnId: 31,
      cname: "Law of Cosines equation",
      fname: "eqCosineLaw",
      eqnHtml: "<i>a</i><sup>2</sup> = <i>b</i><sup>2</sup>  + <i>c</i><sup>2</sup> &minus; 2 <i>b</i><i>c</i> cos &ang;<i>A</i>",
      eqnLatex: "",
      parentEqn: null,
      version: 0,
      argsmin: 0,
      argsmax: 0,
      argPdimA: [],
      outcnt: 0,
      outPdim: 0,
	    desc: "The law of cosines relates the three sides of a triangle and the cosine of one of the angles.",
      topicA: ["trigs"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqCosineLawA: Equation = new Equation({
    eqn: function (a:PhysQty, b:PhysQty, c:PhysQty): PhysQty {
      let res = b.dcopy().power(2); // this. _pqm.qpower(b,2);
      res.adds(c.dcopy().power(2)).subtracts(a.dcopy().power(2)).times(0.5).divides(b).divides(c);
      // res = this. _pqm.qadds( res, this. _pqm.qpower(c,2) );
      // res = this. _pqm.qsubtracts( res, this. _pqm.qpower(a,2) );
      // res = this. _pqm.qdivides(res, 2);
      // res = this. _pqm.qdivides(res, b);
      // res = this. _pqm.qdivides(res, c);
      res = this.qtrigs('ac',res);
      return res; // return A = acosin[ (b^2 + c^2 - a^2 )/(2 b c) ]
    }.bind(this._pqm), // end eqCosineLawA.eqn
    info: {
      eqnId: 32,
      cname: "Law of Cosines, to solve for an angle",
      fname: "eqCosineLawA",
      eqnHtml: "cos &ang;<i>A</i> = ( <i>b</i><sup>2</sup> + <i>c</i><sup>2</sup> &minus; <i>a</i><sup>2</sup>) / (2 <i>b</i> <i>c</i> )",
      eqnLatex: "",
      parentEqn: 'eqCosineLaw',
      version: 1,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [-1,-1,-1],
      outcnt: 1,
      outPdim: 1,
	    desc: "The law of cosines, solving for an angle. We expect an angle in a triangle to be between 0 and pi. This matches the range of the acosine function. In other words, arc-cosine function is one-to-one in quadrants I and II. There is no ambiguity when we use it to solve for angles of a triangle.",
      topicA: ["trigs"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqCosineLawS1: Equation = new Equation({
    eqn: function (A:PhysQty, b:PhysQty, c:PhysQty): PhysQty {
      let res = b.dcopy().power(2); // this. _pqm.qpower(b,2);
      res.adds(c.dcopy().power(2));
      res.subtracts( c.dcopy().times(2).times(b).times(this.qtrigs('c',A)) );
      // res = this. _pqm.qadds( res, this. _pqm.qpower(c,2) );
      // let tmp = this. _pqm.qtrigs('c',A);
      // tmp = this. _pqm.qtimes(tmp,b);
      // tmp = this. _pqm.qtimes(tmp,c);
      // tmp = this. _pqm.qtimes(tmp,2);
      // res = this. _pqm.qsubtracts(res,tmp);
      // res = this. _pqm.qsqrt(res)[0];
      // res = res.sqrt()[0];
      return res.sqrt()[0]; // return   (b^2 + c^2 - 2bc cosA)^1/2  ;
    }.bind(this._pqm), // end eqCosineLawS1.eqn
    info: {
      eqnId: 33,
      cname: "Law of Cosines, to solve for the side opposite to the known angle.",
      fname: "eqCosineLawS1",
      eqnHtml: "<i>a</i> = ( <i>b</i><sup>2</sup>  + <i>c</i><sup>2</sup> &minus; 2 <i>b</i><i>c</i> cos &ang;<i>A</i> )<sup>1/2</sup>",
      eqnLatex: "",
      parentEqn: 'eqCosineLaw',
      version: 2,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [1,-1,-1],
      outcnt: 1,
      outPdim: -1,
	    desc: "The law of cosines, solving for an unknown side opposite to the known angle. This is essentially what we have in a SAS triangle configuration. Apply this here will find the unknown side with relative ease. We can simply ignore the negative solution from the square root.",
      topicA: ["trigs"],
      notes: "Only the positive square root is kept here for obvious reason.",
      systemA: ["all"]
    }
  });
  eqCosineLawS2: Equation = new Equation({
    eqn: function (A:PhysQty, a:PhysQty, b:PhysQty): PhysQty[] {
      return this.eqQuadratic.eqn(-1,  b.dcopy().times(2).times(this.qtrigs('c',A))  , a.dcopy().power(2).subtracts(b.dcopy().power(2)) );
    }.bind(this._pqm), // end eqCosineLawS2.eqn
    info: {
      eqnId: 34,
      cname: "Law of Cosines, to solve for an unknown side, not opposite to the known angle.",
      fname: "eqCosineLawS2",
      eqnHtml: "&minus;<i>c</i><sup>2</sup> + (2<i>b</i> cos &ang;<i>A</i>) <i>c</i> + (<i>a</i><sup>2</sup> &minus; <i>b</i><sup>2</sup> ) = 0",
      eqnLatex: "",
      parentEqn: 'eqCosineLaw',
      version: 3,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [1,-1,-1],
      outcnt: 2,
      outPdim: -1,
	    desc: "The law of cosines, solving for an unknown side NOT opposite to the known angle. The use of this form of the cosine law is strongly discouraged. One can solve the triangle as such much easier using the Law of Sines. The current equation is quadratic in the unknown length. Solving such complicated quadratic equation is not the desirable method.",
      topicA: ["trigs"],
      notes: "Only one solution is provided here, which is in quadrant I. In general, arcsine gives the answer in quadrant I or IV, depending on the polarity of the argument.",
      systemA: ["all"]
    }
  });

  /*

  // law of cosine to solve angle A, given SSS, answer in radians,
  // if not satisfy trig inequality |a +- b|>=c, NaN results. Otherwise, always unique and exist.
  eqLocA(a,b,c,facts=null) {
    if (facts) { facts.addEqnFactlet(facts, "eqLocA", "info", 4, "info"); }
    if(!(a>=0 && a!=null && b>=0 && b!=null && c>=0 && c!=null )) {
      if (facts) { facts.addFactlet({title: "Error", explain: "Some of the three given values are invalid.", typecolor: "error", difficulty: 6, category: "info", eqn: "eqLocA"}); }
      return Infinity;
    }
    const ans = Math.acos((b*b+c*c-a*a)/2/b/c);
    if (isNaN(ans)) {
      if (facts) { facts.addFactlet({title: "Error", explain: "The angle cannot be calculated by LoC. Most likely the three sides do not satisfy the triangular inequality.", typecolor: "error", difficulty: 9, category: "info", eqn: "eqLocA"}); }
      return Infinity;
    }
    if (facts) { facts.addFactlet({title: "Calculated successfully", explain: "The angle is found to be Math.acos((b*b+c*c-a*a)/2/b/c)= "+rv(ans)+" (in radians).", typecolor: "success", difficulty: 9, category: "info", eqn: "eqLocA"}); }
    return ans;
  }
  this.gEqns.addEqn(new Equation({
    eqnId: 0,
    cname: "Law of Cosine (LoC)",
    fname: "eqLoc",
    eqn: null,
    eqnHtml: "<i>a</i><sup>2</sup> = <i>b</i><sup>2</sup> + <i>c</i><sup>2</sup> - 2 <i>b</i> <i>c</i> cos <i>A</i>",
    eqnLatex: "",
    parentEqn: null,
    version: null,
    argsmin: 0,
    argPdimA: [],
    outcnt: 0,
    outPdim: null,
    desc: "We can use this to solve for the side <i>c</i>, or angle <i>C</i> in the given form. In other words, you either know the angle or the opposing side. Using the equation in this form to find <i>a</i> or <i>b</i> involves a messy quadratic equation. There are better ways (Law of Sine) to solve for those variables.",
    topicA: ["basic"],
    notes: "",
    systemA: ["all"] })
  );
  this.gEqns.addEqn(new Equation({
    eqnId: 1,
    cname: "Law of Cosine (LoC)",
    fname: "eqLocA",
    eqn: eqLocA,
    eqnHtml: "<i>A</i> = cos<sup>-1</sup>( (<i>b</i><sup>2</sup> + <i>c</i><sup>2</sup> - <i>a</i><sup>2</sup>) / 2 <i>b</i> <i>c</i>)",
    eqnLatex: "",
    parentEqn: "eqLoc",
    version: 1,
    argsmin: 3,
    argPdimA: [3,3,3], // lengths
    outcnt: 1,
    outPdim: 0, // angle
    desc: "Solving to find an angle of the given side-side-side configuration.",
    topicA: ["basic"],
    notes: "",
    systemA: ["all"] })
  );
  // law of cosine to solve side a, given SAS, unique, always exist.
  eqLocS(b,A,c,facts=null) {
    if (facts) { facts.addEqnFactlet(facts, "eqLocS", "info", 4, "info"); }
    if(!(A>=0 && A!=null && b>=0 && b!=null && c>=0 && c!=null )) {
      if (facts) { facts.addFactlet({title: "Error", explain: "Some of the given values are invalid.", typecolor: "error", difficulty: 6, category: "info", eqn: "eqLocS" }); }
      return Infinity;
    }
    if(A>Math.PI) {
      if (facts) { facts.addFactlet({title: "Error", explain: "The given angle by itself is greater than 180&deg; or &pi;. Please check.", typecolor: "error", difficulty: 6, category: "info", eqn: "eqLocS" }); }
      return Infinity;
    }
    const ans = Math.sqrt(b*b+c*c-2*b*c*Math.cos(A));
    if (isNaN(ans)) {
      if (facts) { facts.addFactlet({title: "Error", explain: "The side cannot be calculated by LoC. Something is wrong. This should not happen. Please report this error to the support team.", typecolor: "error", difficulty: 9, category: "info", eqn: "eqLocS" }); }
      return Infinity;
    }
    if (facts) { facts.addFactlet({title: "Calculated successfully", explain: "The side is found to be Math.sqrt(b*b+c*c-2*b*c*Math.cos(A))= "+rv(ans)+".", typecolor: "success", difficulty: 9, category: "info", eqn: "eqLocS" }); }
    return ans;
  }
  // law of cosine to solve side a, given SAS, unique, always exist.
  this.gEqns.addEqn(new Equation({
    eqnId: 2,
    cname: "Law of Cosine (LoC)",
    fname: "eqLocS",
    eqn: eqLocS,
    eqnHtml: "<i>c</i> = (<i>a</i><sup>2</sup> + <i>b</i><sup>2</sup> - 2 <i>a</i> <i>b</i> cos <i>C</i>)^<sup>1/2</sup>",
    eqnLatex: "",
    parentEqn: "eqLoc",
    version: 2,
    argsmin: 3,
    argPdimA: [3,0,3], // length, angle, length
    outcnt: 1, // 1 or 0 if fails triangular inequality
    outPdim: 3, // length
    desc: "Solving to find the side opposite to the known angle in terms of the given side-angle-side SAS configuration. Notice that although it is technically possible use it in SSA cases, but the resulting quadratic equation is often too messy to solve. In SSA scenario, it is much easier to use Law of Sine instead to solve for the other angles and side. Try it yourself and you will see.",
    topicA: ["basic"],
    notes: "",
    systemA: ["all"] })
  );

  // law of sine to solve side a, given AAS, unique, always exist.
  eqLosS(B,A,b,facts=null) {
    if (facts) { facts.addEqnFactlet(facts, "eqLosS", "info", 6, "info"); }
    if(!(A>=0 && A!=null && B>=0 && B!=null && b>=0 && b!=null )) {
      if (facts) { facts.addFactlet({title: "Error", explain: "Some of the given values are invalid. Please check.", typecolor: "error", difficulty: 6, category: "info", eqn: "eqLosS" }); }
      return Infinity;
    }
    if(A+B>Math.PI) {
      if (facts) { facts.addFactlet({title: "Error", explain: "The two given angles already add up to greater than 180&deg; or &pi;.. Please check.", typecolor: "error", difficulty: 6, category: "info", eqn: "eqLosS" }); }
      return Infinity;
    }
    const ans = b*Math.sin(A)/Math.sin(B);
    if (isNaN(ans)) {
      if (facts) { facts.addFactlet({title: "Error", explain: "The side cannot be calculated by LoS. Something is wrong. This should not happen. Please report this error to the support team.", typecolor: "error", difficulty: 9, category: "info", eqn: "eqLosS" }); }
      return Infinity;
    }
    if (facts) { facts.addFactlet({title: "Calculated successfully", explain: "The side is found to be b*Math.sin(A)/Math.sin(B)= "+rv(ans)+".", typecolor: "success", difficulty: 9, category: "info", eqn: "eqLosS" }); }
    return ans;
  }
  this.gEqns.addEqn(new Equation({
    eqnId: 3,
    cname: "Law of Sine (LoS)",
    fname: "eqLos",
    eqn: null,
    eqnHtml: "<i>a</i>/sin <i>A</i> = <i>b</i>/sin <i>B</i> = <i>c</i>/sin <i>C</i> ",
    eqnLatex: "",
    parentEqn: null,
    version: null,
    argsmin: 0,
    argPdimA: [],
    outcnt: 0,
    outPdim: null, // length
    desc: "We can use any pairs of these relations to solve for the side or the angle when applicable. Solving for the angle however involves arcsine function which can gives an angle in quadrant I or II. One needs to check if the quadrant II solution is admissible (the sum not greater than &pi;).",
    topicA: ["basic"],
    notes: "",
    systemA: ["all"] })
  );
  this.gEqns.addEqn(new Equation({
    eqnId: 4,
    cname: "Law of Sine (LoS)",
    fname: "eqLosS",
    eqn: eqLosS,
    eqnHtml: "<i>a</i> = <i>b</i> (sin <i>A</i>/sin <i>B</i>)",
    eqnLatex: "",
    parentEqn: "eqLos",
    version: 1,
    argsmin: 3,
    argPdimA: [0,0,3], // angle, angle, length
    outcnt: 1,
    outPdim: 3, // length
    desc: "By solving for an unknown side, this always leads to an unique solution for this angle-angle-side scenario. Notice that the third angle can be easily calculated. There is virtually no difference between AAS or ASA configurations.",
    topicA: ["basic"],
    notes: "",
    systemA: ["all"] })
  );

  // law of sine to solve Angle A, given SSA, NOT UNIQUE, Ambiguous case. returns an array of (0 or 1 or 2 angles)
  eqLosA(b,a,B,facts=null) { // return an array of length 0, 1, or 2
    if (facts) { facts.addEqnFactlet(facts, "eqLosA", "info", 6, "info"); }
    if(!(B>=0 && B!=null && a>=0 && a!=null && b>=0 && b!=null )) {
      if (facts) { facts.addFactlet({title: "Error", explain: "Some of the input values are not numeric.", typecolor: "error", difficulty: 9, category: "info", eqn: "eqLosA" }); }
      return Infinity;
    }
    if(B>Math.PI) {
      if (facts) { facts.addFactlet({title: "Error", explain: "The angle is larger than &pi;.", typecolor: "error", difficulty: 9, category: "info", eqn: "eqLosA" }); }
      return Infinity;
    }
    if( B>Math.PI/2 && b<=a ) {
      if (facts) { facts.addFactlet({title: "Error", explain: "The given angle is greater than &pi;/2, is facing the shorter side. So the other angle facing the longer side must be > &pi;/2, which is an impossible triangle.", typecolor: "error", difficulty: 9, category: "info", eqn: "eqLosA" }); }
      return Infinity;
    }
    if( pchk(B,Math.PI/2) && b<a ) {
      if (facts) { facts.addFactlet({title: "Error", explain: "The given angle of &pi;/2, is facing the shorter side. So the other angle facing the longer side must be > &pi;/2, which is an impossible triangle.", typecolor: "error", difficulty: 9, category: "info", eqn: "eqLosA" }); }
      return Infinity;
    } // when B>Pi/2, sin(B) is same as sin(comlementary angle). The equations do not know which angle is the actual case.
    var A =  [];
    var tmp = Math.sin(B)*a/b;
    if (tmp>1){
      if (facts) { facts.addFactlet({title: "Error", explain: "There is no possible triangle with the given configuration.", typecolor: "error", difficulty: 9, category: "info", eqn: "eqLosA" }); }
      return A;
    } // return array of 0 length;
    var A1 = Math.asin(tmp);
    A.push(A1);
    if (a<=b || A1==Math.PI) {
      if (facts) { facts.addFactlet({title: "Success", explain: "One unique solution is found here: angle= "+rv(A1)+" (rad). ", typecolor: "success", difficulty: 9, category: "info", eqn: "eqLosA" }); }
      return A;
    } // unique case.
    // last case, a>b, and B < pi/2 (otherwise alrealdy ruled out with  if( B>Math.PI/2 && b<=a ) ), AND A1 < pi/2
    A.push(Math.PI - A1);
      if (facts) { facts.addFactlet({title: "Success", explain: "Two possibles solutions are found here: angle= "+rv(A1)+" or "+rv(Math.PI - A1)+" (rad)", typecolor: "success", difficulty: 9, category: "info", eqn: "eqLosA" }); }
      return A; // array of length 2
  }
  this.gEqns.addEqn(new Equation({
    eqnId: 5,
    cname: "Law of Sine (LoS)",
    fname: "eqLosA",
    eqn: eqLosA,
    eqnHtml: "<i>A</i> = sin<sup>-1</sup>(<i>a</i> sin <i>B</i>/<i>b</i>)",
    eqnLatex: "",
    parentEqn: "eqLos",
    version: 2,
    argsmin: 3,
    argPdimA: [0,3,3], // length, length, angle
    outcnt: 2,
    outPdim: 0, // angle
    desc: "By solving for an unknown angle, arcsine always gives one solution in quadrant I and one in quadrant II in this side-side-angle scenario.  One can checks if the sum of the resulting angles is greater than &pi; then result that solution. On the other hand, arcsine gives no solution if the argument is greater than unity.",
    topicA: ["basic"],
    notes: "",
    systemA: ["all"] })
  );

  */

  // ---------------------------     END  Trigs Geometry END   ----------------------------------- //

  // ---------------------------      Kinetmatics 1-D ----------------------------------- //
  // Kinematics 1-D equations
  // five vars are t, a, d, i, f (last two are velocities)
  // five basic 1D kinematics const acc equations, each re-dress to one of four variables as subject. Twenty equations all together.
  // each named as eq + Kin1D + missing var of equation + subject of equation
  // function eqKin1Dtd(a,i,f) { return (f*f-i*i)/2/a; }
  // function eqKin1Dta(d,i,f) { return (f*f-i*i)/2/d; }
  // function eqKin1Dti(d,a,f) { return sqrt(f*f-2*a*d); } // array
  // function eqKin1Dtf(d,a,i) { return sqrt(2*a*d-i*i); } // array
  // function eqKin1Ddt(a,i,f) { return (f-i)/a; }
  // function eqKin1Dda(t,i,f) { return (f-i)/t; }
  // function eqKin1Ddi(t,a,f) { return f-a*t; }
  // function eqKin1Ddf(t,a,i) { return i+a*t; }
  // function eqKin1Dat(d,i,f) { return 2*d/(i+f); }
  // function eqKin1Dad(t,i,f) { return (i+f)*t/2; }
  // function eqKin1Dai(t,d,f) { return (2*d/t-f); }
  // function eqKin1Daf(t,d,i) { return (2*d/t-i); }
  // function eqKin1Dit(d,a,f) { return eqQuadratic(a/2,-f,d); } // array
  // function eqKin1Did(t,a,f) { return (f*t-a*t*t/2); }
  // function eqKin1Dia(t,d,f) { return 2*(f*t-d)/t/t; }
  // function eqKin1Dif(t,d,a) { return (d/t+a*t/2); }
  // function eqKin1Dft(d,a,i) { return eqQuadratic(a/2,i,-d); } // array
  // function eqKin1Dfd(t,a,i) { return (i*t+a*t*t/2); }
  // function eqKin1Dfa(t,d,i) { return 2*(d-i*t)/t/t; }
  // function eqKin1Dfi(t,d,a) { return (d/t-a*t/2); }

  // Equations missing time t
  eqKin1Dt: Equation = new Equation({
    eqn: function (): void { // not really a function. Need to rewrite into one of the four variations
      return;
    }, // end eqKin1Dt.eqn
    info: {
      eqnId: 100,
      cname: "Kinematics 1-D Work-Energy equation",
      fname: "eqKin1Dt",
      eqnHtml: "<i>v</i><sub>2</sub><sup>2</sup> &minus; <i>v</i><sub>1</sub><sup>2</sup> = 2 <i>a</i> &Delta;<i>x</i>",
      eqnLatex: "",
      parentEqn: null,
      version: 0,
      argsmin: 0,
      argsmax: 0,
      argPdimA: [],
      outcnt: 0,
      outPdim: 0,
	    desc: "Kinematics equation 1-D, without time &Delta;<i>t</i>. This is essentially the work energy equation with constant net force.  Put it another way, we do not care about how long it takes. Only the initial and final \"states\" of the system are relevant.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dtd: Equation = new Equation({
    eqn: function (a:PhysQty, i:PhysQty, f:PhysQty): PhysQty {
      // return (f*f-i*i)/2/a; // if (a==0), infinite solution if f^2=i^2, otherwise no solution.
      const res = f.dcopy().power(2).subtracts(i.dcopy().power(2)).stimes(0.5);
      if (a.valueSI !=0) return res.divides(a);
      const tmp = a.dcopy(); tmp.setNewValueSI(1);
      res.divides(tmp); res.setNewValueSI(0); res.value=null; res.valueSI=null;
      return res;
    }, // end eqKin1Dtd.eqn
    info: {
      eqnId: 101,
      cname: "Kinematics 1-D Work-Energy equation, solve for displacement",
      fname: "eqKin1Dtd",
      eqnHtml: "&Delta;<i>x</i> = (<i>v</i><sub>2</sub><sup>2</sup> &minus; <i>v</i><sub>1</sub><sup>2</sup>)/(2 <i>a</i>)",
      eqnLatex: "",
      parentEqn: 'eqKin1Dt',
      version: 1,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [11,10,10], // acc, vel, vel
      outcnt: 1,
      outPdim: 4, // length
	    desc: "Kinematics equation 1-D, without time &Delta;<i>t</i>. Solving for displacement &Delta;<i>x</i>.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dta: Equation = new Equation({
    eqn: function (d:PhysQty, i:PhysQty, f:PhysQty): PhysQty {
      // return (f*f-i*i)/2/d ; // if (d=0), and if (v1=v2), then t=0 and infinite solutions for a. if (v1=-v2), infinite solutions for (t,a) if (v1!=v2), no solution
      const res = f.dcopy().power(2).subtracts(i.dcopy().power(2)).stimes(0.5);
      if (d.valueSI !=0) return res.divides(d);
      const tmp = d.dcopy(); tmp.setNewValueSI(1);
      res.divides(tmp); res.setNewValueSI(0); res.value=null; res.valueSI=null;
      return res;
    }, // end eqKin1Dta.eqn
    info: {
      eqnId: 102,
      cname: "Kinematics 1-D Work-Energy equation, solve for acceleration",
      fname: "eqKin1Dta",
      eqnHtml: "<i>a</i> = (<i>v</i><sub>2</sub><sup>2</sup> &minus; <i>v</i><sub>1</sub><sup>2</sup>)/(2 &Delta;<i>x</i> )",
      eqnLatex: "",
      parentEqn: 'eqKin1Dt',
      version: 2,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [4,10,10], // length, vel, vel
      outcnt: 1,
      outPdim: 11, // acc
	    desc: "Kinematics equation 1-D, without time &Delta;<i>t</i>. Solving for acceleration <i>a</i>.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dti: Equation = new Equation({
    eqn: function (d:PhysQty, a:PhysQty, f:PhysQty): PhysQty[] {
      // return gEqns.sf("eqQuadratic")(1,0,2*a*d-f*f);  or just use the sqrt()
      // if ( PureMathService.pchk(res1.valueSI,0,0.00001) ) { res1.setNewValueSI(0); return [ res1.sqrt() ]; }
      return f.dcopy().power(2).subtracts(a.dcopy().stimes(2).times(d)).sqrt();  // output punitId = 26 for velocity in m/s
    }, // end eqKin1Dti.eqn
    info: {
      eqnId: 103,
      cname: "Kinematics 1-D Work-Energy equation, solve for velocity 1",
      fname: "eqKin1Dti",
      eqnHtml: "<i>v</i><sub>1</sub> = (<i>v</i><sub>2</sub><sup>2</sup> &minus; 2 <i>a</i> &Delta;<i>x</i>)<sup>1/2</sup>",
      eqnLatex: "",
      parentEqn: 'eqKin1Dt',
      version: 3,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [4,11,10], // length, acc, vel
      outcnt: 2,
      outPdim: 10, // vel
	    desc: "Kinematics equation 1-D, without time &Delta;<i>t</i>. Solving for velocity <i>v</i><sub>1</sub>. Two possible answers with opposite signs, which means the oject would come back to the same place moving in the opposite direction.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dtf: Equation = new Equation({
    eqn: function (d:PhysQty, a:PhysQty, i:PhysQty): PhysQty[] {
      // return gEqns.sf("eqQuadratic")(1,0,-2*a*d-i*i) ;  or just use the sqrt()
      // if ( PureMathService.pchk(res1.valueSI,0,0.00001) ) { res1.setNewValueSI(0); return [ res1.sqrt() ]; }
      return i.dcopy().power(2).adds(a.dcopy().stimes(2).times(d)).sqrt(); // output punitId = 26 for velocity in m/s
    }, // end eqKin1Dtf.eqn
    info: {
      eqnId: 104,
      cname: "Kinematics 1-D Work-Energy equation, solve for velocity 2",
      fname: "eqKin1Dtf",
      eqnHtml: "<i>v</i><sub>2</sub> = (<i>v</i><sub>1</sub><sup>2</sup> + 2 <i>a</i> &Delta;<i>x</i>)<sup>1/2</sup>",
      eqnLatex: "",
      parentEqn: 'eqKin1Dt',
      version: 4,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [4,11,10], // length, acc, vel
      outcnt: 2,
      outPdim: 10, // vel
	    desc: "Kinematics equation 1-D, without time &Delta;<i>t</i>. Solving for velocity <i>v</i><sub>2</sub>. Two possible answers with opposite signs, which means the oject would come back to the same place moving in the opposite direction.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  // END  Equations missing time t

  // Equations missing displacement d
  eqKin1Dd: Equation = new Equation({
    eqn: function (): void { // not really a function. Need to rewrite into one of the four variations
      return;
    }, // end eqKin1Dd.eqn
    info: {
      eqnId: 105,
      cname: "Kinematics 1-D constant acceleration defintion/equation",
      fname: "eqKin1Dd",
      eqnHtml: "<i>v</i><sub>2</sub> &minus; <i>v</i><sub>1</sub> = <i>a</i> &Delta;<i>t</i>",
      eqnLatex: "",
      parentEqn: null,
      version: 0,
      argsmin: 0,
      argsmax: 0,
      argPdimA: [],
      outcnt: 0,
      outPdim: 0,
	    desc: "This base equation is essentially the definition of (constant) acceleration in 1-D, without the displacement unknown &Delta;<i>x</i>.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Ddf: Equation = new Equation({
    eqn: function (t:PhysQty, a:PhysQty, i:PhysQty): PhysQty { // return i+a*t ;
      return a.dcopy().times(t).adds(i);
    }, // end eqKin1Ddf.eqn
    info: {
      eqnId: 106,
      cname: "Kinematics 1-D constant acceleration defintion/equation, solve for <i>v</i><sub>2</sub>",
      fname: "eqKin1Ddf",
      eqnHtml: "<i>v</i><sub>2</sub> = <i>v</i><sub>1</sub> + <i>a</i> &Delta;<i>t</i>",
      eqnLatex: "",
      parentEqn: 'eqKin1Dd',
      version: 1,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,11,10], // time, acc, vel
      outcnt: 1,
      outPdim: 10, // length
	    desc: "The velocity <i>v</i><sub>2</sub> can be calculated from the other three variables easily.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Ddi: Equation = new Equation({
    eqn: function (t:PhysQty, a:PhysQty, f:PhysQty): PhysQty { // return f-a*t ;
      return a.dcopy().times(t).stimes(-1).adds(f);
    }, // end eqKin1Ddi.eqn
    info: {
      eqnId: 107,
      cname: "Kinematics 1-D constant acceleration defintion/equation, solve for <i>v</i><sub>1</sub>",
      fname: "eqKin1Ddi",
      eqnHtml: "<i>v</i><sub>1</sub> = <i>v</i><sub>2</sub> &minus; <i>a</i> &Delta;<i>t</i>",
      eqnLatex: "",
      parentEqn: 'eqKin1Dd',
      version: 2,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,11,10], // time, acc, vel
      outcnt: 1,
      outPdim: 10, // length
	    desc: "The velocity <i>v</i><sub>1</sub> can be calculated from the other three variables easily.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Ddt: Equation = new Equation({
    eqn: function (a:PhysQty, i:PhysQty, f:PhysQty): PhysQty {
      // return (f-i)/a ;
      const res = f.dcopy().subtracts(i);
      if (a.valueSI !=0) return res.divides(a);
      const tmp = a.dcopy(); tmp.setNewValueSI(1);
      res.divides(tmp); res.setNewValueSI(0); res.value=null; res.valueSI=null;
      return res;
    }, // end eqKin1Ddt.eqn
    info: {
      eqnId: 108,
      cname: "Kinematics 1-D constant acceleration defintion/equation, solve for time <i>t</i>",
      fname: "eqKin1Ddt",
      eqnHtml: "&Delta;<i>t</i> = (<i>v</i><sub>2</sub> &minus; <i>v</i><sub>1</sub>) / <i>a</i>)",
      eqnLatex: "",
      parentEqn: 'eqKin1Dd',
      version: 3,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [11,10,10], // acc, vel, vel
      outcnt: 1,
      outPdim: 3, // time
	    desc: "Solving for time &Delta;<i>t</i>, we can compute the answer from the other three variables.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dda: Equation = new Equation({
    eqn: function (t:PhysQty, i:PhysQty, f:PhysQty): PhysQty {
      // return (f-i)/t ;
      const res = f.dcopy().subtracts(i);
      if (t.valueSI !=0) return res.divides(t);
      const tmp = t.dcopy(); tmp.setNewValueSI(1);
      res.divides(tmp); res.setNewValueSI(0); res.value=null; res.valueSI=null;
      return res;
    }, // end eqKin1Dda.eqn
    info: {
      eqnId: 109,
      cname: "Kinematics 1-D constant acceleration defintion/equation, solve for acceleration <i>a</i>",
      fname: "eqKin1Dda",
      eqnHtml: "<i>a</i> = (<i>v</i><sub>1</sub> &minus; <i>v</i><sub>2</sub>) /  &Delta;<i>t</i>",
      eqnLatex: "",
      parentEqn: 'eqKin1Dd',
      version: 4,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,10,10], // time, vel, vel
      outcnt: 1,
      outPdim: 11, // acc
	    desc: "Solving for acceleration <i>a</i>, we can compute the answer from the other three variables.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  // END  Equations missing displacement d

  // Equations missing acceleration a
  eqKin1Da: Equation = new Equation({
    eqn: function (): void { // not really a function. Need to rewrite into one of the four variations
      return;
    }, // end eqKin1Da.eqn
    info: {
      eqnId: 110,
      cname: "Kinematics 1-D Average Velocity equation",
      fname: "eqKin1Da",
      eqnHtml: "(<i>v</i><sub>1</sub> + <i>v</i><sub>2</sub>)/2 = &Delta;<i>x</i> / &Delta;<i>t</i>",
      eqnLatex: "",
      parentEqn: null,
      version: 0,
      argsmin: 0,
      argsmax: 0,
      argPdimA: [],
      outcnt: 0,
      outPdim: 0,
	    desc: "The left hand side of the base equation is the arithmetic mean of the two velocities, and the right side is the definition of average velocity for any motion. They are equal for constant acceleration motion because the velocity-time graph is a straight line. This equation does not involve time &Delta;<i>t</i>.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dat: Equation = new Equation({
    eqn: function (d:PhysQty, i:PhysQty, f:PhysQty): PhysQty {
      // return 2*d/(i+f) ;
      const denom = f.dcopy().adds(i);
      if (denom.valueSI !=0) return d.dcopy().stimes(2).divides(denom);
      const res = d.dcopy().stimes(2); // times 2 or not doesn't matter here
      denom.setNewValueSI(1);
      res.divides(denom); res.setNewValueSI(0); res.value=null; res.valueSI=null;
      return res;
    }, // end eqKin1Dat.eqn
    info: {
      eqnId: 111,
      cname: "Kinematics 1-D Average Velocity equation, solve for time <i>t</i>",
      fname: "eqKin1Dat",
      eqnHtml: "(<i>v</i><sub>1</sub> + <i>v</i><sub>2</sub>)/2 = &Delta;<i>x</i> / &Delta;<i>t</i>",
      eqnLatex: "",
      parentEqn: 'eqKin1Da',
      version: 1,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [4,10,10], // length, vel, vel
      outcnt: 1,
      outPdim: 3, // time
	    desc: "Solving for displacement &Delta;<i>x</i>, we can compute the answer from the other three variables.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dad: Equation = new Equation({
    eqn: function (t:PhysQty, i:PhysQty, f:PhysQty): PhysQty { // return (i+f)*t/2 ;
      return i.dcopy().adds(f).times(t).stimes(0.5);
    }, // end eqKin1Dad.eqn
    info: {
      eqnId: 112,
      cname: "Kinematics 1-D Average Velocity equation, solve for displacement &Delta;<i>x</i>",
      fname: "eqKin1Dad",
      eqnHtml: "(<i>v</i><sub>1</sub> + <i>v</i><sub>2</sub>)/2 = &Delta;<i>x</i> / &Delta;<i>t</i>",
      eqnLatex: "",
      parentEqn: 'eqKin1Da',
      version: 2,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,10,10], // time, vel, vel
      outcnt: 1,
      outPdim: 4, // length
	    desc: "Solving for displacement &Delta;<i>x</i>, we can compute the answer from the other three variables.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dai: Equation = new Equation({
    eqn: function (t:PhysQty, d:PhysQty, f:PhysQty): PhysQty {
      // return (2*d/t-f) ;
      // special case, if t=0 && d!=0, no solution. if t=0 && d=0, then vi=vf, a can be anything.
      if (t.valueSI !=0) return d.dcopy().stimes(2).divides(t).subtracts(f);
      if (d.valueSI ==0) return f.dcopy();
      const res = f.dcopy(); res.setNewValueSI(0); res.value=null; res.valueSI=null;
      return res;
    }, // end eqKin1Dai.eqn
    info: {
      eqnId: 113,
      cname: "Kinematics 1-D Average Velocity equation, solve for <i>v</i><sub>1</sub>",
      fname: "eqKin1Dai",
      eqnHtml: "<i>v</i><sub>1</sub> = (2 &Delta;<i>x</i>) / &Delta;<i>t</i>) &minus; <i>v</i><sub>2</sub>",
      eqnLatex: "",
      parentEqn: 'eqKin1Da',
      version: 3,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,4,10], // time, length, vel
      outcnt: 1,
      outPdim: 10, // vel
	    desc: "Solving for velocity <i>v</i><sub>1</sub>, we can compute the answer from the other three variables.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Daf: Equation = new Equation({
    eqn: function (t:PhysQty, d:PhysQty, i:PhysQty): PhysQty {
      // return (2*d/t-i) ;
      // special case, if t=0 && d!=0, no solution. if t=0 && d=0, then vf=vi, a can be anything.
      if (t.valueSI !=0) return d.dcopy().stimes(2).divides(t).subtracts(i);
      if (d.valueSI ==0) return i.dcopy();
      const res = i.dcopy(); res.setNewValueSI(0); res.value=null; res.valueSI=null;
      return res;
    }, // end eqKin1Daf.eqn
    info: {
      eqnId: 114,
      cname: "Kinematics 1-D Average Velocity equation, solve for <i>v</i><sub>2</sub>",
      fname: "eqKin1Daf",
      eqnHtml: "<i>v</i><sub>2</sub> = (2 &Delta;<i>x</i>) / &Delta;<i>t</i>) &minus; <i>v</i><sub>1</sub>",
      eqnLatex: "",
      parentEqn: 'eqKin1Da',
      version: 4,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,4,10], // time, length, vel
      outcnt: 1,
      outPdim: 10, // vel
	    desc: "Solving for velocity <i>v</i><sub>2</sub>, we can compute the answer from the other three variables.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  // END  Equations missing acceleration a

  //  Equations missing velocity v1 / vi
  eqKin1Did: Equation = new Equation({
    eqn: function (t:PhysQty, a:PhysQty, f:PhysQty): PhysQty { // return (f*t-a*t*t/2) ;
      return a.dcopy().times(t).stimes(-0.5).adds(f).times(t);
    }, // end eqKin1Did.eqn
    info: {
      eqnId: 115,
      cname: "Kinematics 1-D Roll-back-time equation",
      fname: "eqKin1Did",
      eqnHtml: "&Delta;<i>x</i> = <i>v</i><sub>2</sub> &Delta;<i>t</i> &minus; (1/2) <i>a</i> (&Delta;<i>t</i>)<sup>2</sup>",
      eqnLatex: "",
      parentEqn: null,
      version: 1,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,11,10], // time, acc, vel
      outcnt: 1,
      outPdim: 4, // length
	    desc: "The base equation is the opposite of the basic constant acceleration equation &Delta;<i>x</i> = <i>v</i><sub>1</sub> &Delta;<i>t</i> + (1/2) <i>a</i> (&Delta;<i>t</i>)<sup>2</sup>.  This does not involve the initial velocity <i>v</i><sub>1</sub>. In many ways, you can imagine things started at point 2, and roll the time backwards, with opposite acceleration.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dit: Equation = new Equation({
    eqn: function (d:PhysQty, a:PhysQty, f:PhysQty): PhysQty[] {
      if (a.valueSI ==0 && f.valueSI ==0) { return []; }
      if (a.valueSI ==0) { return [ d.dcopy().divides(f) ]; }
      return this.findEqn('eqQuadratic').eqn( a.dcopy().stimes(0.5) , f.dcopy().stimes(-1) , d.dcopy(),true); // output punitId = 10 for time in sec // return gEqns.sf("eqQuadratic")(a/2,-f,d) ;
    }, // end eqKin1Dit.eqn
    info: {
      eqnId: 116,
      cname: "Kinematics 1-D Roll-back-time equation, solve for time <i>t</i>",
      fname: "eqKin1Dit",
      eqnHtml: "(<i>a</i>/2) (&Delta;<i>t</i>)<sup>2</sup> &minus; <i>v</i><sub>2</sub> &Delta;<i>t</i> + &Delta;<i>x</i> = 0 &rarr; Quadratic formula",
      eqnLatex: "eqKin1Did",
      parentEqn: '',
      version: 2,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [4,11,10], // length, acc, vel
      outcnt: 1,
      outPdim: 3, // time
	    desc: "Solving for time &Delta;<i>t</i>, then we typically use the quadratic formula so solve. Remember that we can get two, one, or no real solution.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dia: Equation = new Equation({
    eqn: function (t:PhysQty, d:PhysQty, f:PhysQty): PhysQty {
      // return 2*(f-d/t)/t ;  // if t=0, infinity solution if d=0 or no solution otherwise.
      if (t.valueSI ==0) { const tmp = t.dcopy(); tmp.setNewValueSI(1); const res = f.dcopy().divides(tmp); res.setNewValueSI(0); res.value=null; res.valueSI=null; return res; }
      return d.dcopy().divides(t).subtracts(f).stimes(-2).divides(t);
    }, // end eqKin1Dia.eqn
    info: {
      eqnId: 117,
      cname: "Kinematics 1-D Roll-back-time equation, solve for acceleration <i>a</i>",
      fname: "eqKin1Dia",
      eqnHtml: "<i>a</i> = 2 (<i>v</i><sub>2</sub> &Delta;<i>t</i> &minus; &Delta;<i>x</i>) / (&Delta;<i>t</i>)<sup>2</sup>",
      eqnLatex: "",
      parentEqn: "eqKin1Did",
      version: 3,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,4,10], // time, length, vel
      outcnt: 1,
      outPdim: 11, // acc
	    desc: "Solving for acceleration <i>a</i>, we can compute the answer from the other three variables.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dif: Equation = new Equation({
    eqn: function (t:PhysQty, d:PhysQty, a:PhysQty): PhysQty {
      //  return (d/t+a*t/2) ; // if t=0, infinite solution if d=0 as well, or no solution otherwise
      const res = a.dcopy().times(t).stimes(0.5);
      if (t.valueSI ==0) { res.setNewValueSI(0); res.value=null; res.valueSI=null; return res; }
      return res.adds(d.dcopy().divides(t));
    }, // end eqKin1Dif.eqn
    info: {
      eqnId: 118,
      cname: "Kinematics 1-D Roll-back-time equation, solve for velocity <i>v</i><sub>2</sub>",
      fname: "eqKin1Dif",
      eqnHtml: "<i>v</i><sub>2</sub> = ( &Delta;<i>x</i> / &Delta;<i>t</i>) + (<i>a</i> &Delta;<i>t</i>) / 2",
      eqnLatex: "",
      parentEqn: "eqKin1Did",
      version: 4,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,4,11], // time, length, acc
      outcnt: 1,
      outPdim: 10, // vel
	    desc: "Solving for velocity <i>v</i><sub>2</sub>, we can compute the answer from the other three variables.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  // END  Equations missing velocity v1 / vi

  // Equations missing velocity v2 / vf
  eqKin1Dfd: Equation = new Equation({
    eqn: function (t:PhysQty, a:PhysQty, i:PhysQty): PhysQty { // return (i*t+a*t*t/2) ;
      return a.dcopy().times(t).stimes(0.5).adds(i).times(t);
    }, // end eqKin1Dfd.eqn
    info: {
      eqnId: 119,
      cname: "Kinematics 1-D Basic displacement equation",
      fname: "eqKin1Dfd",
      eqnHtml: "&Delta;<i>x</i> = <i>v</i><sub>1</sub> &Delta;<i>t</i> + (1/2) <i>a</i> (&Delta;<i>t</i>)<sup>2</sup>",
      eqnLatex: "",
      parentEqn: null,
      version: 1,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,11,10], // time, acc, vel
      outcnt: 1,
      outPdim: 4, // length
	    desc: "The base equation might be the first constant acceleration equatino that comes to mind. This together with the final velocity <i>v</i><sub>2</sub> equation naturally describes how the system evolve in time in the most intuitive sense.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dft: Equation = new Equation({
    eqn: function (d:PhysQty, a:PhysQty, i:PhysQty): PhysQty[] {
      if (a.valueSI ==0 && i.valueSI ==0) { return []; }
      if (a.valueSI ==0) { return [ d.dcopy().divides(i) ]; }
      return this.findEqn('eqQuadratic').eqn( a.dcopy().stimes(0.5) , i.dcopy(), d.dcopy().stimes(-1) , true); // output punitId = 10 for time in sec // return gEqns.sf("eqQuadratic")(a/2,i,-d) ;
    }, // end eqKin1Dft.eqn
    info: {
      eqnId: 120,
      cname: "Kinematics 1-D Basic displacement equation, solve for time <i>t</i>",
      fname: "eqKin1Dft",
      eqnHtml: "(<i>a</i>/2) (&Delta;<i>t</i>)<sup>2</sup> + <i>v</i><sub>1</sub> &Delta;<i>t</i> &minus; &Delta;<i>x</i> = 0 &rarr; Quadratic formula",
      eqnLatex: "eqKin1Dfd",
      parentEqn: '',
      version: 2,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [4,11,10], // length, acc, vel
      outcnt: 1,
      outPdim: 3, // time
	    desc: "Since this equation is quadratic in &Delta;<i>t</i>, we can get up to two solutions here using, say, the quadratic formula. Remember that we can get two, one, or no real solution. And if any of the solutions has a negative &Delta;<i>t</i> value, that means the second point happened before the first.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dfa: Equation = new Equation({
    eqn: function (t:PhysQty, d:PhysQty, i:PhysQty): PhysQty {
      // return 2*(d-i*t)/t/t;  // if t=0, infinity solution if d=0 or no solution otherwise.
      if (t.valueSI ==0) { const tmp = t.dcopy(); tmp.setNewValueSI(1); const res = i.dcopy().divides(tmp); res.setNewValueSI(0); res.value=null; res.valueSI=null; return res; }
      return d.dcopy().divides(t).subtracts(i).stimes(2).divides(t);
    }, // end eqKin1Dfa.eqn
    info: {
      eqnId: 121,
      cname: "Kinematics 1-D Basic displacement equation, solve for acceleration <i>a</i>",
      fname: "eqKin1Dfa",
      eqnHtml: "<i>a</i> = 2 (&Delta;<i>x</i> &minus; <i>v</i><sub>1</sub> &Delta;<i>t</i>) / (&Delta;<i>t</i>)<sup>2</sup>",
      eqnLatex: "",
      parentEqn: "eqKin1Dfd",
      version: 3,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,4,10], // time, length, vel
      outcnt: 1,
      outPdim: 11, // acc
	    desc: "Solving for acceleration <i>a</i>, we can compute the answer from the other three variables.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  eqKin1Dfi: Equation = new Equation({
    eqn: function (t:PhysQty, d:PhysQty, a:PhysQty): PhysQty {
      // return (d/t-a*t/2) ; // if t=0, infinite solution if d=0 as well, or no solution otherwise
      const res = a.dcopy().times(t).stimes(-0.5);
      if (t.valueSI ==0) { res.setNewValueSI(0); res.value=null; res.valueSI=null; return res; }
      return res.adds(d.dcopy().divides(t));
    }, // end eqKin1Dfi.eqn
    info: {
      eqnId: 122,
      cname: "Kinematics 1-D Basic displacement equation, solve for velocity <i>v</i><sub>1</sub>",
      fname: "eqKin1Dfi",
      eqnHtml: "<i>v</i><sub>1</sub> = ( &Delta;<i>x</i> / &Delta;<i>t</i>) &minus; (<i>a</i> &Delta;<i>t</i>) / 2",
      eqnLatex: "",
      parentEqn: "eqKin1Dfd",
      version: 4,
      argsmin: 3,
      argsmax: 3,
      argPdimA: [3,4,11], // time, length, acc
      outcnt: 1,
      outPdim: 10, // vel
	    desc: "Solving for velocity <i>v</i><sub>1</sub>, we can compute the answer from the other three variables.",
      topicA: ["kinematics"],
      notes: "",
      systemA: ["all"]
    }
  });
  // END  Equations missing velocity v2 / vf
  // ---------------------------    END  Kinetmatics 1-D  END  ----------------------------------- //



}

