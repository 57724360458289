import { Component, OnInit } from '@angular/core';
import { GlobalPhysUnitsService, PhysConShell, SiPrefix, siPrefixes, PhysDimComp, unitDropdownList, CatDropdownOption } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { GlobalClipboardService } from '../../common/scratch-pad/global-clipboard.service';
import { SnackBarService, SnackBarMsg } from 'src/app/service/facts/snack-bar.service';
import { Vector3d } from 'src/app/numeric-library/tensor/tensor.module';
import { PhysQty, PhysDim, PhysUnit, PhysCon, PhysQty0 } from 'src/app/numeric-library/bbb/bbb.module';
import { AppInjector } from 'src/app/app-injector';
import { GlobalDigitService } from 'src/app/service/global-vars/global-digit.service';
import { FactoryService } from 'src/app/service/facts/factory.service';
import { Factsier, Factsheet } from 'src/app/service/facts/facts.module';
import { MatDialogRef } from '@angular/material/dialog';
import { PrefixDropListPipe } from 'src/app/pipe/common-pipe/prefix-drop-list.pipe';
import { MonologService } from 'src/app/service/monolog-box/monolog.service';
import { MonologComponent } from 'src/app/service/monolog-box/monolog/monolog.component';


class vectorCompShowHide { // control which components to show/hide for rect/cyl/sph
  x: boolean; // x fpr rectangular
  y: boolean; // y for rectangular
  z: boolean; // z for rectangular and cylindrical
  r: boolean; // r for spherical
  o: boolean; // rho for cylindrical
  t: boolean; // theta for spherical
  p: boolean; // phi for spherical and cylindrical
  // private _gds: GlobalDigitService;

  constructor(sys) {
    this.chg(sys);
    // this._gds= AppInjector.get(GlobalDigitService);
  }

  chg(sys): void {
    if (sys=="s" || sys=="sp" || sys=="sph") { this.x=false;  this.y=false;  this.z=false;  this.r=true;  this.o=false;  this.t=true;  this.p=true;  // spherical
    } else if (sys=="c" || sys=="cy" || sys=="cyl" || sys=="polar" || sys=="p") { this.x=false;  this.y=false;  this.z=true;  this.r=false;  this.o=true;  this.t=false;  this.p=true;  // cylindrical
    } else { this.x=true;  this.y=true;  this.z=true;  this.r=false;  this.o=false;  this.t=false;  this.p=false;  // default rectangular // if (sys=="r" || sys=="rec" || sys=="rect" || sys=="rectangular" || sys=="car" || sys=="ca" ) { // cartesian
    }
  } // end chg
}


@Component({
  selector: 'app-linearalgebra',
  templateUrl: './linearalgebra.component.html',
  styleUrls: ['./linearalgebra.component.scss']
})
export class LinearalgebraComponent implements OnInit {
  readonly angle180 = new PhysQty0( <PhysConShell>{ value: 180, pdimId: 1, system: 'SI', unit: "°", prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: 2, name: 'angle180', nameHtml: 'angle180' } );
  readonly comp7 = [{name: 'r', html:'r'}, {name: 'o', html:'ρ'}, {name: 't', html:'θ'}, {name: 'p', html:'φ'}, {name: 'x', html:'x'}, {name: 'y', html:'y'}, {name: 'z', html:'z'} ];
  readonly comp5names = ['r', 'o', 'x', 'y', 'z'];
  // readonly comp5 = [{name: 'r', html:'r'}, {name: 'o', html:'ρ'}, {name: 'x', html:'x'}, {name: 'y', html:'y'}, {name: 'z', html:'z'} ];
  readonly comp2names = ['p', 't'];
  // readonly comp2 = [{name: 'p', html:'φ'}, {name: 't', html:'θ'} ];
  instructionHtml: string;

  catA: CatDropdownOption[]; // example- { pdimId:8, title="luminous intensity / luminous flux : [Lum Int]", label:"luminous intensity", comp:{M:0, L:0, T:0, t:0, td:0, N:0, I:0, J:0} }
  vecAngUnitA: unitDropdownList[]; // vec1UnitA, vec1UnitA are dynamic, vecAngUnitA is static constant

  get vec1UnitA(): unitDropdownList[] { return this._gpu.physUnitAList4dropdown({pdimId: this.v1cat}); } // using getter will refresher model in ngFor loops etc.
  get vec2UnitA(): unitDropdownList[] { return this._gpu.physUnitAList4dropdown({pdimId: this.v2cat}); } // using getter will refresher model in ngFor loops etc.

  v1cat: number; // essentially dimId/pdimId, if changed, use this to reset vec1, should tracks with vec1.dimId, but needs to exist before vec1 exist, or before vec1 is changed.
  vec1: Vector3d;
  v1showcomps: vectorCompShowHide; // {x: true, y:true, etc}

  v2cat: number; // pdimId, if changed, use this to reset vec2
  vec2: Vector3d;
  v2showcomps: vectorCompShowHide; // {x: true, y:true, etc}

  resultSdHide: boolean; // hide results for sum/difference
  resultProdHide: boolean; // hide results for products
  // resultSVGHide: boolean; // hide SVG results (resultSdHide || resultProdHide)
  cproductNoteHide: boolean; // hide notes for cross products

  figtype: string;


  constructor(private _facts: FactoryService, private _gpu: GlobalPhysUnitsService, private _gcb: GlobalClipboardService, private _sbs: SnackBarService, private _mono: MonologService ) { }

  ngOnInit() {
    // set up factsier/report and factsheet for the reveal module
    this._facts.addFactsier( new Factsier({factsier: {id:4, name:'linearalgebra', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Vectors and Linear Algebra', topicA: ['linearalgebra'] }));
    this._facts.bkmark.factsier = {id:4, name:'linearalgebra'}; // either one should do. name is the overriding criteria
    this._facts.findFactsier({name: 'linearalgebra'}).addFactsheet( new Factsheet({sheet: {id:0, name:'mainVector', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Vector sum and products', topicA: ['linearalgebra'] }));

    this.instructionHtml = "<br><ol><li>Some units are traditionally for scalars (not vectors), such as temperature K. Other units, for example, Joules which is the same as Newton-meter, can be for scalars (energy) or vectors (torque). You need to distinguish such usages clearly yourself.</li><li>You should first choose the category for vectors 1 and 2. The corresponding units and allowed prefixes will be available accordingly.</li><li>You should set the units and the prefixes next (in any coordinate system of your choice) before you enter the desired numerical values. As you are changing the units and prefixes, the corresponding numerical values will change automatically, assuming the physical quantity themselves  are unchanged. Setting the units and prefixes first will save the extra steps.</li><li>For the dropdown options, most browswers will show more info (title) if you click-point-and-wait on the option manual for a second or so.</li></ol>";
    this.vecAngUnitA = this._gpu.physUnitAList4dropdown({pdimId: 1});
    this.catA = this._gpu.physDimAList; // for dropdown manual
    this.catA.shift(); // remove first undefined element
    // this._angCat = 1;

    // these showHide states will be lost, but okay.
    this.resultSdHide = true;
    this.resultProdHide = true;
    // this.resultSVGHide = true;
    this.cproductNoteHide = true;

    // global state var
    const svars = this._gcb.stateCfgs.g('linearalgebra');
    if (svars) {
      this.vec1 = <Vector3d>svars.vars.vec1;
      this.v1showcomps = <vectorCompShowHide>svars.vars.v1showcomps;
      this.vec2 = <Vector3d>svars.vars.vec2;
      this.v2showcomps = <vectorCompShowHide>svars.vars.v2showcomps;

      this.v1cat = this.vec1.r.pdimId;
      this.v2cat = this.vec2.r.pdimId;
    } else {
      this.v1cat = 20; // pdimId = 20; punitId = 42 -force in Newtons
      this.v2cat = 4; // pdimId = 4; punitId = 14 -length in meters

      const v1sys : string = this.vec1UnitA[0]['systemA'][0]; // these are from the dropdown list
      const v1unit : string = this.vec1UnitA[0]['unit']; // these are from the dropdown list
      const v1punitid : number = this.vec1UnitA[0]['punitId']; // these are from the dropdown list
      const v2sys : string = this.vec2UnitA[0]['systemA'][0]; // these are from the dropdown list
      const v2unit : string = this.vec2UnitA[0]['unit']; // these are from the dropdown list
      const v2punitid : number = this.vec2UnitA[0]['punitId']; // these are from the dropdown list

      let x1 = new PhysQty(<PhysConShell>{ value: 1, pdimId: this.v1cat, system: v1sys, unit: v1unit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: v1punitid });
      let y1 = new PhysQty(<PhysConShell>{ value: -2, pdimId: this.v1cat, system: v1sys, unit: v1unit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: v1punitid });
      let z1 = new PhysQty(<PhysConShell>{ value: -1, pdimId: this.v1cat, system: v1sys, unit: v1unit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: v1punitid });
      let x2 = new PhysQty(<PhysConShell>{ value: 2, pdimId: this.v2cat, system: v2sys, unit: v2unit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: v2punitid });
      let y2 = new PhysQty(<PhysConShell>{ value: -2, pdimId: this.v2cat, system: v2sys, unit: v2unit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: v2punitid });
      let z2 = new PhysQty(<PhysConShell>{ value: 0, pdimId: this.v2cat, system: v2sys, unit: v2unit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: v2punitid });
      const v1cosys = 'r';
      const v2cosys = 'r';
      this.vec1 = new Vector3d( { name: 'vec1', nameHtml: '<var class="vector"><i>v</i><span>&#8407;</span></var><sub>1</sub>', cosys: v1cosys, x: x1, y: y1, z: z1 } );
      this.vec2 = new Vector3d( { name: 'vec2', nameHtml: '<var class="vector"><i>v</i><span>&#8407;</span></var><sub>2</sub>', cosys: v2cosys, x: x2, y: y2, z: z2 } );
      // states for showing 3 components at a time between rect / cyl / spherical
      this.v1showcomps = new vectorCompShowHide(v1cosys);
      this.v2showcomps = new vectorCompShowHide(v2cosys);

      this._gcb.stateCfgs.list.push({stateName:'linearalgebra', vars:{ vec1: this.vec1, vec2: this.vec2, v1showcomps: this.v1showcomps, v2showcomps: this.v2showcomps } });
    }





  }

  // readonly pfxDropList = siPrefixes.copylist(); //: SiPrefix[];
  pfxDropList(pq: PhysQty): SiPrefix[] {
    const pipe: PrefixDropListPipe = new PrefixDropListPipe();
    return pipe.transform(pq);
  }

  // sum and products
  // v1p2cat: number; // essentially dimId/pdimId, if changed, use this to reset vec1, should tracks with vec1.dimId, but needs to exist before vec1 exist, or before vec1 is changed.
  // get v1p2(): Vector3d { return this._tos.vadds3d(this.vec1, this.vec2); } // plus
  get v1p2(): Vector3d { return this.vec1.dcopy().adds(this.vec2); } // plus
  // get v1m2(): Vector3d { return this._tos.vadds3d(this.vec1, this.vec2, true); } // minus (differene)
  get v1m2(): Vector3d { return this.vec1.dcopy().adds(this.vec2, true); } // minus (differene)
  // get v1d2(): PhysQty[] { return this._tos.vdot3d(this.vec1, this.vec2); } // dot // return array of [ dot, angle ], dot can be any unit/dimension, while angle is radian
  get v1d2(): PhysQty { return this.vec1.dot(this.vec2); } // dot // return the scalar PhysQty
  get angle12(): PhysQty { return this.vec1.dottheta(this.vec2); }
  // get v1c2(): Vector3d { return this._tos.vcross(this.vec1, this.vec2); } // cross
  get v1c2(): Vector3d { return this.vec1.cross(this.vec2); } // plus
  get v2perp(): PhysQty {
    let res= new PhysQty( new PhysConShell( Object.assign({},this.vec2.r ) ) );
    res.setNewValueSI(res.valueSI * Math.sin(this.angle12.valueSI)); // if error >0, need to set errors too?
    res.name='v2perp';
    res.nameHtml='v_2perp';
    return res;
  }
  get v2para(): PhysQty {
    let res= new PhysQty( new PhysConShell( Object.assign({},this.vec2.r ) ) );
    res.setNewValueSI(res.valueSI * Math.cos(this.angle12.valueSI)); // if error >0, need to set errors too?
    res.name='v2para';
    res.nameHtml='v_2para';
    return res;
  }


  revealInstructions(): MatDialogRef<MonologComponent>  {
    return this._mono.openlog({ override: {title: 'Vector Module Instructions', message: this.instructionHtml } });
  }

  catChg(i: number): void {
    // check if pdimId changed
    let v = this.vec1; // Vector3d
    let c = this.v1cat; // dimId // newly changed
    if (i==2) { v = this.vec2; c = this.v2cat; }
    if (c==v.dimId) return;

    let pu = this._gpu.findPhysUnitA({pdimId: c})[0];
    v.dimId = c;
    // set x,y,z,r,o new units
    this.comp5names.forEach(function(cp){
      // pdimId, punitId, systemA, system, unitA, unit, categoryA, category, unitSI, compDisp, cu2si, cu2siInt, level, prefixes, errors,
      // might need to override unitA[0] from "" to "rad" for angle measures. And category from "dimensionless" to "angle", etc.
      v[cp].update({pdimId: c, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) });
      v[cp].setPrefixVals(0); // set prefixes to 0
      v[cp].setNewValueSI(); // leave numerical SI values unchanged, so that the angles will be preserved
    });
    return;
  }

  unitChg(i: number, comp: string): void {
    // find new PhysUnit / punitId from model
    let v = (i==1)? this.vec1 : this.vec2 ;
    v[comp].setPrefixVals(v[comp].prepower); // in case it's changed
    v[comp].chgSysUnitID(<PhysConShell>{punitId: v[comp]['punitId'] });
    return;
  }
  prefixChg(i: number, comp: string): void {
    let v = (i==1)? this.vec1 : this.vec2 ;
    v[comp].setPrefixVals(v[comp].prepower);
    v[comp].setNewValueSI();
    return;
  }

  valueChg(i: number, comp: string, event): void {
    let v = (i==1)? this.vec1 : this.vec2 ;
    const val: number = Number(event.target.value);
    let sbarMsg: object = null;
    let tmx: object; // theta max is pi
    let pmx: number = 0; // phi min/max is +/- pi
    // for r and o, if < 0, reset to 0, sbar
    if (comp=='r' || comp=='o') {
      if (val<0) {
        event.target.value=0;
        v[comp].value=0;
        this._sbs.now( <SnackBarMsg>{ importance: 7, message: "Component "+comp+" cannot be negative here.", action: "Warning", duration: 3000 });
      }
    } else
    if (comp=='t') { // for t, set sbar
      tmx = this.pivalue(1,i,comp); // multiple 1
      if (val<0) {
        event.target.value=0;
        v.t.setNewValue(0);
        v.t.value = 0; // force refresh display
        this._sbs.now( <SnackBarMsg>{ importance: 7, message: "Angle θ cannot be negative here.", action: "Warning", duration: 3000 });
      } else if (val > tmx['value']) {
        v.t.value = tmx['value']; // force refresh display
        event.target.value=tmx['value'];
        v.t.setNewValue(tmx['value']);
        this._sbs.now( <SnackBarMsg>{ importance: 7, message: "Angle θ cannot be > "+tmx['html']+" here.", action: "Warning", duration: 3000 });
      }
    } else // for p, set sbar
    if (comp=='p') { // for p, set sbar
      tmx = this.pivalue(2,i,comp); // multiple 2
      if (val<0 || val >= tmx['value']) { // should instead use proximity check?
        const nval = this._angleShift(val,v.p.punitId); // use mod (2pi) minus pi (for radians)
        event.target.value=nval;
        v.p.setNewValue(nval);
        v.p.value =nval; // force refresh display
        this._sbs.now( <SnackBarMsg>{ importance: 8, message: "Our choice of φ should be within [0,"+tmx['html']+") here. We replaced your input with the equivalent angle instead.", action: "Warning", duration: 5000 });
      }
    }

    // set all vector comps
    v[comp].setNewValue(event.target.value); // event.target.value might have been fixed in above
    v.setOtherVals(comp);
  }

  chkcomp5(c:string): boolean { return this.comp5names.includes(c); }
  chkcomp2(c:string): boolean { return this.comp2names.includes(c); }

  private _angleShift(ang: number, puid: number): number {
    let tmx: number;
    if (puid==2) { tmx = 360; } else if (puid==3) { tmx = 2; } else { tmx = 2*Math.PI; } // if (puid==1) { tmx = Math.PI; } else
    ang = ang/tmx; // now ang is between 0 and 1
    ang = ((ang%1 + 1)%1)*tmx; // extra +1 to fix negatives
    return ang;
  }

  get resultSVGHide(): boolean { return this.resultSdHide && this.resultProdHide; } // hide SVG results (resultSdHide && resultProdHide)
  toggleCrossProductNote(): void { this.cproductNoteHide = !this.cproductNoteHide; }
  get cProdButtonLabel(): string { return (this.cproductNoteHide)?'Show':'Hide&nbsp;'; }



  vecBinaryOp3d(type: string): void {
    if (type=='s' || type=='sum') {
      if (this.vec1.dimId != this.vec2.dimId) {
        this._sbs.now( <SnackBarMsg> { importance: 10, message: "Cannot add two vectors of different types!", action: "FAIL", duration: 3000 }) ;
        this.resultSdHide = true;
        return;
      }
      this.figtype = 's';
      // temporary message for ongoing work
      this.resultSdHide = false;
      this.resultProdHide = true;
      return;
    }
    if (true || type=='p' || type=='product') {
      this.figtype = 'p';
      this.resultSdHide = true;
      this.resultProdHide = false;
    }
  }

  pivalue(multiple:number, veci:number, comp:string): object {
    // multiple: 0.5, 1 or 2 (pi), veci: 1 or 2, comp: 't' or 'p'
    let res = {value:0, html:''};
    let v = (veci==1)? this.vec1 : this.vec2 ;
    const dg:number = AppInjector.get(GlobalDigitService).gDigit -1;
    const c = v[comp].punitId;  // 1-rad, 2-degree, 3-pi
    if (c==2) { res.value = multiple*180; res.html=(multiple*180).toString(); }
    else if (c==3) { res.value = multiple; res.html=multiple.toString(); }
    else { // if (c==1)
      res.value = Math.round((10**dg)*multiple*Math.PI)/(10**dg); // set values with sig. figs
      if (multiple==0.5) { res.html = 'π/2'; } else if (multiple==2) { res.html = '2π'; } else if (multiple==1) { res.html = 'π'; } else { res.html = '?π'; }
    }
    return res;
  }

  paste2input(vecid:number, comp:string): void {
    let sbarMsg: object = null;
    let spadlen = this._gcb.spad.list.length;
    console.log("paste2input clicked: vecid= "+vecid+" ; comp= "+comp);
    // check clipboard
    if ( spadlen<1 ) {
      this._sbs.now( <SnackBarMsg>{ importance: 10, message: "Clipboard is empty. Nothing to paste.", action: "Error", duration: 4000 });
      return;
    }

    // check compatible category/dimension
    const cb = this._gcb.spad.list[spadlen-1];
    let v = (vecid==1)?this.vec1:this.vec2;
    if (cb.pdimId != v.dimId) {
      this._sbs.now( <SnackBarMsg>{ importance: 10, message: "Clipboard element is not of the same dimenion/category of your vector. Please fix.", action: "Error", duration: 5000 });
      return;
    }

    // right category. Same unit/system chosen? If not, perform internal change.
    // v[comp].setNewValueSI(cb.valueSI); // this does not set uncertainty and other potential misses
    v[comp].update(cb); // = new PhysQty( new PhysConShell(cb));

    this._sbs.now( <SnackBarMsg>{ importance: 4, message: "Paste from clipboard successful. Unit is reset to SI.", action: "Success", duration: 4000 });

    this.valueChg(vecid, comp, {target: {value: cb.value}});
    return;
  }

  addPqty(pq: PhysQty): void {  this._gcb.spad.addPqty(pq);  }

}
