import { Pipe, PipeTransform } from '@angular/core';
import { PhysQty0} from 'src/app/numeric-library/bbb/bbb.module';
import { siPrefixes, SiPrefix } from 'src/app/numeric-library/invariant/global-phys-units.service';

@Pipe({ name: 'pfxDisp' })
export class PfxDispPipe implements PipeTransform {
  transform(pq: any): string {
    if ( (pq instanceof PhysQty0) && pq.hasOwnProperty('prehtml') ) { return pq.prehtml.trim(); }
    if ( pq.type === 'siprefix' ) { return pq.prehtml; }
    let pf:number = 0;
    let prehtml = '';
    if ((pq instanceof PhysQty0) &&  pq.hasOwnProperty('prepower')) { pf = pq.prepower; }
    if ( !isNaN(pq) ) { pf = pq.prepower; }
    siPrefixes.list.forEach(function(p){ if(p.prepower==pf){prehtml=p.prehtml; return; } });
    return prehtml;
  }
}
