import { Pipe, PipeTransform } from '@angular/core';
import { Vector3d } from 'src/app/numeric-library/tensor/tensor.module';
import { ValueDispPipe } from './value-disp.pipe';
import { ValueUnitDispPipe } from './value-unit-disp.pipe';
import { PfxUnitDispPipe } from './pfx-unit-disp.pipe';

@Pipe({ name: 'vec3dDispRect', pure: false }) // impure // Angular only detect object reference, not deep object mutation 
export class Vec3dDispRectPipe implements PipeTransform {

  transform(vec: Vector3d): string {
    if ( vec.x.punitId==vec.y.punitId && vec.y.punitId==vec.z.punitId && vec.x.prepower==vec.y.prepower && vec.y.prepower==vec.z.prepower ) { 
      const vd: ValueDispPipe = new ValueDispPipe();
      const pud: PfxUnitDispPipe = new PfxUnitDispPipe();
      return "("+vd.transform(vec.x)+", "+vd.transform(vec.y)+", "+vd.transform(vec.z)+") "+pud.transform(vec.x); 
    } else {
      const vdu: ValueUnitDispPipe = new ValueUnitDispPipe();
      return "("+vdu.transform(vec.x)+", "+vdu.transform(vec.y)+", "+vdu.transform(vec.z)+")"; 
    }
  }

}
