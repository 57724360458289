import { Pipe, PipeTransform } from '@angular/core';
import { Vector3d } from 'src/app/numeric-library/tensor/tensor.module';
import { Vec3dDispSphPipe } from './vec3d-disp-sph.pipe';
import { Vec3dDispCylPipe } from './vec3d-disp-cyl.pipe';
import { Vec3dDispRectPipe } from './vec3d-disp-rect.pipe';

@Pipe({ name: 'vec3dDisp', pure: false }) // impure // Angular only detect object reference, not deep object mutation 
export class Vec3dDispPipe implements PipeTransform {

  transform(vec: Vector3d, cosys?: string): string {
    if (!cosys) cosys =vec.cosys;
    if (cosys=="s" || cosys=="sp" || cosys=="sph") { const pipe: Vec3dDispSphPipe = new Vec3dDispSphPipe(); return pipe.transform(vec); // spherical
    } else if (cosys=="c" || cosys=="cy" || cosys=="cyl" || cosys=="polar" || cosys=="p") { const pipe: Vec3dDispCylPipe = new Vec3dDispCylPipe(); return pipe.transform(vec); // cylindrical
    } // else { // catch all // default rectangular // if (cosys=="r" || cosys=="rec" || cosys=="rect" || cosys=="rectangular" || cosys=="car" || cosys=="ca" ) { // cartesian
    // }
    const pipe: Vec3dDispRectPipe = new Vec3dDispRectPipe(); 
    return pipe.transform(vec);
  }
}

