import { Injectable } from '@angular/core';
import { Factopia, Bookmark, FactIdty, Factsier, Factsheet } from './facts.module';
import { SnackBarService } from './snack-bar.service';
import { AppInjector } from 'src/app/app-injector';

export interface UsrComForm {
  // readonly type: "usercomform";
  popup?: boolean;
  reveal?: boolean;
  notebook?: FactoryService | Factopia | Factsier | Factsheet;
}

/**
 * For every calculation/computation, each step could add explanation/reveal to a notebook/factopia. 
 * Also snackbarservice might be needed. Including both into this UserCommnunication object.
 */
export class UsrCom {
  popup: boolean;
  reveal: boolean;
  notebook: FactoryService | Factopia | Factsier | Factsheet;
  notepad: Factsheet;
  sbs: SnackBarService;

  constructor( usrcom?: UsrComForm ) { 
    this.sbs = AppInjector.get(SnackBarService);
    // if (reveal && !notebook) { this.notebook = AppInjector.get(FactoryService); }
    if (usrcom && usrcom.reveal) {
      if (!usrcom.notebook) {
        this.notebook = AppInjector.get(FactoryService);
        const nb = this.notebook;
        this.notepad = nb.findFactsier(nb.bkmark.factsier).findFactsheet(nb.bkmark.sheet);
      } else if ( (usrcom.notebook instanceof Factopia) &&  usrcom.notebook.bkmark ) {
        this.notebook = usrcom.notebook;
        const nb = this.notebook;
        this.notepad = nb.findFactsier(nb.bkmark.factsier).findFactsheet(nb.bkmark.sheet);
      } else if ( (usrcom.notebook instanceof Factsier) && usrcom.notebook.bkmark ) {
        this.notebook = usrcom.notebook;
        const nb = this.notebook;
        this.notepad = nb.findFactsheet(nb.bkmark.sheet);
      } else if ( usrcom.notebook instanceof Factsheet) {
        this.notebook = usrcom.notebook;
        this.notepad = this.notebook; // shallow copies
      }
    }
  }
}


let nextId = 1; 
// currently this is set as a singleton only. Can be extended to different users, when say a user is taking a quiz, etc

@Injectable({ providedIn: 'root' })
export class FactoryService extends Factopia {
  // singleton, instance named principia
  private idcnt = nextId++;
  // bkmark: Bookmark;
  // curdossier: number; // factsier id
  // cursheet: number; // factsheet id
  // currow: number; // factlet row id
  // curfactumid: number; // factum id
  // maxlen: number;

  // constructor(input: any, private _gpu: GlobalPhysUnitsService) { 
  constructor() { 
    super({book: {id: 0, name:'physicslandlocal'} , author: {id: 2}, title: 'Physics.Land Principia', topicA: ['unicon', 'kinematics1d', 'linearalgebra'], sysDesc: 'My Physics.Land Solution Manual' });
    this.bkmark = <Bookmark>{ reader: {id:0}, book: {id:0, name:'physicslandlocal'}, factsier: {}, sheet: {}, fact: {} }
    // if (this.idcnt>1) { throw new Error('FactoryService is already loaded. Import it in the AppModule only'); }
    if (this.idcnt>1) { console.warn('FactoryService is already loaded. Import it in the AppModule only');  }

    // add one factsier for each applicable topic
    // will be added indivdually by each component
    // this.factsierA.push( new Factsier({factsier: {id:1, name:'scratch', type: 'notes'}, author: {id: 2, uname: 'system'}, title: 'Scratch paper', topicA: ['scratch'] }));
    // this.factsierA.push( new Factsier({factsier: {id:2, name:'unicon', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Unit Conversions', topicA: ['unicon'] }));
    // this.factsierA.push( new Factsier({factsier: {id:3, name:'kinematics1d', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Kinematics 1D', topicA: ['kinematics1d'] }));
    // this.factsierA.push( new Factsier({factsier: {id:4, name:'linearalgebra', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Vectors and Linear Algebra', topicA: ['linearalgebra'] }));

    console.log("this is FactoryService.factsierA");
    console.log(this.factsierA);
    console.warn(`this is FactoryService idcnt= ${this.idcnt}.`);

  } // end constructor

  setBkMark(search: FactIdty): void {
    let res: Factsier;
    res = this.findFactsier(search);
    if (res) { 
      this.bkmark.factsier.id = res.factsier.id;
      // this.bkmark.
    }
    return
  }

}
