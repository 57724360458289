import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SineCosineLawComponent } from './sine-cosine-law/sine-cosine-law.component';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';
import { FormsModule } from '@angular/forms';
import { FigureSvgModule } from 'src/app/visual/figure-svg/figure-svg.module';
// import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [ SineCosineLawComponent ], //
  imports: [ CommonModule, CommonPipeModule, FormsModule, FigureSvgModule ],
  // imports: [ CommonModule, CommonPipeModule, FormsModule ],
  exports: [ SineCosineLawComponent ], //
  providers: []
})
export class TrigsModule { 
  constructor(@Optional() @SkipSelf() parentModule: TrigsModule) {
    if (parentModule) { throw new Error('UniConModule is already loaded. Import it in the AppModule only'); }
  }

  static forRoot(): ModuleWithProviders {
    // console.log("instantiating TrigsModule.forRoot");
    return {
      ngModule: TrigsModule,
      providers: [ 
        // { provide: GlobalClipboardService, useValue: { cbmax: cbm, pconList: pclist } } // set clipboard/scratchPad max length 
      ]
    };
  }


}
