import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalRevealLevelService } from '../global-vars/global-reveal-level.service';
// import { RevealModule } from './reveal.module'; // this creates circular dependency. Instead of providedIn, now use providers

export interface SnackBarMsg {
  readonly type: 'sbarmsg';
  message: string;
  action: string;
  duration?: number;
  importance?: number;
}

@Injectable()
// @Injectable({ providedIn: RevealModule })
export class SnackBarService {
  sbmsgs: SnackBarMsg[];
  // private _rlevel: number;

  constructor(private _grev: GlobalRevealLevelService, private _msb: MatSnackBar) { 
    // this._rlevel = this._grev.gReveal;
  }

  get rlevel(): number { return this._grev.gReveal; }

  now(inp: SnackBarMsg): void {
    // object: {important<number>, message<string>, action<string>, duration:<number> }
    const impmin = 1; // should be min reveal level (0) + 1
    const impmax = 10; // should be max reveal level (whole 9 yard) + 1
    const durmin = 800;
    const durmax = 6500;
    const durdefault = 2500;
    let imp:number = (inp['importance'] && !isNaN(inp['importance']) ) ? Math.floor(inp['importance']) : impmin;
    if(imp>impmax){imp=impmax;}else if(imp<impmin){imp=impmin;}
    let dur:number = ( !inp['duration'] || isNaN(inp['duration']) ) ? durdefault : Math.floor(inp['duration']) ;
    if(dur<durmin){dur=durmin;}else if(dur>durmax){dur=durmax;}
    const msg = (inp['message'])?inp['message']:''; 
    const act = (inp['action'])?inp['action']:''; 

    if (this.rlevel + imp < impmax || msg==='') return;
    if(dur){this._msb.open(msg,act,{ duration: dur });}else{this._msb.open(msg,act);}
    return;
  }

  serve(msgs?: SnackBarMsg[]): void{
    if (!msgs) { msgs = this.sbmsgs; }
    msgs.forEach(function(m: SnackBarMsg){this.now(m);}.bind(this));
    // this does not actually wait for one message to finish to show the next. 
    // To-be-implemented
  }
}
