import { Pipe, PipeTransform } from '@angular/core';
import { GlobalDigitService } from 'src/app/service/global-vars/global-digit.service';
import { GlobalSigfigService } from 'src/app/service/global-vars/global-sigfig.service';
import { AppInjector } from 'src/app/app-injector';

@Pipe({ name: 'valueRound', pure: false })
export class ValueRoundPipe implements PipeTransform {
  private _gds: GlobalDigitService;
  private _gss: GlobalSigfigService;

  constructor() { 
    this._gds = AppInjector.get(GlobalDigitService);
    this._gss = AppInjector.get(GlobalSigfigService);
  }

  transform(value: number, gdig?: number, gsig?: string ): number {
    if (value===null) return null; 
    if (value==0) return 0; // otherwise it returns NaN
    if (!gdig){gdig=this._gds.gDigit;}else if(gdig>=2&&gdig<=12){gdig=gdig;}else{gdig=(gdig>12)?12:2;}
    if (!gsig){gsig=this._gss.gSigfig;}else if(gsig=='decimals'||gsig=='decimal'||gsig=='deci'||gsig=='d'){gsig='decimals';}else{gsig='sf';} // 'decimals' : 'sig.fig.'
    const abvalue: number = Math.abs(value); 
    const dgs: number = (gsig=='decimals'||gsig=='decimal'||gsig=='deci'||gsig=='d')? gdig : gdig-1-Math.floor(Math.log(abvalue)/Math.LN10);
    const val: number = Number( this.mvdgs( Math.round( parseFloat(this.mvdgs(abvalue,dgs)) ) , -dgs) ) ;
    if (!isNaN(val) && isFinite(val)) { return (value<0)? -val : val; } // otherwise -21.85 rounds to -21.8, but 21.85 rounds to 21.9
    // note some values are small/large but not showing scientific notation. Needs to be transformed in value-disp.pipe, not here.
    console.warn(`ValueRoundPipe failed to round ${value}.`);console.log(value);
    return null;
  }

  mvdgs(val: number, dgs: any): string { 
    dgs = parseInt(dgs); 
    const s: string = val.toString(); 
    let a: string[] = s.split("e"); // move digits
    if (a.length<2) { return val.toString()+'e'+dgs.toString(); } // else assume length == 2
    a[1] = (parseInt(a[1]) + dgs).toString(); return a.join("e");
	}
	
}
