import { Component, OnInit } from '@angular/core';
import { GlobalClipboardService, ScratchPadList } from './global-clipboard.service';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';
import { FactoryService } from 'src/app/service/facts/factory.service';
import { Factsier, Factsheet } from 'src/app/service/facts/facts.module';
// import { PhysCon } from 'src/app/numeric-library/bbb/bbb.module';

@Component({
  selector: 'app-scratch-pad',
  templateUrl: './scratch-pad.component.html',
  styleUrls: ['./scratch-pad.component.scss']
})
export class ScratchPadComponent implements OnInit {
  gspad: ScratchPadList;
  cp: CommonPipeModule;
  absErrHide: boolean;
  compsHide: boolean;
  sysHide: boolean;

  // gconlist: PhysCon[];

  constructor(private _facts: FactoryService, gcb: GlobalClipboardService, cp: CommonPipeModule) { 
    this.gspad = gcb.spad;
    this.cp = cp;
    // this.gconlist = gcb.spad.list;
  }

  ngOnInit() {
    // set up factsier/report and factsheet for the reveal module
    this._facts.addFactsier( new Factsier({factsier: {id:1, name:'scratch', type: 'notes'}, author: {id: 2, uname: 'system'}, title: 'Scratch paper', topicA: ['scratch'] }));
    this._facts.bkmark.factsier = {id:1, name:'scratch'}; // either one should do. name is the overriding criteria
    this._facts.findFactsier({name: 'scratch'}).addFactsheet( new Factsheet({sheet: {id:0, name:'mainScratch', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Scratch paper', topicA: ['scratch'] }));
    this.absErrHide = true;
    this.compsHide = true;
    this.sysHide = false;
  }

  toggleErr(): void { this.absErrHide = !this.absErrHide; }
  toggleComps(): void { this.compsHide = !this.compsHide; }
  toggleSys(): void { this.sysHide = !this.sysHide; }

}
