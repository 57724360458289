import { Pipe, PipeTransform } from '@angular/core';
import { PhysQty } from 'src/app/numeric-library/bbb/bbb.module';
import { PhysConShell } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { ValueDispPipe } from './value-disp.pipe';

@Pipe({ name: 'stdErrRelDisp' })
export class StdErrRelDispPipe implements PipeTransform {

  transform(pq: PhysQty | PhysConShell | number ): string {
    const vd: ValueDispPipe = new ValueDispPipe();
    let result: string = ''; 
    let exact: boolean = true;
    let stderrrel: number = 0;
    if (pq instanceof PhysQty || pq instanceof PhysConShell) {
      exact = (pq.exact === false)?false:true;
      if (!exact) { stderrrel = pq.stdErrRel; }
    } else {
      // assume pq is a number
      exact = (<number>pq > 0)? false : true ;
      if (!exact) { stderrrel = pq; }
    }
    if (exact) { return "(exact)"; } else {
      result = vd.transform(stderrrel*100, 2, 'sig.fig.').toString();
      // let ep:number = result.search('e'); // e-position, change to 10^
      // if (ep>0&&ep<result.length-1) { result=result.substr(0,ep)+"&times;10<sup>"+result.substr(ep+1)+"</sup>";}  
      result = "&plusmn;"+result+"%";
    }
    return result;
  }

}
