import { Component, OnInit } from '@angular/core';
import { GlobalSiteTitleService } from 'src/app/service/global-vars/global-site-title.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  gTitle: string;

  constructor(private site: GlobalSiteTitleService) {
    // this.gTitle = site.gTitle;
   }

  ngOnInit() {
    this.gTitle = this.site.gTitle;
  }

  closeMenu() { document.getElementById('navbarNavDropdown').classList.remove('show'); }
  // the button (w/o id yet) also should have a class attribute of 'collapsed' added. But it will reset itself when menu toggle.

}
