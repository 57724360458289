import { Injectable, Optional } from '@angular/core';

// let nextId = 1; 
// currently this is set as a global variable only. Can be extended to different modules, when say a user is taking a quiz, etc

@Injectable({
  providedIn: 'root'
})
export class GlobalSystemService {
  // idcnt = nextId++;
  gSystem: string;

  constructor() {
    this.gSystem = ( this.gSystem==null || this.gSystem=='' || this.gSystem=='si' || this.gSystem=='S.I.' || this.gSystem=='s.i.' || this.gSystem=='Si' || !this.gSystem) ? 'SI' : this.gSystem ;
  }
  // constructor(@Optional() sys: string ) { 
  //   // forRoot() will overide these... this constructor is not called
  //   this.gSystem = ( sys==null || sys=='' || sys=='si' || sys=='S.I.' || sys=='s.i.' || sys=='Si' || !sys) ? 'SI' : sys ;
  //   // console.log("instantiating GlobalSystemService.");
  //   // console.log("the idcnt = "+ this.idcnt);
  //   // console.log(this);
  // }

  // setSystem(x: string): void { this.gSystem = (x==null||!x||x==''||x=='si'||x=='S.I.'||x=='s.i.'||x=='Si')?'SI':x; }
}
