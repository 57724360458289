import { Pipe, PipeTransform } from '@angular/core';
import { PhysQty0 } from 'src/app/numeric-library/bbb/bbb.module';
import { PhysConShell } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { Vector3d } from 'src/app/numeric-library/tensor/tensor.module';

@Pipe({ name: 'nameHtmlDisp' })
export class NameHtmlDispPipe implements PipeTransform {
  transform(pq: PhysQty0|PhysConShell|string|Vector3d): string {
    if (pq instanceof PhysQty0 || pq instanceof Vector3d || pq instanceof PhysConShell) { return (pq.nameHtml)?pq.nameHtml:pq.name; }
    return pq;
  }

}
