import { Injectable, Optional } from '@angular/core'; 

// let nextId = 1; 
// currently this is set as a global variable only. Can be extended to different modules, when say a user is taking a quiz, etc

@Injectable({
  providedIn: 'root'
})
export class GlobalRevealLevelService {
  // idcnt = nextId++;
  gReveal: number;

  constructor() {
    this.gReveal = (this.gReveal && this.gReveal >=0 && this.gReveal <= 9)? Math.floor(this.gReveal) : 9; 
  }
  // constructor(@Optional() rev: number ) { 
  //   // forRoot() will overide these... this constructor is not called
  //   this.gReveal = (rev && rev >=0 && rev <= 9)? Math.floor(rev) : 9; 
  //   // console.log("instantiating GlobalRevealLevelService.");
  //   // console.log("the idcnt = "+ this.idcnt);
  //   // console.log(this);
  // }

  // setReveal(x: number): void { 
  //   if (x && x >=0 && x <= 9) {this.gReveal = Math.floor(x);}else if(x<0){this.gReveal=0;}else if(x>9){this.gReveal=9;}else{this.gReveal=9;}; 
  // }
}
