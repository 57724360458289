import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TriangleFigureSvgComponent } from './triangle-figure-svg/triangle-figure-svg.component';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';
import { Vec2dSumFigureSvgComponent } from './vec2d-sum-figure-svg/vec2d-sum-figure-svg.component';
import { ParabolaFigureSvgComponent } from './parabola-figure-svg/parabola-figure-svg.component';
// import { FigureSvgService } from './figure-svg.service';
// import { FigureSvg } from './figure-svg';



@NgModule({
  declarations: [ TriangleFigureSvgComponent, Vec2dSumFigureSvgComponent, ParabolaFigureSvgComponent ],
  imports: [ CommonModule, CommonPipeModule ], 
  exports: [ TriangleFigureSvgComponent, Vec2dSumFigureSvgComponent, ParabolaFigureSvgComponent ], // export these so that other modules/components can use them standalone
  providers: []
})
export class FigureSvgModule { }
