import { Pipe, PipeTransform } from '@angular/core';

// capitalize first letter of every word, with the exception of SI. 
@Pipe({ name: 'capWord1L' })
export class CapWord1LPipe implements PipeTransform {
  transform(str: string ): string {
    if (str=='si'||str=='s.i.'||str=='Si'||str=='S.i.'||str=='SI'||str=='S.I.'){ return 'SI'; }
    // all others for now
    let arr: string[] = str.split(' ');
    for (let i:number = 0; i < arr.length; i++) { arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].substring(1); }
    return arr.join(' '); 
  }
}
