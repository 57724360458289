import { Pipe, PipeTransform } from '@angular/core';
import { PhysQty0, PhysQty } from 'src/app/numeric-library/bbb/bbb.module';
import { ValueDispPipe } from './value-disp.pipe';

@Pipe({ name: 'angleDisp', pure: false })
export class AngleDispPipe implements PipeTransform {
  constructor() { }
  transform(pq: PhysQty0, unit: string, gdig?, gsig?): string {
    // only works for angle PhysQtys
    let result: string = '';
    let unitdisp: string = '';
    if (pq.pdimId != 1) { console.warn(`angleDisp can only apply to angle quantities not id= ${pq.pdimId}.`); return result; }
    const vd: ValueDispPipe = new ValueDispPipe() ;
    let val: number;
    if (unit=='radian'||unit=='rad'||unit=='r'||unit==='') { val = pq.valueSI; unitdisp = ' rad'; }
    else if (unit=='degree'||unit=='deg'||unit=='&deg;'||unit=='d'||unit=='°') { val=pq.valueSI*180/Math.PI; unitdisp = '°'; }
    else if (unit=='pi'||unit=='p'||unit=='&pi;'||unit=='π') { val=pq.valueSI/Math.PI ; unitdisp = 'π'; }
    result = vd.transform(val,gdig,gsig);
    return result+unitdisp;
  }

}
