import { Pipe, PipeTransform } from '@angular/core';
import { PhysQty0, PhysQty } from 'src/app/numeric-library/bbb/bbb.module';
import { ValueRoundPipe } from './value-round.pipe';
import { GlobalSigfigService } from 'src/app/service/global-vars/global-sigfig.service';
import { PhysConShell } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { AppInjector } from 'src/app/app-injector';


@Pipe({ name: 'valueDisp', pure: false })
export class ValueDispPipe implements PipeTransform {
  private _gss: GlobalSigfigService;

  constructor() { this._gss = AppInjector.get(GlobalSigfigService); }

  transform(pq: PhysQty0|PhysConShell|number, gdig?: number, gsig?: string ): string {
    const vr: ValueRoundPipe = new ValueRoundPipe() ;
    let result: string = '';
    let res: number;
    const val: number = (pq instanceof PhysQty0 || pq instanceof PhysConShell) ? pq.value : pq;
    if (!gdig || !gsig) { res = vr.transform(val); } else { res = vr.transform(val, gdig, gsig); }
    if ( res === null ) { result == ''; } else 
    if ( res<0 ) { result = '&minus;'+(-res).toString(); } // instead of hyphen, use &minus; 
    else { result = res.toString(); }
    // if (!gdig || !gsig) {
    //   result = (vr.transform(val) ===null) ? '' : vr.transform(val).toString() ;
    // } else {
    //   result = (vr.transform(val, gdig, gsig) ===null) ? '' : vr.transform(val, gdig, gsig).toString() ;
    // }
    // console.log(this);
    // console.log(this._gss);
    const ep:number = result.search('e'); // e-position, change to 10^
    if (ep>0&&ep<result.length-1) { result=result.substr(0,ep)+"&times;10<sup>"+result.substr(ep+1)+"</sup>";} 
    let thisgsig:string = "sig.fig.";
    if (gsig=='decimals'||gsig=='decimal'||gsig=='deci'||gsig=='d') { thisgsig = 'decimals'; }
    else if (gsig=='sig.fig.'||gsig=='sigfig'||gsig=='sf'||gsig=='s') { thisgsig = 'sig.fig.'; }
    else if (this._gss.gSigfig=='decimals'||this._gss.gSigfig=='decimal'||this._gss.gSigfig=='deci'||this._gss.gSigfig=='d') { thisgsig = 'decimals'; }
    if (ep<0 && thisgsig=='sig.fig.') { // no "e" in string. check to see if too small or too large, and sig.fig. setting
      if ( (Math.abs(val)<0.01 && val != 0) || ( Math.abs(val)>= 10000) ) { 
        const pow = Math.floor( Math.log(Math.abs(val))/Math.LN10) ; 
        if (!gdig || !gsig) { res = vr.transform(val*Math.pow(10,-pow)); } else { res = vr.transform(val*Math.pow(10,-pow), gdig, gsig); }
        if ( res === null ) { result == ''; } else 
        if ( res<0 ) { result = '&minus;'+(-res).toString(); } // instead of hyphen, use &minus; 
        else { result = res.toString(); }
        // if (!gdig || !gsig) {
        //   result = (vr.transform(val*Math.pow(10,-pow))===null ) ? '' : vr.transform(val*Math.pow(10,-pow)).toString();
        // } else {
        //   result = (vr.transform(val*Math.pow(10,-pow), gdig, gsig)) ? '' : vr.transform(val*Math.pow(10,-pow), gdig, gsig).toString();
        // }
        result = (result=='')?'':result+"&times;10<sup>"+pow.toString()+"</sup>";
      }
    }
    return result;
  }

}
