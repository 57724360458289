import { Pipe, PipeTransform } from '@angular/core';
import { PhysQty } from 'src/app/numeric-library/bbb/bbb.module';
import { ValueUnitDispPipe } from './value-unit-disp.pipe';
import { StdErrRelDispPipe } from './std-err-rel-disp.pipe';
import { PhysConShell } from 'src/app/numeric-library/invariant/global-phys-units.service';

@Pipe({ name: 'valueStdErrorUnitDisp', pure: false })
export class ValueStdErrorUnitDispPipe implements PipeTransform {
  transform(pq: PhysQty|PhysConShell): string {
    const vudp = new ValueUnitDispPipe();
    const serdp = new StdErrRelDispPipe;
    return vudp.transform(pq)+" "+serdp.transform(pq);
  }

}
