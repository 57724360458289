import { Injectable, Optional } from '@angular/core';
import { PureMathService } from '../operator/pure-math.service';
// import { siPrefixes, SiPrefix } from '../bbb/bbb.module';
// import { PureMathService } from '../operator/pure-math.service';

let nextId = 1;

export interface SiPrefix {
  readonly type: 'siprefix';
	prefix?: string;
	prehtml?: string;
	prepower?: number;
  prename?: string;
  // prelatex?: string;
  // constructor(input:any) { this.prefix = input.prefix; this.prehtml = input.prehtml; this.prepower = input.prepower; this.prename = input.prename; }
}

export const siPrefixes = {
  list : [
    <SiPrefix>{ prefix: "Y", prehtml: "Y", prepower: 24, prename: "yotta"} ,
    <SiPrefix>{ prefix: "Z", prehtml: "Z", prepower: 21, prename: "zetta"} ,
    <SiPrefix>{ prefix: "E", prehtml: "E", prepower: 18, prename: "exa"} ,
    <SiPrefix>{ prefix: "P", prehtml: "P", prepower: 15, prename: "peta"} ,
    <SiPrefix>{ prefix: "T", prehtml: "T", prepower: 12, prename: "tera"} ,
    <SiPrefix>{ prefix: "G", prehtml: "G", prepower: 9,  prename: "giga"} ,
    <SiPrefix>{ prefix: "M", prehtml: "M", prepower: 6,  prename: "mega"} ,
    <SiPrefix>{ prefix: "k", prehtml: "k", prepower: 3,  prename: "kilo"} ,
    <SiPrefix>{ prefix: "h", prehtml: "h", prepower: 2,  prename: "hecto"} ,
    <SiPrefix>{ prefix: "da",prehtml: "da",prepower: 1,  prename: "deka"} ,
    <SiPrefix>{ prefix: "" , prehtml: "" , prepower: 0,  prename: ""} ,
    <SiPrefix>{ prefix: "d", prehtml: "d", prepower: -1, prename: "deci"} ,
    <SiPrefix>{ prefix: "c", prehtml: "c", prepower: -2, prename: "centi"} ,
    <SiPrefix>{ prefix: "m", prehtml: "m", prepower: -3, prename: "milli"} ,
    <SiPrefix>{ prefix:"mu", prehtml: "μ", prepower: -6, prename: "micro"} ,
    //<SiPrefix> prefix:"mu",prehtml:"&mu;",prepower: -6, prename: "micro"} ,
    <SiPrefix>{ prefix: "n", prehtml: "n", prepower: -9, prename: "nano"} ,
    <SiPrefix>{ prefix: "p", prehtml: "p", prepower: -12,prename: "pico"} ,
    <SiPrefix>{ prefix: "f", prehtml: "f", prepower: -15,prename: "femto"} ,
    <SiPrefix>{ prefix: "a", prehtml: "a", prepower: -18,prename: "atto"} ,
    <SiPrefix>{ prefix: "z", prehtml: "z", prepower: -21,prename: "zepto"} ,
    <SiPrefix>{ prefix: "y", prehtml: "y", prepower: -24,prename: "yocto"}
  ],

  the0 : <SiPrefix> { prefix: "" , prehtml: "" , prepower: 0,  prename: ""},

  matchprefix: function(input:any) {
    let pfx = '';
    let result = this.the0;
    if (typeof input === 'string' || input instanceof String) { pfx = <string>(input);}
    else if (input.prefix) { pfx = input.prefix; }
    // else pfx=''

    // check special common conventions
    if (pfx=="micro"||pfx=="&mu;"||pfx=="&#956;"||pfx=="<i>&mu;</i>"||pfx=="<i>&#956;</i>"||pfx=="μ") { pfx="mu"; }
    else if (pfx=="K") { pfx = 'k'; }
    // else if (pfx==null||pfx==0||pfx===" ") { pfx = ''; }

    if (pfx=='') return result;
    this.list.forEach(function(s: SiPrefix){ if (s.prefix === pfx || s.prename===pfx.toLowerCase()) result=s; });
    return result; // default if not found
  },

  matchprepower: function(input:any) {
    let pwr = 0; // default
    let sgn = 1;
    let result = this.the0;
    if ( input.prepower ) { pwr = input.prepower; }
    else { pwr = input; } // assume input is numeric

    if (pwr<4 && pwr>-4) { sgn=(pwr>0)?1:-1; pwr= sgn*Math.floor(Math.abs(pwr)); }
    else if (pwr<25 && pwr>-25) { sgn=(pwr>0)?1:-1; pwr= sgn*3*Math.floor(Math.abs(pwr/3)); } // up to +/- 24
    else { pwr = 0; }
    // else { pwr = 0; }

    if (pwr==0) return result;
    this.list.forEach(function(s: SiPrefix){ if (s.prepower == pwr) result=s; });
    return result; // default if not found
  },

  findSiPrefix: function(input: any) {
    if (input>0||input<0||input===0) { return this.matchprepower(input); }
    else if (input.prepower) { return this.matchprepower(input); }
    else if (typeof input === 'string' || input instanceof String) { return this.matchprefix(input); }
    else if (input.prefix) { return this.matchprefix(input); }
    return this.the0; // default
  },

  findPrepower: function(input: any) { return this.findSiPrefix(input).prepower; },

  copylist: function() { return <SiPrefix[]> [].concat(this.list); }

}

export const physDimCompNames: string[] = ['M','T','L','t','td','N','I','J']; // added td to handle temperature difference. it will have t=1 and td=1 values.
export interface PhysDimCompForm {
  // readonly type: 'physdimcompform';
  M?: number; // M(ass), kg (0 arrows in, 3 out)  need no other to define it, while three others need this to define them
  T?: number; // T(ime), s (0 in, 3 out)
  L?: number; // L(ength), m (0 in, 2 out)
  t?: number; // temperature theta, K (0 in, 0 out)
  td?: number; // temperature difference, Delta theta, K
  N?: number; // # of moles (Avagardo), mol (1 in, 0 out)
  I?: number; // electric current, A (3 in, 0 out)
  J?: number; // luminous intensity, candela cd (3 in, 0 out)
  // dimensional analysis for these SEVEN base units, (https://physics.nist.gov/cuu/Units/units.html)
  // typical variable symbol used: m, t, x (or l,r,y,...), T (or theta), n, i (or I), I_v
  // comp (composition, for dimensional analysis, for example: {M:1,T:1.5,L:-1,t:0,N:0,I:0,J:0} ) // unique, but can have duplicate common names
  // tried restrict to numbers before and use Infinity for underfined. But JSON parse stringify will turn Infinity and NaN into null, which confuses with zeros. Not exactly what we want.

}
export class PhysDimComp {
  M: number=0; // M(ass), kg (0 arrows in, 3 out)  need no other to define it, while three others need this to define them
  T: number=0; // T(ime), s (0 in, 3 out)
  L: number=0; // L(ength), m (0 in, 2 out)
  t: number=0; // temperature theta, K (0 in, 0 out)
  td: number=0; // temperature difference, Delta theta, K
  N: number=0; // # of moles (Avagardo), mol (1 in, 0 out)
  I: number=0; // electric current, A (3 in, 0 out)
  J: number=0; // luminous intensity, candela cd (3 in, 0 out)
  // dimensional analysis for these SEVEN base units, (https://physics.nist.gov/cuu/Units/units.html)
  // typical variable symbol used: m, t, x (or l,r,y,...), T (or theta), n, i (or I), I_v
  // comp (composition, for dimensional analysis, for example: {M:1,T:1.5,L:-1,t:0,N:0,I:0,J:0} ) // unique, but can have duplicate common names
  // tried restrict to numbers before and use Infinity for underfined. But JSON parse stringify will turn Infinity and NaN into null, which confuses with zeros. Not exactly what we want.

  constructor(input: PhysDimCompForm) {
    // input can be ({ T:2 } or { M:0, T:0, L:0, t:0, N:0, I:0, J:0, td:0 })
    physDimCompNames.forEach(function(c: string){ if ( input.hasOwnProperty(c) ) { this[c]=input[c]; } }.bind(this));
  }

  /**
   * Compare the 7 components (ignore td-temperature difference)
   * @remarks
   * @param M not required, but expected to be an integer
   * @param T not required, but expected to be an integer
   * @param L not required, but expected to be an integer
   * @param t not required, but expected to be an integer
   * @param N not required, but expected to be an integer
   * @param I not required, but expected to be an integer
   * @param J not required, but expected to be an integer
   */
  compcomps7(M: any,T: any,L: any,t: any,N: any,I: any,J: any): boolean { return (this.M==M && this.T==T && this.L==L && this.t==t && this.N==N && this.I==I && this.J==J); }

  /**
   * Compare the 7 components (ignore td-temperature difference)
   * @param c
   */
  compcomp7(c: PhysDimComp): boolean { return this.compcomps7(c.M,c.T,c.L,c.t,c.N,c.I,c.J); }

  /**
   * Compare all 8 components separately
   * @param M not required, but expected to be an integer
   * @param T not required, but expected to be an integer
   * @param L not required, but expected to be an integer
   * @param t not required, but expected to be an integer
   * @param td not required, but expected to be an integer
   * @param N not required, but expected to be an integer
   * @param I not required, but expected to be an integer
   * @param J not required, but expected to be an integer
   */
  compcomps(M: any,T: any,L: any,t: any, td: any,N: any,I: any,J: any): boolean { return (this.M==M && this.T==T && this.L==L && this.t==t && this.td==td && this.N==N && this.I==I && this.J==J); }

  /**
   * Compare all 8 components separately
   * @param c
   */
  compcomp(c: PhysDimComp): boolean { return this.compcomps(c.M,c.T,c.L,c.t,c.td,c.N,c.I,c.J); }

  /**
   * Combine t and td as one, then compare all components
   * @param M not required, but expected to be an integer
   * @param T not required, but expected to be an integer
   * @param L not required, but expected to be an integer
   * @param t not required, but expected to be an integer
   * @param td not required, but expected to be an integer
   * @param N not required, but expected to be an integer
   * @param I not required, but expected to be an integer
   * @param J not required, but expected to be an integer
   */
  compcomps1t(M: any,T: any,L: any,t: any, td: any,N: any,I: any,J: any): boolean { return (this.M==M && this.T==T && this.L==L && this.t+this.td==t+td && this.N==N && this.I==I && this.J==J); }

  /**
   * Compare all 8 components separately
   * @param c
   */
  compcomp1t(c: PhysDimComp): boolean { return this.compcomps1t(c.M,c.T,c.L,c.t,c.td,c.N,c.I,c.J); }

  dcopy(): PhysDimComp { return new PhysDimComp( this ); }

} // PhysDimComp

// an impossible combo for undefined dimension/unit
export const physDimCompNull = new PhysDimComp( { M:0.100,T:0.010,L:0.001,t:0.111,td:0.157,N:0.011,I:0.110,J:0.101 } );

// sample output- [ ... , { pdimId: 8, title="luminous intensity | luminous flux : [Lum Int]", category:"luminous intensity", comp:{M:0, L:0, T:0, t:0, td:0, N:0, I:0, J:0}, compDisp: "[Length]/[Time]", unitSI: "m/s" } , ... ]
export class CatDropdownOption { // almost like PhysDim, but needs to be defined here to avoid circular definitions.
  pdimId: number;
  title: string;
  category: string;
  comp: PhysDimComp;
  compDisp: string;
  unitSI: string;

  constructor (input: PhysConShell) {
    this.pdimId = input.pdimId;
    const catA = [ ...input.categoryA ];
    const cind = catA.indexOf("");
    if ( cind > -1 ) { catA[cind] = "dimensionless"; }
    this.title = catA.join(' | ');
    this.title += (input.pdimId>1)?' : '+ input.compDisp : '' ;
    this.category = catA[0];
    this.unitSI = input.unitSI;
    this.compDisp = input.compDisp
    this.comp = input.comp;
  }

}


export class PhysConShell {
  // a shell class to include the highest class properties, for creating mockup global lists (physDims, physUnits, physCons, physQtys)
  private readonly _objkeys: string[] = ['pdimId', 'comp', 'categoryA', 'compDisp', 'unitSI', 'punitId',  'cu2si',  'cu2siInt',  'level', 'systemA', 'unitA', 'name', 'nameHtml', 'value', 'valueSI', 'system', 'category', 'unit', 'prefix', 'prename', 'prepower', 'prehtml', 'exact', 'stdErr', 'stdErrSI', 'stdErrRel', 'valueMin', 'valueMax', 'valueMinSI', 'valueMaxSI', 'pconID', 'desc', 'source', 'sourceUrl', 'rtvdate', 'topicA', 'altPunitA', 'qid', 'cbid' ];

  // PhysDim
  pdimId: number; // for use in global PhysDiims // -1 reserved for unknown, when two multiplied together, for example. 0 is for undefined. (JSON stringify does not handle undefined or Infinity well.) 1 is for radian.
  comp: PhysDimComp; // {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0} // 7 base dimensions/components
  categoryA: string[];
  compDisp: string;
  unitSI: string;
  // PhysUnit
  punitId: number; // for use in global PhysUnits// -1 reserved for unknown,  (JSON stringify does not handle undefined or Infinity well.) 1 is for radian.
  cu2si: number; // conversion to SI unit, scale factor
  cu2siInt: number; // conversion to SI unit, y-intercept, for Celsius as an example. Note that temperature change should not use the y-intercept
  level: number;
  systemA: string[];
  unitA: string[]; // all the units in unitA must have the same cu2si (convert-unit-to-si) value.
  // PhysQty0
  // pqtyID: number; // for use in scratchPad and other local lists
  name: string;
  nameHtml: string;
  value: number;
  valueSI: number;
  system: string;
  category: string;
  unit: string;
  prefix: string;
  prename: string;
  prepower: number;
  prehtml: string;
  // PhysQty
  exact: boolean;
  stdErr: number;
  stdErrSI: number;
  stdErrRel: number;
  valueMin: number;
  valueMax: number;
  valueMinSI: number;
  valueMaxSI: number;
  // PhysCon
  pconID: number;
  desc: string;
  source: string;
  sourceUrl: string;
  rtvdate: string;
  topicA: string[];
  altPunitA: object[]; // for physical constants to know what other possible units to change to
  // clipboard PhysQty
  qid: number; // quantity id
  cbid: number; // clipboard id

  constructor (input: object) {
    // loop thru intersection of property list
    this.merge(input);
    this._setUnitSI(); // and compDisp
  }

  merge(input: object):void {
    // special handling order: compcomp, object[], Array, primitives
    this._objkeys.filter(x => Object.keys(input).includes(x)).forEach(function (key: string) {
      if (key === 'comp') {
        if (!this[key] || this.comp.compcomp(<PhysDimComp>input['comp'])) { this[key] = new PhysDimComp(input[key]); }
      } else if (key === 'altPunitA') {
        if (!this[key]) { this[key] = JSON.parse(JSON.stringify(input[key])); }
      } else if (this[key] instanceof Array) {
        if (!this[key] || this[key].length==0) { this[key] = [].concat(input[key]); }
      } else {
        if (!this[key] || this[key] != input[key]) { this[key] = input[key]; }
      };
    }.bind(this));
  }

  // duplicate from PhysDim, get compsum and setUnitSI()
  get compsum(): number { let sum: number=0; physDimCompNames.forEach(function(v:string){sum+=(this.comp)?Math.abs(this.comp[v]):NaN;}.bind(this)); return (isNaN(sum) || !isFinite(sum)) ? Infinity : sum; }

  private _setUnitSI(): void {  // duplicate from PhysDim
    // set this.unitSI and this.compDisp
    let denom: string =""; let numerator: string =""; let uname: string ="";
    let tmp: number = 0;
    let cdenom: string =""; let cnumerator: string =""; let cname: string ="";
    if ( !(PureMathService.isNumeric(this.compsum))) { this.unitSI = "undefined"; this.compDisp = "undefined"; return; }
    physDimCompNames.forEach(function(t){
      if(t=="M"){uname="kg";cname="[Mass]";}else
      if(t=="T"){uname="s";cname="[Time]"}else
      if(t=="L"){uname="m";cname="[Length]"}else
      if(t=="t"||t=="theta"||t=="td"){uname="K";cname="[Temp]"}else
      if(t=="N"){uname="mol";cname="[Mol]"}else
      if(t=="I"){uname="A";cname="[Current]"}else
      if(t=="J"){uname="cd";cname="[Lum Int]"}
      tmp = this.comp[t];
      if (tmp==1){numerator += uname +" ";cnumerator += cname +" ";}else
      if(tmp>0){numerator += uname+"^"+tmp+" ";cnumerator += cname+"^"+tmp+" ";}else
      if(tmp== -1){denom += uname+" ";cdenom += cname+" ";}else
      if(tmp<0){denom += uname+"^"+(-tmp)+" ";cdenom += cname+"^"+(-tmp)+" ";}
    }.bind(this));
    if(denom=="" && numerator=="") { this.unitSI = ""; this.compDisp = ""; return; }
    denom = denom.trim(); numerator = numerator.trim(); cdenom = cdenom.trim(); cnumerator = cnumerator.trim();
    if(denom=="") { this.unitSI = numerator; this.compDisp = cnumerator; return; }
    if(numerator=="") { this.unitSI = "1 / "+denom; this.compDisp = "1 / "+cdenom; return; }
    this.unitSI = numerator+" / "+denom;
    this.compDisp = cnumerator+" / "+cdenom;
    return;
  } // end this.unitsi

  dcopy(): PhysConShell {return new PhysConShell( { ...this } ); }

}

class PhysDimList {
  // collection of physical Qtys, can be just Dims, Units, Qtys (scratchpad), Physical Constants
  list: PhysConShell[];

  constructor() { this.list = []; }

  addPhysDim(pd: PhysConShell): void {
    // pd: Pdim Object // check duplicate, then update
    // if ( !(pd instanceof PhysConShell) ) return;
    let match: number = 0;
    // exception: allow two difference temperature entries since they have different convert-2-si-intercept values, cannot be converted amoung themselves.
    this.list.forEach(function(d){ if( d.comp.compcomp(pd.comp) ){ match++; console.warn("Duplicate Unit (same components) found."); console.log(d); console.log(pd); }}); // no need to bind this
    if (match==0) { this.list.push(new PhysConShell(Object.assign({},pd))); }
    // else try merge?
  }

  /**
   * @param o can be ({ T:2 } or { M:0, T:0, L:0, t:0, N:0, I:0, J:0 }) or {category: "angle"} or {categoryA: ["angle","dimensionless"]} or  {pdimId: n} in this order of search
   */
  matchPhysDimA(o: object): PhysConShell[] {
    // o can be ({ T:2 } or { M:0, T:0, L:0, t:0, N:0, I:0, J:0 }) or {category: "angle"} or {categoryA: ["angle","dimensionless"]} or  {pdimId: n} in this order of search
    let pda: PhysConShell[] = [];
    let ocomp: PhysDimComp = new PhysDimComp( {M:0} );
    if ( o.hasOwnProperty('comp') || o.hasOwnProperty('M') || o.hasOwnProperty('T') || o.hasOwnProperty('L') || o.hasOwnProperty('t') || o.hasOwnProperty('N') || o.hasOwnProperty('I') || o.hasOwnProperty('J') || o.hasOwnProperty('td') ) {
      ocomp = ( o.hasOwnProperty('comp') ) ? new PhysDimComp(o['comp']) : new PhysDimComp(o);
      this.list.forEach(function(p: PhysConShell){
        if(ocomp.compcomp(p.comp)){
          // // add to array unless it is temperature vs temperature difference
          // if (ocomp.compcomps(0,0,0,1,0,0,0)) {
          // // need to know more, if it is for temperature or temperature difference. default: temperature
          //   // strategy: add pdimId==5 first, assuming it loops thru first, then remove immediately after if add pdimId==6 instead
          //   // otherwise, difficult to check the conditions where some category is given, some are null, etc
          //   if (p.pdimId==5) { pda.push(p); } else
          //   if (p.pdimId==6 && (o['pdimId']==6||o['category']=="temperature difference"|| (o['categoryA'] && o['categoryA'][0]=="temperature difference")) ){pda.pop(); pda.push(p);}
          // } else {
          //   pda.push(p);
          // }
          pda.push(p.dcopy());
        }
      });
      if (pda.length==0) { pda.push( new PhysConShell({ pdimId: -1, comp: ocomp, categoryA: ["not in system"] }) );}
    } else // next o does not have comp or M/T/L/t/N/I/J components, try category
    if (o.hasOwnProperty('category') || o.hasOwnProperty('categoryA')) {
      if (o.hasOwnProperty('category')) {
        this.list.forEach(function(p: PhysConShell){
          if ( ( p.categoryA && p.categoryA.includes(o['category']) ) || p.category === (o['category']) ) {
            pda.push(p.dcopy());
          }
        });
      } else
      if (o.hasOwnProperty('categoryA')) {
        this.list.forEach(function(p: PhysConShell){
          if ( o['categoryA'].every( (val:string) => p.categoryA.includes(val) ) ) {
            pda.push(p.dcopy());
          }
        });
      }
    } else
    if (o.hasOwnProperty('pdimId') && !isNaN(o['pdimId']) && isFinite(o['pdimId']) ) { // lastly, o does not have comp or M/T/L/t/N/I/J components, nor category, rely on pdimId
      let id = Math.floor(o['pdimId']);
      this.list.forEach(function(p: PhysConShell){ if ( p.hasOwnProperty('pdimId') && p['pdimId'] == id) { pda.push(p.dcopy()); } });
    }
    // the last two scenarios, if no result, push pdimId=0
    if (pda.length==0) { pda.push( new PhysConShell({pdimId:0,comp: physDimCompNull,categoryA:["undefined"]}) );}
    return pda;
  }

  matchPhysDim(o: object): PhysConShell {
    const t: PhysConShell[] = this.matchPhysDimA(o);
    if (t.length>1) { console.warn("matchPhysDim resulted in "+ t.length +" matches. First one reported."); }
    return t[0];
  }

  matchPhysDimId(o: object): number { return this.matchPhysDim(o).pdimId; }

} // end PhysDimList

class PhysUnitList extends PhysDimList {
  // collection of physical Units,

  constructor() { super(); }

  // // -----------------  PhysUnits   --------------------- // //
  addPhysUnit(pu: object, @Optional() lookuplist: PhysDimList = null): void {
    // typical use case, lookuplist is physDims, look up pdimId match, if pu does not specify all the info (pdimId, comp, categoryA)
    // pu: Punit Object // neeed to check duplicate, then update
    let toappend: PhysConShell = new PhysConShell({});
    if (lookuplist) {
      // assume pu has at least one of the four properties: pdimId, comp, category, categoryA
      let tolookup: object = {};
      if (pu.hasOwnProperty('pdimId') && pu['pdimId'] != null ) { Object.assign(tolookup, { pdimId: pu['pdimId'] }); }
      if (pu.hasOwnProperty('comp') && pu['comp'] != null ) { Object.assign(tolookup, { comp: pu['comp'] }); }
      if (pu.hasOwnProperty('category') && (pu['category'] != null || pu['category'] ==="") ) { Object.assign(tolookup, { category: pu['category'] }); }
      if (pu.hasOwnProperty('categoryA') && pu['categoryA'].length > 0 ) { Object.assign(tolookup, { categoryA: [].concat(pu['categoryA']) }); }
      toappend = lookuplist.matchPhysDim( tolookup );
    }
    this.list.push( new PhysConShell(Object.assign({},pu,toappend)) ); // use own judgement for now. No checks. // duplicate an indept copy of pu
    // this.cid++;
  }

  matchPhysdimId(o: object): PhysConShell[] {
    // typical usage: physUnits.matchPhysdimId( some pdimId or components )
    // return array of PhysUnits with matched id/components, if o.system is given, find lowest level
    // const sys: string = (o.hasOwnProperty('system') && o['system']) ? o['system'] : "SI";
    // let oid: number = this.matchPhysDimId(o);
    // let res: PhysConShell[];
    // let minLevel: number = Infinity;
    // if (oid == null || oid<0 ) { return res; }
    // // if (oid == null || oid<0 || oid >= pdims.list.length) { return res; }
    // this.list.forEach(function(pu: PhysConShell){
    //   if (pu.pdimId == oid) {
    //     if (pu.systemA.includes(sys) && pu.level < minLevel ) {
    //       res.unshift( JSON.parse(JSON.stringify( pu )) ); minLevel = pu.level;
    //     } else {
    //       res.push( JSON.parse(JSON.stringify( pu )) );
    //     } // first one will always be the lowest level unit (fundamental) within o.system
    //   };
    // });
    // if (res.length == 0) { console.warn("matchPhysdimId arr length 0."); console.log(o); }
    return this.matchPhysDimA(o);
  }

  matchPhysUnitA(o: object): PhysConShell[] {
    // if punitId is given, find the matching element (unique)
    // instead of punitId, typical althernative would be category+system+unit, while category can be swapped with pdimId/comp, usually an unique find
    // if only (pdimId/comp/category/categoryA) combo is given, then an array should results, for change of units for example.
    // in that case, will set the level 0 (or lowest) element as first element of array as default.

    let pua: PhysConShell[] = [];
    // let ocomp: PhysDimComp = new PhysDimComp( {M:0} );
    let minLevel: number = Infinity;

    if (o.hasOwnProperty('punitId') && !isNaN(o['punitId']) && isFinite(o['punitId']) && o['punitId']>0) {
      let id = Math.floor(o['punitId']);
      this.list.forEach(function(p: PhysConShell){
        if ( p.hasOwnProperty('punitId') && p['punitId'] == id) {
          if (p.level < minLevel ) { pua.unshift(p); minLevel = p.level;} else { pua.push(p.dcopy()); }
        }
      });
      if (pua.length==0) {
        pua.push( new PhysConShell( Object.assign({},{ punitId: 0, systemA: ["undefined"], unitA: ["undefined"],level:0,cu2si:1,cu2siInt:0, pdimId:0,comp: physDimCompNull,categoryA:["undefined"]}) ) );
      }
    } else { // no punitId. given either category/pdimId/comp + system + unit (for now, only works if unit is exactly as filed.)
      // do both with and without system+unit at the same time.
      let unitchked: boolean = (o.hasOwnProperty('system') && o['system'] !=null && o.hasOwnProperty('unit') && (o['unit']===""||o['unit'] !=null))?false:true; // true means checked okay, which actually is "no need to check"
      if ( o.hasOwnProperty('comp') ) {
        let ocomp = new PhysDimComp(o['comp']);
        this.list.forEach(function(p: PhysConShell){
          if( (unitchked || this.matchSysUnit(p,o)) && ocomp.compcomp(p.comp)){
            if (p.level < minLevel ) { pua.unshift(p); minLevel = p.level;} else { pua.push(p.dcopy()); }
            // add to array unless it is temperature vs temperature difference
            // if (ocomp.compcomps(0,0,0,1,0,0,0)) {
            //   // same logic as PhysDimList.matchPhysDimA case
            //   if (p.pdimId==5) {
            //     pua.push(p.dcopy());  // special case, assume push or unshift the same, as no other units available. assume first one will always be lowest here for temperature
            //   } else
            //   if (p.pdimId==6 && (o['pdimId']==6||o['category']=="temperature difference"|| (o['categoryA'] && o['categoryA'][0]=="temperature difference") )){
            //     pua.pop();
            //     pua.push(p.dcopy());
            //   }
            // } else {
            //   if (p.level < minLevel ) { pua.unshift(p); minLevel = p.level;} else { pua.push(p.dcopy()); }
            // }
          }
        }.bind(this));
        if (pua.length==0) {
          pua.push( new PhysConShell( { punitId: -1, systemA: ["undefined"], unitA: ["undefined"],level:0,cu2si:1,cu2siInt:0, pdimId:-1,comp: ocomp,categoryA:["not in system"]}) );
        }
      } else // next o does not have comp or M/T/L/t/N/I/J components, try category
      if (o.hasOwnProperty('category') || o.hasOwnProperty('categoryA')) {
        if (o.hasOwnProperty('category')) {
          this.list.forEach(function(p: PhysConShell){
            if ( (unitchked || this.matchSysUnit(p,o)) && (( p.categoryA && p.categoryA.includes(o['category']) ) || p.category === (o['category']) ) ) {
              if (p.level < minLevel ) { pua.unshift(p); minLevel = p.level;} else { pua.push(p.dcopy()); }
            }
          }.bind(this));
        } else
        if (o.hasOwnProperty('categoryA')) {
          this.list.forEach(function(p: PhysConShell){
            if ( (unitchked || this.matchSysUnit(p,o)) && o['categoryA'].every( (val:string) => p.categoryA.includes(val) ) ) {
              if (p.level < minLevel ) { pua.unshift(p); minLevel = p.level;} else { pua.push(p.dcopy()); }
            }
          }.bind(this));
        }
      } else
      if (o.hasOwnProperty('pdimId') && !isNaN(o['pdimId']) && isFinite(o['pdimId']) ) { // lastly, o does not have comp or M/T/L/t/N/I/J components, nor category, rely on pdimId
        let id = Math.floor(o['pdimId']);
        this.list.forEach(function(p: PhysConShell){
          if ( (unitchked || this.matchSysUnit(p,o)) && p.hasOwnProperty('pdimId') && p['pdimId'] == id) {
            if (p.level < minLevel ) { pua.unshift(p); minLevel = p.level;} else { pua.push(p.dcopy()); }
          }
        }.bind(this));
      }
    } // end case for: no punitId. only given category/pdimId/comp + possibly system+unit
    // the last two scenarios, if no result, push punitId=0
    if (pua.length==0) { pua.push( new PhysConShell(
      Object.assign({},{ punitId: 0, systemA: ["undefined"], unitA: ["undefined"],level:0,cu2si:1,cu2siInt:0, pdimId:0,comp: physDimCompNull,categoryA:["undefined"]}) ) );}
    return pua;
  } // end matchPhysUnitA(o)

  matchPhysUnit(o: object): PhysConShell {
    const t: PhysConShell[] = this.matchPhysUnitA(o);
    if (t.length>1) { console.warn(`findPhysUnit resulted in ${t.length} matches. First one reported.`); }
    return t[0];
  }
  matchPhysUnitID(o: object): number { return this.matchPhysUnit(o).punitId; }

  matchSysUnit(p: PhysConShell, o: object): boolean {
    return ( p.hasOwnProperty('systemA') && p.systemA.includes(o['system']) && p.hasOwnProperty('unitA') && p.unitA.includes(o['unit']) ) ? true : false;
  }

} // end PhysUnitList

export interface unitDropdownList {
  readonly type: 'unitDropdownList';
  pdimId: number;
  punitId: number;
  systemA: string[];
  title: string;
  unit: string;
}


@Injectable({ providedIn: 'root' })
export class GlobalPhysUnitsService {
  private idcnt = nextId++;
  physDims: PhysDimList; // just list of PhysConShell, no structure, eventually from DB
  physUnits: PhysUnitList; // just list of PhysConShell, no structure, eventually from DB

  constructor() {
    // if (this.idcnt>1) { throw new Error('GlobalPhysUnitsService is already loaded. Import it in the AppModule only'); }
    if (this.idcnt>1) { console.warn('GlobalPhysUnitsService is already loaded. Import it in the AppModule only');  }
    this.physDims = new PhysDimList();
    // this.physDims.addPhysDim( new PhysConShell( { pdimId:-1, comp: anything, categoryA: ["not in system"] }) );
    // below could be implemented from backend DB
    // Basic seven + 2
    this.physDims.addPhysDim( new PhysConShell( { pdimId:0, comp: physDimCompNull, categoryA: ["undefined"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:1, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, categoryA: ["scalar","dimensionless","angle"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:2, comp: {M:1,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, categoryA: ["mass"] }) ); // tricky, mass is the only SI unit with prefix in the standard base unit.
    this.physDims.addPhysDim( new PhysConShell( { pdimId:3, comp: {M:0,T:1,L:0,t:0,N:0,I:0,J:0,td:0}, categoryA: ["time"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:4, comp: {M:0,T:0,L:1,t:0,N:0,I:0,J:0,td:0}, categoryA: ["length"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:5, comp: {M:0,T:0,L:0,t:1,N:0,I:0,J:0,td:0}, categoryA: ["temperature"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:6, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:1}, categoryA: ["temperature difference"] }) );
    // temperature and temp difference have different convert-2-si-intercept values, cannot be converted between them. This is an exceptional case
    this.physDims.addPhysDim( new PhysConShell( { pdimId:7, comp: {M:0,T:0,L:0,t:0,N:1,I:0,J:0,td:0}, categoryA: ["mole"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:8, comp: {M:0,T:0,L:0,t:0,N:0,I:1,J:0,td:0}, categoryA: ["current"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:9, comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:1,td:0}, categoryA: ["luminous intensity","luminous flux"] }) );
    // kinetmatics
    this.physDims.addPhysDim( new PhysConShell( { pdimId:10, comp: {M:0,T:-1,L:1,t:0,N:0,I:0,J:0,td:0}, categoryA: ["velocity","speed"] }) ); // unit for special physical constants
    this.physDims.addPhysDim( new PhysConShell( { pdimId:11, comp: {M:0,T:-2,L:1,t:0,N:0,I:0,J:0,td:0}, categoryA: ["acceleration"] }) ); // unit for special physical constants
    this.physDims.addPhysDim( new PhysConShell( { pdimId:12, comp: {M:0,T:-3,L:1,t:0,N:0,I:0,J:0,td:0}, categoryA: ["jerk"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:13, comp: {M:0,T:0,L:2,t:0,N:0,I:0,J:0,td:0}, categoryA: ["area"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:14, comp: {M:0,T:0,L:3,t:0,N:0,I:0,J:0,td:0}, categoryA: ["volume"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:15, comp: {M:-1,T:0,L:3,t:0,N:0,I:0,J:0,td:0}, categoryA: ["specific volume"] }) );
    // material property
    this.physDims.addPhysDim( new PhysConShell( { pdimId:16, comp: {M:1,T:0,L:-3,t:0,N:0,I:0,J:0,td:0}, categoryA: ["mass density"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:17, comp: {M:1,T:0,L:-2,t:0,N:0,I:0,J:0,td:0}, categoryA: ["surface mass density"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:18, comp: {M:1,T:0,L:-1,t:0,N:0,I:0,J:0,td:0}, categoryA: ["linear mass density"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:19, comp: {M:0,T:0,L:-3,t:0,N:1,I:0,J:0,td:0}, categoryA: ["amount of substance concentration"] }) );
    // Energy and Newton
    this.physDims.addPhysDim( new PhysConShell( { pdimId:20, comp: {M:1,T:-2,L:1,t:0,N:0,I:0,J:0,td:0}, categoryA: ["force"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:21, comp: {M:1,T:-2,L:2,t:0,N:0,I:0,J:0,td:0}, categoryA: ["energy","torque","work","heat","moment of force"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:22, comp: {M:1,T:-1,L:1,t:0,N:0,I:0,J:0,td:0}, categoryA: ["momentum","impulse"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:23, comp: {M:1,T:-3,L:2,t:0,N:0,I:0,J:0,td:0}, categoryA: ["power","radiant intensity"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:24, comp: {M:1,T:-3,L:0,t:0,N:0,I:0,J:0,td:0}, categoryA: ["intensity","radiance","heat flux density","irradiance"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:25, comp: {M:1,T:-2,L:-1,t:0,N:0,I:0,J:0,td:0}, categoryA: ["pressure","stress","energy density"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:26, comp: {M:1,T:-2,L:0,t:0,N:0,I:0,J:0,td:0}, categoryA: ["spring constant","surface tension"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:27, comp: {M:1,T:-1,L:-1,t:0,N:0,I:0,J:0,td:0}, categoryA: ["dynamic viscosity"] }) );
    // wave
    this.physDims.addPhysDim( new PhysConShell( { pdimId:28, comp: {M:0,T:0,L:-1,t:0,N:0,I:0,J:0,td:0}, categoryA: ["wave number"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:29, comp: {M:0,T:-1,L:0,t:0,N:0,I:0,J:0,td:0}, categoryA: ["frequency","angular freqnency","angular velocity","angular speed","radioactive decay activity"] }) ); // "becquerel" for radio-activity
    this.physDims.addPhysDim( new PhysConShell( { pdimId:30, comp: {M:0,T:-2,L:0,t:0,N:0,I:0,J:0,td:0}, categoryA: ["angular accleration"] }) );
    // EM
    this.physDims.addPhysDim( new PhysConShell( { pdimId:31, comp: {M:0,T:1,L:0,t:0,N:0,I:1,J:0,td:0}, categoryA: ["electric charge"] }) );  // unit for physical constants e
    this.physDims.addPhysDim( new PhysConShell( { pdimId:32, comp: {M:0,T:1,L:-3,t:0,N:0,I:1,J:0,td:0}, categoryA: ["electric charge density"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:33, comp: {M:0,T:1,L:-2,t:0,N:0,I:1,J:0,td:0}, categoryA: ["surface charge density","electric flux density"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:34, comp: {M:0,T:1,L:-1,t:0,N:0,I:1,J:0,td:0}, categoryA: ["linear charge density"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:35, comp: {M:1,T:-3,L:2,t:0,N:0,I:-1,J:0,td:0}, categoryA: ["electric potential","electromotive force"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:36, comp: {M:1,T:-3,L:1,t:0,N:0,I:-1,J:0,td:0}, categoryA: ["electric field"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:37, comp: {M:-1,T:4,L:-2,t:0,N:0,I:2,J:0,td:0}, categoryA: ["capacitance"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:38, comp: {M:0,T:0,L:-2,t:0,N:0,I:1,J:0,td:0}, categoryA: ["current density"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:39, comp: {M:1,T:-2,L:0,t:0,N:0,I:-1,J:0,td:0}, categoryA: ["magnetic field","magnetic flux density"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:40, comp: {M:1,T:-2,L:2,t:0,N:0,I:-1,J:0,td:0}, categoryA: ["magnetic flux"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:41, comp: {M:1,T:-3,L:2,t:0,N:0,I:-2,J:0,td:0}, categoryA: ["electric resistance","electrical resistance"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:42, comp: {M:1,T:-3,L:3,t:0,N:0,I:-2,J:0,td:0}, categoryA: ["electric resistivity","electrical resistivity"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:43, comp: {M:-1,T:3,L:-2,t:0,N:0,I:2,J:0,td:0}, categoryA: ["electric conductance","electrical conductance"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:44, comp: {M:-1,T:3,L:-3,t:0,N:0,I:2,J:0,td:0}, categoryA: ["electric conductivity","electrical conductivity"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:45, comp: {M:1,T:-2,L:2,t:0,N:0,I:-2,J:0,td:0}, categoryA: ["inductance"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:46, comp: {M:-1,T:4,L:-3,t:0,N:0,I:2,J:0,td:0}, categoryA: ["permittivity"] }) ); // unit for special physical constant epsilon_0
    this.physDims.addPhysDim( new PhysConShell( { pdimId:47, comp: {M:1,T:-2,L:1,t:0,N:0,I:-2,J:0,td:0}, categoryA: ["permeability"] }) ); // unit for special physical constant mu_0
    // thermal
    this.physDims.addPhysDim( new PhysConShell( { pdimId:48, comp: {M:1,T:-3,L:1,t:-1,N:0,I:0,J:0,td:0}, categoryA: ["thermal conductivity"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:49, comp: {M:1,T:-2,L:2,t:0,N:-1,I:0,J:0,td:0}, categoryA: ["molar energy"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:50, comp: {M:1,T:-2,L:2,t:-1,N:-1,I:0,J:0,td:0}, categoryA: ["molar entropy","molar heat capacity"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:51, comp: {M:1,T:-2,L:2,t:-1,N:0,I:0,J:0,td:0}, categoryA: ["heat capacity","entropy"] }) ); // unit for special physical constant Boltzman k
    this.physDims.addPhysDim( new PhysConShell( { pdimId:52, comp: {M:0,T:-2,L:2,t:-1,N:0,I:0,J:0,td:0}, categoryA: ["specific heat capacity","specific entropy"] }) );
    // radiation, radioactivity
    this.physDims.addPhysDim( new PhysConShell( { pdimId:53, comp: {M:0,T:0,L:-2,t:0,N:0,I:0,J:1,td:0}, categoryA: ["luminance","illuminance"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:54, comp: {M:0,T:-2,L:2,t:0,N:0,I:0,J:0,td:0}, categoryA: ["absorbed dose","specific energy","kerma","dose equivalent"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:55, comp: {M:0,T:-3,L:2,t:0,N:0,I:0,J:0,td:0}, categoryA: ["absorbed dose rate"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:56, comp: {M:0,T:-1,L:0,t:0,N:1,I:0,J:0,td:0}, categoryA: ["catalytic activity"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:57, comp: {M:-1,T:1,L:0,t:0,N:0,I:1,J:0,td:0}, categoryA: ["exposure"] }) );
    this.physDims.addPhysDim( new PhysConShell( { pdimId:58, comp: {M:0,T:-1,L:-3,t:0,N:1,I:0,J:0,td:0}, categoryA: ["catalytic activity concentration"] }) );
    // special physical constants
    this.physDims.addPhysDim( new PhysConShell( { pdimId:59, comp: {M:-1,T:-2,L:3,t:0,N:0,I:0,J:0,td:0}, categoryA: ["newton's g"] }) ); //
    this.physDims.addPhysDim( new PhysConShell( { pdimId:60, comp: {M:1,T:-1,L:2,t:0,N:0,I:0,J:0,td:0}, categoryA: ["action","planck constant"] }) ); // planck constant, lagrangian,
    console.log("this is PhysDims");
    console.log(this.physDims);
    // ------------------  END  global physDims ----------------------------- //

    // ------------------  global physUnits ----------------------------- //

    this.physUnits = new PhysUnitList();
    //  unit conversions  https://www.digitaldutch.com/unitconverter/mass.htm
    //////////////////////////// template ///////////////////
    // this.physUnits.addPhysUnit( new PhysConShell( { punitId: -1, level: 1, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["m/s"], pdimId:10, comp: {M:0,T:-1,L:1,t:0,N:0,I:0,J:0,td:0}, categoryA: ["velocity","speed"] } ));
    // this.physUnits.addPhysUnit( new PhysConShell( { punitId: -1, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["kg"], pdimId:2, comp: {M:1,T:0,L:0,t:0,N:0,I:0,J:0,td:0}, categoryA: ["mass"] }) );

    // Basic seven + 2
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 0, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI","imperial"], unitA: ["not in system"], pdimId:0 }), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 1, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI","imperial"], unitA: ["","rad","radian","sr","steradian"], category: "angle" }), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 2, level: 1, cu2siInt: 0, cu2si: Math.PI/180, systemA: ["SI","imperial"], unitA: ["°","deg","degree"], comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:0} } ), this.physDims ); // ,"&deg;"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 3, level: 2, cu2siInt: 0, cu2si: Math.PI, systemA: ["SI","imperial"], unitA: ["π","pi"], category: "angle" } ), this.physDims ); // ,"&pi;"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 4, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["kg"], comp: {M:1,T:0,L:0,t:0,N:0,I:0,J:0,td:0} } ), this.physDims ); // tricky, mass is the only SI unit with prefix in the standard base unit.
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 5, level: 1, cu2siInt: 0, cu2si: 0.001, systemA: ["SI"], unitA: ["g"], category: "mass" }), this.physDims ); // need to restrict kg to NOT allow prefix.
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 6, level: 0, cu2siInt: 0, cu2si: 0.45359237, systemA: ["imperial"], unitA: ["lb","pound","pound-mass"], category: "mass" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 7, level: 1, cu2siInt: 0, cu2si: 14.593903, systemA: ["imperial"], unitA: ["slug","g-pound"], category: "mass" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 8, level: 1, cu2siInt: 0, cu2si: 907.184740, systemA: ["other"], unitA: ["ton (US)","short tons","ST"], category: "mass" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 9, level: 1, cu2siInt: 0, cu2si: 1016.046909, systemA: ["imperial"], unitA: ["ton (UK)","long tons","LT"], category: "mass" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 10, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI","imperial"], unitA: ["s","sec","second"], category: "time" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 11, level: 1, cu2siInt: 0, cu2si: 60, systemA: ["SI","imperial"], unitA: ["min","minute"], category: "time" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 12, level: 1, cu2siInt: 0, cu2si: 3600, systemA: ["SI","imperial"], unitA: ["h","hr","hour"], category: "time" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 13, level: 1, cu2siInt: 0, cu2si: Number(3600*24*365.242196), systemA: ["SI","imperial"], unitA: ["y","yr","year"], category: "time" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 14, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["m","meter"], category: "length" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 15, level: 0, cu2siInt: 0, cu2si: 0.3048, systemA: ["imperial"], unitA: ["ft","foot","feet"], category: "length" } ), this.physDims ); // exact
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 16, level: 1, cu2siInt: 0, cu2si: 0.0254, systemA: ["imperial"], unitA: ["in","inch","inches"], category: "length" } ), this.physDims ); // exact
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 17, level: 1, cu2siInt: 0, cu2si: 1609.344, systemA: ["imperial"], unitA: ["mi","miles"], category: "length" } ), this.physDims );
    // temperature
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 18, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["K","Kelvin"], category: "temperature" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 19, level: 0, cu2siInt: 273.15, cu2si: 1, systemA: ["other"], unitA: ["°C","Celsius"], category: "temperature" } ), this.physDims ); // ,"&deg;C"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 20, level: 1, cu2siInt: Number(273.15-32*5/9), cu2si: Number(5/9), systemA: ["imperial"], unitA: ["°F","Fahrenheit"], category: "temperature" } ), this.physDims ); // ,"&deg;F"
    // temperature difference
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 21, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["K","Kelvin","°C","Celsius"], comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:1}, category: "temperature difference" } ), this.physDims ); // ,"&deg;C"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 22, level: 1, cu2siInt: 0, cu2si: Number(5/9), systemA: ["imperial"], unitA: ["°F","Fahrenheit"], comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:0,td:1}, category: "temperature difference" } ), this.physDims ); // ,"&deg;F"
    // end temperatures
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 23, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["mol"], comp: {M:0,T:0,L:0,t:0,N:1,I:0,J:0,td:0}, category: "mole"} ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 24, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["A","Amp","C/s","Coulomb/s"], category: "current" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 25, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["cd","lm","cd sr"], comp: {M:0,T:0,L:0,t:0,N:0,I:0,J:1,td:0}, categoryA: ["luminous intensity","luminous flux"] } ), this.physDims );


    // // kinetmatics
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 26, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["m/s"], category: "velocity" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 27, level: 0, cu2siInt: 0, cu2si: 0.3048, systemA: ["imperial"], unitA: ["ft/s"], category: "velocity" } ), this.physDims ); // exact from ft-m conversion
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 28, level: 1, cu2siInt: 0, cu2si: 1609.344, systemA: ["imperial"], unitA: ["mi/s"], category: "velocity" } ), this.physDims ); // not exact, from mi-m conversion
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 29, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["m/s^2"], category: "acceleration" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 30, level: 0, cu2siInt: 0, cu2si: 0.3048, systemA: ["imperial"], unitA: ["ft/s^2"], category: "acceleration" } ), this.physDims ); // exact from ft-m conversion
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 31, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["m/s^3"], comp: {M:0,T:-3,L:1,t:0,N:0,I:0,J:0,td:0}, category: "jerk" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 32, level: 0, cu2siInt: 0, cu2si: 0.3048, systemA: ["imperial"], unitA: ["ft/s^3"], comp: {M:0,T:-3,L:1,t:0,N:0,I:0,J:0,td:0}, category: "jerk" } ), this.physDims ); // exact from ft-m conversion
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 33, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["m^2"], category: "area" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 34, level: 0, cu2siInt: 0, cu2si: 0.3048*0.3048, systemA: ["imperial"], unitA: ["ft^2"], category: "area" } ), this.physDims ); // exact
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 35, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["m^3"], category: "volume" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 36, level: 0, cu2siInt: 0, cu2si: 0.3048*0.3048*0.3048, systemA: ["imperial"], unitA: ["ft^3"], category: "volume" } ), this.physDims ); // exact
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 37, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["m^3/kg"], category: "specific volume" } ), this.physDims );

    // // material property
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 38, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["kg/m^3"], comp: {M:1,T:0,L:-3,t:0,N:0,I:0,J:0,td:0}, category: "mass density" } ), this.physDims ); // mass density
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 39, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["kg/m^2"], comp: {M:1,T:0,L:-2,t:0,N:0,I:0,J:0,td:0}, category: "surface mass density" } ), this.physDims ); // surface mass density
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 40, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["kg/m"], comp: {M:1,T:0,L:-1,t:0,N:0,I:0,J:0,td:0}, category: "linear mass density" } ), this.physDims ); // linear mass density
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 41, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["mol/m^3"], comp: {M:0,T:0,L:-3,t:0,N:1,I:0,J:0,td:0}, categoryA: "amount of substance concentration" } ), this.physDims ); // molar density, amount of substance concentration

    // // Energy and Newton
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 42, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["N","kg m^2/s^2"], category: "force" } ), this.physDims ); // force
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 43, level: 0, cu2siInt: 0, cu2si: 4.448222, systemA: ["imperial"], unitA: ["pound force","lbs force"], category: "force" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 44, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["J","N m"], category: "energy" } ), this.physDims ); // energy
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 45, level: 1, cu2siInt: 0, cu2si: 3600000, systemA: ["other"], unitA: ["kWh"], category: "energy" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 46, level: 0, cu2siInt: 0, cu2si: 4.19002, systemA: ["other"], unitA: ["cal","calories (mean)"], category: "energy" } ), this.physDims ); // average over different defintions and temperature, etc
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 47, level: 0, cu2siInt: 0, cu2si: 1054.35, systemA: ["imperial"], unitA: ["btu (th)"], category: "energy" } ), this.physDims ); // average over different defintions and temperature, etc
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 48, level: 1, cu2siInt: 0, cu2si: Number(1.602176634e-19), systemA: ["other"], unitA: ["eV"], category: "energy" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 49, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["N s","J s/m","kg m/s"], category: "momentum" } ), this.physDims ); // "momentum","impulse"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 50, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["W","J/s","W/sr","kg m^2/s^3"], category: "power" } ), this.physDims ); // "power","radiant intensity"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 51, level: 0, cu2siInt: 0, cu2si: 745.699872, systemA: ["other"], unitA: ["hp","horsepower (international)"], category: "power" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 52, level: 0, cu2siInt: 0, cu2si: 1055.056, systemA: ["other"], unitA: ["btu/s"], category: "power" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 53, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["W/m^2","W/m^2 sr","J/m^2 s","kg/s^3"], comp: {M:1,T:-3,L:0,t:0,N:0,I:0,J:0,td:0}, category: "intensity" } ), this.physDims ); // "intensity","radiance","heat flux density","irradiance"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 54, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["Pa","kg/s^2 m","N/m^2","J/m^3"], category: "pressure" } ), this.physDims ); // "pressure","stress","energy density"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 55, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["N/m","kg/s^2"], comp: {M:1,T:-2,L:0,t:0,N:0,I:0,J:0,td:0}, category: "surface tension" } ), this.physDims ); // "spring constant / surface tension"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 56, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["Pa s","kg/s m"], comp: {M:1,T:-1,L:-1,t:0,N:0,I:0,J:0,td:0}, category: "dynamic viscosity" } ), this.physDims ); // "dynamic viscosity"

    // // wave
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 57, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["1/m"], comp: {M:0,T:0,L:-1,t:0,N:0,I:0,J:0,td:0}, category: "wave number" } ), this.physDims ); // wave number
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 58, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI","imperial"], unitA: ["Hz","1/s","rad/s","Bq"], comp: {M:0,T:-1,L:0,t:0,N:0,I:0,J:0,td:0}, category: "frequency" } ), this.physDims ); // "frequency","angular freqnency","angular velocity","angular speed","radioactive decay activity"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 59, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI","imperial"], unitA: ["1/s^2","rad/s^2"], comp: {M:0,T:-2,L:0,t:0,N:0,I:0,J:0,td:0}, category: "angular accleration" } ), this.physDims ); // angular accleration

    // // EM
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 60, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["s A","C"], comp: {M:0,T:1,L:0,t:0,N:0,I:1,J:0,td:0}, category: "electric charge" } ), this.physDims ); // unit for special physical constant e
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 61, level: 0, cu2siInt: 0, cu2si: Number(1.602176634e-19), systemA: ["other"], unitA: ["e"], comp: {M:0,T:1,L:0,t:0,N:0,I:1,J:0,td:0}, category: "electric charge" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 62, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["C/m^3","s A/m^3"], comp: {M:0,T:1,L:-3,t:0,N:0,I:1,J:0,td:0}, category: "electric charge density" }), this.physDims ); // "electric charge density"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 63, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["C/m^2","s A/m^2"], comp: {M:0,T:1,L:-2,t:0,N:0,I:1,J:0,td:0}, category: "electric flux density" }), this.physDims ); // "surface charge density","electric flux density"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 64, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["C/m","s A/m"], comp: {M:0,T:1,L:-1,t:0,N:0,I:1,J:0,td:0}, category: "linear charge density" }), this.physDims ); // "linear charge density"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 65, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["V","W/A","kg m^2/s^3 A"], comp: {M:1,T:-3,L:2,t:0,N:0,I:-1,J:0,td:0}, category: "electric potential" }), this.physDims ); // "electric potential","electromotive force"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 66, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["V/m","kg m/s^3 A"], comp: {M:1,T:-3,L:1,t:0,N:0,I:-1,J:0,td:0}, category: "electric field" }), this.physDims ); // "electric field"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 67, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["F","C/V","s^4 A^2/kg m^2"], comp: {M:-1,T:4,L:-2,t:0,N:0,I:2,J:0,td:0}, category: "capacitance" }), this.physDims ); // capacitance
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 68, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["A/m^2"], comp: {M:0,T:0,L:-2,t:0,N:0,I:1,J:0,td:0}, category: "current density" }), this.physDims ); // current density
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 69, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["Wb/m^2","T","kg/s^2 A"], comp: {M:1,T:-2,L:0,t:0,N:0,I:-1,J:0,td:0}, category: "magnetic field" }), this.physDims ); // "magnetic field","magnetic flux density"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 70, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["Wb","kg m^2/s^2 A"], comp: {M:1,T:-2,L:2,t:0,N:0,I:-1,J:0,td:0}, category: "magnetic flux" }), this.physDims ); // magnetic flux
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 71, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["Ohm","&Omega;","V/A","kg m^2/s^3 A^2"], comp: {M:1,T:-3,L:2,t:0,N:0,I:-2,J:0,td:0}, category: "electric resistance" }), this.physDims ); // "electric resistance","electrical resistance"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 72, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["Ohm m","&Omega; m","V m/A","kg m^3/s^3 A^2"], comp: {M:1,T:-3,L:3,t:0,N:0,I:-2,J:0,td:0}, category: "electric resistivity" }), this.physDims ); // "electric resistivity","electrical resistivity"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 73, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["S","siemens","A/V","s^3 A^2/kg m^2"], comp: {M:-1,T:3,L:-2,t:0,N:0,I:2,J:0,td:0}, category: "electric conductance" }), this.physDims ); // "electric conductance","electrical conductance"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 74, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["S/m","A/V m","s^3 A^2/kg m^3"], comp: {M:-1,T:3,L:-3,t:0,N:0,I:2,J:0,td:0}, category: "electric conductivity" }), this.physDims ); // "electric conductivity","electrical conductivity"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 75, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["H","Wb/A","kg m^2/s^2 A^2"], comp: {M:1,T:-2,L:2,t:0,N:0,I:-2,J:0,td:0}, category: "inductance" }), this.physDims ); // inductance
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 76, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["F/m","s^4 A^2/kg m^3","s^2 C^2/kg m^3"], comp: {M:-1,T:4,L:-3,t:0,N:0,I:2,J:0,td:0}, category: "permittivity" } ), this.physDims ); // unit for special physical constant epsilon_0
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 77, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["H/m","N/A^2","kg m/C^2","kg m/s^2 A^2"], comp: {M:1,T:-2,L:1,t:0,N:0,I:-2,J:0,td:0}, category: "permeability" } ), this.physDims ); // unit for special physical constant mu_0

    // // thermal
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 78, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["W/m K","kg/s^3 K"], comp: {M:1,T:-3,L:1,t:-1,N:0,I:0,J:0,td:0}, category: "thermal conductivity" } ), this.physDims ); // thermal conductivity
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 79, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["J/mol","kg m^2/s^2 mol"], comp: {M:1,T:-2,L:2,t:0,N:-1,I:0,J:0,td:0}, category: "molar energy" } ), this.physDims ); // molar energy
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 80, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["J/mol K","kg m^2/s^2 K mol"], comp: {M:1,T:-2,L:2,t:-1,N:-1,I:0,J:0,td:0}, category: "molar entropy" } ), this.physDims ); // molar entropy, molar heat capacity
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 81, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["J/K","kg m^2/s^2 K"], comp: {M:1,T:-2,L:2,t:-1,N:0,I:0,J:0,td:0}, category: "entropy" } ), this.physDims ); // unit for Boltzman constant, entropy, heat capacity
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 82, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["J/kg K","m^2/s^2 K"], comp: {M:0,T:-2,L:2,t:-1,N:0,I:0,J:0,td:0}, category: "specific entropy" } ), this.physDims ); // "specific heat capacity","specific entropy"

    // // radiation, radioactivity
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 83, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["lx","lm/m^2"], comp: {M:0,T:0,L:-2,t:0,N:0,I:0,J:1,td:0}, category: "luminance" }), this.physDims ); // "luminance","illuminance"
    // this.physUnits.addPhysUnit( new PhysConShell( { punitId: 84, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["Gy","Sv","J/kg"], comp: {M:0,T:-2,L:2,t:0,N:0,I:0,J:0,td:0}, category: "specific energy" }), this.physDims ); // "absorbed dose","specific energy","kerma","dose equivalent"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 85, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["Gy/s"], comp: {M:0,T:-3,L:2,t:0,N:0,I:0,J:0,td:0}, category: "absorbed dose rate" }), this.physDims ); // "absorbed dose rate"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 86, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["kat"], comp: {M:0,T:-1,L:0,t:0,N:1,I:0,J:0,td:0}, category: "catalytic activity" }), this.physDims ); // "catalytic activity"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 87, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["C/kg"], comp: {M:-1,T:1,L:0,t:0,N:0,I:1,J:0,td:0}, category: "exposure" }), this.physDims ); // "exposure"
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 88, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["kat/m^3"], comp: {M:0,T:-1,L:-3,t:0,N:1,I:0,J:0,td:0}, category: "catalytic activity concentration" }), this.physDims ); // "catalytic activity concentration"

    // special physical constants
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 89, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["N m^2/kg^2","m^3/kg s^2"], comp: {M:-1,T:-2,L:3,t:0,N:0,I:0,J:0,td:0}, category: "newton's g" } ), this.physDims ); // unit for special physical constant G
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 90, level: 0, cu2siInt: 0, cu2si: 1, systemA: ["SI"], unitA: ["J s","J/Hz","kg m^2/s"], comp: {M:1,T:-1,L:2,t:0,N:0,I:0,J:0,td:0}, category: "action" } ), this.physDims ); // unit for special physical constant h, hbar
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 91, level: 0, cu2siInt: 0, cu2si: Number(1.602176634e-19), systemA: ["other"], unitA: ["eV s", "eV/Hz"], comp: {M:1,T:-1,L:2,t:0,N:0,I:0,J:0,td:0}, category: "action" } ), this.physDims );

    // others
    // , comp: {M:0,T:0,L:1,t:0,N:0,I:0,J:0,td:0}
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 92, level: 1, cu2siInt: 0, cu2si: 299792458, systemA: ["other"], unitA: ["c","speed of light"], category: "velocity" } ), this.physDims ); // exact
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 93, level: 1, cu2siInt: 0, cu2si: 3*0.3048, systemA: ["imperial"], unitA: ["yd","yard"], category: "length" } ), this.physDims ); // exact
    // astronmy (202008)
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 94, level: 1, cu2siInt: 0, cu2si: 149597870691 , systemA: ["other"], unitA: ["Astro unit","au","Astronomical unit"], category: "length" } ), this.physDims ); // need to put in uncertatinty?
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 95, level: 1, cu2siInt: 0, cu2si: 365.2422*24*3600*299792458 , systemA: ["other"], unitA: ["ly","light-year"], category: "length" } ), this.physDims ); // exact if days in year is exactly defined
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 96, level: 1, cu2siInt: 0, cu2si: 1852 , systemA: ["other"], unitA: ["NM","nautical mile"], category: "length" } ), this.physDims ); // exact
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 97, level: 1, cu2siInt: 0, cu2si: Number(3.08567758149137e16) , systemA: ["other"], unitA: ["pc","parsec"], category: "length" } ), this.physDims ); // exact from 2015 definition

    // volumes (202009)
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 98, level: 1, cu2siInt: 0, cu2si: Number(1e-3) , systemA: ["SI"], unitA: ["L","liter","litre","l"], category: "volume" } ), this.physDims ); // exact

    // // these should be derived from lengths
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 99, level: 0, cu2siInt: 0, cu2si: 0.3048*0.3048*0.3048 , systemA: ["imperial"], unitA: ["cubic foot","ft^3","cubic feet"], category: "volume" } ), this.physDims ); // exact
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 100, level: 2, cu2siInt: 0, cu2si: 0.0254*0.0254*0.0254 , systemA: ["imperial"], unitA: ["cubic inch","in^3"], category: "volume" } ), this.physDims ); // exact
    // this.physUnits.addPhysUnit( new PhysConShell( { punitId: 15, level: 0, cu2siInt: 0, cu2si: 0.3048, systemA: ["imperial"], unitA: ["ft","foot","feet"], category: "length" } ), this.physDims ); // exact
    // this.physUnits.addPhysUnit( new PhysConShell( { punitId: 16, level: 1, cu2siInt: 0, cu2si: 0.0254, systemA: ["imperial"], unitA: ["in","inch","inches"], category: "length" } ), this.physDims ); // exact
    // this.physUnits.addPhysUnit( new PhysConShell( { punitId: 17, level: 1, cu2siInt: 0, cu2si: 1609.344, systemA: ["imperial"], unitA: ["mi","miles"], category: "length" } ), this.physDims );

    // // other volumes
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 101, level: 2, cu2siInt: 0, cu2si: Number(1e-6) , systemA: ["SI"], unitA: ["cc","cubic centimeter","mL"], category: "volume" } ), this.physDims ); // exact
    // (2016, NIST, https://www.nist.gov/system/files/documents/pml/wmd/pubs/2016/02/18/appc-16-hb44-final.pdf )
    // (2006, NIST, https://nvlpubs.nist.gov/nistpubs/Legacy/SP/nistspecialpublication1038.pdf )
    // US: 1 Gallon = 4 Qt = 8 Pint =~ 15.7725 (NOT 16) cup = 128 fl oz = 256 Tbsp = 768 tsp =~ 3.785412 L (exact value from 1 US gal = 231 in^3, and 1 in = 2.54 cm exact)
    // note that  1 cup = 240 mL, or 16 cup = 3840 mL = 3.84 L (exact)
    // Imperial: 1 Gallon = 4 Qt = 8 Pint = 16 cup = 160 (NOT 128) fl oz = 256 Tbsp = 768 tsp = 0.00454609 L (exact)
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 102, level: 2, cu2siInt: 0, cu2si: 231*0.0254*0.0254*0.0254 , systemA: ["other"], unitA: ["US gallon", "US gal"], category: "volume" } ), this.physDims ); // A gallon is a unit of volume specifically regarding liquid capacity in both the US customary and imperial systems of measurement. The US gallon is defined as 231 cubic inches (3.785 liters). In contrast, the imperial gallon, which is used in the United Kingdom, Canada, and some Caribbean nations, is defined as 4.54609 liters.
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 103, level: 3, cu2siInt: 0, cu2si: 231*0.0254*0.0254*0.0254/4 , systemA: ["other"], unitA: ["US liquid quart", "US quart", "US qt"], category: "volume" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 104, level: 3, cu2siInt: 0, cu2si: 231*0.0254*0.0254*0.0254/8 , systemA: ["other"], unitA: ["US liquid pint", "US pint", "US pt"], category: "volume" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 105, level: 3, cu2siInt: 0, cu2si: 0.00024 , systemA: ["other"], unitA: ["US legal cup", "US c"], category: "volume" } ), this.physDims ); // https://www.asknumbers.com/cups-to-liters.aspx
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 106, level: 3, cu2siInt: 0, cu2si: 231*0.0254*0.0254*0.0254/128 , systemA: ["other"], unitA: ["US fluid ounce", "US fluid ounce", "US fluid oz"], category: "volume" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 107, level: 3, cu2siInt: 0, cu2si: 231*0.0254*0.0254*0.0254/256 , systemA: ["other"], unitA: ["US tablespoon", "US Tbsp"], category: "volume" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 108, level: 3, cu2siInt: 0, cu2si: 231*0.0254*0.0254*0.0254/768 , systemA: ["other"], unitA: ["US teaspoon", "US tsp"], category: "volume" } ), this.physDims );
    //
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 109, level: 2, cu2siInt: 0, cu2si: 4.54609/1000 , systemA: ["imperial"], unitA: ["Imperial gallon", "Imperial gal"], category: "volume" } ), this.physDims ); // A gallon is a unit of volume specifically regarding liquid capacity in both the US customary and imperial systems of measurement. The US gallon is defined as 231 cubic inches (3.785 liters). In contrast, the imperial gallon, which is used in the United Kingdom, Canada, and some Caribbean nations, is defined as 4.54609 liters.
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 110, level: 3, cu2siInt: 0, cu2si: 4.54609/1000/4 , systemA: ["imperial"], unitA: ["Imperial liquid quart","Imperial quart", "Imperial qt"], category: "volume" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 111, level: 3, cu2siInt: 0, cu2si: 4.54609/1000/8 , systemA: ["imperial"], unitA: ["Imperial liquid pint","Imperial pint", "Imperial pt"], category: "volume" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 112, level: 3, cu2siInt: 0, cu2si: 4.54609/1000/16 , systemA: ["imperial"], unitA: ["Imperial cup"], category: "volume" } ), this.physDims ); // https://www.asknumbers.com/cups-to-liters.aspx
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 113, level: 3, cu2siInt: 0, cu2si: 4.54609/1000/160 , systemA: ["imperial"], unitA: ["Imperial fluid ounce", "Imperial fluid ounce", "Imperial fluid oz"], category: "volume" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 114, level: 3, cu2siInt: 0, cu2si: 4.54609/1000/256 , systemA: ["imperial"], unitA: ["Imperial tablespoon", "Imperial Tbsp"], category: "volume" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 115, level: 3, cu2siInt: 0, cu2si: 4.54609/1000/768 , systemA: ["imperial"], unitA: ["Imperial teaspoon", "Imperial tsp"], category: "volume" } ), this.physDims );

    // velocity
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 120, level: 1, cu2siInt: 0, cu2si: 1/3600, systemA: ["SI"], unitA: ["m/hr", "m/h", "meter/hr"], category: "velocity" } ), this.physDims );
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 121, level: 1, cu2siInt: 0, cu2si: 1609.344/3600, systemA: ["imperial"], unitA: ["mi/hr", "mi/h", "miles/hr"], category: "velocity" } ), this.physDims ); // exact from mi-m conversion

    // other others
    // spring constant common units
    this.physUnits.addPhysUnit( new PhysConShell( { punitId: 130, level: 1, cu2siInt: 0, cu2si: 100, systemA: ["SI"], unitA: ["N/cm"], comp: {M:1,T:-2,L:0,t:0,N:0,I:0,J:0,td:0} } ), this.physDims ); // "spring constant / surface tension"

    // //////////////////////
    console.log("this is physUnits");
    console.log(this.physUnits);
    // ------------------  END  global physUnits ----------------------------- //

    console.warn(`this is GlobalPhysUnitsService idcnt= ${this.idcnt}.`)

  } // end constructor

  findPhysDimA(o:object): PhysConShell[] { return this.physDims.matchPhysDimA(o); }
  findPhysDim(o:object): PhysConShell { return this.findPhysDimA(o)[0]; }
  findPhysdimId(o:object): number { return this.findPhysDim(o).pdimId; }
  findPhysUnitA(o:object): PhysConShell[] { return this.physUnits.matchPhysUnitA(o); }
  findPhysUnit(o:object): PhysConShell { return this.findPhysUnitA(o)[0]; }
  findPhysUnitID(o:object): number { return this.findPhysUnit(o).punitId; }
  findPhysConS(o:object): PhysConShell {
    // unlike findPhysUnitA or findPhysUnit, this will preserve all other properties
    let res: PhysConShell = new PhysConShell(o);
    if (res['punitId']===0||res['punitId']>0) { res.merge(this.findPhysUnit(o)); }
    else { res.merge(this.findPhysDim(o)); }
    return res;
  } // end findPhysConS

  get physDimAList(): CatDropdownOption[] { // create list for dropdown menu for example
    // sample output- [ ... , { pdimId: 8, title="luminous intensity | luminous flux : [Lum Int]", category:"luminous intensity", comp:{M:0, L:0, T:0, t:0, td:0, N:0, I:0, J:0} } , ... ]
    let result = [];

    this.physDims.list.forEach(function(s: PhysConShell){
      result.push( new CatDropdownOption( s ));
      // let title = s.categoryA.join(' | ');
      // title += (s.pdimId>1)?' : '+ s.compDisp : '' ;
      // const category = s.categoryA[0];

      // result.push(<CatDropdownOption>{pdimId: s.pdimId, title: title, category: category, comp: s.comp, compDisp: s.compDisp, unitSI: s.unitSI});
    })
    return result;
  }

  /*
  pdimId?: number;
  title?: string;
  category?: string;
  comp?: PhysDimComp;
  compDisp?: string;
  unitSI?: string;
  */

  physUnitAList4dropdown(o:object): unitDropdownList[] { // o- {pdimId: 5} or other equivalence // create list for dropdown menu for example,
    // sample output- [ { pdimId: 4, punitId: 14, systemA:['SI'], title:'m | meter', unit:'m' }, { pdimId: 4, punitId: 15, systemA:['imperial'], title:'ft', unit:'ft' }, { pdimId: 4, punitId: 16, systemA:['imperial'], title:'in', unit:'in' }, { pdimId: 4, punitId: 17, systemA:['imperial'], title:'mi', unit:'mile' } ]
    let result = [];
    const resList = this.findPhysUnitA(o);

    resList.forEach(function(s: PhysConShell){
      let title=s.unitA.join(' | ');
      if (title.substr(0,2)==" |") { title = title.substr(3); }
      // title = this.decodeHtml(title); // decode special &deg; etc
      let unit = (s.unitA[0]=="")?"rad":s.unitA[0];
      // unit = this.decodeHtml(unit); // decode special &deg; etc

      result.push({ pdimId: s.pdimId, punitId: s.punitId, systemA: s.systemA, title: title, unit: unit });
    }.bind(this))
    return result;
  }
  // this.physDims.addPhysDim( new PhysConShell( { pdimId:60, comp: {M:1,T:-1,L:2,t:0,N:0,I:0,J:0,td:0}, categoryA: ["action","planck constant"] }) ); // planck constant, lagrangian,

  prefixAllow(o:object): boolean {
    // some units (non-SI) should not have prefixes
    // kg is a special SI unit where prefix is applied to g, not kg.
    // default here is true. list out the ones that are false
    if (o['pdimId'] && o['pdimId'] < 2) return false;
    if (o['unitA'] && o['unitA'].includes('kg') && o['categoryA'] && o['categoryA'].includes('mass')) return false; // kg cannot have kkg/
    if (o['unitA'] && o['unitA'].includes('mi') && o['categoryA'] && o['categoryA'].includes('length')) return false; // miles

    if ( o['systemA'] && o['systemA'].includes('SI') ) return true; // assume kg is the only SI exception
    return false; // default
    // return result;
  }

  // decodeHtml(html: string): string { let txt = document.createElement("textarea"); txt.innerHTML = html; return txt.value; } // to fix degree &deg; &mu; and other unusual units

} // end class GlobalPhysUnitsService
