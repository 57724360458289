import { Injectable } from '@angular/core';
import { MonologBoxModule } from './monolog-box.module';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MonologComponent, MonologData } from './monolog/monolog.component';

export interface monologBoxOverride { // modeled from RevealOverride
  // readonly type: 'monologboxoverride';
  title?: string;
  message?: string;
  showCancelBtn?: boolean;
  showOKBtn?: boolean;
}

@Injectable({ providedIn: MonologBoxModule })
// @Injectable({ providedIn: 'root' })
export class MonologService {
  private _dialogRef: MatDialogRef<MonologComponent>;

  constructor(private _mdl: MatDialog) { }

  openlog(input: { override?: monologBoxOverride, additionalDialogConfigData?: any, helper?: string}): MatDialogRef<MonologComponent>  { // show dialog box
    // material design dialog box has only title and message, 2 attributes. Use JSON if needed to pass more info
    // scenarios
    // helper: globalsettings (gsettings), physicalConstants (physcons), scractPad (scratch)
    const data = new MonologData();

    if ( input.override && input.override.title &&  input.override.title.trim() !='' && input.override.message && input.override.message.trim() !='' ) { data.title = input.override.title; data.message = JSON.stringify({type:'override', msg: input.override.message}); } else 
    if ( input.helper && input.helper == 'gsettings' ) { data.title = 'Global Settings'; data.message = JSON.stringify({type:'gsettings'}); } else 
    if ( input.helper && input.helper == 'physcons' ) { data.title = 'Physical Constants'; data.message = JSON.stringify({type:'physcons'}); } else 
    if ( input.helper && input.helper == 'scratchpad' ) { data.title = 'Scratch Pad'; data.message = JSON.stringify({type:'scratchpad'}); }  
    else { data.title = 'Scratch Pad'; data.message = JSON.stringify({type:'scratchpad'}); }  

    data.showCancelBtn = (input.override && input.override.showCancelBtn)?true:false;
    data.showOKBtn = (input.override && input.override.showOKBtn===false)?false:true;

    return this._openDialog(data, input.additionalDialogConfigData);

  }

  private _openDialog(data: MonologData, additionalDialogConfigData?: any): MatDialogRef<MonologComponent> {

    if (this._dialogRef) { this._dialogRef.close(); }
    this._dialogRef = this._mdl.open(MonologComponent, {
      width: '900px',
      data,
      ...additionalDialogConfigData
    });

    this._dialogRef.afterClosed().subscribe( result => { } );

    return this._dialogRef;
  } // end _openDialog() // Before creating the dialog component, we check to see whether there is another dialog open, and close it first.

}
