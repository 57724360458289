import { Injectable, Optional } from '@angular/core';

// let nextId = 1; 
// currently this is set as a global variable only. Can be extended to different modules, when say a user is taking a quiz, etc

@Injectable({
  providedIn: 'root'
})
export class GlobalSiteTitleService {
  // idcnt = nextId++;
  gTitle: string;

  constructor() {
    this.gTitle = (this.gTitle && this.gTitle != '') ? this.gTitle : 'Physics.Land 101' ;
  }

  // constructor(@Optional() public gTitle: string ) { 
  //   // forRoot() will overide these... this constructor is not called
  //   this.gTitle = (this.gTitle) ? this.gTitle : 'default title: Physics.Land 101' ;
  //   console.log("instantiating GlobalSiteTitleService.");
  //   // console.log("the idcnt = "+ this.idcnt);
  //   console.log(this);
  // }
}
