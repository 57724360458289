import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonologComponent } from './monolog/monolog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { PhysConstantsModule } from 'src/app/common/phys-constants/phys-constants.module';
import { SysSettingsModule } from 'src/app/common/sys-settings/sys-settings.module';
import { ScratchPadModule } from 'src/app/common/scratch-pad/scratch-pad.module';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';
// import { MonologService } from './monolog.service';

// This is modeled from the reason module, with the difference of not involving the reveal and reveal level 
// Also this module and the service is NOT a singleton service.
// One main purpose for this module is to avoid circular dependency for (as an example) the 
// physicalConstants component -> GlobalClipboard -> SnackBar -> RevealModule -> physicalConstantsCompnent
// In other words, if you need a diaglog box but not needing to check the reveal level setting, just you telling the users what 
// to do, don't care if they listen, this is the 1-way communication box. Monologue.

@NgModule({
  declarations: [MonologComponent],
  // providers: [ MonologService ],
  // providers: [ SnackBarService, MonologService, MatSnackBar, MatDialog ],
  entryComponents: [ MonologComponent ],
  imports: [ CommonModule, MatDialogModule, BrowserAnimationsModule, FormsModule, PhysConstantsModule, SysSettingsModule, ScratchPadModule, CommonPipeModule ]
  // imports: [ CommonModule, MatSnackBarModule, MatDialogModule, BrowserAnimationsModule, FormsModule ], // MatButtonModule, BrowserModule, 
  // exports: [ MonologComponent ]
})
export class MonologBoxModule { }
