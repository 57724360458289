import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonPipeModule } from 'src/app/pipe/common-pipe/common-pipe.module';
import { FormsModule } from '@angular/forms';
import { FigureSvgModule } from 'src/app/visual/figure-svg/figure-svg.module';
import { QuadraticComponent } from './quadratic.component';
import { QuadBasicComponent } from './quad-basic/quad-basic.component';
import { QuadAdvComponent } from './quad-adv/quad-adv.component';



@NgModule({
  declarations: [ QuadraticComponent, QuadBasicComponent, QuadAdvComponent ],
  // imports: [ CommonModule, CommonPipeModule, FormsModule, FigureSvgModule ],
  imports: [ CommonModule, CommonPipeModule, FormsModule, FigureSvgModule ],
  exports: [ QuadBasicComponent, QuadAdvComponent ], // export these so that other modules/components can use them standalone
  providers: []
})
export class QuadraticModule { }
