import { Injectable, Optional } from '@angular/core';
import { GlobalPhysUnitsService, PhysConShell, physDimCompNames, PhysDimComp } from './global-phys-units.service';
import { PhysCon } from '../bbb/bbb.module';
import { GlobalSystemService } from 'src/app/service/global-vars/global-system.service';
import { AppInjector } from 'src/app/app-injector';

let nextId = 1; 

class PhysConList { 
  // collection of Physical Constants (Scratch pad needs more structures for moving/shifting/copying entries)
  // more than just PhysDimList and PhysUnitList, which are just PhysConShell[]
  // this needs the full functions of PhysCon to change system/units/etc
  list: PhysCon[];
  private _gsys: GlobalSystemService;

  constructor() { 
    this._gsys = AppInjector.get(GlobalSystemService);
    this.list = []; 
  }

  //  For clipboard list, see src/app/service/clipboard.service

  addPhysCon(pc: PhysCon|PhysConShell): void { 
    // pu: Punit Object // neeed to check duplicate, then update
    if (pc instanceof PhysCon) { this.list.push( pc.dcopy() ); } // use own judgement for now. No checks. // duplicate an indept copy of pu
    if (pc instanceof PhysConShell) { this.list.push( new PhysCon(pc) ); }
    // this.cid++;
    return;
  } 

  chgSystem(): void {
    this.list.forEach(function(pc: PhysCon){
      if ( !(pc.systemA.includes(this._gsys.gSystem))) { 
        // find match in altPunitA
        pc.altPunitA.forEach(function(alt){
          if (alt.system == this._gsys.gSystem) { pc.chgSysUnitID(alt); return; }
        }.bind(this)); 
      }
    }.bind(this));
    return;
  }

} // end PhysConList


@Injectable({ providedIn: 'root' })
export class GlobalPhysConstsService {
  private idcnt = nextId++;

  // identity elements, zero
  readonly id0: PhysCon = new PhysCon( new PhysConShell({pconID: -1, desc: "additive identity", source: "", sourceUrl: "", rtvdate: "200000314", topicA: ["all"], altPunitA: [{punitId: 1, system: "SI", unit: ""} ], valueMin: 0, valueMax: 0, stdErr: 0, stdErrRel: 0, exact: true, name: "id0", nameHtml: "id<sub>0</sub>", value: 0, system: "SI", category: "dimensionless", unit: "", prepower: 0, punitId: 1, comp: {M:0,T:0,L:0,t:0,td:0,N:0,I:0,J:0} }) );

  // identity elements, one
  readonly id1: PhysCon = new PhysCon( new PhysConShell({pconID: -1, desc: "multiplicative identity", source: "", sourceUrl: "", rtvdate: "200000314", topicA: ["all"], altPunitA: [{punitId: 1, system: "SI", unit: ""} ], valueMin: 1, valueMax: 1, stdErr: 0, stdErrRel: 0, exact: true, name: "id1", nameHtml: "id<sub>1</sub>", value: 1, system: "SI", category: "dimensionless", unit: "", prepower: 0, punitId: 1, comp: {M:0,T:0,L:0,t:0,td:0,N:0,I:0,J:0} }) );
  // pi
  // e
  physCons: PhysConList;

  constructor() { 
    if (this.idcnt>1) { console.warn('GlobalPhysUnitsService is already loaded. Import it in the AppModule only');  }
    // ------------------  global physCons ----------------------------- //
    this.physCons = new PhysConList();
    // this.physCons.addPhysCon( new PhysConShell({name: "e",symbol: "e",type: "fund",desc: "El",value: 16,unit: "C",altunit: "C",category: "E&M",system: "SI",min: 15,max: 19,source: "NIST",rtvdate: "20190314"}) ); 
    // all requires si unit version defined first. 
    // NIST  1 lb (mass, avoirdupois) = 0.4535924 kg
    // NIST  1 lb-force = 4.448222 N
    // https://www.nist.gov/pml/nist-guide-si-appendix-b9-factors-units-listed-kind-quantity-or-field-science
    // template
    // this.physCons.addPhysCon( new PhysConShell({pconID: -1, desc: "Speed ", source: "NIST", sourceUrl: "", rtvdate: "20190314", topicA: ["Kinematics","E&M","Modern","Relativity","Quantum","Optics"], altPunitA: [{punitId: 22, system: "SI", unit: "m/s"},{punitId: 23, system: "Imperial", unit: "ft/s"}], valueMin: 299792458, valueMax: 299792458, stdErr: 0, stdErrRel: 0, exact: true, name: "c", nameHtml: "<i>c</i>", value: 299792458, system: "SI", category: "velocity", unit: "m/s", prepower: 0, punitId: 22 }) ); 
    // speed of light c
    this.physCons.addPhysCon( new PhysConShell({pconID: 0, desc: "Speed of light in vacuum", source: "NIST", sourceUrl: "https://physics.nist.gov/cgi-bin/cuu/Value?c", rtvdate: "20190314", topicA: ["Kinematics","E&M","Modern","Relativity","Quantum","Optics"], altPunitA: [{punitId: 26, system: "SI", unit: "m/s"},{punitId: 27, system: "imperial", unit: "ft/s"}], valueMin: 299792458, valueMax: 299792458, stdErr: 0, stdErrRel: 0, exact: true, name: "c", nameHtml: "<i>c</i>", value: 299792458, system: "SI", category: "velocity", unit: "m/s", prepower: 0, punitId: 26, comp: {M:0,T:-1,L:1,t:0,N:0,I:0,J:0,td:0} }) ); // exact value 
    // gravity g
    this.physCons.addPhysCon( new PhysConShell({pconID: 1, desc: "Gravitational acc near Earth's surface", source: "common", sourceUrl:"https://google.com", rtvdate: "20190314", topicA: ["Kinematics","Gravity","Relativity"], altPunitA: [{punitId: 29, system: "SI", unit: "m/s^2"},{punitId: 30, system: "imperial", unit: "ft/s^2"}], valueMin: 9.51, valueMax: 10.11, stdErr: 0.30, stdErrRel: Number(0.3/9.81), exact: false, name: "g", nameHtml: "<i>g</i>", value: 9.81, system: "SI", category: "acceleration", unit: "m/s^2", prepower: 0, punitId: 29, comp: {M:0,T:-2,L:1,t:0,N:0,I:0,J:0,td:0} }) ); // experimental value, wide range
    // gravity G
    this.physCons.addPhysCon( new PhysConShell({pconID: 2, desc: "Newton's universal gravitational constant", source: "NIST", sourceUrl:"https://physics.nist.gov/cgi-bin/cuu/Value?bg", rtvdate: "20190314", topicA: ["Kinematics","Gravity","Relativity"], altPunitA: [{punitId: 89, system: "SI", unit: "m^3/kg s^2"}], valueMin: 6.67415e-11, valueMax: 6.67445e-11, stdErr: 0.00015e-11, stdErrRel: Number(0.00015/6.6743), exact: false, name: "G", nameHtml: "<i>G</i>", value: 6.67430e-11, system: "SI", category: "newton's g", unit: "m^3/kg s^2", prepower: 0, punitId: 89, comp: {M:-1,T:-2,L:3,t:0,N:0,I:0,J:0,td:0} }) ); // experimental value
    // Planck h
    this.physCons.addPhysCon( new PhysConShell({pconID: 3, desc: "Planck constant", source: "NIST", sourceUrl: "https://physics.nist.gov/cgi-bin/cuu/Value?h", rtvdate: "20190314", topicA: ["Modern","Quantum"], altPunitA: [{punitId: 90, system: "SI", unit: "J s"},{punitId: 91, system: "other", unit: "eV/Hz"}], valueMin: 6.62607015e-34, valueMax: 6.62607015e-34, stdErr: 0, stdErrRel: 0, exact: true, name: "h", nameHtml: "<i>h</i>", value: 6.62607015e-34, system: "SI", category: "planck constant", unit: "J s", prepower: 0, punitId: 90, comp: {M:1,T:-1,L:2,t:0,N:0,I:0,J:0,td:0} }) ); // exact value
    // Planck h-bar or h-over-2-pi
    this.physCons.addPhysCon( new PhysConShell({pconID: 4, desc: "Planck constant / 2 pi", source: "NIST", sourceUrl: "https://physics.nist.gov/cgi-bin/cuu/Value?hbar", rtvdate: "20190314", topicA: ["Modern","Quantum"], altPunitA: [{punitId: 90, system: "SI", unit: "J s"},{punitId: 91, system: "other", unit: "eV/Hz"}], valueMin: Number(6.62607015e-34/2/Math.PI), valueMax: Number(6.62607015e-34/2/Math.PI), stdErr: 0, stdErrRel: 0, exact: true, name: "hbar", nameHtml: "&#8463;", value: Number(6.62607015e-34/2/Math.PI), system: "SI", category: "planck constant", unit: "J s", prepower: 0, punitId: 90, comp: {M:1,T:-1,L:2,t:0,N:0,I:0,J:0,td:0} }) ); // exact value
    // electronic charge e
    this.physCons.addPhysCon( new PhysConShell({pconID: 5, desc: "Elementary electronic charge", source: "NIST", sourceUrl: "https://physics.nist.gov/cgi-bin/cuu/Value?e", rtvdate: "20190314", topicA: ["E&M","Modern","Quantum"], altPunitA: [{punitId: 60, system: "SI", unit: "C"},{punitId: 61, system: "other", unit: "e"}], valueMin: 1.602176634e-19, valueMax: 1.602176634e-19, stdErr: 0, stdErrRel: 0, exact: true, name: "e", nameHtml: "<i>e</i>", value: 1.602176634e-19, system: "SI", category: "electric charge", unit: "C", prepower: 0, punitId: 60, comp: {M:0,T:1,L:0,t:0,N:0,I:1,J:0,td:0} }) ); // exact value
    // Boltzman k
    this.physCons.addPhysCon( new PhysConShell({pconID: 6, desc: "Boltzmann constant", source: "NIST", sourceUrl: "https://physics.nist.gov/cgi-bin/cuu/Value?k", rtvdate: "20190314", topicA: ["Thermodynamics","Chemical Physics","Kinetic Theory","Statistical Mechanics"], altPunitA: [{punitId: 81, system: "SI", unit: "J/K"}], valueMin: 1.380649e-23, valueMax: 1.380649e-23, stdErr: 0, stdErrRel: 0, exact: true, name: "k", nameHtml: "<i>k</i>", value: 1.380649e-23, system: "SI", category: "entropy", unit: "J/K", prepower: 0, punitId: 81, comp: {M:1,T:-2,L:2,t:-1,N:0,I:0,J:0,td:0} }) ); // exact value
    // electric permittivity
    this.physCons.addPhysCon( new PhysConShell({pconID: 7, desc: "Electric constant / Permittivity of free space", source: "NIST", sourceUrl: "https://physics.nist.gov/cgi-bin/cuu/Value?ep0", rtvdate: "20190314", topicA: ["E&M","Modern","Relativity","Quantum","Optics"], altPunitA: [{punitId: 76, system: "SI", unit: "F/m"}], valueMin: 8.8541878115e-12, valueMax: 8.8541878141e-12, stdErr: 0.0000000013e-12, stdErrRel: Number(0.0000000013/8.8541878128), exact: false, name: "epsilon0", nameHtml: "<i>&epsilon;<sub>0</sub></i>", value: 8.8541878128e-12, system: "SI", category: "permittivity", unit: "F/m", prepower: 0, punitId: 76, comp: {M:-1,T:4,L:-3,t:0,N:0,I:2,J:0,td:0} }) ); 
    // magnetic permeability
    this.physCons.addPhysCon( new PhysConShell({pconID: 8, desc: "Magnetic constant / Permeability of free space", source: "NIST", sourceUrl: "https://physics.nist.gov/cgi-bin/cuu/Value?mu0", rtvdate: "20190314", topicA: ["E&M","Modern","Relativity","Quantum","Optics"], altPunitA: [{punitId: 77, system: "SI", unit: "H/m"}], valueMin: 1.25663706231e-6, valueMax: 1.25663706193e-6, stdErr: 0.00000000019e-6, stdErrRel: Number(0.00000000019/1.25663706212), exact: false, name: "mu0", nameHtml: "<i>&mu;<sub>0</sub></i>", value: 1.25663706212e-6, system: "SI", category: "permeability", unit: "H/m", prepower: 0, punitId: 77, comp: {M:1,T:-2,L:1,t:0,N:0,I:-2,J:0,td:0} }) ); 
    // surface of sun temperature
    this.physCons.addPhysCon( new PhysConShell({pconID: 9, desc: "Surface of Sun temperature", source: "NIST", sourceUrl: "https://www.nist.gov/pml/weights-and-measures/si-units-temperature", rtvdate: "20190717", topicA: ["Thermodynamics","Astronomy","Astrophysics"], altPunitA: [{punitId: 18, system: "SI", unit: "K"},{punitId: 19, system: "SI", unit: "&deg;C"},{punitId: 20, system: "imperial", unit: "&deg;F"}], valueMin: 5800, valueMax: 6000, stdErr: 100, stdErrRel: Number(1/59), exact: false, name: "T_sun", nameHtml: "<i>T</i><sub>sun</sub>", value: 5900, system: "SI", category: "temperature", unit: "K", prepower: 0, punitId: 18, comp: {M:0,T:0,L:0,t:1,N:0,I:0,J:0,td:0} }) ); 

    // e-mass, p-mass, n-mass, NA, R, Stefan-Boltz, R_H, n_glass, n_water, densities, astronomical data, Earth data, 

    // //////////////////////
    console.log("this is physCons");
    console.log(this.physCons);
    console.log(this.id0);
    console.log(this.id1);
    // ------------------  END  global physCons ----------------------------- //

    // console.warn(`this is GlobalPhysConstsService idcnt= ${this.idcnt}.`)
  }
} // end GlobalPhysConstsService
