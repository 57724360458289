import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PhysQty } from 'src/app/numeric-library/bbb/bbb.module';
import { FigureSvg, FigureProps } from '../figure-svg';


class TriangleFigureSvg extends FigureSvg {
  a: PhysQty; 
  b: PhysQty; 
  c: PhysQty; 
  A: PhysQty; 
  B: PhysQty; 
  C: PhysQty; 

  constructor( input: {width:number, height:number, a: PhysQty, b: PhysQty, c: PhysQty, A: PhysQty, B: PhysQty, C: PhysQty, depth?:number} ) { 
    // const d = Math.floor(input['depth']); // for 3-D rendering in the future
    super({ width: Math.floor(input['width']), height: Math.floor(input['height']), xs: [], ys: [], zs: [] }); // input { width, height, depth, xs: number[], ys, zs

    // convention : set BC as horizontal line, A on top.
    // First set A as origin, then scale to fit frame, then set left corner at (0,0). Rendering mighe want to shift X/Y further.
    // Set A at origin (0,0)

    this.a = input.a;
		this.b = input.b;
		this.c = input.c;
		this.A = input.A;
    this.B = input.B;
    this.C = input.C;
    this.setXsYs();
  }

	setXsYs(): void { 
    // 9 points with A, B, C vertices. Points are A, A1, A2, B, B1, B2, C, C1, C2 (to create the arcs)
    // A: ( b cos C, -b sinc C)
    // B: ( a, 0 )
    // C: ( 0, 0 )
    // p=0.2   :   1-p=q=0.8
    // A1: q A + p B         A2: q A + p C
    // B1: q B + p C         B2: q B + p A
    // C1: q C + p A         C2: q C + p B
    //
    // scale = min( maxwidth/xrange, maxheight/yrange)   maxwidth = 0.86 width = r*width, 
    // Shift min/max to 0.12 and 0.88 of height  //  NOT Shift cm to width/2, height/2  (shift w/2-cmx, h/2-cmy). CM is at 1/3 from base

    const p=0.2; const q=0.8; const r=0.76;
    const pA = [ this.b.valueSI * Math.cos(this.C.valueSI), -this.b.valueSI * Math.sin(this.C.valueSI)];
    const pB = [ this.a.valueSI, 0 ];
    // const pC = [0,0];
    this.xs=[pA[0], q*pA[0]+p*pB[0], q*pA[0],       pB[0], q*pB[0], q*pB[0]+p*pA[0],     0, p*pA[0], p*pB[0] ];
    this.ys=[pA[1], q*pA[1]        , q*pA[1],       pB[1],       0,         p*pA[1],     0, p*pA[1],      0 ];

    const scale = Math.min(r*this.width/this.xrange, r*this.height/this.yrange);
    this.scaleXY(scale);
    this.shiftX( (this.width-this.xmin-this.xmax)/2 );
    this.shiftY( (this.height-this.ymin-this.ymax)/2 );
    return;
  }
  
}

@Component({
  selector: 'app-triangle-figure-svg',
  templateUrl: './triangle-figure-svg.component.svg',
  styleUrls: ['./triangle-figure-svg.component.scss']
})
export class TriangleFigureSvgComponent implements OnInit, OnChanges {
  @Input() a: PhysQty;
  @Input() b: PhysQty;
  @Input() c: PhysQty;
  @Input() A: PhysQty;
  @Input() B: PhysQty;
  @Input() C: PhysQty;
  @Input() vcolor: {a:string, b:string, c:string, A:string, B:string, C:string};
  @Input() ronly: {a:boolean, b:boolean, c:boolean, A:boolean, B:boolean, C:boolean};
  @Input() hiLite: string; // yellow or green, for solution 1 and 2
  figure: TriangleFigureSvg;
  // readonly figwidth = 400;
  // readonly figheight = 250;
  // inpColor: {a:string, b:string, c:string, A:string, B:string, C:string};

  constructor() { }

  ngOnInit() { this.figRender(); }

  ngOnChanges() { this.figRender(); }

  ngDoCheck() { this.figRender(); }

  // get angleApath(): string { const f=this.figure; return `"M ${f.xs[0]} ${f.ys[0]} L ${f.xs[1]} ${f.ys[1]} L ${f.xs[2]} ${f.ys[2]} z`; }
  // get angleBpath(): string { const f=this.figure; return `"M ${f.xs[3]} ${f.ys[3]} L ${f.xs[4]} ${f.ys[4]} L ${f.xs[5]} ${f.ys[5]} z`; }
  // get angleCpath(): string { const f=this.figure; return `"M ${f.xs[6]} ${f.ys[6]} L ${f.xs[7]} ${f.ys[7]} L ${f.xs[8]} ${f.ys[8]} z`; }

  figRender(): void {
    // console.log(this.a);
    this.figure = new TriangleFigureSvg( { width: this.figsize().width, height: this.figsize().height, a: this.a, b: this.b, c: this.c, A: this.A, B: this.B, C: this.C } );
    return;
  }

  figsize(): FigureProps { 
    const tw=document.getElementById("divTriangleFigSvg").clientWidth; // could have multiple identical id, force them same size
    const th = Math.max(Math.min(tw*0.8, 250),180); // setting max and min height
    return <FigureProps>{ width: tw, height: th }; 
  }


}
