import { Injectable, Inject } from '@angular/core';
import { GlobalDigitService } from 'src/app/service/global-vars/global-digit.service';
import { GlobalSigfigService } from 'src/app/service/global-vars/global-sigfig.service';
// import { GlobalRevealLevelService } from 'src/app/service/global-vars/global-reveal-level.service';
// import { GlobalSystemService } from 'src/app/service/global-vars/global-system.service';

@Injectable({ providedIn: 'root' })
export class PureMathService {
  private static gd: GlobalDigitService;
  private static gsig: GlobalSigfigService;
	// basic math functions on numerical inputs, as opposed to PqtyMath, where functions are on PhysQty class objects
  constructor(cgd: GlobalDigitService, cgsig: GlobalSigfigService) {
    PureMathService.gd = cgd; PureMathService.gsig = cgsig; 
  }

  // Fundamental Math functions
  // Blank-Check: return true if val = (NaN, '', null, undefined, false), BUT return false if val == 0 or anything else
	public static blankchk(v: any): boolean { return (typeof v == 'undefined' || isNaN(v) || v === '' || v === ' ' || v === null || v === false ); } 
	// blankCheck: null, undefined, false, '', ' ' , non-numeric, all considered blank (true) but not Infinity, not zero. Zero and Infinity are not blank.
  public static bchk(v: any): boolean { return this.bchk(v); }
  public static blankchk0(v: any): boolean { if (v===0 || v==='0') return true; return this.bchk(v); } // add 0 or '0' to bchk list
  public static bchk0(v: any): boolean { return this.bchk0(v); } // add 0 or '0' to bchk list
  
  public static isNumeric(n: any): boolean { return !isNaN(n) && isFinite(n); }
  
  // proximity check // threshold=0.001 for 0.1% threshold, compare percent difference except...
  public static pchk(n1: any, n2: any, threshold: number =null) { 
    n1=Number(n1);n2=Number(n2); 
    if (n1==0&&n2==0) return true;
    threshold = (threshold==null || threshold==0)? Math.pow(10,-4) : threshold;
    const d: number=Math.abs(n1-n2);
    if (n1==0||n2==0) { return (d<=threshold)?true:false; }
    return ((2*d/Math.abs(n1+n2))<=threshold)?true:false; 
  } // proximityCheck
  public static plchk(n1: any,n2: any,threshold: number=null) { n1=Number(n1);n2=Number(n2); return ( n1<n2 || this.pchk(n1,n2,threshold) ); } // less than or appromimately equal check
  public static pgchk(n1: any,n2: any,threshold: number=null) { n1=Number(n1);n2=Number(n2); return ( n1>n2 || this.pchk(n1,n2,threshold) ); } // greater than or appromimately equal check

  // jsonCopy(arg: any): object { return JSON.parse(JSON.stringify( arg )); } // only properties, no methods or other info copied
  // parse stringify will turn Infinity and NaN into null, not exactly what we want.
}
