import { Component, OnInit, Input } from '@angular/core';
import { PhysQty } from 'src/app/numeric-library/bbb/bbb.module';
// import { FigureSvg } from 'src/app/visual/figure-svg/figure-svg';
import { PureMathService } from 'src/app/numeric-library/operator/pure-math.service';
import { GlobalClipboardService } from '../../../common/scratch-pad/global-clipboard.service';

export class FigSvgSpiral7 { // no need to extend FigureSvg
  width: number; // xcenter
  height: number; // xcenter
  xc: number; // xcenter
  yc: number; // ycenter
  arcs: object[]; // array of semi-circle params: { l: level , xmin: xmin, xmax: xmax, y: this.yc , radius: r }
  angle: PhysQty;
  castTextColor: string;
  yaxisPath: string;
  xaxisPath: string;
  sectorColor: string;
  edgeColor: string;
  color: string;

  constructor(input: object) {
    // this.angleSI = input['angleSI'];
    this.angle = input['angle'];
    this.width = input['width'];
    this.height = input['height'];
    this.xc = input['xc'];
    this.yc = input['yc'];
    this.arcs = [];
    this.castTextColor = "#d959f3";
    this.yaxisPath = "M "+this.xc+","+(this.yc-100)+" v200";
    this.xaxisPath = "M "+(this.xc-100)+","+this.yc+" h200";
    this.sectorColor = "#00ff00aa";
    this.edgeColor = "#0000ff";
    this.color = "#ff0000";
  }

  get showSpiral(): boolean { return (Math.abs(this.angle.npi)>14.3 || PureMathService.pchk(this.angle.npi,0,0.001))?false:true; }  
  get showSector(): boolean { return !PureMathService.pchk(this.angle.npi,0,0.001); } 
  get path(): string {
    const nmax = ( PureMathService.pchk(0,this.angle.npi%1,0.001) ) ? Math.ceil(Math.abs(this.angle.npi)+0.5) : Math.ceil(Math.abs(this.angle.npi));
    let r = 66; // outer radius for maximum allowed 7 loops => i=14
    for (let i=14; i>-1;i--) {
      r=r-Math.min( (16-i)/2,4 ); // change in radii ranges from 1.5 to 4 px. Goes from 65-63.5-61.5-59-56-52.5---
      if (i==nmax-1) { this.arcs.unshift( { l:i , xmin: (this.xc-r), xmax: (this.xc+r), y: this.yc , radius: r } );
      } else if (i < nmax-1) { 
        if (i%2==0) { this.arcs.unshift( { l:i , xmin: this.arcs[0]['xmin'], xmax: (this.arcs[0]['xmin']+2*r), y: this.yc , radius: r } ); }
        else { this.arcs.unshift( { l:i , xmin: (this.arcs[0]['xmax']-2*r), xmax: this.arcs[0]['xmax'], y: this.yc , radius: r } ); }
      }
    }

    let path: string = "M "+this.arcs[0]['xmax']+","+this.arcs[0]['y'];
    let thisx=0; let lastx = 0; let thisy=0; // let lasty=0; 
    for (let i=0;i<nmax;i++) { 
      if (i==nmax-1) { 
        if (i%2==0) { 
          thisx= (this.xc+this.arcs[i]['radius']*Math.cos((this.angle.npi%1)*Math.PI)); 
          thisy= (this.yc-this.arcs[i]['radius']*Math.sin((this.angle.npi%1)*Math.PI));
        } else { 
          thisx= (this.xc-this.arcs[i]['radius']*Math.cos((this.angle.npi%1)*Math.PI));
          thisy= (this.yc+this.arcs[i]['radius']*Math.sin((this.angle.npi%1)*Math.PI)); 
        }
      } else { 
        if (i%2==0) { 
          thisx= this.arcs[i]['xmin'];
          thisy= this.arcs[i]['y']; 
        } else {
          thisx= this.arcs[i]['xmax'];
          thisy= this.arcs[i]['y']; 
        }
      }
      if (PureMathService.pchk(thisx,lastx,0.001)) continue; // skip if new one point is same as last. This sometimes happen when angle is right on x-axis, subject to rounding

      path+=" A "+this.arcs[i]['radius']+" "+this.arcs[i]['radius'];
      if (i==nmax-1 || i%2==0) { 
        if (this.angle.npi >=0) { path+=" 1 0 0 "; } else 
        if (this.angle.npi <0) { path+=" 0 0 1 "; }
      } else if (i%2==1) { 
        if (this.angle.npi >=0) { path+=" 0 1 0 "; } else 
        if (this.angle.npi <0) { path+=" 0 0 1 "; }
      } 
      // else {
      //   if (this.angle.npi >=0) { path+=" 1 0 0 "; } else 
      //   if (this.angle.npi <0) { path+=" 0 0 1 "; }
      // }
      path += thisx+" "+thisy;
      lastx = thisx;
      // lasty = thisy; // y never change here, except maybe last point.
    }
    return path;
  }
  // angle sector
  get sectorX2(): number { return (this.xc+100*Math.cos(this.angle.valueSI)); }
  get sectorY2(): number { return (this.yc-100*Math.sin(this.angle.valueSI)); }
  get sectorPath(): string { 
    const radius = 11; // should be smaller than the innermost circle
    let d:string ="M "+this.xc+" "+this.yc+" h "+radius+" A "+radius+" "+radius;
    d += (this.angle.quad<3)?" 1 0 0 " : " 0 0 1 ";
    d += (this.xc+radius*Math.cos(this.angle.valueSI))+" "+(this.yc-radius*Math.sin(this.angle.valueSI));
    d += " z";
    return d;
  }

}

@Component({
  selector: 'app-unicon-angle-figure-svg',
  templateUrl: './unicon-angle-figure-svg.component.svg',
  styleUrls: ['./unicon-angle-figure-svg.component.scss']
})
export class UniconAngleFigureSvgComponent implements OnInit {
  // private _angleSI: number;
  @Input() angle : PhysQty;
  @Input() figwidth : number;
  @Input() figheight : number;
  spiral: FigSvgSpiral7;

  constructor(private _gcb: GlobalClipboardService) { }
  // constructor(private _gpu: GlobalPhysUnitsService, private _gcb: GlobalClipboardService) { 
 
  ngOnInit() {
    this.spiral = new FigSvgSpiral7({angle: this.angle, width: this.figwidth, height: this.figheight, xc:100, yc:100});
    // this.spiral.angle = this.angle;

    // move CAST boxes
    let castBoxes = []; 
    let maxcastboxl = 0;
    "cast".split("").forEach(function(v){ 
      const eid="svgTextCAST"+v;
      castBoxes.push( { name: v, elemID: eid, txtElem: document.getElementById(eid), box: (document.getElementById(eid) as any).getBBox(), x: 0, y: 0 } );
      maxcastboxl = Math.max(maxcastboxl,(document.getElementById(eid) as any).getBBox().width,(document.getElementById(eid) as any).getBBox().height);
    });
    maxcastboxl = 0.7*maxcastboxl; // this is roughly the half distance of the max diagonal length of the max box size.
    castBoxes.forEach(function(o){
      let mv="translate("+(100-o.box.width/2)+","+(maxcastboxl-72+o.box.height/2)+")";
      if (o.name=='c'){mv="rotate(135 100 100) "+mv; }else if (o.name=='a'){mv="rotate(45 100 100) "+mv; }else if (o.name=='s'){mv="rotate(-45 100 100) "+mv; }else if (o.name=='t'){mv="rotate(-135 100 100) "+mv; }
      o.txtElem.setAttribute('transform', mv);
    });
    // move quadrant label boxes
    let quadBoxes = [];
    let maxquadboxl = 0;
    ["&#8547;","&#8544;","&#8545;","&#8546;"].forEach(function(v,i){
      const qid="svgTextQuad"+i; // 0 corresponds to quadrant 4. (use mod 4)
      quadBoxes.push( { name: v, elemID: qid, txtElem: document.getElementById(qid), box: (document.getElementById(qid) as any).getBBox(), x: 0, y: 0 } )
      maxquadboxl = Math.max(maxquadboxl,(document.getElementById(qid) as any).getBBox().width,(document.getElementById(qid) as any).getBBox().height);
    });
    // maxquadboxl = 0.5*maxquadboxl; // this is roughly the half distance of the max diagonal length of the max box size.
    quadBoxes.forEach(function(o,i){
      let mv="translate("+(100+50*(1-2*Math.floor(i/2))-o.box.width/2)+","+(60+50*(1-2*Math.floor((i+1)%4/2))+o.box.height/2)+")";
      o.txtElem.setAttribute('transform', mv);
    });
    
  }

  addPcon(pc: PhysQty): void { this._gcb.spad.addPqty(pc); }

}
