import { Component, OnInit } from '@angular/core';
import { unitDropdownList, GlobalPhysUnitsService, PhysConShell, SiPrefix, PhysDimComp, CatDropdownOption } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { Vector2d } from 'src/app/numeric-library/tensor/tensor.module';
import { GlobalClipboardService } from '../../../common/scratch-pad/global-clipboard.service';
import { SnackBarService, SnackBarMsg } from 'src/app/service/facts/snack-bar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { PhysQty } from 'src/app/numeric-library/bbb/bbb.module';
import { PrefixDropListPipe } from 'src/app/pipe/common-pipe/prefix-drop-list.pipe';
import { PfxUnitDispPipe } from 'src/app/pipe/common-pipe/pfx-unit-disp.pipe';
import { FactoryService } from 'src/app/service/facts/factory.service';
import { Factsier, Factsheet } from 'src/app/service/facts/facts.module';
import { MonologService } from 'src/app/service/monolog-box/monolog.service';
import { MonologComponent } from 'src/app/service/monolog-box/monolog/monolog.component';

interface vector2dReadOnly { // control which components to be read only, and will also set highlight color
  x: boolean; // x fpr rectangular
  y: boolean; // y for rectangular
  r: boolean; // r for cylindrical (or spherical)
  p: boolean; // phi for cylindrical (or spherical)
}

@Component({
  selector: 'app-vec2d-sum',
  templateUrl: './vec2d-sum.component.html',
  styleUrls: ['./vec2d-sum.component.scss']
})
export class Vec2dSumComponent implements OnInit {
  readonly compRect = ['x','y']; //  [{name: 'x', html:'<i>x</i>'}, {name: 'y', html:'<i>y</i>'}];
  readonly compPolar = ['r','p']; // [{name: 'r', html:'<i>r</i>'}, {name: 'p', html:'</i>φ</i>'} ];
  readonly comp4 = this.compRect.concat(this.compPolar); // [{name: 'x', html:'<i>x</i>'}, {name: 'y', html:'<i>y</i>'}, {name: 'r', html:'<i>r</i>'}, {name: 'p', html:'</i>φ</i>'} ];
  readonly comp3 = this.compRect.concat(this.compPolar[0]);  //[{name: 'x', html:'<i>x</i>'}, {name: 'y', html:'<i>y</i>'}, {name: 'r', html:'<i>r</i>'}];
  readonly colorHiLite = [ 'white', '#ffff0099', '#e9a0e960' ]; // ['white','#ffff0099','#55dd1190']; // rgba(255,255,0,0.6) is #ffff0099, with 99 about 0.60 alpha in hex  // 'none' doesn't work. needs 'white'  // FDC5C6 253197198
  readonly colorVector = [ 'rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(105, 212, 64, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)' ];
  // readonly colorVectorLite = [ 'rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(105, 212, 64, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)' ];
  instructionHtml: string;
  catA: CatDropdownOption[]; // example- { pdimId:8, title="luminous intensity / luminous flux : [Lum Int]", label:"luminous intensity", comp:{M:0, L:0, T:0, t:0, td:0, N:0, I:0, J:0} }
  vecAngUnitA: unitDropdownList[]; // vec1UnitA, vec1UnitA are dynamic, vecAngUnitA is static constant
  get vecUnitA(): unitDropdownList[] { return this._gpu.physUnitAList4dropdown({pdimId: this.vecs[0].x.pdimId}); } // using getter will refresher model in ngFor loops etc.

  // global state var
  vecs: Vector2d[]; // The first one is always the sum. Also use to keep track of all dimensions and prefix, etc. set max length??
  readonlys: vector2dReadOnly[]; // keep tracks of which ones are input, which ones are calculated

  constructor(private _gpu: GlobalPhysUnitsService, private _gcb: GlobalClipboardService, private _sbs: SnackBarService, private _mono: MonologService, private _facts: FactoryService) { }

  ngOnInit() {
    // set up factsier/report and factsheet for the reveal module
    this._facts.addFactsier( new Factsier({factsier: {id:6, name:'2dVectorSum', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: '2D Vector Addition', topicA: ['linearalgebra'] }));
    this._facts.bkmark.factsier = {id:6, name:'2dVectorSum'}; // either one should do. name is the overriding criteria

    this.instructionHtml = "<br><ol><li>Some units are traditionally for scalars (not vectors), such as temperature K. Other units, for example, Joules which is the same as Newton-meter, can be for scalars (energy) or vectors (torque). You need to distinguish such usages clearly yourself.</li>   <li>If you plan to add vectors of different units, you can set one at a time. When a overall unit is changed, all the present values will be changed according, allowing you to enter different vectors with the units you desire.</li>   <li>For the dropdown options, most browswers will show more info (title) if you click-point-and-wait on the option manual for a second or so.</li></ol>";
    this.vecAngUnitA = this._gpu.physUnitAList4dropdown({pdimId: 1});
    this.catA = this._gpu.physDimAList; // for dropdown manual
    this.catA.shift(); // remove first undefined element

    // global state var
    const svars = this._gcb.stateCfgs.g('2dVectorSum');
    if (svars) {
      this.vecs = <Vector2d[]>svars.vars.vecs;
      this.readonlys = <vector2dReadOnly[]>svars.vars.readonlys;
    } else {
      const vpuid = 14;
      const vcat = this._gpu.findPhysUnit({punitId: vpuid})['pdimId']; // const vcat = 4;
      const vsys = this._gpu.findPhysUnit({punitId: vpuid})['systemA'][0]; // these are from the dropdown list
      const vunit = this._gpu.findPhysUnit({punitId: vpuid})['unitSI']; // these are from the dropdown list

      let x1 = new PhysQty(<PhysConShell>{ value: -1, pdimId: vcat, system: vsys, unit: vunit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: vpuid });
      let y1 = new PhysQty(<PhysConShell>{ value: 2, pdimId: vcat, system: vsys, unit: vunit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: vpuid });
      let x2 = new PhysQty(<PhysConShell>{ value: 3, pdimId: vcat, system: vsys, unit: vunit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: vpuid });
      let r2 = new PhysQty(<PhysConShell>{ value: 5, pdimId: vcat, system: vsys, unit: vunit, prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true, punitId: vpuid });

      this.vecs = [];
      this.vecs.push( new Vector2d({x: x1.dcopy(), y: y1.dcopy()})); // this first one is dummy. Will be overwritten with findVsum
      this.vecs.push( new Vector2d({x: x1, y: y1}));
      this.vecs.push( new Vector2d({x: x2, r: r2}));
      this.findVsum(); // set this.vecSum for the first time.

      this.readonlys = [];
      this.readonlys.push(<vector2dReadOnly>{x:true, y:true, r:true, p:true}); // for vecsum, never change
      this.readonlys.push(<vector2dReadOnly>{x:false, y:false, r:true, p:true});
      this.readonlys.push(<vector2dReadOnly>{x:false, y:true, r:false, p:true});

      this._gcb.stateCfgs.list.push({stateName:'2dVectorSum', vars:{ vecs: this.vecs, readonlys: this.readonlys } });
    }

  }

  get pfxDropList(): SiPrefix[] {
    const pipe: PrefixDropListPipe = new PrefixDropListPipe();
    return pipe.transform(this.vecs[0].x);
  }

  revealInstructions(): MatDialogRef<MonologComponent>  { return this._mono.openlog({ override: {title: 'Vector Module Instructions', message: this.instructionHtml} }); }

  private _vecValidChk(vec:Vector2d): boolean { return (vec.x && vec.y && vec.r && vec.p && vec.x.value!==null && vec.y.value!==null && vec.r.value!==null && vec.p.value!==null && vec.p.valueSI >=0 && vec.p.valueSI < 2*Math.PI) ; }

  solveVec(i: number): boolean {
    if (i<1) { return false; }
    const vec = this.vecs[i];
    this.comp4.forEach(function(c:string){ if (this.readonlys[i][c]){ this.vecs[i][c].setNewValueSI(0); this.vecs[i][c].value = null; } }.bind(this)); // reset all calcuated values to start
    if (vec.x && vec.x.value!==null && vec.y && vec.y.value!==null && vec.x.pdimId == vec.y.pdimId) {
      vec.setWRectangular2D();
      this.readonlys[i]={x:false, y:false, r:true, p:true};
      return true;
    }
    if (vec.r && vec.r.value!==null && vec.p && vec.p.pdimId == 1 && vec.p.value !==null && vec.p.value>=0 && vec.p.valueSI<2*Math.PI) {
      vec.setWCylindrical2D();
      this.readonlys[i]={x:true, y:true, r:false, p:false};
      return true;
    }
    if (vec.x && vec.x.value!==null && vec.r && vec.r.value!==null && vec.x.pdimId == vec.r.pdimId) {
      if (Math.abs(vec.x.valueSI) > Math.abs(vec.r.valueSI)) {
        this._sbs.now( <SnackBarMsg>{ importance: 9, message: "The vector you entered has x component longer than the radial r. Please check your entries.", action: "Impossible", duration: 5000 });
        this.readonlys[i]={x:false, y:false, r:false, p:false};
        return false;
      }
      vec.setWrx2D();
      this._sbs.now( <SnackBarMsg>{ importance: 8, message: "From the x and r components, there could be in general two possible configurations. We will assume the vector to be in quadrant I or II (i.e. positive y). You might want to use another combination to enter the info.", action: "Warning", duration: 5000 });
      this.readonlys[i]={x:false, y:true, r:false, p:true};
      return true;
    }
    if (vec.y && vec.y.value!==null && vec.r && vec.r.value!==null && vec.y.pdimId == vec.r.pdimId) {
      if (Math.abs(vec.y.valueSI) > Math.abs(vec.r.valueSI)) {
        this._sbs.now( <SnackBarMsg>{ importance: 9, message: "The vector you entered has y component longer than the radial r. Please check your entries.", action: "Impossible", duration: 5000 });
        this.readonlys[i]={x:false, y:false, r:false, p:false};
        return false;
      }
      vec.setWry2D();
      this._sbs.now( <SnackBarMsg>{ importance: 8, message: "From the y and r components, there could be in general two possible configurations. We will assume the vector to be in quadrant I or IV (i.e. positive x). You might want to use another combination to enter the info.", action: "Warning", duration: 5000 });
      this.readonlys[i]={x:true, y:false, r:false, p:true};
      return true;
    }
    if (vec.x && vec.x.value!==null && vec.p && vec.p.pdimId == 1 && vec.p.value!==null && vec.p.value>=0 && vec.p.valueSI<2*Math.PI) {
      vec.setWxphi2D();
      this.readonlys[i]={x:false, y:true, r:true, p:false};
      return true;
    }
    if (vec.y && vec.y.value!==null && vec.p && vec.p.pdimId == 1 && vec.p.value!==null && vec.p.value>=0 && vec.p.valueSI<2*Math.PI) {
      vec.setWyphi2D();
      this.readonlys[i]={x:true, y:false, r:true, p:false};
      return true;
    }

    this._resetReadOnlys(i); // ={x:false, y:false, r:false, p:false};
    return false;
  }

  private _resetReadOnlys(i: number): void { this.readonlys[i] = (i<1) ? {x:true, y:true, r:true, p:true} : {x:false, y:false, r:false, p:false}; return; }
  private _resetAllReadOnlys(): void { for(let i=0; i<this.vecs.length; i++){ this._resetReadOnlys(i);}; return; }

  findVsum(): void {
    // refresh all vectors (except the sum)
    // for(let i=1; i<this.vecs.length; i++){ this.solveVec(i); }
    let sum: Vector2d;
    const oangpuid = this.vecs[0].p.punitId;
    this.vecs.forEach(function(vec:Vector2d, i:number){
      if (i==0) {sum = vec.dcopy().adds(vec,true); } else // start from zero everytime with vecs[0] - vecs[0]
      if ( this._vecValidChk(vec) ) { sum.adds(vec); }
    }.bind(this));
    sum.setWRectangular2D();
    sum.p.chgSysUnitID(<PhysConShell>{punitId: oangpuid }); // default angle from vector2d is SI.
    this.vecs[0] = sum.dcopy();
    this.vecs[0].x.name = 'v_x'; this.vecs[0].x.nameHtml = '<i>v<sub>x</sub></i>';
    this.vecs[0].y.name = 'v_y'; this.vecs[0].y.nameHtml = '<i>v<sub>y</sub></i>';
    // this.vecs[0].r.name = 'r'; this.vecs[0].r.nameHtml = '<i>r</i>';
    // this.vecs[0].p.name = 'phi'; this.vecs[0].p.nameHtml = '<i>&phi;</i>';
    return;
  }

  catChg(): void {
    let vs = this.vecs;
    let pu = this._gpu.findPhysUnitA({pdimId: vs[0].x.pdimId})[0];
    let oval: number;
    this.vecs.forEach(function(vec:Vector2d){
      vec.dimId = pu.pdimId;
      ['x','y','r'].forEach(function(c:string){
        oval=vec[c].value;
        vec[c].update({pdimId: pu.pdimId, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) } );
        vec[c].setPrefixVals(0); // reset all prefixes to 0
        vec[c].setNewValueSI(); // leave numerical SI values unchanged
        // this[c].setNewValue(this[c].value); // force DOM to refresh
        if (oval===null) { vec[c].value=null; }
      });
    })
    return;
  }

  unitChg(): void {
    const p=this.vecs[0].x.prepower;
    const puid = this.vecs[0].x.punitId;
    let oval: number;
    this.vecs.forEach(function(vec:Vector2d){['x','y','r'].forEach(function(c:string){
      oval=vec[c].value;
      vec[c].setPrefixVals(p);
      vec[c].chgSysUnitID(<PhysConShell>{punitId: puid });
      if (oval===null) { vec[c].value=null; }
    }); })
    this.findVsum(); // shouldn't change the sum though.
    return;
  }

  angUnitChg(): void {
    const puid = this.vecs[0].p.punitId;
    this.vecs[0].p.chgSysUnitID(<PhysConShell>{punitId: puid });
    let oval: number;
    this.vecs.forEach(function(vec:Vector2d, i:number){
      oval=vec.p.value;
      vec.p.chgSysUnitID(<PhysConShell>{punitId: puid });
      if (oval===null) { vec.p.value=null; }
    })
    return;
  }

  prefixChg(): void {
    const p=this.vecs[0].x.prepower;
    let oval: number;
    this.vecs.forEach(function(vec:Vector2d){['x','y','r'].forEach(function(c:string){
      oval=vec[c].value;
      vec[c].setPrefixVals(p);
      vec[c].setNewValueSI();
      if (oval===null) { vec[c].value=null; }
    }); })
    return;
  }

  valueChg(i: number, comp: string, event: any): void {
    const val = event.target.value;
    // const val: number = Number(event.target.value);
    let tmx: object; // theta max is pi
    // let pmx: number = 0; // phi min/max is +/- pi

    this.comp4.forEach(function(c:string){ if (this.readonlys[i][c]){ this.vecs[i][c].setNewValueSI(0); this.vecs[i][c].value = null; } }.bind(this)); // reset all calcuated values,

    if (comp=='r' || comp=='o') { // only r in 2-D
      if (val<0) {
        // event.target.value=0;
        // this.vecs[i][comp].value=0;
        this._sbs.now( <SnackBarMsg>{ importance: 9, message: "In 2D, we allow raidial component r to be negative just to make change of coordinate easier. In general, try to avoid using that, so that we can have a more conventional representation of the vector.", action: "Warning", duration: 5000 });
      }
    } else
    if (comp=='p') { // for p, set sbar
      tmx = this.pivalue; // multiple 2
      if (val<0 || val >= tmx['value']) { // should instead use proximity check?
        const nval = (( (val/tmx['value'])%1 + 1)%1)*tmx['value'] ; // use %1 twice and +1 to handle negative values
        event.target.value=nval;
        this.vecs[i].p.setNewValue(nval);
        this.vecs[i].p.value =nval; // force refresh display
        this._sbs.now( <SnackBarMsg>{ importance: 8, message: "Our choice of φ should be within [0,"+tmx['html']+") here. We replaced your input with the equivalent angle instead.", action: "Warning", duration: 5000 });
      }
    }
    this.vecs[i][comp].setNewValue(event.target.value); // event.target.value might have been fixed in above

    if (val==''||val===null) { // for chrome, blank is '', not null.
      this.readonlys[i][comp] = false;
      this.vecs[i][comp].setNewValueSI(0); this.vecs[i][comp].value = null;
      event.target.value = null;
    }

    this.solveVec(i);
    this.findVsum();
  }

  get pivalue(): object {
    const res = {value:0, html:''};
    // const dg:number = AppInjector.get(GlobalDigitService).gDigit -1;
    const c = this.vecs[0].p.punitId;  // 1-rad, 2-degree, 3-pi
    if (c==2) { res.value = 360; res.html="360"; }
    else if (c==3) { res.value = 2; res.html="2"; }
    else { res.value = 2*Math.PI; res.html = "2π"; } // if (c==1) // res.value = Math.round((10**dg)*2*Math.PI)/(10**dg); // set values with sig. figs
    return res;
  }

  resetVals(): void {
    this.vecs.forEach(function(vec:Vector2d){ ['x','y','r','p'].forEach(function(c:string){ vec[c].setNewValue(0); vec[c].value=null; }); })
    this._resetAllReadOnlys();
    return;
  }


  headerDisp(c:string): string {
    // comp c = x, y, r, or p(phi)
    const puid = (c=='p'||c=='t')? this.vecs[0].p.punitId : this.vecs[0].x.punitId; // t for theta in 3D, just in case.
    if (c=='p' || c=='t') {
      if (puid==2) { return 'φ (°)'; } else if (puid==3) { return 'φ (π)';} else { return 'φ (rad)'; }
    }
    const pud: PfxUnitDispPipe = new PfxUnitDispPipe();
    return '<i>'+c+'</i> ('+ pud.transform(this.vecs[0].x) +')';
  }

  // chkRonly(i:number, c:object): boolean { return (c&&c['name'])? this.readonlys[i][c['name']] : true; }

  pickHiLite(i: number, comp:string): string {
    if (i==0) return this.colorHiLite[2];
    if (!this.readonlys[i][comp]) return this.colorHiLite[0];
    return this.colorHiLite[1];
  }

  get insertVec(): void {
    if (this.vecs.length >7) {
      this._sbs.now( <SnackBarMsg>{ importance: 3, message: "The current version allows at most 7 vectors here for addition, as we can't more rainbow colors to paint it. We hope to find some better way to lift the limit in the future.", action: "Info", duration: 3000 });
      return;
    }
    const newvec = this.vecs[0].dcopy();
    newvec.comps4.forEach(function(c:string){newvec[c].setNewValueSI(0); newvec[c].value=null; })
    this.vecs.push(newvec);
    this.readonlys.push({x:false, y:false, r:false, p:false});
    return;
  }

  deleteVec(i:number): void {
    this._sbs.now( <SnackBarMsg>{ importance: 3, message: "Deleting a vector cannot be undone. You can copy it to the scratch pad first if you need it later.", action: "Info", duration: 3000 });
    this.vecs.splice(i,1); this.readonlys.splice(i,1);
    this.findVsum();
    return;
  }

  get sumButton(): void {
    this._sbs.now( <SnackBarMsg>{ importance: 10, message: "This is the sum of all the complete/valid vectors entered.", action: "Success!", duration: 3000 });
    return;
  }

  paste2input(i:number,comp:string): void {
    let spadlen = this._gcb.spad.list.length;
    if ( spadlen<1 ) {
      this._sbs.now( <SnackBarMsg>{ importance: 10, message: "Clipboard is empty. Nothing to paste.", action: "Error", duration: 4000 });
      return;
    }

    // check compatible category/dimension
    const cb = this._gcb.spad.list[spadlen-1];
    if (cb.pdimId != this.vecs[i][comp].pdimId) {
      this._sbs.now( <SnackBarMsg>{ importance: 10, message: "Clipboard element is not of the same dimenion/category. Please fix.", action: "Error", duration: 5000 });
      return;
    }

    // all prefix/units are tied to vec[0].  Need to change that first. The sum will be re-calculated

    this.vecs[i][comp].update(cb);
    // change unit for all others together

    this._sbs.now( <SnackBarMsg>{ importance: 4, message: "Paste from clipboard successful. Unit is reset to SI.", action: "Success", duration: 4000 });

    this.valueChg(i, comp, { target: {value: cb.value} } );
    return;
  }

  addPqty(pq: PhysQty): void {  this._gcb.spad.addPqty(pq);  }

}
