import { Component, OnInit } from '@angular/core';
import { PhysQty } from 'src/app/numeric-library/bbb/bbb.module';
import { PureMathService } from 'src/app/numeric-library/operator/pure-math.service';
import { PhysConShell, GlobalPhysUnitsService, unitDropdownList, siPrefixes, PhysDimComp, SiPrefix, CatDropdownOption } from 'src/app/numeric-library/invariant/global-phys-units.service';
import { GlobalClipboardService } from '../../../common/scratch-pad/global-clipboard.service';
import { Triangle } from 'src/app/numeric-library/geometry/geometry.module';
import { EeeeService } from 'src/app/service/eqns/eeee.service';
import { FactoryService } from 'src/app/service/facts/factory.service';
import { Factsheet, Factsier, Factum, FactIdty } from 'src/app/service/facts/facts.module';
import { AppInjector } from 'src/app/app-injector';
import { SnackBarService, SnackBarMsg } from 'src/app/service/facts/snack-bar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ValueUnitDispPipe } from 'src/app/pipe/common-pipe/value-unit-disp.pipe';
import { AngleDispPipe } from 'src/app/pipe/common-pipe/angle-disp.pipe';
import { ValueDispPipe } from 'src/app/pipe/common-pipe/value-disp.pipe';
import { PrefixDropListPipe } from 'src/app/pipe/common-pipe/prefix-drop-list.pipe';
import { MonologService } from 'src/app/service/monolog-box/monolog.service';
import { MonologComponent } from 'src/app/service/monolog-box/monolog/monolog.component';
import { ValueRoundPipe } from 'src/app/pipe/common-pipe/value-round.pipe';
// import { TriangleFigureSvgComponent } from 'src/app/visual/figure-svg/triangle-figure-svg/triangle-figure-svg.component';

export class TriangleSolver {
  //   readonly comp6, name, nameHtml, dimId, a, b, c, A, B, C, exist
  // readonly colorScheme = {highlight: '#ffff0099', highlight2: '#55dd1190', otherborder: '#ff0000' };
  // readonly colorhighlight = '#ffff0099';
  readonly colorHiLite = [ 'white', '#ffff0099', '#e9a0e960' ]; // ['white','#ffff0099','#55dd1190']; // rgba(255,255,0,0.6) is #ffff0099, with 99 about 0.60 alpha in hex  // 'none' doesn't work. needs 'white'  // FDC5C6 253197198
  private _eeee: EeeeService;
  private _gpu: GlobalPhysUnitsService;
  private _sbs: SnackBarService;
  private _vudp: ValueUnitDispPipe;
  private _vdp: ValueDispPipe;
  private _adp: AngleDispPipe;
  private _vrp: ValueRoundPipe;
  private _gcb: GlobalClipboardService;

  solnsheet: Factsheet;
  // angle unit conversions
  angUnitA: unitDropdownList[]; // { return this._gpu.physUnitAList4dropdown({pdimId: 1}); }
  // general unit conversions // get pqUnitA(): unitDropdownList[] { return this._gpu.physUnitAList4dropdown( { pdimId: this.given.dimId } ); } // eg. pdimId: 20, Force

  // name: string;
  // nameHtml: string;
  given: Triangle; // can be impossible, not exist
  // validCnts: number[];
  tclass: { type: string, solveBy: string, vmap: string }; // type: SSSAAA, SAAAxx, etc, solveBy: four options SSS, SAS, SAA, or SSA(multiple answers), vmap (variable map): bAc (for SAS) etc.
  solnAlt: Triangle; // if exist, is the alternative solution, must be a valid triangle
  solnCnt: number;
  solnType: string; // unique, non-exist, insufficient, scalable, extreme(straight line), modified (if over constrained like SSSAxx)

  inpNull: {a:boolean,b:boolean,c:boolean,A:boolean,B:boolean,C:boolean}; // track which ones are null/not-touched/clean, which ones are non-null/touched/dirty

  inpReadonly: {a:boolean,b:boolean,c:boolean,A:boolean,B:boolean,C:boolean};
  // eqnColor: {v1:string,v2:string,a:string,t:string,d:string};

  constructor(factsheet?: Factsheet, input?: Triangle ) {
    this._eeee = AppInjector.get(EeeeService);
    this._gpu = AppInjector.get(GlobalPhysUnitsService);
    this._sbs = AppInjector.get(SnackBarService);
    this._gcb = AppInjector.get(GlobalClipboardService);
    this._vdp = new ValueDispPipe();
    this._vudp = new ValueUnitDispPipe();
    this._adp = new AngleDispPipe();
    this._vrp = new ValueRoundPipe();
    this.solnsheet = factsheet;
    this.angUnitA = this._gpu.physUnitAList4dropdown({pdimId: 1});

    this.inpNull = {a:true,b:true,c:true,A:true,B:true,C:true}; // initialize
    this.tclass = { type: '', solveBy: '', vmap: '' }; // initialize
    this.inpReadonly = {a:false,b:false,c:false,A:false,B:false,C:false}; // will be set after attempt to solve/classify

    // global state var
    const svars = this._gcb.stateCfgs.g('sineCosineLawTriangle');
    if (svars) {
      this.given = <Triangle>svars.vars.givenTri;
      this.inpNull = svars.vars.inpNull; // need inpNull info to setInpNulls()
      const inull = this.inpNull;
      Object.keys(this.inpNull).forEach(function(k:string){
        if (inull[k]) { svars.vars.givenTri[k].value = null; } // this.given will also be changed.
      });
      this.classify2solve(); // added this line to force the figures to appear after switching away and back to this page
    } else {
      const pcomp = {T:-1,L:1}; // {M:0,T:-1,L:1,t:0,N:0,I:0,J:1,td:0} // velocity m/s
      const angcomp = {T:0}; // {M:0,T:0,L:0,t:0,N:0,I:0,J:1,td:0} // angle
      const a=(input && input.a) ? input.a : new PhysQty(<PhysConShell>{ name: 'a', nameHtml: '<i>a</i>', value: 4, comp: pcomp, system: 'SI', unit: 'm/s', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true });
      const b=(input && input.b) ? input.b : new PhysQty(<PhysConShell>{ name: 'b', nameHtml: '<i>b</i>', value: 5, comp: pcomp, system: 'SI', unit: 'm/s', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true });
      const c=(input && input.c) ? input.c : new PhysQty(<PhysConShell>{ name: 'c', nameHtml: '<i>c</i>', value: 3, comp: pcomp, system: 'SI', unit: 'm/s', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true });
      c.setNewValue(0); c.value=null;
      const A=(input && input.A) ? input.A : new PhysQty(<PhysConShell>{ name: 'A', nameHtml: '<i>A</i>', value: 30, comp: angcomp, system: 'SI', unit: '°', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true });
      // A.setNewValue(0); A.value=null;
      const B=(input && input.B) ? input.B : new PhysQty(<PhysConShell>{ name: 'B', nameHtml: '<i>B</i>', value: 60, comp: angcomp, system: 'SI', unit: '°', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true });
      B.setNewValue(0); B.value=null;
      const C=(input && input.C) ? input.C : new PhysQty(<PhysConShell>{ name: 'C', nameHtml: '<i>C</i>', value: 90, comp: angcomp, system: 'SI', unit: '°', prepower: 0, valueMin: null, valueMax: null, stdErr: 0, exact: true });
      C.setNewValue(0); C.value=null;
      this.given = new Triangle( { a: a, b: b, c: c, A: A, B: B, C: C } );

      this._gcb.stateCfgs.list.push({stateName:'sineCosineLawTriangle', vars:{ givenTri: this.given, inpNull: this.inpNull } });

      this._setInpNulls(); // set appropriate null values here // can also integrate this into the steps above
      // let s = this._setInpNulls(); // set appropriate null values here // can also integrate this into the steps above
      this.classify(); // will set ttype/tclass, inpReadonly ???
      // this.eqnColor = {a:'white',b:'white',c:'white',A:'white',B:'white',C:'white'};
      this.solve();
    }

    // // let s = this._setInpNulls(); // set appropriate null values here // can also integrate this into the steps above
    // this.classify(); // will set ttype/tclass, inpReadonly ???
    // // this.eqnColor = {a:'white',b:'white',c:'white',A:'white',B:'white',C:'white'};
    // this.solve();
  }

  // readonly pfxDropList = siPrefixes.copylist(); //: SiPrefix[];
  pfxDropList(pq: PhysQty): SiPrefix[] {
    const pipe: PrefixDropListPipe = new PrefixDropListPipe();
    return pipe.transform(pq);
  }

  // angle unit conversions //  angUnitA: unitDropdownList[];  { return this._gpu.physUnitAList4dropdown({pdimId: 1}); }
  // general unit conversions
  get pqUnitA(): unitDropdownList[] { return this._gpu.physUnitAList4dropdown( { pdimId: this.given.dimId } ); } // eg. pdimId: 20, Force

  private _setInpNullSides(): void { // use this before calculating any deduced results
    const giv = this.given; const inull = this.inpNull;
    giv.comp3s.forEach(function(v:string){inull[v]=(!giv[v] || giv[v].value ===null)?true:false;}); // the valueSI might be 0, but value = null, which indicates the input is actually null.
    return;
  }
  private _setInpNullAngles(): void { // use this before calculating any deduced results
    const giv = this.given; const inull = this.inpNull;
    giv.comp3a.forEach(function(v:string){inull[v]=(!giv[v] || giv[v].value ===null)?true:false;}); // the valueSI might be 0, but value = null, which indicates the input is actually null.
    return;
  }
  private _setInpNulls(): void { this._setInpNullSides(); this._setInpNullAngles(); return; }

  private _resetReadonly(): void { this.inpReadonly= {a:false,b:false,c:false,A:false,B:false,C:false}; return; }
  // private _resetInpNull(): void { this.inpNull = {a:true,b:true,c:true,A:true,B:true,C:true}; return; }
  // this.eqnColor={v1:'white',v2:'white',a:'white',t:'white',d:'white'}; // 'none' doen't work.

  // resetVals(): void {
  //   this.solnCnt = 0;
  //   const giv=this.given;
  //   // reset all units
  //   // TODO
  //   // reset all prefixes
  //   giv.comp6.forEach(function(v:string){giv[v].setPrefixVals(0);});
  //   // reset all six values, and SI values
  //   giv.comp6.forEach(function(v:string){giv[v].setNewValue(0);giv[v].value=null;});
  //   this._resetInpNull(); this._resetReadonly();
  //   return;
  // }

  pickHiLite(comp:string,soln:number=1): string {
    if (!this.inpReadonly[comp]) return this.colorHiLite[0];
    soln=(soln==2)?2:1; // make sure it's either 1 or
    return this.colorHiLite[soln];
  }


  catChg(): void {
    const giv = this.given;
    const c = giv.dimId;
    const pu = this._gpu.findPhysUnitA({pdimId: c})[0];
    const inull = this.inpNull;
    const sbs = this._sbs;
    giv.comp3s.forEach(function(cp){
      // pdimId, punitId, systemA, system, unitA, unit, categoryA, category, unitSI, compDisp, cu2si, cu2siInt, level, prefixes, errors,
      // might need to override unitA[0] from "" to "rad" for angle measures. And category from "dimensionless" to "angle", etc.
      giv[cp].update({pdimId: c, punitId: pu.punitId, unitA: [].concat(pu.unitA), unit: pu.unitA[0], systemA: [].concat(pu.systemA), system: pu.systemA[0], categoryA: [].concat(pu.categoryA), category: pu.categoryA[0], unitSI: pu.unitSI, compDisp: pu.compDisp, cu2si: pu.cu2si, cu2siInt: pu.cu2siInt, comp: new PhysDimComp(pu.comp) });
      giv[cp].setPrefixVals(0); // set prefixes to 0
      giv[cp].setNewValueSI(); // leave numerical SI values unchanged, so that the angles will be preserved
      sbs.now( <SnackBarMsg>{ importance: 4, message: "New category/dimesion set. The sides are set to the default SI unit. The original values in SI was left unchanged.", action: "Success", duration: 4000 });
      if(inull[cp]){giv[cp].setNewValue(0); giv[cp].value=null;}
    });
    giv.comp3a.forEach(function(cp){if(inull[cp]){giv[cp].setNewValue(0); giv[cp].value=null;} });

    this.classify2solve(); // change of cat will force all values to SI, which might change the result.
    return;
  }

  valueChg(comp: string, event): void {
    let giv = this.given;
    let inull = this.inpNull;
    let ov: number = giv[comp].value;
    let nv = event.target.value;
    let errmsg = '';
    let vdp = this._vdp;
    giv[comp].setNewValue(nv); // this will change the valueSI and everything.
    if (giv.comp3s.includes(comp)) {
      if (nv<0) {
        errmsg = "Side "+comp+" cannot have negative values. It has been reset to ";
        errmsg += (ov)? "the original value of "+vdp.transform(ov)+"." : "null.";
        this._sbs.now( <SnackBarMsg>{ importance: 6, message: errmsg, action: "Fail", duration: 4000 });
        if (ov) { giv[comp].setNewValue(ov); event.target.value = this._vrp.transform(ov); }
        else { giv[comp].setNewValue(0); giv[comp].value=null; event.target.value = ''; }
      }
    } else
    if (giv.comp3a.includes(comp)) {
      if (giv[comp].valueSI < 0 || giv[comp].valueSI > Math.PI )  {
        errmsg = "∠"+comp+" must be between zero and 𝜋 (180º). It has been reset to ";
        errmsg += (ov)? "the original value of "+vdp.transform(ov)+"." : "null.";
        this._sbs.now( <SnackBarMsg>{ importance: 7, message: errmsg, action: "Fail", duration: 4000 });
        if (ov) { giv[comp].setNewValue(ov); event.target.value = this._vrp.transform(ov); }
        else { giv[comp].setNewValue(0); giv[comp].value=null; event.target.value = ''; }
      } else {
        let asum = 0;
        giv.comp3a.forEach(function(s:string){
          // if s== comp, count
          // if inpNull true and s != comp, don't count
          // if .value === null, don't count
          asum+=( s==comp || (!inull[s] && giv[s].value) ) ? giv[s].valueSI : 0;
        });
        if (!PureMathService.plchk(asum,Math.PI,0.00001)) {
          errmsg = "The sum of two given angles must 𝜋 (180º) or less. (The sum of the given angles was "+vdp.transform(asum)+" instead.) ∠"+comp+" is now reset to ";
          errmsg += (ov)? "the original value of "+vdp.transform(ov)+"." : "null.";
          this._sbs.now( <SnackBarMsg>{ importance: 9, message: errmsg, action: "Fail", duration: 6000 });
          if (ov) { giv[comp].setNewValue(ov); event.target.value = this._vrp.transform(ov); }
          else { giv[comp].setNewValue(0); giv[comp].value=null; event.target.value = ''; }
        }
      }
    }
    if (nv==''||nv===null || giv[comp].value === null ) { // for chrome, blank is '', not null.
      this.inpNull[comp] = true;
      giv[comp].value = null;
      this._resetReadonly();
    } else { this.inpNull[comp] = false;
    }
    // reset all calcuated values
    giv.comp6.forEach(function(v:string){ if(inull[v]&& v!=comp){giv[v].setNewValue(0); giv[v].value=null;}} );

    // now solve, regardless. the solver will handle different number of inputs differently
    this.tclass = { type: '', solveBy: '', vmap: '' }; // reset
    this.classify2solve();

    return;
  }

  unitChg(comp: string): void {
    const giv = this.given;
    let inull = this.inpNull;

    this.prefixChg(comp); // in case it's changed as some non-SI units force prefix to 0.
    giv[comp].chgSysUnitID(<PhysConShell>{punitId: giv[comp]['punitId'] });
    if ( this.solnCnt == 2 ) {
      this.solnAlt[comp].chgSysUnitID(<PhysConShell>{punitId: giv[comp]['punitId'] });
    }
    if (inull[comp] && !this.inpReadonly[comp]) giv[comp].value = null; // if inpNull is true, AND not readonly (calculated results showing), keep blank input blank, otherwise will show as zero.

    // need to solve again, as that will change the units used in the solution sheet
    // reset all calcuated values
    giv.comp6.forEach(function(v:string){ if(inull[v]&& v!=comp){giv[v].setNewValue(0); giv[v].value=null;}} );
    // now solve, regardless. the solver will handle different number of inputs differently
    this.tclass = { type: '', solveBy: '', vmap: '' }; // reset
    this.classify2solve();

    return;
  }
  prefixChg(comp: string): void {
    const giv = this.given;
    let inull = this.inpNull;

    giv[comp].setPrefixVals(giv[comp].prepower);
    giv[comp].setNewValueSI();
    if ( this.solnCnt==2 ) { // change altenative solution accordingly if applicable
      this.solnAlt[comp].setPrefixVals(giv[comp].prepower);
      this.solnAlt[comp].setNewValueSI();
    }
    if (inull[comp] && !this.inpReadonly[comp]) giv[comp].value = null; // if inpNull is true, AND not readonly (calculated results showing), keep blank input blank, otherwise will show as zero.

    // need to solve again, as that will change the units used in the solution sheet
    // reset all calcuated values
    giv.comp6.forEach(function(v:string){ if(inull[v]&& v!=comp){giv[v].setNewValue(0); giv[v].value=null;}} );
    // now solve, regardless. the solver will handle different number of inputs differently
    this.tclass = { type: '', solveBy: '', vmap: '' }; // reset
    this.classify2solve();
    return;
  }

  classify(): void {
    // valid Cnt:
    // 6: SSSAAA   method: SAS
    // 5: SSSAA    method: SAS
    // 5: SSAAA    method: SAS
    // 4: SSSA     method: SAS

    // 4: SASA     method: SAA
    // 4: SSAA     method: SAA
    // 4: SAAA     method: SAA

    // 3: SSS
    // 3: SAS
    // 3: SSA   (non-unique)
    // 3: SAA
    // 3: ASA

    // 3: AAA   no solution
    // 2: AA    no solution
    // 2: SA    no solution
    // 2: SS    no solution
    // 1: S     no solution
    // 1: A     no solution

    const bichk = this.basicInputChk();
    if (!bichk) { return; }

    // let s = this._setInpNulls();
    this._resetReadonly(); // set inpReadonly to false, and inpColor to 'white'

    const giv = this.given;
    const vvcnt = giv.validValCnt;
    const vscnt = giv.validSideCnt;
    const tcl = this.tclass;
    const inpread = this.inpReadonly;
    const ssf = this.solnsheet.factA;

    // Cnt == 6
    if (vvcnt ==6) {
      tcl.type = 'SSSAAA'; tcl.solveBy = 'SSS'; tcl.vmap = 'abcABC';
      giv.comp3a.forEach(function(s:string){ inpread[s] = true; });
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Classification: All three sides and three angles are given. We assume SSS case, and compute the three angles as unknowns just to confirm. SSS admits only one solution as long as the triangular inequality is satisfied.',
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));
      return;
    }

    // string[]
    // ['a','b','c'].filter(function(s){return ['a','c'].indexOf(s) == -1;}) // getting the difference of two arrays
    // ['a','b','c'].filter( x => !['a','c'].includes(x)); // getting the difference of two arrays
    const vsides = giv.vsides; // valid sides
    const nsides = giv.comp3s.filter(x=>!vsides.includes(x)); // null sides
    const vangles = giv.vangles; // valid angles
    const nangles = giv.comp3a.filter(x=>!vangles.includes(x)); // null angles
    const sapairs = giv.vsides.filter(x=>vangles.includes(x.toUpperCase())); // common side+angle pair, resulting array, lowercase letters
    // Cnt == 5
    if (vvcnt ==5 && vscnt ==3 ) {
      tcl.type = 'SSSAA'; tcl.solveBy = 'SSS'; tcl.vmap = 'abc'+vangles.join(''); // abcBC, abcAC, abcBC
      giv.comp3a.forEach(function(s:string){inpread[s] = true; });
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Classification: Three sides and two angles are given. We can easily calculate the third angle from the equation for sum of triangle interior angles being 𝜋 (180º). We will however assume SSS case, and compute the three angles as unknowns to confirm. Other options are also possible. SSS admits only one solution as long as the triangular inequality is satisfied.',
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));
      return;
    }
    if (vvcnt ==5 && vscnt ==2 ) {
      tcl.type = 'SSAAA'; tcl.solveBy = 'SAS';
      tcl.vmap = vsides[0] + nsides[0].toUpperCase() + vsides[1] + vsides.join('').toUpperCase(); // bAcBC, aBcAC, aCbAB
      inpread[vsides[0].toUpperCase()] = true;
      inpread[nsides[0]] = true;
      inpread[vsides[1].toUpperCase()] = true;
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Classification: Two sides and three angles are given. These are overconstrained, and we need to confirm the given values are consistent. To that goal, we choose to assume the SAS case ("+ giv[vsides[0]].nameHtml +"-"+ giv[nsides[0].toUpperCase()].nameHtml +"-"+ giv[vsides[1]].nameHtml + "), and compute the remaining three quantities to confirm. Other options are also possible. Notice that SAS always admit one unique solution.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));
      return;
    }
    // Cnt == 4
    if (vvcnt ==4 && vscnt ==3 ) {
      tcl.type = 'SSSA'; tcl.solveBy = 'SSS'; tcl.vmap = 'abc'+vangles[0]; // abcA, abcB, abcC
      giv.comp3a.forEach(function(s:string){inpread[s] = true; });
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Classification: Three sides and one angle are given. We can actually calculate all three angles from SSS, and confirm the given angle is indeed consistent that way. Other options are also possible. SSS admits only one solution as long as the triangular inequality is satisfied.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));
      return;
    }
    if (vvcnt ==4 && vscnt ==1 ) {
      tcl.type = 'SAAA'; tcl.solveBy = 'SAA';
      const lst=nsides[1].toUpperCase();
      tcl.vmap = vsides[0] + nsides[0].toUpperCase() + vsides[0].toUpperCase() + lst ; // aCAB, bABC, cACB, etc
      nsides.forEach(function(s:string){inpread[s]=true;});
      inpread[lst]=true;
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Classification: Three angles and one side are given. We should quickly check the three angles are consistent with sum of interior angles being 𝜋 (180º). Afterwards, we can then solve the rest using SAA, which always only admit one unique solution.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));
      return;
    }
    if (vvcnt ==4 && vscnt ==2 ) { // SSAA or SASA, there will be at least one pair aA or bB or cC both present
      tcl.type = 'SSAA'; tcl.solveBy = 'SAA'; // no need to distinguish SSAA and SASA here.
      if (sapairs.length == 2) {
        tcl.vmap = sapairs[0]+sapairs[1].toUpperCase()+sapairs[0].toUpperCase()+sapairs[1]; // aBAb, aBAc, bABa. bABc, ...
        inpread[sapairs[1]]=true;
      } else { // length == 1
        const lst=nangles[0].toLowerCase();
        tcl.vmap = sapairs[0]+nsides[0].toUpperCase()+sapairs[0].toUpperCase()+lst; //
        inpread[lst]=true;
      }
      inpread[nsides[0]]=true;
      inpread[nangles[0]]=true;
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Classification: Two sides and two angles are given. These are overconstrained: we only need three. We can use either SAS, SAA or ASA to solve the triangle completely, and check if the fourth piece of information is at least consistent. The fourth possibility of using SSA is not recommended, as it admits up to 2 distinct solutions. We will use SAA here, since no matter which combinations of 2 sides and 2 angles are given, we can always find at least one side-angle pair, which is all we need in the SAA solution.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));
      return;
    }
    // Cnt == 3
    if (vvcnt ==3 && vscnt ==3 ) {
      tcl.type = 'SSS'; tcl.solveBy = 'SSS'; tcl.vmap = 'abc';
      giv.comp3a.forEach(function(s:string){inpread[s] = true; });
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: 'Classification: All three sides are given. We will solve the triangle using SSS rule, which admits only one solution as long as the triangular inequality is satisfied.',
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));
      return;
    }
    if (vvcnt ==3 && vscnt ==0 ) { // not solvable
      tcl.type = 'AAA'; tcl.solveBy = 'AAA'; tcl.vmap = 'ABC';
      // reset last one as null, and use calculated value instead. Same as case AA
      inpread['C']=true; this.inpNull['C']=true;
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Classification: Only three angles are given - AAA. Such combination, even if consistent with the sum of triangle interior angles being 𝜋 (180º), this only gives us a scalable triangle of arbitrary size. We need at least one side length to 'fix' the triangle.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));
      return;
    }
    if (vvcnt ==3 && vscnt ==1 ) { // SAA or ASA
      if (sapairs.length ==1) {
        tcl.type = 'SAA'; tcl.solveBy = 'SAA';
        tcl.vmap = (vsides[0].toUpperCase() == vangles[0])? vsides[0]+vangles[1]+vangles[0] : vsides[0]+vangles[0]+vangles[1];
        ssf.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
          sysDesc: "Classification: With two angles and one side, this can be either SAA or ASA situation, depending on whether the given side is between the two angles (ASA)or not (SAA). We have angles &ang;"+giv[tcl.vmap[1]].nameHtml+" and &ang;"+giv[tcl.vmap[2]].nameHtml+" given, and side "+giv[tcl.vmap[0]].nameHtml +". This gives us the SAA scenario. Both SAA and ASA admits an unique solution.",
          title: '', // end FactBasic
          fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
          importance: 10,
          eqn: <FactIdty>{}
        }));
      } else
      if (sapairs.length ==0) {
        tcl.type = 'ASA'; tcl.solveBy = 'ASA';
        tcl.vmap = vangles[0]+vsides[0]+vangles[1];
        ssf.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
          sysDesc: "Classification: With two angles and one side, this can be either SAA or ASA situation, depending on whether the given side is between the two angles (ASA)or not (SAA). We have angles &ang;"+giv[tcl.vmap[0]].nameHtml+" and &ang;"+giv[tcl.vmap[2]].nameHtml+" given, and side "+giv[tcl.vmap[1]].nameHtml +". This gives us the ASA scenario. Both SAA and ASA admits an unique solution. The two can be solved similarly, since if one knows two angles, the third one can be calculated easily.",
          title: '', // end FactBasic
          fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
          importance: 10,
          eqn: <FactIdty>{}
        }));
      }
      nsides.forEach(function(s:string){inpread[s]=true;});
      inpread[nangles[0]]=true;
      return;
    }
    if (vvcnt ==3 && vscnt ==2 ) { // SSA or SAS
      if (sapairs.length ==1) {
        tcl.type = 'SSA'; tcl.solveBy = 'SSA';
        tcl.vmap = (vsides[0].toUpperCase() == vangles[0])? vsides[0]+vsides[1]+vangles[0] : vsides[1]+vsides[0]+vangles[0];
        ssf.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
          sysDesc: "Classification: With two sides and one angle, this can be either SSA or SAS situation, depending on whether the given angle is between the two sides (SAS-unique solution)or not (SSA-up to 2 solutions). We have &ang;"+giv[tcl.vmap[2]].nameHtml+" given as well as side "+giv[tcl.vmap[0]].nameHtml+" and side "+giv[tcl.vmap[1]].nameHtml +". This gives us the SSA scenario (admits up to 2 distinct solutions).",
          title: '', // end FactBasic
          fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
          importance: 10,
          eqn: <FactIdty>{}
        }));
      } else
      if (sapairs.length ==0) {
        tcl.type = 'SAS'; tcl.solveBy = 'SAS';
        tcl.vmap = vsides[0]+vangles[0]+vsides[1];
        ssf.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
          sysDesc: "Classification: With two sides and one angle, this can be either SSA or SAS situation, depending on whether the given angle is between the two sides (SAS-unique solution)or not (SSA-up to 2 solutions). We have &ang;"+giv[tcl.vmap[1]].nameHtml+" given as well as side "+giv[tcl.vmap[0]].nameHtml+" and side "+giv[tcl.vmap[2]].nameHtml +". This gives us the SAS scenario (gives an unique solution).",
          title: '', // end FactBasic
          fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
          importance: 10,
          eqn: <FactIdty>{}
        }));
      }
      nangles.forEach(function(s:string){inpread[s]=true;});
      inpread[nsides[0]]=true;
      return;
    }
    // Cnt == 2
    if (vvcnt ==2 && vscnt ==0 ) { // AA // find the last angle
      tcl.type = 'AA'; tcl.solveBy = 'AA'; tcl.vmap = vangles.join('');
      inpread[nangles[0]]=true;
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Classification: Two angles are given (AA), which is as good as three (AAA). Just use the sum of triangle interior angles being 𝜋 (180º), we can find the last angle right away. But that still only give us a scalable triangle of arbitrary size. We need at least one side length to 'fix' the triangle.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{}
      }));
      return;
    }
    if (vvcnt ==2 && vscnt ==2 ) { tcl.type = 'SS'; tcl.solveBy = 'SS'; tcl.vmap = vsides.join(''); return; }
    if (vvcnt ==2 && vscnt ==1 ) { tcl.type = 'SA'; tcl.solveBy = 'SA'; tcl.vmap = vsides[0]+vangles[0]; return; }
    // Cnt == 1
    if (vvcnt ==1 && vscnt ==1 ) { tcl.type = 'S'; tcl.solveBy = 'S'; tcl.vmap = vsides[0]; return; }
    if (vvcnt ==1 && vscnt ==0 ) { tcl.type = 'A'; tcl.solveBy = 'A'; tcl.vmap = vangles[0]; return; }

    return;
  }

  classify2solve(): void {
    this.solnsheet.factA = <Factum[]>[]; // start a fresh sheet
    this.classify();
    this.solnCnt = this.basicSolnCnt(); // preliminary check
    if (this.tclass.solveBy.length>2 || this.tclass.solveBy == 'AA') { this.solve(); }
    return;
  }

  basicInputChk(): boolean {
    const giv = this.given;

    giv.comp3s.forEach(function(s:string){
      if (giv[s].valueSI < 0) {
        giv[s].setNewValue(0);
        giv[s].value=null;
        this._resetReadonly();
        this._sbs.now( <SnackBarMsg>{ importance: 4, message: "Side "+s+" cannot be negative.", action: "Fail", duration: 4000 });
        return false;
      }
    }.bind(this)); // check all lengths >=0
    giv.comp3a.forEach(function(s:string){
      if (giv[s].valueSI < 0 || giv[s].valueSI > Math.PI) {
        giv[s].setNewValue(0);
        giv[s].value=null;
        this._resetReadonly();
        this._sbs.now( <SnackBarMsg>{ importance: 4, message: "∠"+s+" must be between zero and 𝜋 (180º).", action: "Fail", duration: 4000 });
        return false;
      }
    }.bind(this)); // check all 0<= angle <= Pi

    // all checked
    return true;
  }

  basicSolnCnt(): number {
    const tineq = this._eeee.findEqn('eqTriangularInequality'); // (a,b,c,n)
    // const tintsumtest = this._eeee.findEqn('eqTriangleInteriorSumTest'); // (A,B,C,n)
    // const tintsum = this._eeee.findEqn('eqTriangleInteriorSum'); // (A,B)
    const giv = this.given;
    const tcl = this.tclass;
    const vm = tcl.vmap;
    let cnt = 0;

    if (tcl.type=='AAA' || tcl.type=='AA' || tcl.type=='SA' || tcl.type=='SS' || tcl.type=='S' || tcl.type=='A' || tcl.type=='' || !tcl.type )
    { this.solnAlt = null; return 0; }

    if (tcl.solveBy=='SSS') {
      cnt = (tineq.eqn(giv.a,giv.b,giv.c,0))?1:0;
      this.solnAlt = null;
      if (cnt==0) {
        this._sbs.now( <SnackBarMsg>{ importance: 9, message: "Failed triangular inequality -SSS.", action: "Fail", duration: 4000 });
      }
      return cnt;
     }
    if (tcl.solveBy=='SAS' || tcl.solveBy=='SAA' || tcl.solveBy=='ASA') { this.solnAlt = null; return 1; }
    if (tcl.solveBy=='SSA') { // could be 0, 1, or 2  need to check during solve.
      // check at least the shorter side has an angle less than pi/2, SSA -> vmap = abA, etc
      if (giv[vm[0]].valueSI <= giv[vm[1]].valueSI && giv[vm[2]].valueSI > Math.PI/2) {
        this._sbs.now( <SnackBarMsg>{ importance: 9, message: "SSA - shorter side ("+vm[0]+") cannot have opposite angle ("+vm[2]+") > 𝜋/2 (90º).", action: "Fail", duration: 4000 });
        this.solnAlt = null;
        return 0;
      }
      // this.solnAlt = null; // not sure if we should clear if already exist.
      return 1.5; // can still be 0, 1, or 2
    }
  }

  solve(): void {
    // this.solnCnt = this.basicSolnCnt(); // preliminary check // now done just before solve in classify2solve
    // checks
    // longer side faces greater angle (if 2 pairs)
    // also shorter side cannot have angle > 90
    const giv = this.given;
    const tcl = this.tclass;
    const vm = tcl.vmap;
    const ssf = this.solnsheet.factA;
    const mangles = giv.comp3a.filter( x=>!vm.substr(0,3).split('').includes(x) ); // the missing angles
    const intsum = this._eeee.findEqn('eqTriangleInteriorSum'); // (A,B)
    const sbs = this._sbs;
    const vdp = this._vdp;
    const vudp = this._vudp;
    const adp = this._adp;

    if (tcl.solveBy=='AA') { // vmap: AB
      // solve third angle
      giv[mangles[0]].updateValFrom( intsum.eqn( giv[vm[0]], giv[vm[1]] ) );
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Using the sum of triangle interior angles being 𝜋 (180º), we calculated &ang;"+mangles[0]+" to be "+vudp.transform(giv[mangles[0]])+". This only specify a scalable/similarity triangle. We will need at least one side length to 'fix' the triangle completely.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 7,
        eqn: <FactIdty>{ name: intsum.eqn.name }
      }));
    } // end AA
    if (tcl.solveBy=='AAA') { // vmap: AB
      // solve third angle
      const tryangle = intsum.eqn( giv['A'], giv['B'] );
      if ( !PureMathService.pchk( tryangle.valueSI, giv['C'].valueSI, 0.00001) ) {
        // can use the angle display pipe instead.
        sbs.now( <SnackBarMsg>{ importance: 10, message: "AAA - inconsistent. The three angles do not add up to 𝜋 (180º). We updated ∠C from the other two given angles to "+vudp.transform(tryangle)+" (instead of the originally given input of "+vudp.transform(giv['C'])+"). Please double check.", action: "Fail", duration: 6000 });
        ssf.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
          sysDesc: "Using the sum of triangle interior angles being 𝜋 (180º), we checked &ang;C right away, and we found &ang;C should be "+vudp.transform(tryangle)+" instead of the given value of "+vudp.transform(giv['C'])+". We fixed it. Please double check.",
          title: '', // end FactBasic
          fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
          importance: 10,
          eqn: <FactIdty>{ name: intsum.eqn.name }
        }));
        giv['C'].updateValFrom( tryangle );
      } else {
        ssf.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
          sysDesc: "AAA - The three given angles is consistent with the sum of interior angles being 𝜋 (180º). This only specify a scalable/similarity triangle. We will need at least one side length to 'fix' the triangle completely.",
          title: '', // end FactBasic
          fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
          importance: 10,
          eqn: <FactIdty>{ name: "eqTriangleInteriorSumTest" }
        }));
      }
    } // end AAA

    // const vsides = giv.vsides; // valid sides
    // const nsides = giv.comp3s.filter(x=>!vsides.includes(x)); // null sides
    // const vangles = giv.vangles; // valid angles
    // const nangles = giv.comp3a.filter(x=>!vangles.includes(x)); // null angles
    // const sapairs = giv.vsides.filter(x=>vangles.includes(x.toUpperCase())); // common side+angle pair, resulting array, lowercase letters
    const msides = giv.comp3s.filter( x=>!vm.substr(0,3).split('').includes(x) ); // the missing sides

    // const tineq = this._eeee.findEqn('eqTriangularInequality'); // (a,b,c)
    const loc = this._eeee.findEqn('eqCosineLaw'); // (a,b,c)
    const loca = this._eeee.findEqn('eqCosineLawA'); // (a,b,c)
    const locs = this._eeee.findEqn('eqCosineLawS1'); // (A,b,c)
    // const locs2 = this._eeee.findEqn('eqCosineLawS2'); // (A,a,b)
    const los = this._eeee.findEqn('eqSineLaw'); // (a,B,A)
    const loss = this._eeee.findEqn('eqSineLawS'); // (a,B,A)
    const losa = this._eeee.findEqn('eqSineLawA'); // (a,b,A)

    // SSS
    if (tcl.solveBy=='SSS') {
      // solve
      // check triangular inequality
      // solve three angles using law of cosine

      if (this.solnCnt==0) {
        sbs.now( <SnackBarMsg>{ importance: 4, message: "Failed Triangular Inequality.", action: "Fail", duration: 4000 });
        ssf.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
          sysDesc: "The given info of the triangle is classified as SSS, but the triangular inequality of the three sides failed. The two shorter sides should add up to at least the length of the longest side.",
          title: '', // end FactBasic
          fact: <FactIdty>{ type: 'fail', id: ssf.length , note: '' }, // type: success, error, warn, info
          importance: 10,
          eqn: <FactIdty>{ name: "eqTriangularInequality" }
        }));
        return;
      }
      // solve
      let greatests = 'a';
      giv.comp3a.forEach(function(x,i){
        if(giv[giv.comp3s[i]].valueSI>giv[greatests].valueSI){ greatests=giv.comp3s[i]; };
        giv[x].updateValFrom( loca.eqn( giv[giv.comp3s[i]] , giv[giv.comp3s[(i+1)%3]] , giv[giv.comp3s[(i+2)%3]] ) );
      });
      // explain
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Solve by: SSS. First the three sides are checked to satisfy the triangular inequality.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 5,
        eqn: <FactIdty>{ name: "eqTriangularInequality" }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Next, we can solve each angle using the law of cosine equation: "+loca.info.eqnHtml,
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 7,
        eqn: <FactIdty>{ name: loca.eqn.name }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Notice that when taking inverse-cosine, it returns a unique angle between 0 and &pi; if exist. Unlike the inverse-sine which gives one in auadrant I, and one in auadrant II in general. Both of which could be possible angles of a triangle.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 5,
        eqn: <FactIdty>{ name: loca.eqn.name }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "You can also gain a slight adventage here to first solve for the largest of the three angles first, which always faces the longest side. The reason is that to solve the second and third angles, if one chooses to use the law of sine instead (which is simpler on a old-fashion calculator), we can be sure the two smaller angles can never be greater than 𝜋/2 (90º) in a triangle. There will be no need to check or verify the inverse-sine solution in quadrant II.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "As an example, we first find the longest here to be side "+giv[greatests].nameHtml+", and law of cosine gives the &ang;"+greatests.toUpperCase()+" to be "+vdp.transform(giv[greatests.toUpperCase()])+".",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 5,
        eqn: <FactIdty>{ name: loca.eqn.name }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "We can then solve for the next angle (any one of them) with law of sine, and the final one typically by the law of interior angle sum being &pi; (180&deg;).",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 5,
        eqn: <FactIdty>{ }
      }));
    } // end SSS

    // ASA
    if (tcl.solveBy=='ASA') { // vmap: AbC, etc
      // solve third angle
      giv[mangles[0]].updateValFrom( intsum.eqn( giv[vm[0]], giv[vm[2]] ) );
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "ASA - "+giv[vm[0]].nameHtml+giv[vm[1]].nameHtml+giv[vm[2]].nameHtml+". We should be able to calculate the last unknow angle (∠"+giv[mangles[0]].nameHtml+") from the interior angle sum being 𝜋 (180º) for triangles. With the given info, we found ∠"+giv[mangles[0]].nameHtml+" to be "+vudp.transform(giv[mangles[0]])+".",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: intsum.eqn.name }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Notice that it is crucial to calculate this third angle right away, as we need the values for a side-angle pair in order to apply the law of sine equation. We now have the pair ∠"+giv[mangles[0]].nameHtml+" and side "+giv[mangles[0].toLowerCase()].nameHtml+".",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: intsum.eqn.name }
      }));
    // use sine law twice to find the two unknown sides, msides[0] and msides[1]
      giv[msides[0]].updateValFrom( loss.eqn(  giv[vm[1]] , giv[msides[0].toUpperCase()] , giv[vm[1].toUpperCase()] ) ); // to find b, need to put in a, B, A
      giv[msides[1]].updateValFrom( loss.eqn(  giv[vm[1]] , giv[msides[1].toUpperCase()] , giv[vm[1].toUpperCase()] ) ); // to find b, need to put in a, B, A
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Next, we can apply the law of sine ("+los.info.eqnHtml+") to calculate the two unknown sides "+giv[msides[0]].nameHtml+" (="+vudp.transform(giv[msides[0]])+") and "+giv[msides[1]].nameHtml+" (="+vudp.transform(giv[msides[1]])+") respectively.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 7,
        eqn: <FactIdty>{ name: loss.eqn.name }
      }));
    } // end ASA

    // SAA
    if (tcl.solveBy=='SAA') { // vmap: aBA, aCA
      giv[vm[1].toLowerCase()].updateValFrom( loss.eqn(  giv[vm[0]] , giv[vm[1]] , giv[vm[0].toUpperCase()] ) ); // to find b, need to put in a, B, A
      // giv[msides[1]].updateValFrom( loss.eqn(  giv[vm[0]] , giv[msides[1].toUpperCase()] , giv[vm[0].toUpperCase()] ) ); // to find b, need to put in a, B, A
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "SAA - "+giv[vm[0]].nameHtml+giv[vm[1]].nameHtml+giv[vm[2]].nameHtml+". Since side "+giv[vm[0]].nameHtml+" and ∠"+giv[vm[2]].nameHtml+" are both given as the side-angle pair, we can compute the unknown side "+giv[vm[1].toLowerCase()].nameHtml+" by law of sine ("+los.info.eqnHtml+") directly. After that, we can calculate the last unknown angle with the simple interior angle sum being 𝜋 (180º) for triangles, and repeat the law of sine to find the corresponding side.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Now apply the law of sine to find side "+giv[vm[1].toLowerCase()].nameHtml+" from the given "+giv[vm[0]].nameHtml+", ∠"+giv[vm[1]].nameHtml+", and ∠"+giv[vm[2]].nameHtml+". We found "+giv[vm[1].toLowerCase()].nameHtml+" = "+vudp.transform(giv[vm[1].toLowerCase()])+".",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: loss.eqn.name }
      }));
      // solve third angle
      giv[mangles[0]].updateValFrom( intsum.eqn( giv[vm[1]], giv[vm[2]] ) );
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Next find the last angle ∠"+giv[mangles[0]].nameHtml+" from interior angle sum equation. And found ∠"+giv[mangles[0]].nameHtml+" = "+vudp.transform(giv[mangles[0]])+".",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 4,
        eqn: <FactIdty>{ name: intsum.eqn.name }
      }));
      // use sine law twice to find the two unknown sides, msides[0] and msides[1]
      giv[mangles[0].toLowerCase()].updateValFrom( loss.eqn(  giv[vm[0]] , giv[mangles[0]] , giv[vm[0].toUpperCase()] ) ); // to find b, need to put in a, B, A
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Last step is to use the law of sine again, to find side "+giv[mangles[0].toLowerCase()].nameHtml+" this time. The result is "+giv[mangles[0].toLowerCase()].nameHtml+" = "+vudp.transform(giv[mangles[0].toLowerCase()])+".",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 5,
        eqn: <FactIdty>{ name: loss.eqn.name }
      }));
    } // end SAA

    // SAS
    if (tcl.solveBy=='SAS') { // vmap: aBc, aCb, etc
      // solve right away
      giv[msides[0]].updateValFrom( locs.eqn( giv[vm[1]], giv[vm[0]] , giv[vm[2]] ) );
      // use sine law to find the SMALLER angle first, which MUST BE < pi/2, then use interior sum to find last one.
      // OR
      // use cosine law angle form to solve both. (missing angles and the given sides should be the same)
      let fil: string;
      mangles.forEach(function(x,i){ fil = 'abc'.replace(x.toLowerCase(),''); giv[x].updateValFrom( loca.eqn( giv[x.toLowerCase()] , giv[fil[0]] , giv[fil[1]] ) ) });

      // explain
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "SAS - "+giv[vm[0]].nameHtml+giv[vm[1]].nameHtml+giv[vm[2]].nameHtml+". This admits a unique solution, with the known angle ∠"+giv[vm[1]].nameHtml+" <b>sandwiched</b> between the two known sides "+giv[vm[0]].nameHtml+" and "+giv[vm[2]].nameHtml+" here. A direct application of the law of cosine will solve the third unknown side "+giv[vm[1].toLowerCase()].nameHtml+" directly. Once that is found, we can find the next remaining angle from law of sine (see the next few steps), and then the last angle from the interior angle sum equation.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 7,
        eqn: <FactIdty>{ }
      }));
      // solve third side with law of cosine
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "So applying the law of cosine, we find the missing side "+giv[vm[1].toLowerCase()].nameHtml+" to be "+vudp.transform(giv[vm[1].toLowerCase()])+".",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 7,
        eqn: <FactIdty>{ }
      }));

      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Now we have all three sides and angle ∠"+giv[vm[1]].nameHtml+". In principle, we can apply the law of cosines two more times to solve for the two unknown angles. Notice that using law of cosine (and inverse cosine) to solve for an angle gives a unique result between 0 and 𝜋 (180º), which is perfect for our purpose.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 5,
        eqn: <FactIdty>{ }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Traditinoally, if we were to calculate these using an old-fashion calculator, law of sine would have been easier, although we have to deal with potentially two possible inverse-sine values.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 5,
        eqn: <FactIdty>{ name: loss.eqn.name }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "One great method is to use the law of sine to first find the smallest angle. Since we know all three sides, and the smaller angle in a triangle always sits opposite to the shortest side, we can easily pick that out, and solve for that angle. The smaller angles in a triangle cannot be greater than 𝜋/2 (90º). So we can use the law of sine knowing very well only the first quadrant solution is needed.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: loss.eqn.name }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Then the last unknown angle, should it be in quadrant I or II, can be solved simply from the interior sum of angles being 𝜋 (180º). They are found to be ∠"+giv[mangles[0]].nameHtml+" = "+vudp.transform(giv[mangles[0]])+", and ∠"+giv[mangles[1]].nameHtml+" = "+vudp.transform(giv[mangles[1]])+".",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 6,
        eqn: <FactIdty>{ name: loss.eqn.name }
      }));

    } // end SAS

    // SSA (up to 2 solutions)
    if (tcl.solveBy=='SSA') { // vmap: abA, etc
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "SSA - "+giv[vm[0]].nameHtml+giv[vm[1]].nameHtml+giv[vm[2]].nameHtml+". This is the most complicated case as there can be potentially two distinct sets of solutions under certain conditions. First notice that unlike the SAS case, the known angle ∠"+giv[vm[2]].nameHtml+" is <b>NOT</b> sandwiched between the two known sides "+giv[vm[0]].nameHtml+" and "+giv[vm[2]].nameHtml+" here. If we were to try using the law of cosine to solve for side "+giv[vm[2].toLowerCase()].nameHtml+", we will end up with solving a messy quadratic equation in "+giv[vm[2].toLowerCase()].nameHtml+".",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{ }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "To be fair, with computating power we now have, solving the messy quadratic equation from the cosine law by brute force is a piece of cake. The possible outcomes will be no solution (either the quadratic equation yields imaginary answers, or both roots are negative, which we need to reject them), one solution (if either the quadratic results in a double (positive) root, or one positive and one negative root, which we have to reject the negative), or two solutions (if the quadratic equation has two distinct positive roots).",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 8,
        eqn: <FactIdty>{ }
      }));

      // solve for B (up to two possible answers, depends. If b<=a, only 1, b>a, possibly two. )
      // losa // (a,b,A)
      const a2 = vm[1].toUpperCase(); // second angle
      giv[a2].updateValFrom( losa.eqn( giv[vm[0]] , giv[vm[1]] , giv[vm[2]] ) );
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "Let us use a more traditional approach and explore the beauty of geometry and algebra here. Since the side-angle pair "+giv[vm[0]].nameHtml+" and ∠"+giv[vm[2]].nameHtml+" is given, we can try to calculate the unknow angle ∠"+giv[a2].nameHtml+" by law of sine ("+los.info.eqnHtml+"). We have to keep it mind there could be another solution in quadrant II from the inverse-sine however.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 9,
        eqn: <FactIdty>{ name: losa.eqn.name }
      }));

      // after trying, ...
      if (!giv[a2] || giv[a2].value ===null) {sbs.now( <SnackBarMsg>{ importance: 8, message: "SSA - impossible situation. Side "+vm[0]+" is too short, or side "+vm[1]+" too long. Inverse-sine gives an imaginary result.", action: "Fail", duration: 5000 });
        this.solnCnt = 0;
        ssf.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
          sysDesc: "Turns out inverse-sine doesn't exist here. Side "+giv[vm[0]].nameHtml+" is too short, or side "+giv[vm[1]].nameHtml+" too long. The angle doesn't exist.",
          title: '', // end FactBasic
          fact: <FactIdty>{ type: 'error', id: ssf.length , note: '' }, // type: success, error, warn, info
          importance: 10,
          eqn: <FactIdty>{ }
        }));
        return;
      }

      // it does work, and obtained the first solution for the angle.
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "So we found one possible answer for angle ∠"+giv[a2].nameHtml+" to be "+vdp.transform(giv[a2].valueSI)+" or "+adp.transform(giv[a2],'d')+". If there were a second possible solution, it would be for supplementary angle for ∠"+giv[a2].nameHtml+" (𝜋 &minus; "+vdp.transform(giv[a2].valueSI)+" or 180º &minus; "+adp.transform(giv[a2],'d')+"). We will explore this possibility after we completely solve this first scenario.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 9,
        eqn: <FactIdty>{ }
      }));

      // next finish this first solution
      // find last angle using interior sum.
      giv[msides[0].toUpperCase()].updateValFrom( intsum.eqn( giv[vm[2]], giv[a2] ) );
      // it does work, and obtained the first solution for the angle.
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "The last missing angle should be solved from the interior sum being 𝜋 (180º). So angle ∠"+giv[msides[0].toUpperCase()].nameHtml+" = "+vudp.transform(giv[msides[0].toUpperCase()])+".",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 7,
        eqn: <FactIdty>{ name: intsum.eqn.name  }
      }));

      // finally, find last side
      giv[msides[0]].updateValFrom( loss.eqn( giv[vm[0]], giv[msides[0].toUpperCase()] , giv[vm[2]] ) );
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "The final piece of information for this triangle is side "+giv[msides[0]].nameHtml+", which can be solved using the sine law or cosine to be "+vudp.transform(giv[msides[0]])+". This completely solved for the first possible triangle solution.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 7,
        eqn: <FactIdty>{ name: loss.eqn.name  }
      }));
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "So is there a second possibility for ∠"+giv[a2].nameHtml+"? Recall the longer side in a triangle should face a larger angle. If ∠"+giv[a2].nameHtml+" were to take on the supplementary angle value, an obtuse angle, the corresponding side must be the longest side among the given ones.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 7,
        eqn: <FactIdty>{ }
      }));

      if ( PureMathService.plchk( giv[a2].valueSI, giv[vm[2]].valueSI ) ) {
        // the alternative answer has last angle a3 = this_a2 minus the given angle a1. Say 30-80-70, then the other possibility is 30-110-50.  (50 is 80-30 )
        ssf.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
          sysDesc: "A quick check finds the opposite. Side "+giv[a2].nameHtml+" is actually shorter than "+giv[vm[2]].nameHtml+". So we will only have one solution.",
          title: '', // end FactBasic
          fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
          importance: 7,
          eqn: <FactIdty>{ }
        }));
          this._sbs.now( <SnackBarMsg>{ importance: 6, message: "SSA - admits 0, 1, or 2 solutions. This is one of the cases with only one possible solution however. The other potential solution turns out to be not feasible.", action: "Success", duration: 6000 });
        this.solnCnt = 1;
        return;
      }

      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "So we checked and side "+giv[a2].nameHtml+" is the longer side. So let use try solve for the second possibility.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'warn', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 7,
        eqn: <FactIdty>{ name: loss.eqn.name  }
      }));
      if ( PureMathService.pchk(giv[a2].valueSI, Math.PI/2) ) {
        // a second scenario is a2 = 90.   Say 30-90-60, the other possiblity is also 30-90-60/
        ssf.push(new Factum({
          author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
          sysDesc: "It turns out ∠"+giv[a2].nameHtml+" was found to be 𝜋 (90º). The supplementary angle would be the value. This is yet another special case that we end up with only one unique solution. And in particular, a right-angled triangle.",
          title: '', // end FactBasic
          fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
          importance: 7,
          eqn: <FactIdty>{ name: loss.eqn.name  }
        }));
        this._sbs.now( <SnackBarMsg>{ importance: 6, message: "SSA - admits 0, 1, or 2 solutions. This is one of the cases with only one possible solution however. The other potential solution is identical to this right angled triangle.", action: "Success", duration: 6000 });
        this.solnCnt = 1;
        return;
      }
      //
      // next find alternative solution
      // first duplicated the givens
      this.solnCnt = 2;
      this.solnAlt = new Triangle( { a: giv.a.dcopy(), b: giv.b.dcopy(), c: giv.c.dcopy(), A: giv.A.dcopy(), B: giv.B.dcopy(), C: giv.C.dcopy() } );
      // vm.split('').forEach(function(c:string){this.solnAlt[c] = giv[c].dcopy();}.bind(this));
      // next find new angle a2
      this.solnAlt[a2] = giv[a2].dcopy();
      this.solnAlt[a2].setNewValueSI( Math.PI - giv[a2].valueSI );
      // follow methods above, find the remaining angle and side
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "The supplementary angle is easily found to be ∠"+giv[a2].nameHtml+" = "+vudp.transform(this.solnAlt[a2])+".",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 9,
        eqn: <FactIdty>{ }
      }));
      this.solnAlt[msides[0].toUpperCase()].updateValFrom( intsum.eqn( giv[vm[2]], this.solnAlt[a2] ) );
      // finally, find last side
      this.solnAlt[msides[0]].updateValFrom( loss.eqn( giv[vm[0]], this.solnAlt[msides[0].toUpperCase()] , giv[vm[2]] ) );
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "One last step, with this new angle, we will use the law of sine again to solve for the last unknow side "+this.solnAlt[msides[0]].nameHtml+" = "+vudp.transform(this.solnAlt[msides[0]])+". This is the alternative triangle solution.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'success', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 10,
        eqn: <FactIdty>{ name: intsum.eqn.name  }
      }));

      return;
    } // end SSA

    // if all cases above missed,
    if (this.solnCnt == 0) {
      ssf.push(new Factum({
        author: <FactIdty>{ uname: 'system' }, topicA: ['trignonometry','triangle','basic'], cdate: null, mdate: null, userDesc: '',
        sysDesc: "No triangle can be determined from the given specification.",
        title: '', // end FactBasic
        fact: <FactIdty>{ type: 'fail', id: ssf.length , note: '' }, // type: success, error, warn, info
        importance: 3,
        eqn: <FactIdty>{}
      }));
    return;
    }

    // others?
    return;
  } // end solve

} // end class TriangleSolver

@Component({
  selector: 'app-sine-cosine-law',
  templateUrl: './sine-cosine-law.component.html',
  styleUrls: ['./sine-cosine-law.component.scss']
})
export class SineCosineLawComponent implements OnInit {
  trig: TriangleSolver;
  instructionHtml: string;
  catA: CatDropdownOption[]; // example- { pdimId:8, title="luminous intensity / luminous flux : [Lum Int]", label:"luminous intensity", comp:{M:0, L:0, T:0, t:0, td:0, N:0, I:0, J:0} }
  // a: PhysQty;
  // b: PhysQty;
  // c: PhysQty;
  // A: PhysQty;
  // B: PhysQty;
  // C: PhysQty;
  readonly vcolor = {a:'#0000ff', b:'#00ff00', c:'#ff0000', A:'#0000ff', B:'#00ff00', C:'#ff0000'};

  constructor(private _facts: FactoryService, private _sbs: SnackBarService, private _gcb: GlobalClipboardService, private _gpu: GlobalPhysUnitsService, private _mono: MonologService ) { };

  ngOnInit() {
    // set up factsier/report and factsheet for the reveal module
    this._facts.addFactsier( new Factsier({factsier: {id:5, name:'trigonometry', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Trigonometry', topicA: ['trigs'] }));
    this._facts.bkmark.factsier = {id:5, name:'trigonometry'}; // either one should do. name is the overriding criteria
    this._facts.findFactsier({name: 'trigonometry'}).addFactsheet( new Factsheet({sheet: {id:0, name:'triangle', type: 'explanations'}, author: {id: 2, uname: 'system'}, title: 'Solving Triangle', topicA: ['trigonometry'] }));

    this.catA = this._gpu.physDimAList; // for dropdown manual
    this.catA.shift(); // remove first undefined element
    this.instructionHtml = "<br><p>We use the standard notation of using lower case variables to denote the sides, and corresponding upper (capital) case variable to label the angle <b>opposite</b> to that side.</p><p><b>Fill in three unknowns</b>, including at least <b>one side</b>, to try calculate the others as unknowns.</p><p>The methods at our disposal are <b>SSS, SAS, SAA, ASA</b> (these give a unique solution if solvable), and <b>SSA</b> (this can have up to 2 distinct solutions).</p><p>The information given should satisfy the triangular inequality as well as the sum of interior angles being &pi; (180&deg;).</p>";

    // set up Triangle object
    this.trig = new TriangleSolver(this._facts.findFactsier({name: 'trigonometry'}).findFactsheet({name: 'triangle'}) );
    console.log(`this.trig.solnCnt = ${this.trig.solnCnt}`);

  }

  revealInstructions(): MatDialogRef<MonologComponent>  {
    return this._mono.openlog({ override: {title: 'Triangle Solver Instructions', message: this.instructionHtml } });
  }

  get solved(): object { const sb = this.trig.tclass.solveBy; return (sb.length>2 && sb!='AAA')? { tf: true, by: sb } : {tf: false, by:'' }; }

  paste2input(comp:string): void {
    let spadlen = this._gcb.spad.list.length;
    if ( spadlen<1 ) {
      this._sbs.now( <SnackBarMsg>{ importance: 10, message: "Clipboard is empty. Nothing to paste.", action: "Error", duration: 4000 });
      return;
    }

    // check compatible category/dimension
    const cb = this._gcb.spad.list[spadlen-1].dcopy();
    const pq = this.trig.given[comp];
    if ( !cb.compcomp(pq) ) {
      this._sbs.now( <SnackBarMsg>{ importance: 10, message: "Clipboard element is not of the same dimenion/category. Please fix.", action: "Error", duration: 5000 });
      return;
    }

    pq.update(cb);
    // pq.updatePunits(cb);
    // pq.updateValFrom(cb);
    // pq.setPrefixVals(cb.prepower); // change the prefix if it's really non-zero here
    // this.prefixChg(c);
    this.trig.unitChg(comp); // this will change back prefix to zero by default for safety

    pq.setPrefixVals(cb.prepower); // change the prefix if it's really non-zero here
    this.trig.prefixChg(comp);

    this.trig.valueChg(comp, {target: {value: cb.value}}); // fake an $event with event.target.value

    this._sbs.now( <SnackBarMsg>{ importance: 4, message: "Paste from clipboard successful. Unit is reset to SI.", action: "Success", duration: 4000 });

    return;
  }

  addPqty(pq: PhysQty): void {  this._gcb.spad.addPqty(pq);  }
}
