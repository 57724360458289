import { Pipe, PipeTransform } from '@angular/core';
import { Vector3d } from 'src/app/numeric-library/tensor/tensor.module';
import { ValueUnitDispPipe } from './value-unit-disp.pipe';
import { AngleDispPipe } from './angle-disp.pipe';

@Pipe({ name: 'vec3dDispSph', pure: false }) // impure // Angular only detect object reference, not deep object mutation 
export class Vec3dDispSphPipe implements PipeTransform {

  transform(vec: Vector3d): string {
    const vud: ValueUnitDispPipe = new ValueUnitDispPipe();
    const ad: AngleDispPipe = new AngleDispPipe();
    return "("+vud.transform(vec.r)+", "+ad.transform(vec.t,vec.t.unit)+", "+ad.transform(vec.p,vec.p.unit)+")"; 
  }
}
