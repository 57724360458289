import { Component, OnInit } from '@angular/core';
import { GlobalPhysUnitsService, PhysConShell } from 'src/app/numeric-library/invariant/global-phys-units.service';
// import swal from 'src/assets/sweetalert.min.js';
import { GlobalClipboardService } from 'src/app/common/scratch-pad/global-clipboard.service';
import { BbbModule, PhysCon } from 'src/app/numeric-library/bbb/bbb.module';
import { GlobalPhysConstsService } from 'src/app/numeric-library/invariant/global-phys-consts.service';


@Component({
  selector: 'app-phys-constants',
  templateUrl: './phys-constants.component.html',
  styleUrls: ['./phys-constants.component.scss']
})
export class PhysConstantsComponent implements OnInit {
  pconslist: PhysCon[]; 

  constructor(private _gpc: GlobalPhysConstsService, private _gcb: GlobalClipboardService ) {
    this.pconslist = _gpc.physCons.list;
  }

  ngOnInit() {
  }

  updateSystem(): void {
    this._gpc.physCons.chgSystem(); 
  }

  addPcon(pc: PhysConShell|PhysCon): void { 
    pc = (pc instanceof PhysCon)? pc : new PhysCon(pc);
    this._gcb.spad.addPqty(pc);
  }

}

